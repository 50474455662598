import React, { useContext } from "react";

import CustomAccordion from "components/Accordion";
import { isNumberType, isStringType } from "components/Datagrid/utils/data";

import { FilterModalContext } from "../Context";
import {
  AccordionSummaryCollapsed,
  AccordionSummaryExpanded,
} from "./AccordionSummary";
import { NumberFilter } from "./NumberFilter";
import { StringFilter } from "./StringFilter";

enum FilterTypes {
  String = "string",
  Number = "number",
  Fallback = "fallback",
}

const filterTypes = {
  [FilterTypes.String]: StringFilter,
  [FilterTypes.Number]: NumberFilter,
  [FilterTypes.Fallback]: StringFilter,
};

const getType = (type: string) => {
  if (isNumberType(type)) {
    return FilterTypes.Number;
  }
  if (isStringType(type)) {
    return FilterTypes.String;
  }
  return FilterTypes.Fallback;
};

const accordionItemName = "FILTERS_CONTENT";

type Props = {
  expandedItem: string;
  setExpandedItem: (itemName: string) => void;
};

export const FiltersContent = (props: Props) => {
  const { expandedItem, setExpandedItem } = props;
  const { column } = useContext(FilterModalContext);
  const { _typeUpperCase } = column;
  const type = getType(_typeUpperCase);

  const FilterType = filterTypes[type];

  const expanded = expandedItem === accordionItemName;
  return (
    <CustomAccordion
      expanded={expanded}
      collapsedSummary={
        <AccordionSummaryCollapsed
          itemText="Filter by condition"
          testId="filter-by-accordion-toggle"
          onClick={() => setExpandedItem(accordionItemName)}
        />
      }
      expandedSummary={
        <AccordionSummaryExpanded
          itemText="Filter by condition"
          testId="filter-by-accordion-toggle"
          onClick={() => setExpandedItem("")}
        />
      }
      accordionDetails={
        <div data-testid="tab-content-filters">
          <FilterType />
        </div>
      }
    />
  );
};
