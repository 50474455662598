import { WorkspaceEngine } from "services/engines/engine.types";

import { EngineStatusToggle } from "components/EngineStatusToggle/EngineStatusToggle";
import { RecordLabel } from "components/LeftSidebar/RecordLabel";
import { RecordNode } from "components/LeftSidebar/RecordNode";

import { EngineMenu, EngineMenuActions } from "./EngineMenu";

import styles from "./styles.module.scss";

type Props = {
  engine: WorkspaceEngine;
  actions: EngineMenuActions;
};

export const EngineRow = (props: Props) => {
  const { engine, actions } = props;

  return (
    <RecordNode className={styles.node}>
      <div className={styles.node__inner}>
        <RecordLabel
          className={styles.label}
          testId={`engine-label-${engine.engineName}`}
        >
          {engine.engineName}
        </RecordLabel>
        <EngineStatusToggle engine={engine} />

        <EngineMenu
          engine={engine}
          actions={actions}
        />
      </div>
    </RecordNode>
  );
};
