import { useTranslation } from "react-i18next";

import ErrorMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ErrorMessage/ErrorMessage";

import styles from "./styles.module.scss";

type Meta = {
  name: string;
  type: string;
};

const Header = (props: { meta: Meta[] }) => {
  const { meta } = props;
  return (
    <div className={styles.header}>
      {meta.map(column => {
        return (
          <div
            className={styles.column}
            key={column.name}
          >
            {column.name}
          </div>
        );
      })}
    </div>
  );
};

type Props = {
  previewData: { data: Record<string, unknown>[]; meta: Meta[]; error: string };
};

export const PreviewTable = (props: Props) => {
  const { previewData } = props;
  const { t } = useTranslation();

  const { data, meta, error } = previewData;
  return (
    <div className={styles.table}>
      <div className={styles.table__inner}>
        {!!error && <ErrorMessage errorMessage={t(error)} />}
        {!error && (
          <>
            <Header meta={meta} />
            <div className={styles.list}>
              {data.map((row, index) => {
                return (
                  <div
                    className={styles.row}
                    key={index} // eslint-disable-line react/no-array-index-key
                  >
                    {meta.map(column => {
                      const value = row[column.name];
                      return (
                        <div
                          className={styles.column}
                          key={column.name}
                        >
                          {value as string}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
