import { SortDirection } from "@mui/material";
import classNames from "classnames";
import React, { useMemo, useRef, useState } from "react";

import { Header } from "./Header";
import { TableContext, WidthContext } from "./context";
import { ColumnType } from "./types";
import { useScroll } from "./useScroll";

import styles from "./styles.module.scss";

type Row = unknown;
type ColumnWidths = Record<string, number>;

type TableProps = {
  rowRenderer: ({
    index,
    row,
  }: {
    index: number;
    row: Row;
  }) => React.ReactNode | null;
  rows: Row[];
  columns: ColumnType[];
  className?: string;
  disableResize?: boolean;
  order?: SortDirection;
  orderBy?: string | null;
  toggleSorting?: (fieldId: string) => void;
  onScrolledToBottom?: () => void;
  minWidth?: number;
  loader?: React.ReactNode | null;
  emptyState?: React.ReactNode | null;
  dataTestId?: string;
  floatingColumn?: string;
};

export const Table: React.FC<TableProps> = React.memo(props => {
  const {
    rowRenderer,
    rows,
    columns,
    className,
    order,
    orderBy,
    toggleSorting,
    disableResize,
    minWidth,
    onScrolledToBottom,
    loader,
    emptyState,
    dataTestId,
    floatingColumn,
  } = props;

  const wrapper = useRef<HTMLDivElement | null>(null);

  useScroll({
    wrapper,
    onScrolledToBottom,
  });

  const [columnWidths, setColumnWidths] = useState<ColumnWidths>(() => {
    return columns.reduce<Record<string, number>>((acc, { id, width }) => {
      acc[id] = width;
      return acc;
    }, {});
  });

  const fullWidthColumnIndex = columns.findIndex(
    column => column.fillAvailableWidth
  );

  const columnIndex =
    fullWidthColumnIndex === -1 ? columns.length - 1 : fullWidthColumnIndex;

  const leadingWidth = useMemo(
    () =>
      columns.reduce((acc, { id }, index) => {
        if (columnIndex === index) {
          return acc;
        }
        const width = columnWidths[id];
        return acc + width;
      }, 0),
    [columns, columnWidths, columnIndex]
  );

  const finishResize = (newWidths: ColumnWidths) => {
    setColumnWidths(newWidths);
  };

  const rowElements = useMemo(
    () => rows.map((row, index) => rowRenderer({ row, index })),
    [rows, rowRenderer]
  );

  const tableContextValue = useMemo(() => {
    return {
      wrapper,
      floatingColumn,
    };
  }, [wrapper, floatingColumn]);

  const widthContextValue = useMemo(() => {
    return {
      leadingWidth,
      columnWidths,
    };
  }, [leadingWidth, columnWidths]);

  return (
    <TableContext.Provider value={tableContextValue}>
      <WidthContext.Provider value={widthContextValue}>
        <div
          className={classNames(styles.wrapper, className)}
          ref={wrapper}
          data-testid={dataTestId}
        >
          <div
            className={styles.table}
            style={{ minWidth: minWidth ? `${minWidth}px` : undefined }}
          >
            <Header
              columns={columns}
              columnWidths={columnWidths}
              finishResize={finishResize}
              disableResize={disableResize}
              order={order}
              orderBy={orderBy}
              toggleSorting={toggleSorting}
              wrapper={wrapper}
            />
            {rowElements}
            {loader}
          </div>
          {emptyState}
        </div>
      </WidthContext.Provider>
    </TableContext.Provider>
  );
});
