import { SqlRbacAction } from "services/rbac/action";

import Checkbox from "components/Checkbox";
import Tooltip from "components/Tooltip";

import styles from "./styles.module.scss";

type Props = {
  tooltip: string;
  label: string;
  disabled: boolean;
  handleSelect: () => void;
  checked: boolean;
  indeterminate: boolean;
  action: SqlRbacAction;
};

export const HeaderLabel = (props: Props) => {
  const {
    tooltip,
    disabled,
    handleSelect,
    label,
    checked,
    indeterminate,
    action,
  } = props;

  return (
    <div className={styles.table__header}>
      <Tooltip
        title={tooltip}
        placement="top"
        classes={{ tooltip: styles.tooltip }}
      >
        <div>
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            disabled={disabled}
            onClick={handleSelect}
            testId={`header-checkbox-${action}`}
          />
        </div>
      </Tooltip>
      <span className={styles.table__label}>{label}</span>
    </div>
  );
};
