import { v4 as uuidv4 } from "uuid";

import {
  DEFAULT_EXECUTION_CONTEXT,
  DEFAULT_LAYOUT,
  INITIAL_STATE,
} from "pages/DevelopWorkspace/contexts/DocumentsContext/DocumentsContext";
import {
  DocumentLayout,
  DocumentOutputTab,
  DocumentsState,
  Execution,
  ExecutionContext,
  ExecutionType,
  QueryStatement,
  QueryStatementResult,
  QueryStatementStatus,
  ScriptType,
  SortOrder,
  Statistics,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const normalizeContext = (rawContext: any): ExecutionContext => {
  const context: ExecutionContext = {
    databaseName:
      rawContext?.databaseName && typeof rawContext.databaseName === "string"
        ? rawContext.databaseName
        : DEFAULT_EXECUTION_CONTEXT.databaseName,
    engineName:
      rawContext?.engineName && typeof rawContext.engineName === "string"
        ? rawContext.engineName
        : DEFAULT_EXECUTION_CONTEXT.engineName,
    settings:
      rawContext?.settings && typeof rawContext.settings === "object"
        ? rawContext.settings
        : DEFAULT_EXECUTION_CONTEXT.settings,
  };

  return context;
};

const normalizeLayout = (rawLayout: any): DocumentLayout => {
  if (typeof rawLayout !== "object" || rawLayout === null) {
    return DEFAULT_LAYOUT;
  }

  const layout: DocumentLayout = {
    editorHeightPx:
      rawLayout?.editorHeightPx && typeof rawLayout.editorHeightPx === "number"
        ? rawLayout.editorHeightPx
        : DEFAULT_LAYOUT.editorHeightPx,
    activeOutputTab:
      rawLayout?.activeOutputTab &&
      rawLayout?.activeOutputTab in DocumentOutputTab
        ? rawLayout.activeOutputTab
        : DEFAULT_LAYOUT.activeOutputTab,
  };

  return layout;
};

const returnNumber = (input: any) => {
  return input && typeof input === "number" ? input : 0;
};

const normalizeStatistics = (rawStatistics: any): Statistics => {
  const statistics: Statistics = {
    executionTimeSec: returnNumber(rawStatistics?.executionTimeSec),
    rowsRead: returnNumber(rawStatistics?.rowsRead),
    bytesRead: returnNumber(rawStatistics?.bytesRead),
    scannedBytesCache: returnNumber(rawStatistics?.scannedBytesCache),
    scannedBytesStorage: returnNumber(rawStatistics?.scannedBytesStorage),
    timeBeforeExecution: returnNumber(rawStatistics?.timeBeforeExecution),
    timeToExecute: returnNumber(rawStatistics?.timeToExecute),
  };

  return statistics;
};

const normalizeQueryStatementResult = (
  rawResult: any
): QueryStatementResult | null => {
  if (!rawResult) return null;

  return {
    // skip data and meta
    rows: rawResult?.rows,
  };
};

const normalizeExecution = (rawExecution: any): Execution | null => {
  if (typeof rawExecution !== "object" || rawExecution === null) {
    return null;
  }

  const activeQueryStatementIndex =
    rawExecution?.activeQueryStatementIndex &&
    typeof rawExecution.activeQueryStatementIndex === "number"
      ? rawExecution.activeQueryStatementIndex
      : 0;

  const userSelectedActiveQueryStatementIndexTimestamp =
    rawExecution?.userSelectedActiveQueryStatementIndexTimestamp &&
    typeof rawExecution.userSelectedActiveQueryStatementIndexTimestamp ===
      "number"
      ? rawExecution.userSelectedActiveQueryStatementIndexTimestamp
      : 0;

  const queryStatements: QueryStatement[] =
    rawExecution?.queryStatements && Array.isArray(rawExecution.queryStatements)
      ? rawExecution.queryStatements.map(
          (rawQueryStatement: any): QueryStatement => {
            const queryStatement = {
              id:
                rawQueryStatement?.id &&
                typeof rawQueryStatement.id === "string"
                  ? (rawQueryStatement.id as string)
                  : "query_statement." + uuidv4(),
              content:
                rawQueryStatement?.content &&
                typeof rawQueryStatement.content === "string"
                  ? (rawQueryStatement.content as string)
                  : "",
              responseStatusCode:
                rawQueryStatement?.responseStatusCode &&
                typeof rawQueryStatement.responseStatusCode === "number"
                  ? (rawQueryStatement.responseStatusCode as number)
                  : null,
              status:
                rawQueryStatement?.status &&
                rawQueryStatement.status in QueryStatementStatus
                  ? (rawQueryStatement.status as QueryStatementStatus)
                  : QueryStatementStatus.cancelled,
              result: normalizeQueryStatementResult(rawQueryStatement?.result),
              error: null,
              statistics: normalizeStatistics(rawQueryStatement?.statistics),
              sourceDocLineNumber:
                rawQueryStatement?.sourceDocLineNumber &&
                typeof rawQueryStatement.sourceDocLineNumber === "number"
                  ? (rawQueryStatement.sourceDocLineNumber as number)
                  : 0,
            };
            if (rawQueryStatement.serverQueryId) {
              Object.assign(queryStatement, {
                serverQueryId: rawQueryStatement.serverQueryId,
              });
            }

            return queryStatement;
          }
        )
      : [];

  const execution: Execution = {
    executionType: ExecutionType.Query,
    activeQueryStatementIndex,
    userSelectedActiveQueryStatementIndexTimestamp,
    queryStatements,
    documentExecutionError: null, // always reset the value
  };

  return execution;
};

const normalizeDocument = (rawDocument: any): WorkspaceDocument => {
  const document: WorkspaceDocument = {
    context: normalizeContext(rawDocument?.context),
    id:
      rawDocument?.id && typeof rawDocument?.id === "string"
        ? (rawDocument.id as string)
        : "",
    script: {
      type: rawDocument?.script?.type || "",
      id: rawDocument?.script?.id || "",
    },
    selection: (rawDocument?.selection &&
    typeof rawDocument?.selection?.[0] === "number" &&
    typeof rawDocument?.selection?.[1] === "number"
      ? [rawDocument.selection[0], rawDocument.selection[1]]
      : [0, 0]) as [number, number],
    layout: normalizeLayout(rawDocument?.layout),
    execution: normalizeExecution(rawDocument?.execution),
    createdAt:
      rawDocument?.createdAt && typeof rawDocument.createdAt === "number"
        ? (rawDocument.createdAt as number)
        : Date.now(),
  };

  return document;
};

const isDocumentValid = (document: any): boolean => {
  if (
    document?.id &&
    typeof document.id === "string" &&
    document?.script?.id &&
    typeof document.script.id === "string" &&
    document?.script?.type &&
    (document.script.type === ScriptType.local ||
      document.script.type === ScriptType.remote)
  ) {
    return true;
  }

  return false;
};

const normalizeDocumentsRawState = (rawState: any): DocumentsState => {
  if (typeof rawState !== "object" || rawState === null) {
    return INITIAL_STATE;
  }

  return {
    documents:
      rawState?.documents && Array.isArray(rawState?.documents)
        ? rawState.documents.filter(isDocumentValid).map(normalizeDocument)
        : [],
    activeDocumentId: rawState?.activeDocumentId || null,
    sortOrder:
      rawState?.sortOrder === SortOrder.NewestFirst
        ? SortOrder.NewestFirst
        : SortOrder.NewestLast,
  };
};

export default normalizeDocumentsRawState;
