// fetched scripts
export type LocalScript = {
  title: string;
  content: string;
  id: string;
  createdAt: string; // timestamp or date string
};

export type RemoteScript = {
  title: string;
  content: string;
  id: string;
  createdAt: string; // timestamp or date string
};

export enum QueryStatementStatus {
  running = "running",
  pending = "pending", // default
  success = "success",
  error = "error",
  cancelled = "cancelled",
  unknown = "unknown",
}

export type Statistics = {
  executionTimeSec: number;
  rowsRead: number;
  bytesRead: number;
  scannedBytesCache?: number;
  scannedBytesStorage?: number;
  timeBeforeExecution?: number;
  timeToExecute?: number;
};

export type QueryStatementResult = {
  data?: any[];
  meta?: any[];
  rows: number;
};

export type QueryStatement = {
  content: string;
  prevStatus?: QueryStatementStatus;
  status: QueryStatementStatus;
  id: string; // ui-generated id
  responseStatusCode: number | null; // TODO do we need it?
  result: QueryStatementResult | null; // stores result data and meta. no statistics here
  error: any | null;
  statistics: Statistics | null;
  sourceDocLineNumber: number;
  explainType?: ExplainType;
  executionType?: ExecutionType;
  explain?: Partial<Record<ExplainType, any>>;
  serverQueryId?: string;
};

export enum ExplainType {
  Physical = "physical",
  Logical = "logical",
  Analyze = "analyze",
}

export enum ExecutionType {
  Query = "query",
  Explain = "explain",
}

export enum CancellationStatus {
  Cancelled = "Cancelled",
  Initiated = "Initiated",
  Pending = "Pending",
  Failure = "Failure",
}

export type Execution = {
  executionType: ExecutionType;
  activeQueryStatementIndex: number;
  userSelectedActiveQueryStatementIndexTimestamp: number;
  queryStatements: QueryStatement[];
  executionTimestamp?: number | undefined;
  // It's an error on document level, not on query statement. The field is relevant for errors that happened before document execution.
  documentExecutionError: DocumentExecutionError | null;
  cancellationStatus?: CancellationStatus;
};

export enum ScriptType {
  local = "local",
  remote = "remote",
}

export type ExecutionContext = {
  databaseName: string | Symbol;
  engineName: string;
  settings: {
    [key: string]: string;
  };
};

export type DocumentExecutionError = {
  title: string;
  description: string;
};

export enum DocumentOutputTab {
  Results = "results",
  Statistics = "statistics",
  QueryHistory = "queryHistory",
  EngineMonitoring = "engineMonitoring",
  Explain = "explain",
  Empty = "empty",
}

export type DocumentLayout = {
  editorHeightPx: number;
  activeOutputTab: DocumentOutputTab;
};

export type WorkspaceDocument = {
  context: ExecutionContext;
  id: string; // ui-generated id
  script: {
    type: ScriptType;
    id: string;
  };
  selection: [number, number];
  layout: DocumentLayout;
  execution: Execution | null;
  createdAt: number;
};

export enum SortOrder {
  NewestFirst = "newestFirst",
  NewestLast = "newestLast",
}

export type DocumentsState = {
  documents: WorkspaceDocument[];
  activeDocumentId: string | null;
  sortOrder: SortOrder;
};

export const VISIBLE_QUERY_STATEMENTS_STATUSES = [
  QueryStatementStatus.success,
  QueryStatementStatus.error,
];
