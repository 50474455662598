import { Dialog } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import WelcomeV2InlineIcon from "assets/icons/WelcomeV2InlineIcon.svg?react";
import WelcomeIllustration from "assets/images/WelcomeIllustration.svg?react";

import Button, { ButtonTemplate } from "components/Button/Button";
import Checkbox from "components/Checkbox";
import { CrossIcon } from "components/Icons";

import styles from "./WelcomeV2.module.scss";

interface Props {
  onClose: (dontShowAgain: boolean) => void;
  onLoadDataClick: (dontShowAgain: boolean) => void;
}

const WelcomeV2 = (props: Props) => {
  const { onClose, onLoadDataClick } = props;
  const { t } = useTranslation();
  const [dontShowAgain, setDontShowAgain] = React.useState(false);

  const handleClose = () => {
    onClose(dontShowAgain);
  };

  return (
    <Dialog
      open={true}
      classes={{ paper: styles.paper }}
      data-testid="welcome-v2-dialog"
      onClose={handleClose}
    >
      <div className={styles.welcomeBody}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h1
              className={styles.title}
              data-testid="welcome-v2-title"
            >
              {t("onboarding_v2.title")}
            </h1>

            <div className={styles.textContainer}>
              <p>{t("onboarding_v2.first_paragraph")}</p>
            </div>

            <div className={styles.textContainer}>
              <div className={styles.subTitle}>
                <div className={styles.icon}>
                  <WelcomeV2InlineIcon />
                </div>
                <div className={styles.description}>
                  {t("onboarding_v2.second_paragraph_header")}
                </div>
              </div>
              <p>{t("onboarding_v2.second_paragraph")}</p>
            </div>

            <div className={styles.buttons}>
              <Button
                text={t("onboarding_v2.load_data")}
                fullWidth={false}
                dataTestid="welcome-v2-load-data"
                onClick={() => onLoadDataClick(dontShowAgain)}
              />
              <Button
                text={t("onboarding_v2.later")}
                fullWidth={false}
                dataTestid="welcome-v2-later"
                template={ButtonTemplate.Ghost}
                onClick={handleClose}
              />
            </div>
          </div>

          <div className={styles.checkboxContainer}>
            <Checkbox
              checked={dontShowAgain}
              testId="welcome-v2-checkbox"
              onChange={() => {
                setDontShowAgain(!dontShowAgain);
              }}
            />
            <div
              className={styles.label}
              data-testid="welcome-v2-checkbox-label"
              onClick={() => {
                setDontShowAgain(!dontShowAgain);
              }}
            >
              {t("onboarding_v2.dont_show_again")}
            </div>
          </div>
        </div>

        <div className={styles.illustration}>
          <WelcomeIllustration />
        </div>

        <div
          className={styles.close}
          onClick={handleClose}
          data-testid="welcome-v2-close"
        >
          <CrossIcon />
        </div>
      </div>
    </Dialog>
  );
};

export default WelcomeV2;
