import {
  DocumentActionType,
  DocumentsAction,
} from "pages/DevelopWorkspace/contexts/DocumentsContext/actions.types";
import { EXECUTION_FINAL_STATUSES } from "pages/DevelopWorkspace/contexts/DocumentsContext/constants";
import getNextDocumentWithFocusTabs from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/getNextDocumentOutputTab";
import { isQueryStatementChangesValid } from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/isQueryStatementChangesValid";
import { normalizeState } from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/normalizeState";
import { setPrevStatus } from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/setPrevStatus";
import {
  CancellationStatus,
  DocumentLayout,
  DocumentOutputTab,
  DocumentsState,
  Execution,
  ExecutionContext,
  QueryStatement,
  QueryStatementStatus,
  SortOrder,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import {
  SwitchActiveOutputPayload,
  SwitchDocumentActiveQueryStatementPayload,
} from "./types";

const actionHandlers = {
  [DocumentActionType.CHANGE_SORT_ORDER]: (
    state: DocumentsState,
    payload: SortOrder
  ) => ({
    ...state,
    sortOrder: payload,
  }),
  [DocumentActionType.UPDATE_DOCUMENT_SELECTION]: (
    state: DocumentsState,
    payload: {
      documentId: string;
      selection: [number, number];
    }
  ) => {
    const { documentId, selection } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === documentId) {
          return {
            ...document,
            selection,
          };
        }

        return document;
      }),
    };
  },
  [DocumentActionType.ADD_DOCUMENT]: (
    state: DocumentsState,
    payload: WorkspaceDocument
  ) => {
    return {
      ...state,
      documents: [...state.documents, payload],
      activeDocumentId: payload.id,
    };
  },
  [DocumentActionType.REMOVE_DOCUMENT]: (
    state: DocumentsState,
    payload: string
  ) => {
    const documentIdToRemove = payload;

    const documentToRemoveIndex = state.documents.findIndex(
      document => document.id === documentIdToRemove
    );

    const removingActiveDocument =
      documentIdToRemove === state.activeDocumentId;

    const getNewActiveDocumentId = () => {
      // choose next active document
      const nextActiveDocumentIndex =
        documentToRemoveIndex === 0 ? 1 : documentToRemoveIndex - 1;
      const nextActiveDocument = state.documents[nextActiveDocumentIndex];
      const nextActiveDocumentId = nextActiveDocument
        ? nextActiveDocument.id
        : null;

      return nextActiveDocumentId;
    };

    const nextDocuments = state.documents.filter(
      document => document.id !== documentIdToRemove
    );

    return {
      ...state,
      documents: nextDocuments,
      activeDocumentId: removingActiveDocument
        ? getNewActiveDocumentId()
        : state.activeDocumentId,
    };
  },
  [DocumentActionType.REMOVE_ALL_DOCUMENTS]: (
    state: DocumentsState,
    _: undefined
  ) => {
    return {
      ...state,
      documents: [],
      activeDocumentId: null,
    };
  },
  [DocumentActionType.SET_ACTIVE_DOCUMENT]: (
    state: DocumentsState,
    payload: string
  ) => {
    return {
      ...state,
      activeDocumentId: payload,
    };
  },
  [DocumentActionType.CHANGE_DOCUMENT_CONTEXT]: (
    state: DocumentsState,
    payload: {
      documentId: string;
      context: Partial<ExecutionContext>;
    }
  ) => {
    const { documentId, context } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === documentId) {
          return {
            ...document,
            execution: document.execution
              ? {
                  ...document.execution,
                  // clear error when switching engine or database
                  documentExecutionError: null,
                }
              : null,
            context: {
              ...document.context,
              ...context,
            },
          };
        }

        return document;
      }),
    };
  },
  [DocumentActionType.INIT_DOCUMENT_EXECUTION]: (
    state: DocumentsState,
    payload: {
      documentId: string;
      execution: Execution;
    }
  ) => {
    const { documentId: executionDocumentId, execution } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === executionDocumentId) {
          return {
            ...document,
            layout: {
              ...document.layout,
              activeOutputTab: DocumentOutputTab.Results,
            },
            execution,
          };
        }

        return document;
      }),
    };
  },
  [DocumentActionType.UPDATE_DOCUMENT_QUERY_STATEMENT]: (
    state: DocumentsState,
    payload: {
      documentId: string;
      id: string;
      queryStatement: Partial<Omit<QueryStatement, "prevStatus">>;
    }
  ) => {
    const {
      documentId: queryStatementDocumentId,
      id,
      queryStatement: nextQueryStatement,
    } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === queryStatementDocumentId) {
          if (!document.execution) {
            return document;
          }

          const nextDocument = {
            ...document,
            execution: {
              ...document.execution,
              queryStatements: document.execution.queryStatements.map(
                queryStatement => {
                  if (
                    queryStatement.id === id &&
                    isQueryStatementChangesValid(
                      queryStatement,
                      nextQueryStatement
                    )
                  ) {
                    const queryStatementState = {
                      ...queryStatement,
                      ...nextQueryStatement,
                    };

                    return setPrevStatus(queryStatement, queryStatementState);
                  }

                  return queryStatement;
                }
              ),
            },
          };

          return getNextDocumentWithFocusTabs(nextDocument);
        }

        return document;
      }),
    };
  },
  [DocumentActionType.CANCEL_DOCUMENT_EXECUTION]: (
    state: DocumentsState,
    payload: string
  ) => {
    const documentIdToCancel = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === documentIdToCancel) {
          if (!document.execution) {
            return document;
          }

          const requireServerCancellation =
            document.context.engineName !== "system";

          return {
            ...document,
            execution: {
              ...document.execution,
              cancellationStatus: requireServerCancellation
                ? CancellationStatus.Initiated
                : CancellationStatus.Cancelled,
              queryStatements: document.execution.queryStatements.map(
                queryStatement => {
                  if (
                    queryStatement.status === QueryStatementStatus.running &&
                    requireServerCancellation
                  ) {
                    return queryStatement;
                  }

                  if (
                    EXECUTION_FINAL_STATUSES.includes(queryStatement.status)
                  ) {
                    return queryStatement;
                  }

                  return {
                    ...queryStatement,
                    status: QueryStatementStatus.cancelled,
                  };
                }
              ),
            },
          };
        }

        return document;
      }),
    };
  },
  [DocumentActionType.UPDATE_DOCUMENT_EXECUTION_CANCELLATION_STATUS]: (
    state: DocumentsState,
    payload: {
      documentId: string;
      queryStatementId: string;
      cancellationStatus: CancellationStatus;
    }
  ) => {
    const {
      documentId: cancellationStatusDocumentId,
      queryStatementId,
      cancellationStatus,
    } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === cancellationStatusDocumentId) {
          if (!document.execution) {
            return document;
          }

          const queryStatement = document.execution.queryStatements.find(
            queryStatement => queryStatement.id === queryStatementId
          );

          if (!queryStatement) {
            // probably query statement belongs to previous execution session
            // don't update the execution object
            return document;
          }

          return {
            ...document,
            execution: {
              ...document.execution,
              cancellationStatus,
            },
          };
        }

        return document;
      }),
    };
  },

  [DocumentActionType.SWITCH_DOCUMENT_ACTIVE_QUERY_STATEMENT]: (
    state: DocumentsState,
    payload: SwitchDocumentActiveQueryStatementPayload
  ) => {
    const {
      documentId: activeQueryStatementDocumentId,
      queryStatementIndex,
      userSelectedActiveQueryStatementIndexTimestamp,
      activeOutputTab,
    } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === activeQueryStatementDocumentId) {
          if (!document.execution) {
            return document;
          }

          return {
            ...document,
            execution: {
              ...document.execution,
              userSelectedActiveQueryStatementIndexTimestamp,
              activeQueryStatementIndex: queryStatementIndex,
            },
            layout: {
              ...document.layout,
              activeOutputTab,
            },
          };
        }

        return document;
      }),
    };
  },

  [DocumentActionType.SWITCH_ACTIVE_OUTPUT_TAB]: (
    state: DocumentsState,
    payload: SwitchActiveOutputPayload
  ) => {
    const { documentId: activeOutputTabDocumentId, activeOutputTab } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === activeOutputTabDocumentId) {
          return {
            ...document,
            layout: {
              ...document.layout,
              activeOutputTab,
            },
          };
        }

        return document;
      }),
    };
  },

  [DocumentActionType.CHANGE_DOCUMENT_LAYOUT]: (
    state: DocumentsState,
    payload: {
      documentId: string;
      layout: Partial<DocumentLayout>;
    }
  ) => {
    const { documentId: layoutDocumentId, layout } = payload;

    return {
      ...state,
      documents: state.documents.map(document => {
        if (document.id === layoutDocumentId) {
          return {
            ...document,
            layout: {
              ...document.layout,
              ...layout,
            },
          };
        }

        return document;
      }),
    };
  },
};

const documentsReducer = (
  state: DocumentsState,
  action: DocumentsAction
): DocumentsState => {
  const { payload, type } = action;

  const handleAction = () => {
    // TODO switch/case remain for type resolution, find the way to do this
    switch (type) {
      case DocumentActionType.CHANGE_SORT_ORDER:
        return actionHandlers[type](state, payload);

      case DocumentActionType.ADD_DOCUMENT:
        return actionHandlers[type](state, payload);

      case DocumentActionType.REMOVE_DOCUMENT:
        return actionHandlers[type](state, payload);

      case DocumentActionType.REMOVE_ALL_DOCUMENTS:
        return actionHandlers[type](state, payload);

      case DocumentActionType.SET_ACTIVE_DOCUMENT:
        return actionHandlers[type](state, payload);

      case DocumentActionType.CHANGE_DOCUMENT_CONTEXT:
        return actionHandlers[type](state, payload);

      case DocumentActionType.INIT_DOCUMENT_EXECUTION:
        return actionHandlers[type](state, payload);

      case DocumentActionType.UPDATE_DOCUMENT_QUERY_STATEMENT:
        return actionHandlers[type](state, payload);

      case DocumentActionType.CANCEL_DOCUMENT_EXECUTION:
        return actionHandlers[type](state, payload);

      case DocumentActionType.UPDATE_DOCUMENT_EXECUTION_CANCELLATION_STATUS:
        return actionHandlers[type](state, payload);

      case DocumentActionType.SWITCH_DOCUMENT_ACTIVE_QUERY_STATEMENT:
        return actionHandlers[type](state, payload);

      case DocumentActionType.SWITCH_ACTIVE_OUTPUT_TAB:
        return actionHandlers[type](state, payload);

      case DocumentActionType.CHANGE_DOCUMENT_LAYOUT:
        return actionHandlers[type](state, payload);

      case DocumentActionType.UPDATE_DOCUMENT_SELECTION:
        return actionHandlers[type](state, payload);

      default:
        return state;
    }
  };

  const newState = handleAction();

  return normalizeState(newState);
};
export { documentsReducer };
