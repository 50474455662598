import { GutterMarker } from "@codemirror/view";

class StatementMarker extends GutterMarker {
  private isStart: boolean;

  private isEnd: boolean;

  toDOM() {
    const div = document.createElement("div");
    div.className = "statement-marker active";

    if (this.isStart) {
      div.className += " start";
    }

    if (this.isEnd) {
      div.className += " end";
    }

    return div;
  }

  constructor(isStart: boolean, isEnd: boolean) {
    super();

    this.isStart = isStart;
    this.isEnd = isEnd;
  }
}

export default StatementMarker;
