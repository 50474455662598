import {
  CLEAR,
  CLICK,
  CTRL_CLICK,
  EDIT,
  EDIT_FINISH,
  MOUSEMOVE,
  RIGHT_CLICK,
  SET_CONTEXT_MENU,
  SHIFT_CLICK,
} from "./actions";
import { addSelections, getActiveSelection, isInsideSelection } from "./utils";

export const initialState = {
  contextMenuPostion: null,
  editingCell: null,
  activeCell: null,
  activeSelection: null,
  selections: [],
};

export const interactionStateReducer = (
  state: {
    contextMenuPostion: any;
    editingCell: any;
    activeCell: any;
    activeSelection: any;
    selections: any;
  },
  action: any
) => {
  const { cursorIndex, contextMenuPostion, columnSelection } = action;
  switch (action.type) {
    case CLEAR: {
      return initialState;
    }

    case RIGHT_CLICK: {
      const { clientX, clientY } = action;
      const { activeSelection, activeCell, selections } = state;
      const isInside = isInsideSelection({
        activeSelection,
        activeCell,
        selections,
        cursorIndex,
      });

      return {
        ...state,
        editingCell: null,
        selections: isInside ? state.selections : [],
        activeSelection: isInside ? state.activeSelection : null,
        activeCell: isInside ? state.activeCell : cursorIndex,
        contextMenuPostion: {
          left: clientX + 8,
          top: clientY + 8,
          row: cursorIndex.row,
          col: cursorIndex.col,
        },
      };
    }

    case CTRL_CLICK: {
      const selections = addSelections(state);
      const activeSelection = getActiveSelection({
        columnSelection,
        cursorIndex,
        defaultValue: null,
      });
      return {
        ...state,
        selections,
        activeCell: cursorIndex,
        editingCell: null,
        activeSelection,
      };
    }

    case SHIFT_CLICK: {
      const activeCell = columnSelection
        ? { row: 0, col: state.activeCell?.col ?? cursorIndex.col }
        : state.activeCell || cursorIndex;

      const activeSelection = getActiveSelection({
        columnSelection,
        cursorIndex,
        defaultValue: cursorIndex,
      });

      return {
        ...state,
        editingCell: null,
        activeCell,
        activeSelection,
      };
    }

    case CLICK: {
      const activeSelection = getActiveSelection({
        columnSelection,
        cursorIndex,
        defaultValue: null,
      });

      return {
        ...state,
        editingCell: null,
        activeSelection,
        selections: [],
        contextMenuPostion: null,
        activeCell: columnSelection
          ? { row: 0, col: cursorIndex.col }
          : cursorIndex,
      };
    }

    case EDIT: {
      if (state.contextMenuPostion || state.editingCell) {
        return state;
      }
      return {
        ...state,
        activeSelection: null,
        activeCell: cursorIndex,
        selections: [],
        editingCell: cursorIndex,
      };
    }

    case EDIT_FINISH: {
      return { ...state, editingCell: null };
    }

    case MOUSEMOVE: {
      if (
        cursorIndex.row === state.activeSelection?.row &&
        cursorIndex.col === state.activeSelection?.col
      ) {
        return state;
      }
      if (columnSelection && cursorIndex.col === state.activeSelection?.col) {
        return state;
      }

      const activeSelection = getActiveSelection({
        columnSelection,
        cursorIndex,
        defaultValue: cursorIndex,
      });

      return {
        ...state,
        activeSelection,
      };
    }

    case SET_CONTEXT_MENU: {
      return {
        ...state,
        contextMenuPostion,
      };
    }

    default: {
      return state;
    }
  }
};
