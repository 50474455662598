import JSONbig from "json-bigint";

function arrayBufferToString(buf: Buffer) {
  if (buf.length === 1) {
    // Probably is a bit column
    return String(buf[0]);
  }
  return buf.toString("utf-8");
}

// reference:
// http://stackoverflow.com/a/21799845/1050818
function isArrayBuffer(value: any) {
  return (
    value &&
    value.buffer instanceof ArrayBuffer &&
    value.byteLength !== undefined
  );
}

export function valueToString(value: any) {
  if (value === null) {
    return "NULL";
  }

  if (typeof value === "boolean") {
    return value.toString();
  }

  if (!value) {
    return String(value);
  }

  if (value.toISOString) {
    return value.toISOString();
  }

  if (typeof value === "object") {
    if (isArrayBuffer(value)) {
      return arrayBufferToString(value);
    }

    return JSONbig.stringify(value, null, 2);
  }

  return String(value);
}
export function rowsValuesToString(rows: any[]): any[] {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define -- circular dependency
  return rows.map(rowValuesToString);
}
export function rowValuesToString(row: any) {
  if (Array.isArray(row)) {
    return rowsValuesToString(row);
  }

  const parsedRow: any = {};

  Object.keys(row).forEach(col => {
    parsedRow[col] = valueToString(row[col]);
  });

  return parsedRow;
}
