const template = `/* 
DIMENSION tables should be the tables that are more descriptive in nature and typically joined with the FACT tables.
DIMENSION tables are fully replicated across all nodes in the cluster. 
While performing a join, the local shard of a FACT table on each node is joined with the local copy of the DIMENSION table.
*/
CREATE DIMENSION TABLE IF NOT EXISTS <firebolt_table_name>
(
    <column_name> <column_type> 
    -- add more columns using the above syntax  
)
`;

export default template;
