import _startCase from "lodash/startCase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { getDatabaseItems } from "./DatabaseItems";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";
import { RenderDatabaseValue } from "./SelectValue";
import { TablesItems } from "./TablesItems";
import { ALL_TABLES, ALL_TABLES_ALL_DATABASES, ANY_TABLE } from "./constant";
import { usePrivilegesHandlers } from "./usePrivilegesHandlers";

import styles from "./styles.module.scss";

const privileges = [
  "DELETE",
  "INSERT",
  "UPDATE",
  "TRUNCATE",
  "VACUUM",
  "MODIFY",
  "SELECT",
];

type Props = {
  index: number;
  resource: string;
};

export const TablesGroup = (props: Props) => {
  const { index, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const { onGrantPrivileges, onRevokePrivileges, handleMore, deleteGroup } =
    usePrivilegesHandlers({
      index,
      resource,
    });

  const groupState = privilegesState.table[index];
  const { expanded, objects } = groupState;

  const menu = useMenu();
  const databases = useDatabasesNames();
  const popoverRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const dependencies = useRef({
    index,
    privilegesDispatch,
    resource,
    objects,
  });

  useEffect(() => {
    const { index, privilegesDispatch, resource, objects } =
      dependencies.current;
    if (!objects.length) {
      privilegesDispatch({
        type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
        resource,
        objects: [{ name: ALL_TABLES_ALL_DATABASES }],
        index,
      });
    }
  }, [dependencies]);

  const items = [
    [
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
            objects: [],
          });
        },
        testId: "roles-wizard-add-tables-group",
      },
    ],

    ...(privilegesState.table.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: deleteGroup,
              testId: "roles-wizard-delete-tables-group",
            },
          ],
        ]
      : []),
  ];

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase()),
    };
  });

  const selectedTables = [
    ...new Set<string>(objects.map(object => object.name)),
  ];

  const handleSelect = (items: string[]) => {
    const objects = items.map(item => {
      return {
        name: item,
      };
    });
    privilegesDispatch({
      type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
      resource,
      objects,
      index,
    });
  };

  const bulkItems = [
    <ContextMenuItem
      value={ALL_TABLES_ALL_DATABASES}
      key={ALL_TABLES_ALL_DATABASES}
      checked={selectedTables.includes(ALL_TABLES_ALL_DATABASES)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.all_tables")}
      direction="left"
      bulkItem
      skipFilter
    />,
  ];

  const databaseItems = getDatabaseItems({
    databases,
    popoverRef,
    renderChildren: (catalogName: string) => (
      <TablesItems
        catalogName={catalogName}
        index={index}
        resource={resource}
      />
    ),
  });

  const renderSpecialValue = (value: string) => {
    if (value === ALL_TABLES || value === ALL_TABLES_ALL_DATABASES) {
      return t("roles_wizard.privileges.all_tables");
    }
    if (value === ANY_TABLE) {
      return t("roles_wizard.privileges.any_table");
    }
    return value;
  };

  const selectItems = [
    ...bulkItems,
    <ContextMenuDivider key="divider" />,
    ...databaseItems,
  ];

  return (
    <>
      <div className={styles.group}>
        <OutlinedSelect
          popoverRef={popoverRef}
          wrapperClassName={styles.select__objects}
          controlledValue={selectedTables}
          onSelect={handleSelect}
          multiple
          renderValue={() => (
            <RenderDatabaseValue
              items={objects}
              expanded={expanded}
              handleMore={handleMore}
              title={t("roles_wizard.privileges.selected_tables")}
            />
          )}
          allowUncheck
          searchOptions={{
            searchPlaceholder: t("roles_wizard.privileges.search_database"),
          }}
          noBorder
          testId={`roles-wizard-select-${resource}-${index}"`}
        >
          {selectItems}
        </OutlinedSelect>
        <GrantPrivilegesSelector
          onChange={onGrantPrivileges}
          privileges={privilegesOptions}
          resource={resource}
          index={index}
        />

        <RevokePrivilegeSelector
          onChange={onRevokePrivileges}
          privileges={privilegesOptions}
          resource={resource}
          index={index}
        />

        <Menu
          items={items}
          menu={menu}
          testId={`roles-wizard-${resource}-${index}-menu`}
          alwaysVisible
        />
      </div>

      {expanded && (
        <div className={styles.objects}>
          {objects.map(object => {
            const { catalogName, name } = object;
            const resourceName = renderSpecialValue(name);
            const resourcePath = catalogName ? (
              <>
                {catalogName}/
                <span className={styles.resourceName}>{resourceName}</span>
              </>
            ) : (
              resourceName
            );
            return (
              <div
                className={styles.object}
                key={name}
              >
                {resourcePath}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
