import { Suspense } from "react";
import { Navigate, matchPath } from "react-router-dom";
import { ROUTES } from "utils/routes.constants";

import { useFormatPath } from "hooks/useFormatPath";

import { LayoutTogglesProvider } from "components/LayoutToggles/context";
import LoadingOverlap from "components/LoadingOverlap";

import { Workspace } from "./Workspace";

const DevelopWorkspace = () => {
  const formatPath = useFormatPath();

  if (!matchPath(ROUTES.DEVELOP, window.location.pathname)) {
    const developPath = formatPath(ROUTES.DEVELOP);
    return <Navigate to={developPath} />;
  }

  return (
    <Suspense fallback={<LoadingOverlap isLoading />}>
      <LayoutTogglesProvider>
        <Workspace />
      </LayoutTogglesProvider>
    </Suspense>
  );
};

export default DevelopWorkspace;
