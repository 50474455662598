import { QueryClient } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";

export const updateDatabases = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  try {
    await queryClient.cancelQueries({
      queryKey: [ReactQueryKeysAccount.databasesNames],
    });
    await queryClient.invalidateQueries({
      queryKey: [ReactQueryKeysAccount.databasesNames],
    });
  } catch (e) {
    console.error("Failed to update databases from WS: ", e);
  }
};
