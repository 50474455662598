import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Button, { ButtonTemplate } from "components/Button/Button";

import styles from "./styles.module.scss";

type Props = {
  title: React.ReactElement | string;
  subtitle: React.ReactElement | string | null;
  body: React.ReactElement;
  bodyClassName?: string;
  onClose: () => void;
  onSubmit: () => void;
  isLast?: boolean;
  disabledSubmit?: boolean;
  isLoading?: boolean;
  onPrevStep: () => void;
  activeStepIndex: number;
  mainActionTitle?: string;
  secondaryActionTestId?: string;
  titleTestId?: string;
};

const LastStepFooter = (props: {
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  mainActionTitle?: string;
  mainActionTestId?: string;
  secondaryActionTestId: string;
}) => {
  const {
    onClose,
    onSubmit,
    isLoading,
    mainActionTestId = "run-ingestion-script",
    mainActionTitle,
    secondaryActionTestId,
  } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <Button
        text={mainActionTitle}
        template={ButtonTemplate.Primary}
        onClick={onSubmit}
        dataTestid={mainActionTestId}
        isLoading={isLoading}
      />
      <Button
        text={t("wizard.step.cancel")}
        template={ButtonTemplate.Ghost}
        onClick={onClose}
        dataTestid={secondaryActionTestId}
      />
    </div>
  );
};

const Footer = (props: {
  onClose: () => void;
  onSubmit: () => void;
  disabledSubmit?: boolean;
  onPrevStep: () => void;
  activeStepIndex: number;
  isLoading?: boolean;
  secondaryActionTestId: string;
  mainActionTitle?: string;
}) => {
  const {
    onClose,
    onSubmit,
    disabledSubmit,
    onPrevStep,
    activeStepIndex,
    isLoading,
    secondaryActionTestId = `go-back-to-step-${activeStepIndex - 1}`,
    mainActionTitle,
  } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <Button
        text={mainActionTitle || t("wizard.step.next")}
        template={ButtonTemplate.Primary}
        dataTestid={`complete-step-${activeStepIndex}`}
        onClick={onSubmit}
        disabled={disabledSubmit}
        isLoading={isLoading}
      />
      {activeStepIndex === 0 && (
        <Button
          text={t("wizard.step.cancel")}
          template={ButtonTemplate.Ghost}
          onClick={onClose}
          dataTestid={secondaryActionTestId}
        />
      )}
      {activeStepIndex > 0 && (
        <Button
          text={t("wizard.step.prev")}
          template={ButtonTemplate.Ghost}
          onClick={onPrevStep}
          dataTestid={secondaryActionTestId}
          disabled={isLoading}
        />
      )}
    </div>
  );
};

export const Step = (props: Props) => {
  const {
    title,
    subtitle,
    body,
    bodyClassName,
    onClose,
    onSubmit,
    isLast,
    disabledSubmit,
    onPrevStep,
    activeStepIndex,
    isLoading,
    mainActionTitle,
    secondaryActionTestId = `go-back-to-step-${activeStepIndex - 1}`,
    titleTestId,
  } = props;
  return (
    <>
      <div className={styles.step}>
        <div className={styles.header}>
          <div
            className={styles.title}
            data-testid={titleTestId}
          >
            {title}
          </div>{" "}
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <div className={classNames(styles.body, bodyClassName)}>{body}</div>
      </div>
      {isLast ? (
        <LastStepFooter
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={isLoading}
          mainActionTitle={mainActionTitle}
          secondaryActionTestId={secondaryActionTestId}
        />
      ) : (
        <Footer
          onClose={onClose}
          onSubmit={onSubmit}
          disabledSubmit={disabledSubmit}
          onPrevStep={onPrevStep}
          activeStepIndex={activeStepIndex}
          isLoading={isLoading}
          secondaryActionTestId={secondaryActionTestId}
          mainActionTitle={mainActionTitle}
        />
      )}
    </>
  );
};
