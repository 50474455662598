import _get from "lodash/get";
import { useReducer, useState } from "react";

import { SqlRbacAction } from "services/rbac/action";
import { Role } from "services/rbac/types";

import { privilegesReducer } from "./privilegesReducer";

const getInitialState = (role?: Role) => {
  const privileges = role?.privileges || [];

  const privilegesByResource = privileges.reduce<any>(
    (acc, privilege) => {
      const { privilegeType, objectName, objectType } = privilege;

      if (!privilegeType) {
        return acc;
      }

      const action = privilegeType as SqlRbacAction;
      const actions = _get(acc, [objectType, objectName], {});
      return {
        ...acc,
        [objectType]: {
          ...(acc[objectType] || {}),
          [objectName]: {
            ...actions,
            [action]: true,
          },
        },
      };
    },
    { engine: {}, database: {}, account: {} }
  );

  return privilegesByResource;
};

export const usePrivileges = (role?: Role) => {
  const [initialState] = useState<any>(() => getInitialState(role));

  const [state, dispatch] = useReducer(privilegesReducer, initialState);

  const getDiff = () => {
    const toAssign: { type: string; resource: string; actions: string[] }[] =
      [];
    const toRevoke: { type: string; resource: string; actions: string[] }[] =
      [];

    const diffResource = (type: string) => {
      const resources = Object.keys(state[type]);

      for (const id of resources) {
        const toAssignActions: string[] = [];
        const toRevokeActions: string[] = [];

        const oldPrivileges = initialState[type][id] || [];
        const newPrivileges = state[type][id] || [];

        const keys: string[] = Array.from(
          new Set([
            ...Object.keys(oldPrivileges),
            ...Object.keys(newPrivileges),
          ])
        );

        for (const privilege of keys) {
          if (oldPrivileges[privilege] && !newPrivileges[privilege]) {
            toRevokeActions.push(privilege);
          }
          if (!oldPrivileges[privilege] && newPrivileges[privilege]) {
            toAssignActions.push(privilege);
          }
        }

        if (toAssignActions.length) {
          toAssign.push({
            type,
            resource: id,
            actions: toAssignActions,
          });
        }

        if (toRevokeActions.length) {
          if (id === "*") {
            toRevoke.unshift({
              type,
              resource: id,
              actions: toRevokeActions,
            });
          } else {
            toRevoke.push({
              type,
              resource: id,
              actions: toRevokeActions,
            });
          }
        }
      }
    };

    diffResource("account");
    diffResource("engine");
    diffResource("database");

    return {
      toAssign,
      toRevoke,
    };
  };

  return {
    state,
    dispatch,
    getDiff,
  };
};
