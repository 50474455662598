import { useTranslation } from "react-i18next";

import styles from "../styles/NoData.module.scss";

const NoQueryHistoryData = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.description}>{t("query_history.no_data")}</div>
    </div>
  );
};

export default NoQueryHistoryData;
