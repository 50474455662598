export enum SortValues {
  noSort = "",
  nameAsc = "nameAsc",
  nameDesc = "nameDesc",
  typeAsc = "typeAsc",
  typeDesc = "typeDesc",
  sizeAsc = "sizeAsc",
  sizeDesc = "sizeDesc",
  modifiedAsc = "modifiedAsc",
  modifiedDesc = "modifiedDesc",
}