import isNetworkError from "is-network-error";
import { MutableRefObject, useEffect, useLayoutEffect, useRef } from "react";

import { authService } from "services/auth";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { FIREBOLT_PROTOCOL_VERSION_HEADER } from "pages/DevelopWorkspace/services/constants";
import getRecentlyStartedQueryByLabel from "pages/DevelopWorkspace/services/helpers/getRecentlyStartedQueryByLabel";
import getRunningQuery from "pages/DevelopWorkspace/services/helpers/getRunningQuery";
import { withRetry } from "pages/DevelopWorkspace/services/helpers/withRetry";
import {
  DocumentExecutionRuntime,
  FetchOptions,
} from "pages/DevelopWorkspace/services/types";
import {
  CancellationStatus,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { useCurrentAccount } from "components/Account/useCurrentAccount";

import { getHistoryQuery } from "./helpers/getHistoryQueryByLabel";
import getQueryLabel from "./helpers/getQueryLabel";
import { delay, isQueryStatementRunning } from "./utils";

const generateUnknownErrorMessage = (serverQueryId: string | undefined) => {
  if (serverQueryId) {
    return `We were unable to retrieve the query status. For further details, please contact our support team, quoting the query ID: ${serverQueryId}`;
  }

  return "Unknown error"
}

export const useResumeQuery = ({
  documentsExecutionRuntime,
}: {
  documentsExecutionRuntime: MutableRefObject<
    Map<string, DocumentExecutionRuntime>
  >;
}) => {
  const {
    state: documentsState,
    actions: { updateDocumentQueryStatement, updateDocumentCancellationStatus },
  } = useDocuments();
  const { getAccount } = useCurrentAccount();
  const { data: engines } = useWorkspaceEngines(true);

  const clearDocumentExecutionRuntime = (
    documentId: string,
    queryStatementId: string
  ) => {
    const documentRuntime = documentsExecutionRuntime.current.get(documentId);

    if (documentRuntime && documentRuntime.id === queryStatementId) {
      // Clear runtime information. No next progress calls will be performed
      documentsExecutionRuntime.current.delete(documentId);
    }
  };

  const resumeDocumentExecution = async (document: WorkspaceDocument) => {
    const documentExecution = document.execution;
    const account = getAccount();
    const abortController = new AbortController();

    if (!documentExecution || !account) {
      return;
    }

    const queryStatements = documentExecution.queryStatements;

    if (!queryStatements || !engines) {
      return;
    }

    const engine = engines.find(
      engine => engine.engineName === document.context.engineName
    );

    const unknownQueryStatement = queryStatements.find(
      queryStatement => queryStatement.status === QueryStatementStatus.unknown
    );

    if (!unknownQueryStatement) {
      // nothing to execute. probably all query statements are finished or still running
      return;
    }

    if (!engine || engine.engineName === "system") {
      updateDocumentQueryStatement(document.id, unknownQueryStatement.id, {
        status: QueryStatementStatus.error,
        error: generateUnknownErrorMessage(unknownQueryStatement.serverQueryId),
      });
      return;
    }

    const queryLabel = getQueryLabel(unknownQueryStatement, document);

    const fetchOptions: FetchOptions = {
      signal: abortController.signal,
      headers: {
        "Content-Type": "application/json",
        [FIREBOLT_PROTOCOL_VERSION_HEADER]: "2.1",
      },
    };

    const updateQueryStatus = async () => {
      if (!engine || engine.engineName === "system") {
        return null;
      }

      const historyQuery = await getHistoryQuery(
        engine,
        queryLabel,
        unknownQueryStatement.serverQueryId,
        fetchOptions,
        authService
      );

      const statusMap = {
        CANCELED_EXECUTION: QueryStatementStatus.cancelled,
        ERROR: QueryStatementStatus.error,
        ENDED_SUCCESSFULLY: QueryStatementStatus.success,
      };

      const status = statusMap[historyQuery?.status as keyof typeof statusMap];
      if (historyQuery && status) {
        clearDocumentExecutionRuntime(document.id, unknownQueryStatement.id);
        updateDocumentQueryStatement(document.id, unknownQueryStatement.id, {
          status: statusMap[historyQuery.status as keyof typeof statusMap],
          statistics: {
            executionTimeSec: historyQuery.durationUsec / 1000000,
            bytesRead: historyQuery.scannedBytes,
            rowsRead: historyQuery.scannedRows,
          },
        });

        if (status === QueryStatementStatus.cancelled) {
          updateDocumentCancellationStatus(
            document.id,
            unknownQueryStatement.id,
            CancellationStatus.Cancelled
          );
        }

        return {
          status,
          runningQuery: null,
        };
      }

      const runningQuery = await getRecentlyStartedQueryByLabel(
        engine,
        queryLabel,
        fetchOptions,
        authService
      );
      if (!runningQuery) {
        throw new Error("Query not found");
      }
      return {
        runningQuery,
      };
    };

    try {
      const query = await withRetry(updateQueryStatus, [], 120, 1000);

      if (!query) {
        throw new Error("Query not found");
      }
      const { runningQuery, status } = query;
      if (status) {
        return;
      }

      if (runningQuery) {
        documentsExecutionRuntime.current.set(document.id, {
          id: unknownQueryStatement.id,
          abortController,
          progressFetchPaused: false,
        });
      }
      while (
        isQueryStatementRunning(
          documentsExecutionRuntime,
          document.id,
          unknownQueryStatement.id
        )
      ) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const runningQueryByServerId = await getRunningQuery(
            engine,
            runningQuery.queryId,
            fetchOptions,
            authService
          );
          updateDocumentQueryStatement(document.id, unknownQueryStatement.id, {
            serverQueryId: runningQuery.queryId,
            status: QueryStatementStatus.running,
            statistics: {
              executionTimeSec: runningQueryByServerId.durationUsec / 1000000,
              bytesRead: runningQueryByServerId.scannedBytes,
              rowsRead: runningQueryByServerId.scannedRows,
            },
          });
          // eslint-disable-next-line no-await-in-loop
          await delay(1000);
        } catch (error) {
          clearDocumentExecutionRuntime(document.id, unknownQueryStatement.id);
          updateDocumentQueryStatement(document.id, unknownQueryStatement.id, {
            status: QueryStatementStatus.unknown,
          });
        }
      }
    } catch (error) {
      if (isNetworkError(error)) {
        updateDocumentQueryStatement(document.id, unknownQueryStatement.id, {
          status: QueryStatementStatus.unknown,
        });
      } else {
        clearDocumentExecutionRuntime(document.id, unknownQueryStatement.id);
        updateDocumentQueryStatement(document.id, unknownQueryStatement.id, {
          status: QueryStatementStatus.error,
          error: generateUnknownErrorMessage(unknownQueryStatement.serverQueryId),
        });
      }
    }
  };

  const latestResumeDocumentExecution = useRef(resumeDocumentExecution);

  useLayoutEffect(() => {
    latestResumeDocumentExecution.current = resumeDocumentExecution;
  });

  useEffect(() => {
    const { documents } = documentsState;
    for (const document of documents) {
      // check for each document if it has to resume execution or do nothing
      latestResumeDocumentExecution.current(document);
    }
  }, [documentsState, latestResumeDocumentExecution]);
};
