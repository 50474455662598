import { QueryClient } from "@tanstack/react-query";

import { NotAMemberError } from "./ErrorBoundary/NotAMember";
import { UnauthorizedError } from "./ErrorBoundary/UnauthorizedError";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes of stale time, as we use WS to update data
      retry: (failureCount, error) => {
        if (
          error instanceof UnauthorizedError ||
          error instanceof NotAMemberError ||
          failureCount > 3
        ) {
          return false;
        }
        return true;
      },
    },
  },
});
