import { useCallback, useState } from "react";

import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { ItemDataType } from "./types";
import { EMPTY_TYPE } from "./utils";

const findNodeOfTree = (
  data: ItemDataType[],
  check: (node: ItemDataType) => boolean
) => {
  const findNode = (
    nodes: readonly ItemDataType[] = []
  ): ItemDataType | undefined => {
    for (let i = 0; i < nodes.length; i += 1) {
      const item = nodes[i];
      if (Array.isArray(item.children)) {
        const node = findNode(item.children);
        if (node) {
          return node;
        }
      }

      if (check(item)) {
        return item;
      }
    }

    return undefined;
  };
  return findNode(data);
};

export const useGetTreeNodeChildren = (treeData: ItemDataType[]) => {
  const [loadingNodeValues, setLoadingNodeValues] = useState<string[]>([]);
  const [data, setData] = useState(treeData);
  const { putStatusMessage } = useStatusMessageQueue();

  const concatChildren = useCallback(
    (
      treeNode: ItemDataType,
      children: ItemDataType[] | null
    ): ItemDataType[] => {
      const { value } = treeNode;
      const node = findNodeOfTree(data, item => value === item.value);
      if (node) {
        node.children = children;
      }
      const newData = data.concat([]);
      setData(newData);
      return newData;
    },
    [data]
  );

  const loadChildren = useCallback(
    (
      node: ItemDataType,
      getChildren: (
        node: ItemDataType
      ) => ItemDataType[] | Promise<ItemDataType[] | null> | null
    ) => {
      setLoadingNodeValues(prev => prev.concat(node.value as string));
      const children = getChildren(node);
      if (children instanceof Promise) {
        return children
          .then(response => {
            const { value } = node;
            const children = response?.length
              ? response
              : [
                  {
                    type: EMPTY_TYPE,
                    label: "",
                    value: `${value}_empty`,
                  },
                ];
            concatChildren(node, children);
            setLoadingNodeValues(prev =>
              prev.filter(item => item !== node.value)
            );
          })
          .catch(error => {
            putStatusMessage({
              type: StatusMessageType.Error,
              message: error.message,
              options: {
                id: "start",
                insertToPosition: StatusMessagePosition.Top,
                autoRemove: true,
              },
            });

            setLoadingNodeValues(prev =>
              prev.filter(item => item !== node.value)
            );
          });
      }
      setData(concatChildren(node, children));
      setLoadingNodeValues(prev => prev.filter(item => item !== node.value));
    },
    [concatChildren, putStatusMessage]
  );
  return { data, setData, loadingNodeValues, loadChildren };
};
