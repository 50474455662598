import React from "react";

import styles from "./styles.module.scss";

interface Props {
  style: React.CSSProperties;
}
export const HeaderFakeItem = (props: Props) => {
  const { style } = props;

  return (
    <div
      data-testid="datagrid-header-fake"
      className={styles.header__column}
      style={style}
    >
      <div className={styles.fake}>
        <div className={styles.fake__name} />
        <div className={styles.fake__stats} />
      </div>
    </div>
  );
};
