import { useContext } from "react";

import { ThemeContext } from "themes/themeContext";

import LightThemeIcon from "assets/icons/LightTheme.svg?react";

import Toggle from "components/Toggle";

import styles from "./styles.module.scss";

const ThemeSwitcher = () => {
  const { theme, toggle } = useContext(ThemeContext);

  const onThemeChange = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("firebolt_theme", newTheme);
    toggle(newTheme);
  };

  return (
    <div className={styles.toggleWrap}>
      <Toggle
        checked={theme === "dark"}
        onChange={onThemeChange}
        vertical
        dataTestId={`theme-switch-${theme}`}
        classes={{ ...styles }}
        icon={<LightThemeIcon />}
        iconChecked={<LightThemeIcon />}
      />
    </div>
  );
};

export default ThemeSwitcher;
