import { WorkspaceEngineStatus } from "services/engines/engine.types";

export const ENGINE_STATUS_NAME = {
  [WorkspaceEngineStatus.RUNNING]: "Running",
  [WorkspaceEngineStatus.STOPPED]: "Stopped",
  [WorkspaceEngineStatus.DRAINING]: "Draining",
  [WorkspaceEngineStatus.STOPPING]: "Stopping",
  [WorkspaceEngineStatus.STARTING]: "Starting",
  [WorkspaceEngineStatus.RESIZING]: "Resizing",
};
