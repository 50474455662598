import { useTranslation } from "react-i18next";

import { SqlRbacAction } from "services/rbac/action";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import Toggle from "components/Toggle";

import { usePrivilegesState } from "../../usePrivilegesContext";
import { useReadOnly } from "../../useReadOnly";
import {
  ADD_ACCOUNT_RESOURCE_PRIVILEGE,
  DELETE_ACCOUNT_RESOURCE_PRIVILEGE,
} from "./privilegesReducer";
import { getActionTooltip } from "./utils";

import styles from "./styles.module.scss";

type Props = {
  selectedTab: string;
  toggleTypes: {
    type: SqlRbacAction;
    label: string;
    resource?: string;
  }[];
};

export const Toggles = (props: Props) => {
  const { toggleTypes, selectedTab } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const { readOnly, isActionDisabled } = useReadOnly();
  const { t } = useTranslation();
  const currentAccount = useCurrentParamsAccount();

  const isChecked = (type: SqlRbacAction) => {
    const privileges = privilegesState.account;
    const accountPrivileges = privileges[currentAccount.accountName] || {};
    const wildCardPrivileges = privileges["*"] || {};
    return !!wildCardPrivileges[type] || !!accountPrivileges[type];
  };

  const changeToggle =
    (type: SqlRbacAction, _resource: string | undefined) => () => {
      const disabled = readOnly || isActionDisabled(selectedTab, type);
      if (disabled) {
        return;
      }

      const actionType = isChecked(type)
        ? DELETE_ACCOUNT_RESOURCE_PRIVILEGE
        : ADD_ACCOUNT_RESOURCE_PRIVILEGE;

      const id = privilegesState.account["*"]?.[type]
        ? "*"
        : currentAccount.accountName;
      privilegesDispatch({
        type: actionType,
        privilege: type,
        resource: { id },
      });
    };

  return (
    <div className={styles.toggles}>
      {toggleTypes.map(toggleType => {
        const { type, label, resource } = toggleType;
        const checked = isChecked(type);
        const tooltip = getActionTooltip(t, type, selectedTab);

        return (
          <div
            className={styles.toggle}
            key={type}
          >
            <Toggle
              size="sm"
              checked={checked}
              onChange={changeToggle(type, resource)}
              label={t(label)}
              disabled={readOnly || isActionDisabled(selectedTab, type)}
              dataTestId={`toggle-${type}`}
              tooltip={tooltip}
              classes={{ tooltip: styles.tooltip }}
            />
          </div>
        );
      })}
    </div>
  );
};
