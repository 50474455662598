import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";

import ErrorMessageCopuIcon from "assets/icons/ErrorMessageCopuIcon.svg?react";
import WarningIcon24 from "assets/icons/WarningIcon24.svg?react";

import { ResponseMessage } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentMessages/ResponseMessage/ResponseMessage";

import styles from "./ErrorMessage.module.scss";

interface ErrorMessageProps {
  errorMessage: any;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { errorMessage } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.errorMessage}>
      <div className={styles.header}>
        <WarningIcon24 className={styles.icon} />
        <span className={styles.title}>{t("results.messages.error")}</span>
      </div>
      {!!errorMessage && (
        <div className={styles.descriptionContainer}>
          {" "}
          <ResponseMessage queryError={errorMessage} />
          <div
            className={styles.copyIcon}
            onClick={() => {
              if (typeof errorMessage === "string") {
                copy(errorMessage);
              }
            }}
          >
            <ErrorMessageCopuIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorMessage;
