import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  SupportAccessAgentType,
  SupportAccessService,
} from "services/api/supportAccess";
import { useSupportAccess } from "services/api/supportAccess/useSupportAccessList";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import FormModal from "components/FormModal/FormModal";
import LoadingOverlap from "components/LoadingOverlap";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { SupportAgent } from "./SupportAgent";
import { shouldInvalidateSupportAgent } from "./helpers";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onSuccess?: (agent: SupportAccessAgentType) => void;
};

export const SupportAgentWrapper = (props: Props) => {
  const { onClose, onSuccess } = props;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [revoking, setRevoking] = useState<boolean>(false);
  const { getAccount } = useCurrentAccount();
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const agent = useSupportAccess();
  const account = getAccount();

  const handleSubmit = async (formFields: any) => {
    if (!account) {
      return;
    }

    const input = {};

    if (formFields.roles.length) {
      Object.assign(input, {
        account_role_names: formFields.roles,
      });
    }

    if (formFields.duration) {
      const now = new Date();
      const expires_at = new Date(
        now.getTime() + 1000 * 60 * 60 * formFields.duration
      ).toISOString();

      Object.assign(input, {
        expires_at,
      });
    }

    try {
      setSubmitting(true);
      const agent = await SupportAccessService.editSupportAccess(
        account.accountName,
        input
      );
      await queryClient.invalidateQueries({
        predicate: query => {
          return shouldInvalidateSupportAgent(query.queryKey[0]);
        },
      });
      onSuccess?.(agent);
    } catch (error: any) {
      putStatusMessage({
        message: error.response?.data?.detail || error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleRevoke = async () => {
    const account = getAccount();
    try {
      if (account) {
        const { accountName } = account;
        setRevoking(true);
        await SupportAccessService.revokeSupportAccess(accountName);
        await queryClient.invalidateQueries({
          predicate: query => {
            return shouldInvalidateSupportAgent(query.queryKey[0]);
          },
        });
      }
      onClose();
    } catch (error: any) {
      putStatusMessage({
        message: error.response?.data?.detail || error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setRevoking(false);
    }
  };

  return (
    <SupportAgent
      onClose={onClose}
      onSubmit={handleSubmit}
      onRevoke={handleRevoke}
      submitting={submitting}
      revoking={revoking}
      agent={agent}
      title={t("supportAgent.title_edit", { accountName: account.accountName })}
      submit={t("supportAgent.submit_edit")}
    />
  );
};

export const SupportAgentEdit = (props: Props) => {
  const { onClose, onSuccess } = props;

  return (
    <FormModal
      open
      onClose={onClose}
      classes={{ paper: classNames(styles.paper, styles.paper__large) }}
    >
      <Suspense fallback={<LoadingOverlap isLoading />}>
        <SupportAgentWrapper
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Suspense>
    </FormModal>
  );
};
