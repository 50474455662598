import classNames from "classnames";

import DatabaseIcon from "assets/icons/workspace/Header/DatabaseHeader.svg?react";
import EngineIcon from "assets/icons/workspace/Header/EngineHeader.svg?react";
import SecurityIcon from "assets/icons/workspace/Header/SecurityHeader.svg?react";

import { useLayoutToggles } from "components/LayoutToggles/context";
import Tooltip from "components/Tooltip";

import { CreateMenu } from "./CreateMenu/CreateMenu";
import { HeaderIcon } from "./HeaderIcon/HeaderIcon";
import { LeftSidebarTabs } from "./types";

import styles from "./styles.module.scss";

const tabs = [
  {
    type: LeftSidebarTabs.DATABASES,
    title: "Databases",
    icon: <DatabaseIcon />,
    testId: "explore-databases",
  },
  {
    type: LeftSidebarTabs.ENGINES,
    title: "Engines",
    icon: <EngineIcon />,
    testId: "explore-engines",
  },
  {
    type: LeftSidebarTabs.SECURITY,
    title: "Security",
    icon: <SecurityIcon />,
    testId: "explore-security",
  },
];

type Props = {
  activeTab: LeftSidebarTabs;
  setTab: (tab: LeftSidebarTabs) => void;
};

export const Header = (props: Props) => {
  const { setTab, activeTab } = props;
  const { layout, setLayout } = useLayoutToggles();
  return (
    <div
      className={styles.header}
      data-testid="object-exporer-header"
    >
      {tabs.map(tab => {
        const { type, icon, title, testId } = tab;
        const selected = activeTab === type;
        return (
          <button
            key={type}
            onClick={() => {
              if (type === activeTab) {
                return;
              }
              setTab(type);
              setLayout({ ...layout, engines: { expanded: false } });
            }}
            className={classNames(styles.tab, {
              [styles.active]: selected,
            })}
            data-testid={testId}
          >
            <Tooltip
              title={selected ? null : title}
              placement="bottom"
            >
              <HeaderIcon
                icon={icon}
                selected={selected}
              />
            </Tooltip>
            <span className={styles.title}>{title}</span>
          </button>
        );
      })}
      <CreateMenu />
    </div>
  );
};
