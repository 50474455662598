import React, { CSSProperties, useContext, useEffect } from "react";

import { DiagramActionContext, DiagramContext } from "../DiagramContext";
import { Subqueries } from "../Subqueries/Subqueries";
import { FIT_CENTER, FIT_MINIMAP } from "../actions";
import { Explain } from "../types";
import { Viewport } from "./Viewport";
import { useDragViewport } from "./useDragViewport";

import styles from "./styles.module.scss";

const MapInner: React.FC<{ explain: Explain }> = props => {
  const { explain } = props;
  const { minimap } = useContext(DiagramContext);
  const { handleViewportDown, handleWrapperDown } = useDragViewport();
  const style = {
    transform: `scale(${minimap.k})`,
    visibility: (minimap.fit
      ? "visible"
      : "hidden") as CSSProperties["visibility"],
  };

  return (
    <div
      className={styles.map__wrapper}
      onPointerDown={handleWrapperDown}
      data-testid="minimap"
    >
      <div
        className={styles.map}
        style={style}
      >
        <div className={styles.map__inner}>
          <Subqueries explain={explain} />
        </div>
      </div>
      <Viewport handlePointerDown={handleViewportDown} />
    </div>
  );
};

export const ExplainDiagramMap: React.FC<{ explain: Explain }> = props => {
  const { explain } = props;
  const { initialized, mode, fit, minimap } = useContext(DiagramContext);
  const dispatch = useContext(DiagramActionContext);

  useEffect(() => {
    if (initialized) {
      dispatch({ type: FIT_MINIMAP });
      dispatch({ type: FIT_CENTER });
    }
  }, [mode, dispatch, initialized, fit]);

  if (!initialized || minimap.collapsed) {
    return null;
  }

  return <MapInner explain={explain} />;
};
