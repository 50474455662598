import ScrewWrenchIcon from "assets/icons/ScrewWrench.svg?react";

import Button from "components/Button";
import { ErrorScreen, ErrorScreenImagePosition } from "components/ErrorScreen";

const NewAppVersion = () => {
  return (
    <ErrorScreen
      imagePosition={ErrorScreenImagePosition.Top}
      image={<ScrewWrenchIcon />}
      title="Firebolt has been updated!"
      description={
        <>New possibilities arise and you don’t want to be left behind</>
      }
      footerSmallMargin
      footer={
        <div>
          <Button
            onClick={() => window.location.reload()}
            text="Let's Reload the page"
          />
        </div>
      }
    />
  );
};

export default NewAppVersion;
