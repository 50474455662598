import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ErrorMessage/ErrorMessage";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import {
  ExecutionType,
  ExplainType,
  QueryStatement,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { QueryExplain } from "./QueryExplainDiagram";
import { Explain, Operator } from "./types";

import styles from "./styles.module.scss";

const buildExplainTree = (explain: Explain) => {
  const { operators, root_operator_id, subquery_root_operator_ids } = explain;

  const operatorsById: Record<string, Operator> = operators.reduce<
    Record<string, Operator>
  >((acc, operator) => {
    const { operator_id } = operator;
    acc[operator_id] = operator;
    return acc;
  }, {});

  const assignOperatorChildren = (node: Operator) => {
    const { input_ids } = node;
    if (input_ids.length) {
      const children = input_ids.map(id => {
        const operator = operatorsById[id];
        assignOperatorChildren(operator);
        return operator;
      });
      // eslint-disable-next-line no-param-reassign
      node.children = children;
    }
    return node;
  };

  const root = operatorsById[root_operator_id];
  const node = assignOperatorChildren(root);
  const subqueries = (subquery_root_operator_ids || []).map(id => {
    const root = operatorsById[id];
    const node = assignOperatorChildren(root);
    return node;
  });

  return {
    ...explain,
    operators: [node, ...subqueries],
  };
};

type Props = {
  document: WorkspaceDocument;
  queryStatement: QueryStatement;
  isProfile?: boolean;
};

export const ExplainStatement = (props: Props) => {
  const { document, queryStatement, isProfile } = props;
  const { explain, explainType = ExplainType.Physical } = queryStatement;

  const {
    actions: { updateDocumentQueryStatement },
  } = useDocuments();
  const { t } = useTranslation();

  const [selectedNode, setSelectedNode] = useState<Operator | null>(null);

  const handleExplainChange = (explainType: ExplainType) => {
    const update = {
      explainType,
      executionType: ExecutionType.Explain,
    };

    if (!queryStatement.explain?.[explainType]) {
      Object.assign(update, {
        status: QueryStatementStatus.pending,
      });
    }

    setSelectedNode(null);
    updateDocumentQueryStatement(document.id, queryStatement.id, update);
  };

  const handleSelectNode = useCallback((node: Operator) => {
    setSelectedNode(node);
  }, []);

  const explainTree = explain?.[explainType]
    ? buildExplainTree(explain[explainType])
    : null;

  if (!explainTree) {
    return <ErrorMessage errorMessage={t("explain.unexpected_error")} />;
  }

  return (
    <div className={styles.explain}>
      <QueryExplain
        selectedNode={selectedNode}
        handleSelectNode={handleSelectNode}
        explain={explainTree}
        explainType={explainType}
        onExplainChange={handleExplainChange}
        isProfile={isProfile}
      />
    </div>
  );
};
