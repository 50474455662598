import classNames from "classnames";
import React from "react";

import CrossIcon from "assets/icons/Cross.svg?react";
import ErrorIcon from "assets/icons/ErrorIcon.svg?react";
import InfoIcon from "assets/icons/Info.svg?react";

import Button, { ButtonSize, ButtonTemplate } from "components/Button";

import styles from "./styles.module.scss";

export enum NotificationType {
  Info = "info",
  Error = "error",
}

type Props = {
  type: NotificationType;
  title: string;
  body?: string | React.ReactNode;
  onDismiss?: () => void;
  primaryAction?: {
    value: string;
    type: ButtonTemplate;
    buttonClassName?: string;
    onAction?: () => void;
    size?: ButtonSize;
  };
  secondaryAction?: {
    value: string;
    type: ButtonTemplate;
    buttonClassName?: string;
    onAction?: () => void;
    href?: string;
    size?: ButtonSize;
  };
};

export const InlineNotification = (props: Props) => {
  const { title, body, type, primaryAction, secondaryAction, onDismiss } =
    props;
  return (
    <div className={classNames(styles.wrapper, styles[type])}>
      <div className={styles.wrapper__main}>
        <div className={classNames(styles.title, styles[type])}>
          <div className={classNames(styles.icon, styles[type])}>
            {type === NotificationType.Error ? <ErrorIcon /> : <InfoIcon />}
          </div>
          {title}
        </div>
        {body && <div className={styles.body}>{body}</div>}

        {(primaryAction || secondaryAction) && (
          <div className={styles.actions}>
            {primaryAction && (
              <Button
                template={primaryAction.type}
                text={primaryAction.value}
                className={primaryAction.buttonClassName}
                onClick={primaryAction.onAction}
                size={primaryAction.size || ButtonSize.Small}
              />
            )}
            {secondaryAction && (
              <Button
                template={secondaryAction.type}
                text={secondaryAction.value}
                className={secondaryAction.buttonClassName}
                href={secondaryAction.href}
                size={secondaryAction.size || ButtonSize.Small}
              />
            )}
          </div>
        )}
      </div>
      {onDismiss && (
        <div
          className={styles.dismiss}
          onClick={onDismiss}
        >
          <CrossIcon className={styles.crossIcon} />
        </div>
      )}
    </div>
  );
};
