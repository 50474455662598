import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import FormRow from "./components/FormRow";

import formRowStyles from "./components/FormRow/FormRow.module.scss";

export const DefaultDatabase = (props: {
  control: Control<any>;
  initialSelected?: string[];
}) => {
  const { control, initialSelected } = props;
  const databases = useDatabasesNames();
  const { t } = useTranslation();
  return (
    <FormRow
      label={t("workspace.create_user.default_database")}
      input={
        <Controller
          control={control}
          name="defaultDatabase"
          render={({ field: { onChange, value } }) => (
            <OutlinedSelect
              initialSelected={initialSelected}
              className={formRowStyles.selectInput}
              onSelect={(items: string[]) => {
                const [type = ""] = items;
                onChange(type);
              }}
              testId="default-database-select"
              allowUncheck
              searchOptions={{
                searchPlaceholder: t("workspace.create_user.search_database"),
                noResultsText: t("workspace.create_user.no_database_found"),
              }}
            >
              {databases.map(database => {
                const { catalogName } = database;
                return (
                  <ContextMenuItem
                    value={catalogName}
                    key={catalogName}
                    checked={catalogName === value}
                    checkedIconPlaceholder={true}
                    testId={`default-database-item-${catalogName}`}
                    text={catalogName}
                  />
                );
              })}
            </OutlinedSelect>
          )}
        />
      }
    />
  );
};
