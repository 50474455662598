import { EditorView } from "@codemirror/view";
import { createContext, useCallback, useMemo, useState } from "react";
import { KeywordCase } from "sql-formatter";

import { format } from "../../utils/format-sql";

interface ActiveEditorViewContextType {
  activeEditorView: EditorView | null;
  setActiveEditorView: (view: EditorView) => void;
  insertTextAtCursorPosition: (text: string) => void;
  formatText: () => void;
}

export const ActiveEditorViewContext =
  createContext<ActiveEditorViewContextType>({
    activeEditorView: null,
    setActiveEditorView: () => {},
    insertTextAtCursorPosition: () => {},
    formatText: () => {},
  });

interface ActiveEditorViewContextProviderProps {
  children: React.ReactNode;
}

const ActiveEditorViewContextProvider = (
  props: ActiveEditorViewContextProviderProps
) => {
  const { children } = props;

  const [activeEditorView, setActiveEditorView] = useState<EditorView | null>(
    null
  );

  const formatText = useCallback(async () => {
    if (!activeEditorView) {
      return;
    }
    const text = activeEditorView.state.doc.toString();

    const formatted = await format(text, {
      keywordCase: "preserve" as KeywordCase,
      linesBetweenQueries: 2,
    });

    if (typeof formatted === "string") {
      activeEditorView.dispatch({
        changes: { from: 0, to: text.length, insert: formatted },
      });
    }
  }, [activeEditorView]);

  const insertTextAtCursorPosition = useCallback(
    (text: string) => {
      if (!activeEditorView) {
        return;
      }

      const state = activeEditorView.state;
      const range = state.selection.ranges[0];
      activeEditorView.dispatch({
        changes: {
          from: range.from,
          to: range.to,
          insert: text,
        },
        selection: {
          anchor: range.from + text.length,
          head: range.from + text.length,
        },
        scrollIntoView: true,
      });

      setTimeout(() => {
        activeEditorView.focus();
      }, 0);
    },
    [activeEditorView]
  );

  const contextValue = useMemo(
    () => ({
      activeEditorView,
      setActiveEditorView,
      insertTextAtCursorPosition,
      formatText,
    }),
    [
      activeEditorView,
      setActiveEditorView,
      insertTextAtCursorPosition,
      formatText,
    ]
  );

  return (
    <ActiveEditorViewContext.Provider value={contextValue}>
      {children}
    </ActiveEditorViewContext.Provider>
  );
};

export default ActiveEditorViewContextProvider;
