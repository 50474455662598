import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

const useActiveDocument = () => {
  const { state } = useDocuments();

  const activeDocumentId = state.activeDocumentId;

  const activeDocument = state.documents.find(
    doc => doc.id === activeDocumentId
  );

  return activeDocument;
};

export default useActiveDocument;
