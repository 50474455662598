import classNames from "classnames";
import React from "react";
import { formatErrorForHtml } from "utils/helpers/Query";

import { ErrorLine } from "./ErrorLine";

import styles from "./styles.module.scss";

export function normalizeQueryErrorMessage(queryError: any): string {
  if (typeof queryError === "string") {
    return queryError;
  }
  if (typeof queryError?.message === "string") {
    return queryError.message;
  }

  return "Query execution error";
}

const ERROR_LINE = /^(\s*)\^===\sERROR\sHERE/;

interface ResponseMessageProps {
  queryError: any;
}
export const ResponseMessage = (props: ResponseMessageProps) => {
  const { queryError } = props;
  if (queryError) {
    const queryErrorFormated = formatErrorForHtml(
      normalizeQueryErrorMessage(queryError)
    );
    const lines = queryErrorFormated.split(/\n\r|\n|\r/);
    const queryErrorElements: JSX.Element[] = [];

    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];
      const lineElement = (
        <div
          key={index}
          className={styles.line}
        >
          {line}
        </div>
      );
      queryErrorElements.push(lineElement);

      if (index === 0) {
        // eslint-disable-next-line no-continue -- legacy code
        continue;
      }

      const match = line.match(ERROR_LINE);
      if (match) {
        const [, group] = match;
        const offset = group.length;
        const previousIndex = index - 1;
        const previousLine = lines[previousIndex];
        const lineMatch = previousLine.match(
          new RegExp(`^(.{${offset}})(.+?(?:\\s|$))(.*)`)
        );

        if (!lineMatch) {
          // eslint-disable-next-line no-continue -- legacy code
          continue;
        }

        queryErrorElements[index] = (
          <div
            key={index}
            className={classNames(styles.line, styles.highlight)}
          >
            {line}
          </div>
        );
        queryErrorElements[previousIndex] = (
          <ErrorLine
            key={previousIndex}
            lineMatch={lineMatch}
          />
        );
      }
    }

    return (
      <div
        data-testid="query-error-message"
        className={styles.message}
      >
        {queryErrorElements}
      </div>
    );
  }
  return null;
};
