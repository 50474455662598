import { useState } from "react";

export const useHover = () => {
  const [hovered, setHoverState] = useState(false);
  const handleMouseOver = () => {
    !hovered && setHoverState(true);
  };

  const handleMouseLeave = () => {
    hovered && setHoverState(false);
  };

  return {
    hovered,
    handleMouseOver,
    handleMouseLeave,
  };
};
