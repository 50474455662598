import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import Fit from "assets/icons/explain/ZoomFit.svg?react";
import Minus from "assets/icons/explain/ZoomMinus.svg?react";
import Add from "assets/icons/explain/ZoomPlus.svg?react";

import { ExplainType } from "pages/DevelopWorkspace/workspace.types";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import OutlinedSelect from "components/OutlinedSelect";

import { DiagramActionContext, DiagramContext } from "../DiagramContext";
import { FIT_CENTER, ZOOM } from "../actions";

import styles from "./styles.module.scss";

type Props = {
  explainType: string;
  onExplainChange: (type: ExplainType) => void;
  isProfile?: boolean;
};

export const Controls = (props: Props) => {
  const { explainType, onExplainChange, isProfile } = props;
  const { draggable } = useContext(DiagramContext);
  const dispatch = useContext(DiagramActionContext);
  const { t } = useTranslation();

  const explainOptions = [
    {
      name: t("explain.controls.physical"),
      value: "physical",
    },
    {
      name: t("explain.controls.logical"),
      value: "logical",
    },
  ];

  const toggleFitCenter = useCallback(() => {
    dispatch({ type: FIT_CENTER });
  }, [dispatch]);

  const zoomUp = () => {
    const rect = draggable.current.getBoundingClientRect();
    const delta = 0.25;

    const ox = ((rect.left - rect.right) / 2) * delta;
    const oy = ((rect.top - rect.bottom) / 2) * delta;

    dispatch({ type: ZOOM, delta, ox, oy });
  };

  const zoomDown = () => {
    const rect = draggable.current.getBoundingClientRect();
    const delta = -0.25;

    const ox = ((rect.left - rect.right) / 2) * delta;
    const oy = ((rect.top - rect.bottom) / 2) * delta;
    dispatch({ type: ZOOM, delta, ox, oy });
  };

  return (
    <div className={styles.controls}>
      <Add
        className={styles.control}
        onClick={zoomUp}
        data-testid="zoom-up"
      />
      <Minus
        className={styles.control}
        onClick={zoomDown}
        data-testid="zoom-down"
      />
      <Fit
        className={styles.control}
        onClick={toggleFitCenter}
        data-testid="zoom-fit"
      />

      {!isProfile && (
        <OutlinedSelect
          className={styles.select}
          renderValue={([selected]) => {
            const item = explainOptions.find(({ value }) => value === selected);
            return item?.name;
          }}
          initialSelected={[explainType]}
          onSelect={([value]) => {
            onExplainChange(value as ExplainType);
          }}
          testId="explain-type"
        >
          {explainOptions.map(option => {
            const { value, name } = option;
            return (
              <ContextMenuItem
                value={value}
                key={name}
                checked={explainType === value}
                text={name}
              />
            );
          })}
        </OutlinedSelect>
      )}
    </div>
  );
};
