import { FormatDataStep, MapDataStep } from "../types";
import { WizardMapData } from "./Table/types";

const types = [
  "date",
  "date null",
  "timestamp",
  "timestamptz",
  "timestamp null",
  "timestamptz null",
];

const getCardinality = (column: WizardMapData) => {
  const uniqueValues = new Set();
  const { data } = column;
  for (const value of data) {
    uniqueValues.add(value);
  }
  return uniqueValues.size;
};

const getIndexByCardinality = (data: WizardMapData[]) => {
  let index = null;
  let indexCardinality = Infinity;
  for (const column of data) {
    const cardinality = getCardinality(column);
    if (cardinality > 2 && cardinality < 50 && cardinality < indexCardinality) {
      index = column;
      indexCardinality = cardinality;
    }
  }
  return index;
};

const getIndexByName = (data: WizardMapData[]) => {
  for (const column of data) {
    const { name, overrideName } = column;
    if ((overrideName || name).match(/date/i)) {
      return column;
    }
  }
  return null;
};

export const getPrimaryIndex = (mapData: WizardMapData[]) => {
  const data = mapData.filter(row => row.included);
  for (const column of data) {
    const { type } = column;
    if (types.includes(type)) {
      return column;
    }
  }
  const byCardinality = getIndexByCardinality(data);

  if (byCardinality) {
    return byCardinality;
  }

  const byName = getIndexByName(data);

  if (byName) {
    return byName;
  }

  return data[0];
};

export const normalizeWizardData = (
  data: FormatDataStep["previewData"]
): MapDataStep["data"] => {
  const dataPreviewLength = data.data.length > 20 ? 20 : data.data.length;
  return data.meta.map(column => {
    return {
      name: column.name,

      type: column.type,
      included: true,
      data: data.data.map(d => d[column.name]),
      dataPreview: data.data
        .slice(0, dataPreviewLength)
        .map(d => d[column.name])
        .join(","),
    };
  });
};
