import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeUnload, useBlocker } from "react-router-dom";

import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";

export const useCloseConfirm = ({
  onClose,
  isDirty,
  confirmationModalTitle,
  confirmationModalMessage,
  blockerTitle,
  blockerMessage,
}: {
  onClose: () => void;
  isDirty: boolean;
  confirmationModalMessage: string;
  confirmationModalTitle: string;
  blockerTitle: string;
  blockerMessage: string;
}) => {
  const { t } = useTranslation();

  const [closeConfirmation, setCloseConfirmation] = useState(false);

  const openConfirmation = () => {
    setCloseConfirmation(true);
  };

  const blocker = useBlocker(() => {
    if (isDirty) {
      return true;
    }
    return false;
  });

  useBeforeUnload(event => {
    if (isDirty) {
      event.preventDefault();
    }
  });

  const handleCloseConfirm = () => {
    setCloseConfirmation(false);
    onClose();
  };

  const confirmationMarkup = (
    <>
      {closeConfirmation && (
        <ConfirmationModal
          onClose={() => setCloseConfirmation(false)}
          onConfirm={handleCloseConfirm}
          title={confirmationModalTitle}
          message={confirmationModalMessage}
          confirmButtonOptions={{
            text: t("wizard.close_confirmation.close"),
          }}
          cancelButtonOptions={{
            text: t("wizard.close_confirmation.cancel"),
          }}
        />
      )}

      {blocker.state === "blocked" && (
        <ConfirmationModal
          onClose={() => {
            if (blocker.state === "blocked") {
              blocker.reset();
            }
          }}
          onConfirm={() => {
            if (blocker.state === "blocked") {
              blocker.proceed();
            }
          }}
          title={blockerTitle}
          message={blockerMessage}
          confirmButtonOptions={{
            text: t("wizard.blocker.confirm"),
          }}
          cancelButtonOptions={{
            text: t("wizard.blocker.cancel"),
          }}
        />
      )}
    </>
  );

  return {
    confirmationMarkup,
    openConfirmation,
  };
};
