import { useCallback, useEffect, useRef, useState } from "react";

import { Row } from "components/Datagrid/types";

import {
  EMPTY,
  WHITESPACE,
  WHITESPACE_VALUE,
  filterMap,
  getLabel,
  includeFilter,
  isValueEmpty,
  sortCandidates,
} from "../utils/filter";
import { sortRows } from "../utils/sortRows";

const filterRows = ({
  originalRows,
  filters,
  isHidden = false,
  previewName,
}: {
  originalRows: React.MutableRefObject<Row[]>;
  filters: any;
  isHidden?: boolean;
  previewName?: string;
}) => {
  const filterEntries = Object.entries<any>(filters);

  if (!filterEntries.length) {
    return originalRows.current;
  }

  const columnMap: {
    [key: string]: any;
  } = {};

  for (const [column, columnFilters] of filterEntries) {
    const predicate = (value: any) => {
      if (column !== previewName && isHidden) {
        return true;
      }

      let allColumnFiltersPass = true;

      const { include, filters = {} } = columnFilters;
      const { items = [] } = filters;

      if (include) {
        allColumnFiltersPass = includeFilter(include, value);
      }

      for (const filter of items) {
        const { type } = filter;
        const filterFunction = filterMap[type as keyof typeof filterMap];

        allColumnFiltersPass = filterFunction(filter, value);
        if (!allColumnFiltersPass) {
          return false;
        }
      }

      return allColumnFiltersPass;
    };

    columnMap[column] = predicate;
  }

  return originalRows.current.filter(row => {
    let allColumnsPass = true;
    for (const key in row) {
      const value = row[key];

      const predicate = columnMap[key];

      if (predicate) {
        allColumnsPass = predicate(value);
      }

      if (!allColumnsPass) {
        return false;
      }
    }
    return allColumnsPass;
  });
};

const getItemsWithCount = ({
  column,
  originalRows,
  filters,
  isHidden = false,
}: {
  column: any;
  originalRows: React.MutableRefObject<Row[]>;
  filters: any;
  isHidden?: boolean;
}) => {
  const { name, _typeUpperCase } = column;
  const valueCount = new Map();

  const filteredRows = filterRows({
    originalRows,
    filters,
    isHidden,
    previewName: name,
  });

  for (const row of filteredRows) {
    const value = row[name];
    let key = value;
    if (typeof value === "string" && value.match(WHITESPACE_VALUE)) {
      key = WHITESPACE;
    }
    if (isValueEmpty(value)) {
      key = EMPTY;
    }
    valueCount.set(key, (valueCount.get(key) || 0) + 1);
  }

  const items = Array.from(valueCount.entries())
    .sort(sortCandidates(_typeUpperCase))
    .map(([value, count]) => {
      const label: string = getLabel(value, _typeUpperCase);
      return {
        value,
        count,
        label,
      };
    });

  return items;
};

export const useColumnFilters = ({
  setRows,
  originalRows,
  columns,
}: {
  setRows: any;
  originalRows: React.MutableRefObject<Row[]>;
  columns: any;
}) => {
  const [filters, setFilters] = useState<{
    [key: string]: any;
  }>({});

  const clearFilters = useCallback(() => {
    setFilters({});
  }, []);

  const getFilters = useCallback(() => {
    return filters;
  }, [filters]);

  const setColumnFilters = useCallback((column: any, filters: any) => {
    const { name } = column;
    setFilters(currentFilters => {
      return {
        ...currentFilters,
        [name]: filters,
      };
    });
  }, []);

  const getColumnFilters = useCallback(
    (column: any) => {
      const { name } = column;
      return filters[name];
    },
    [filters]
  );

  const getColumnCandidates = useCallback(
    (column: any, localFilters: any) => {
      const totalFilters = {
        ...filters,
        [column.name]: { filters: localFilters },
      };

      return getItemsWithCount({
        column,
        originalRows,
        filters: totalFilters,
        isHidden: localFilters?.hidden,
      });
    },
    [originalRows, filters]
  );

  const applyFilters = useCallback(() => {
    const filteredRows = filterRows({
      originalRows,
      filters,
      previewName: undefined,
    });
    const sortColumn = columns.find(
      (column: { sortConfig: { direction: string } }) => column.sortConfig
    );

    if (sortColumn) {
      const { sortConfig } = sortColumn;
      const sortedRows = sortRows({
        rows: filteredRows as {
          [key: string]: string;
        }[],
        destinationSortConfig: sortConfig,
        column: sortColumn,
        originalRows,
      }) as Row[];
      setRows(sortedRows);
    } else {
      setRows(filteredRows);
    }
  }, [filters, setRows, originalRows, columns]);

  const applyFiltersRef = useRef(applyFilters);
  applyFiltersRef.current = applyFilters;

  useEffect(() => {
    applyFiltersRef.current();
  }, [filters]);

  return {
    clearFilters,
    getFilters,
    setColumnFilters,
    getColumnFilters,
    getColumnCandidates,
    applyFiltersRef,
  };
};
