export const getOtherGroupsObjects = (
  groups: { objects: string[] }[],
  index: number
): Record<string, boolean> => {
  const otherGroupsObjects = groups.reduce<Record<string, boolean>>(
    (acc, group, groupIndex) => {
      if (index === groupIndex) {
        return acc;
      }

      for (const object of group.objects) {
        acc[object] = true;
      }
      return acc;
    },
    {}
  );
  return otherGroupsObjects;
};

export const visibleItemsCount = 3;
