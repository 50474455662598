
import { getStatisticsRowData } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentStatistics/QueryStatementsTable/StatisticsRow";
import { QueryStatement } from "pages/DevelopWorkspace/workspace.types";

export const getExportData = (queryStatements: QueryStatement[]) => {
  const rows = queryStatements.map((queryStatement, index) => {
    const {
      rowCount,
      executionTimeSec,
      bytesRead,
      rowsPerSec,
    } = getStatisticsRowData(queryStatement);

    const row: Record<string, unknown> = {
      no: index + 1,
      statement: queryStatement.content,
      status: queryStatement.status,
      duration: executionTimeSec,
      rows: rowCount,
      scannedBytes: bytesRead,
      rowsPerSec,
    };

    return row;
  });

  return {
    rows,
  };
};
