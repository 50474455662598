import { systemEngineEnvironment } from "services/environment/systemEngine";

type Table = {
  tableName: string;
  tableType: string;
};
export const getViews = async (database: string) => {
  const result = await systemEngineEnvironment.execute<Table>(
    `SELECT table_name, view_definition FROM information_schema.views WHERE table_schema = 'public' order by table_name`,
    { database }
  );

  const [response] = result;
  return response.rows;
};
