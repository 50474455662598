import classNames from "classnames";
import React from "react";

import Tooltip from "../Tooltip/Tooltip";

import styles from "./styles.module.scss";

type Props = {
  noIndent?: boolean;
  className?: string;
  icon?: React.ReactElement;
  label?: React.ReactElement;
  testId?: string;
  htmlTitle?: string;
};

export const RecordLabelWithIcon = (props: React.PropsWithChildren<Props>) => {
  const { icon, label, noIndent, className, testId, htmlTitle } = props;
  return (
    <div
      className={classNames(styles.label, className, {
        [styles.noIndent]: noIndent,
      })}
      data-testid={testId}
      title={htmlTitle}
    >
      <div className={styles.label__prefix}>
        <span className={styles.icon}>{icon}</span>
        {label ? (
          <Tooltip
            title={label}
            placement="top-start"
          >
            {label}
          </Tooltip>
        ) : (
          label
        )}
      </div>
    </div>
  );
};
