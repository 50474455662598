import React, { useCallback, useContext, useMemo } from "react";

import { Interactions } from "components/Datagrid/Interactions";
import {
  InteractionsActionsContext,
  StickyGridContext,
} from "components/Datagrid/context";
import { EDIT } from "components/Datagrid/state/actions";

import { Header } from "./Header";
import { STICKY_EXPANDED_HEIGHT, STICKY_HEIGHT } from "./constant";

import styles from "./Datagrid.module.scss";

const getRenderedCursor = (children: any[], columns: any[]) => {
  if (!children.length) {
    return [0, columns.length - 1];
  }
  return children.reduce(
    ([minColumn, maxColumn], { props: { columnIndex } }) => {
      if (columnIndex < minColumn) {
        // eslint-disable-next-line no-param-reassign -- legacy code
        minColumn = columnIndex;
      }

      if (columnIndex > maxColumn) {
        // eslint-disable-next-line no-param-reassign -- legacy code
        maxColumn = columnIndex;
      }

      return [minColumn, maxColumn];
    },
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
  );
};

const innerGridElementType = React.forwardRef<HTMLDivElement, any>(
  ({ children, style, ...rest }, ref) => {
    const { columns, dropdownExpand } = useContext(StickyGridContext);
    const [minColumn, maxColumn] = getRenderedCursor(children, columns);
    const { getCursorIndex, dispatch } = useContext(InteractionsActionsContext);

    const handleDoubleClick = useCallback(
      (event: any) => {
        const cursorIndex = getCursorIndex(event);
        if (cursorIndex) {
          dispatch({ type: EDIT, cursorIndex });
        }
      },
      [dispatch, getCursorIndex]
    );

    const wrapperStyle = useMemo(() => {
      return {
        top: dropdownExpand ? STICKY_EXPANDED_HEIGHT : STICKY_HEIGHT,
      };
    }, [dropdownExpand]);

    return (
      <div
        ref={ref}
        {...rest}
        style={style}
      >
        <Header
          minColumn={minColumn}
          maxColumn={maxColumn}
        />
        <div
          className={styles.container}
          style={wrapperStyle}
          data-testid="datagrid-container"
          onDoubleClick={handleDoubleClick}
        >
          {children}
          <Interactions />
        </div>
      </div>
    );
  }
);

export default innerGridElementType;
