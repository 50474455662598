import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { Suspense, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SqlRbacAction } from "services/rbac/action";
import { RBAC_NAME_TYPE_MAP } from "services/rbac/constants";
import { Role } from "services/rbac/types";

import { CrossIcon } from "components/Icons";

import { Transaction } from "../../PrivilegesTransaction";
import { PrivilegesContext } from "../../usePrivilegesContext";
import { ReadOnlyContext } from "../../useReadOnly";
import { DatabasesList } from "./DatabasesList/DatabasesList";
import { EnginesList } from "./EnginesList/EnginesList";
import { Side } from "./Side";
import { Toggles } from "./Toggles";
import { usePrivileges } from "./usePrivileges";
import { useRoleNameChange } from "./useRoleNameChange";
import { useTabs } from "./useTabs";
import { DATABASE_TAB, ENGINE_TAB } from "./utils";

import styles from "./styles.module.scss";

type Props = {
  onConfirm: (transaction: Transaction) => void;
  confirmText?: string;
  onClose: () => void;
  title: string;
  isLoading?: boolean;
  readOnly?: boolean;
  affectedOnly?: boolean;
  disableNameChange?: boolean;
  role?: Role;
  roles: Role[];
  testId?: string;
};

export const Modal = (props: Props) => {
  const {
    isLoading,
    title,
    onClose,
    onConfirm,
    confirmText,
    readOnly,
    affectedOnly,
    disableNameChange,
    role,
    roles,
    testId,
  } = props;

  const {
    roleName,
    roleNameTrimed,
    initialRoleName,
    validate,
    nameError,
    handleRoleNameChange,
  } = useRoleNameChange(role, roles);

  const { selectTab, selectedTab, tabHeader, tabDescription, toggleTypes } =
    useTabs(isLoading);

  const {
    state: privilegesState,
    dispatch: privilegesDispatch,
    getDiff,
  } = usePrivileges(role);
  const { t } = useTranslation();

  const context = useMemo(() => {
    return {
      privilegesDispatch,
      privilegesState,
    };
  }, [privilegesState, privilegesDispatch]);

  const { toAssign, toRevoke } = getDiff();

  const pristine =
    !toAssign.length && !toRevoke.length && roleName === initialRoleName;

  const isSaveDisabled = readOnly ? true : pristine || validate(roleNameTrimed);

  const isActionDisabled = useCallback(
    (_type: string, _action: SqlRbacAction) => {
      if (!role) {
        return false;
      }
      const roleType =
        RBAC_NAME_TYPE_MAP[role.roleName as keyof typeof RBAC_NAME_TYPE_MAP];
      if (roleType) {
        return true;
      }
      return false;
    },
    [role]
  );

  const handleConfirm = () => {
    if (nameError) {
      return;
    }
    const transaction = {
      initialRoleName,
      toAssign: [],
      toRevoke: [],
    };

    if (roleNameTrimed !== initialRoleName) {
      Object.assign(transaction, { roleName: roleNameTrimed });
    }

    const { toAssign, toRevoke } = getDiff();

    Object.assign(transaction, {
      toAssign,
      toRevoke,
    });

    onConfirm(transaction);
  };

  const readOnlyContext = useMemo(() => {
    return {
      readOnly: Boolean(readOnly || isLoading),
      affectedOnly: Boolean(affectedOnly),
      isActionDisabled,
    };
  }, [readOnly, isLoading, affectedOnly, isActionDisabled]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      scroll="body"
      data-testid={testId}
      open
    >
      {readOnly ? (
        <DialogTitle
          id="dialog-title"
          classes={{ root: styles.close }}
        >
          <IconButton
            onClick={onClose}
            edge="start"
            size="small"
          >
            <CrossIcon />
          </IconButton>
        </DialogTitle>
      ) : null}
      <div
        className={styles.wrapper}
        data-testid="create-role-form"
      >
        <PrivilegesContext.Provider value={context}>
          <ReadOnlyContext.Provider value={readOnlyContext}>
            <Side
              roleName={roleName}
              handleRoleNameChange={handleRoleNameChange}
              title={title}
              confirmText={confirmText}
              onClose={onClose}
              onConfirm={handleConfirm}
              readOnly={readOnly}
              disableNameChange={disableNameChange}
              nameError={nameError}
              selectTab={selectTab}
              selectedTab={selectedTab}
              isLoading={isLoading}
              isDisabled={Boolean(isSaveDisabled)}
            />
            <div className={styles.main}>
              <div className={styles.header}>{t(tabHeader)}</div>
              <div className={styles.description}>{t(tabDescription)}</div>
              <Toggles
                toggleTypes={toggleTypes}
                selectedTab={selectedTab}
              />
              <Suspense fallback={null}>
                {selectedTab === DATABASE_TAB && <DatabasesList />}
                {selectedTab === ENGINE_TAB && <EnginesList />}
              </Suspense>
            </div>
          </ReadOnlyContext.Provider>
        </PrivilegesContext.Provider>
      </div>
    </Dialog>
  );
};
