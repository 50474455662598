import { MutableRefObject } from "react";

import { DocumentExecutionRuntime } from "./types";

export const delay = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const isQueryStatementRunning = (
  documentsExecutionRuntime: MutableRefObject<
    Map<string, DocumentExecutionRuntime>
  >,
  documentId: string,
  queryStatementId: string
) => {
  const documentRuntime = documentsExecutionRuntime.current.get(documentId);

  if (!documentRuntime) {
    // document has finished execution
    return false;
  }

  if (documentRuntime.id !== queryStatementId) {
    // document executes another query statement
    return false;
  }

  return true;
};
