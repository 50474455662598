import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { OrganizationService } from "services/organization";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { ReactQueryKeysGlobal } from "../services/queryKeys";

export const useOrganization = () => {
  const { putStatusMessage } = useStatusMessageQueue();
  const { data, isLoading, isError, error } = useSuspenseQuery({
    queryKey: [ReactQueryKeysGlobal.organization],
    queryFn: () => {
      return OrganizationService.getOrganization();
    },
  });

  useEffect(() => {
    if (isError) {
      putStatusMessage({
        message: error?.message,
        type: StatusMessageType.Error,
      });
    }
  }, [isError, error, putStatusMessage]);

  return { data, isLoading };
};
