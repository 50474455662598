import { useTranslation } from "react-i18next";

import Expand from "assets/icons/workspace/Header/ExpandSidebar.svg?react";

import { useLayoutToggles } from "components/LayoutToggles/context";

import ArrowLeft from "./Arrow.svg?react";

import styles from "./styles.module.scss";

export const CollapsedHeader = () => {
  const { layout, setLayout } = useLayoutToggles();
  const { t } = useTranslation();
  const handleToggleExpand = () => {
    setLayout({
      ...layout,
      engines: {
        ...layout.engines,
        expanded: true,
      },
    });
  };

  return (
    <div className={styles.header}>
      <span className={styles.header__title}>
        {t("workspace.engines.title")}
        <ArrowLeft style={{ opacity: 0 }} />
      </span>
      <button
        className={styles.expand}
        onClick={handleToggleExpand}
        data-testid="expand-engines-explorer-btn"
      >
        <Expand className={styles.expand__icon} />
      </button>
    </div>
  );
};
