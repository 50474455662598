import styles from "./styles.module.scss";

type Props = {
  testId?: string;
};

export const Panel = (props: React.PropsWithChildren & Props) => {
  const { children, testId } = props;
  return (
    <div
      className={styles.panel}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
