import { Divider, FormControl, Input } from "@mui/material";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button, { ButtonSize, ButtonTemplate } from "components/Button/Button";
import { Ticker } from "components/Ticker/Ticker";

import styles from "./styles.module.scss";

const THIRTY_DAYS_IN_MINUTES = 30 * 24 * 60;

const validateInput = (t: TFunction) => (value: number | string) => {
  const errors: string[] = [];
  if (value !== "" && +value <= 0) {
    errors.push(t("engines.autostop.gt_than_zero"));
  }
  if (+value > THIRTY_DAYS_IN_MINUTES) {
    errors.push(t("engines.autostop.less_than"));
  }
  return errors;
};

type Props = {
  handleChange: (name: "autoStopAfter", value: number) => void;
  closeMenu: () => void;
  autoStopAfter: number;
};

export const AutoStopTimeout = (props: Props) => {
  const { closeMenu, handleChange, autoStopAfter } = props;
  const [value, setValue] = useState<number | string>(
    Math.round(autoStopAfter / 60)
  );
  const [errors, setErrors] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleChangeValue = (value: number | string) => {
    if (Number.isNaN(+value)) {
      return;
    }

    const errors = validateInput(t)(value);
    setErrors(errors);
    setValue(value === "" ? value : +value);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeValue(event.target.value);
  };

  const apply = () => {
    handleChange("autoStopAfter", +value * 60);
    closeMenu();
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      event.stopPropagation();
      apply();
    }
  };

  const label = Math.abs(+value) > 1 || value === 0 ? "minutes" : "minute";

  return (
    <div
      className={styles.wrapper}
      data-testid="custom-autostop"
      onKeyPress={handleKeyPress}
    >
      <h2>{t("engines.autostop.title")}:</h2>
      <div className={styles.minutes}>
        <FormControl>
          <div className={styles.inputContainer}>
            <Input
              data-testid="custom-autostop-input"
              type="text"
              value={value}
              name="input"
              onChange={handleChangeInput}
              classes={{ root: styles.inputRoot, input: styles.input }}
              autoFocus
              required={false}
            />
            <Ticker
              onUp={() => handleChangeValue(+value + 1)}
              onDown={() => handleChangeValue(+value - 1)}
              upTestId="custom-autostop-up"
              downTestId="custom-autostop-down"
            />
          </div>
        </FormControl>
        <span className={styles.minutes__label}>{label}</span>
      </div>
      <div className={styles.errors}>
        {errors.map((error, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <div key={index}>{error}</div>;
        })}
      </div>

      <Divider className={styles.divider} />
      <div className={styles.footer}>
        <Button
          dataTestid="custom-autostop-apply"
          text={t("engines.autostop.apply")}
          size={ButtonSize.Small}
          onClick={apply}
          disabled={!!errors.length || value === ""}
        />
        <Button
          dataTestid="custom-autostop-cancel"
          text={t("engines.autostop.cancel")}
          template={ButtonTemplate.Secondary}
          size={ButtonSize.Small}
          onClick={closeMenu}
        />
      </div>
    </div>
  );
};
