const withNullableTypes = (types: string[]): string[] => {
  return types.reduce(
    (acc, type) => [...acc, type, `${type} NULL`, `${type} NOT NULL`],
    [] as string[]
  );
};

export const TIMESTAMP_TYPES = withNullableTypes([
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMESTAMPNTZ",
  "DATETIME",
]);

export const DATE_TYPES = withNullableTypes([
  "DATE",
  "PGDATE",
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMESTAMPNTZ",
  "DATETIME",
]);

export const FLOAT_TYPES = withNullableTypes([
  "FLOAT",
  "DOUBLE",
  "DECIMAL",
  "REAL",
  "DOUBLE PRECISION",
  "NUMERIC",
]);

export const BOOLEAN_TYPES = withNullableTypes(["BOOLEAN"]);

export const INTEGER_TYPES = withNullableTypes([
  "INT",
  "INTEGER",
  "LONG",
  "BIGINT",
]);

export const STRING_TYPES = withNullableTypes(["STRING", "TEXT"]);

export const NUMBER_TYPES = [...INTEGER_TYPES, ...FLOAT_TYPES];

export const isDateType = (type: string) => {
  return DATE_TYPES.includes(type);
};

export const isTimestampType = (type: string) => {
  return TIMESTAMP_TYPES.includes(type);
};

export const isDecimalType = (type: string) => {
  return type.match(/^(decimal|numeric)(.+)?/i);
};

export const isNumberType = (type: string) => {
  return NUMBER_TYPES.includes(type) || isDecimalType(type);
};

export const isStringType = (type: string) => {
  return STRING_TYPES.includes(type);
};

export const isBooleanType = (type: string) => {
  return BOOLEAN_TYPES.includes(type);
};
