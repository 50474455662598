import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import React, { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { authService } from "services/auth";
import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";
import { ReactQueryKeysAccount } from "services/queryKeys";

import DocumentOutputRefreshIcon from "assets/icons/DocumentOutputRefreshIcon.svg?react";

import ErrorMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ErrorMessage/ErrorMessage";
import styles from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/EngineMonitoring.module.scss";
import QueriesChart from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/QueriesChart";
import UtilChart from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/UtilChart";
import {
  TIME_WINDOWS,
  TimeGrouping,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/constants";
import { createEngineMonitoringSQLQuery } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/helpers/createEngineMonitoringSQLQuery";
import EngineFallbackMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/EngineFallbackMessage/EngineFallbackMessage";
import NoEngineMonitoringData from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/NoEngineMonitoringData/NoEngineMonitoringData";
import OutputSubPanel from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/OutputSubPanel/OutputSubPanel";
import { FIREBOLT_UI_USER_ENGINE_QUERY_LABEL } from "pages/DevelopWorkspace/services/constants";
import executeQueryStatement from "pages/DevelopWorkspace/services/helpers/executeQueryStatement";
import { SYSTEM_ENGINE } from "pages/DevelopWorkspace/workspace.constants";
import {
  QueryStatement,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import BadgeSelect from "components/BadgeSelect";
import LoadingOverlap from "components/LoadingOverlap";
import Tooltip from "components/Tooltip/Tooltip";

interface EngineMonitoringProps {
  engine: WorkspaceEngine;
  document: WorkspaceDocument;
}

const DEFAULT_TIME_WINDOW = TIME_WINDOWS[0].value;

export type MeteringRecord = {
  cluster_ordinal: string;
  cpu_avg: number;
  memory_avg: number;
  disk_avg: number;
  cache_avg: number;
  running_queries: number;
  suspended_queries: number;
  spilled_bytes: number;
  timestamp_c: string;
};

const ENGINE_MONITORING_ALLOWED_ENGINE_STATUSES = [
  WorkspaceEngineStatus.RUNNING,
  WorkspaceEngineStatus.DRAINING,
  WorkspaceEngineStatus.RESIZING,
];

const EngineMonitoring = (props: EngineMonitoringProps) => {
  const { engine, document } = props;
  const { t } = useTranslation();
  const [timeWindow, setTimeWindow] =
    React.useState<string>(DEFAULT_TIME_WINDOW);

  const [clusters, setClusters] = React.useState<string[]>([]);
  const [selectedClusters, setSelectedClusters] = React.useState<string[]>([]);

  const showFallback =
    !ENGINE_MONITORING_ALLOWED_ENGINE_STATUSES.includes(engine.status) ||
    engine.engineName === SYSTEM_ENGINE.engineName;

  const getTimeGrouping = (): TimeGrouping => {
    return TIME_WINDOWS.find(tw => tw.value === timeWindow)
      ?.timeGrouping as TimeGrouping;
  };

  useEffect(() => {
    // reset selected clusters when clusters change
    setSelectedClusters([]);
  }, [clusters]);

  const { data, isFetching, isLoading, error, refetch } = useQuery({
    queryKey: [
      ReactQueryKeysAccount.engineMonitoring,
      engine.engineName,
      engine.lastStarted,
      timeWindow,
    ],
    queryFn: async () => {
      if (!engine) {
        throw new Error(t("engine_monitoring.fallback.engine_not_found"));
      }

      if (!ENGINE_MONITORING_ALLOWED_ENGINE_STATUSES.includes(engine.status)) {
        throw new Error(t("engine_monitoring.fallback.engine_not_running"));
      }

      const abortController = new AbortController();

      const fetchOptions = {
        signal: abortController.signal,
        headers: {
          "Firebolt-Machine-Query": "1",
        },
      };

      const queryStatement: QueryStatement = {
        content: createEngineMonitoringSQLQuery(timeWindow, getTimeGrouping()),
        status: QueryStatementStatus.pending,
        id: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
        responseStatusCode: null,
        result: null,
        error: null,
        statistics: null,
        sourceDocLineNumber: 0,
      };

      const doc: WorkspaceDocument = {
        ...document,
        context: {
          ...document.context,
          settings: {
            ...document.context.settings,
            auto_start_stop_control: "ignore",
            query_label: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
          },
        },
      };

      const response = await executeQueryStatement(
        queryStatement,
        doc,
        engine,
        fetchOptions,
        authService
      );

      return response.responseBody;
    },
    refetchOnMount: true,
    retry: false,
    refetchInterval: 5000,
    enabled: !showFallback,
  });

  const { result, meta } = useMemo(() => {
    if (!data || !data.data || !data.meta) {
      return {
        result: undefined,
        meta: undefined,
      };
    }

    const result = data.data as MeteringRecord[];
    const meta = data.meta;

    const maxClusterOrdinal = result.reduce((acc, record) => {
      if (+record.cluster_ordinal > acc) {
        return +record.cluster_ordinal;
      }

      return acc;
    }, 0);

    setClusters((prev: string[]) => {
      const next = Array.from({ length: maxClusterOrdinal + 1 }, (_, i) =>
        i.toString()
      );
      if (prev.length === next.length) {
        return prev;
      }

      return next;
    });

    return {
      result,
      meta,
    };
  }, [data]);

  const renderMetricsChart = () => {
    return (
      <UtilChart
        result={result || []}
        clusters={selectedClusters}
        timeWindow={timeWindow}
        timeGrouping={getTimeGrouping()}
      />
    );
  };

  const renderQueriesChart = () => {
    return (
      <QueriesChart
        result={result || []}
        clusters={selectedClusters}
        timeWindow={timeWindow}
        timeGrouping={getTimeGrouping()}
      />
    );
  };

  const renderCharts = () => {
    if (error) {
      return null;
    }

    if (!result || !meta) {
      return null;
    }

    if (result && meta && !result.length) {
      return <NoEngineMonitoringData />;
    }

    return (
      <div
        className={styles.charts}
        data-testid="engine-monitoring-charts-container"
      >
        <div className={styles.chartWrapper}>{renderMetricsChart()}</div>
        <div className={styles.chartWrapper}>{renderQueriesChart()}</div>
      </div>
    );
  };

  const renderError = () => {
    if (error) {
      return <ErrorMessage errorMessage={(error as any).message as string} />;
    }
  };

  if (showFallback) {
    return (
      <EngineFallbackMessage
        engine={engine}
        message={
          <>
            <Trans
              i18nKey="engine_monitoring.fallback.not_running"
              components={{
                div: <div />,
                strong: <strong />,
              }}
            />
          </>
        }
        noPermissionMessage={
          <>
            <Trans
              i18nKey="engine_monitoring.fallback.no_permission_to_start"
              components={{
                div: <div />,
                strong: <strong />,
              }}
            />
          </>
        }
        messageSystemEngine={
          <>
            <Trans
              i18nKey="engine_monitoring.fallback.system_engine"
              components={{
                div: <div />,
                strong: <strong />,
              }}
            />
          </>
        }
      />
    );
  }

  return (
    <>
      <OutputSubPanel
        body={<div />}
        controls={
          <div className={styles.controls}>
            {clusters.length > 1 && (
              <BadgeSelect
                value={selectedClusters.length ? selectedClusters : []}
                onChange={newValue => {
                  setSelectedClusters(newValue.filter(v => v !== ""));
                }}
                multiple={true}
                options={[
                  {
                    label: "All clusters",
                    value: "",
                    isFallback: true,
                  },
                  ...clusters.map(cluster => ({
                    label: `Cluster ${+cluster + 1}`,
                    value: cluster,
                  })),
                ]}
                testId="clusters-selector"
                enableSearch={false}
              />
            )}

            <BadgeSelect
              value={[timeWindow]}
              onChange={newValue => setTimeWindow(newValue[0])}
              options={TIME_WINDOWS}
              testId="interval-selector"
              tooltipText={t("engine_monitoring.time_interval")}
              enableSearch={false}
            />

            <Tooltip
              enterDelay={300}
              title={t("engine_monitoring.refresh")}
              placement="top-start"
            >
              <div
                data-testid="refresh-button"
                onClick={() => {
                  if (!isFetching) {
                    refetch();
                  }
                }}
                className={cn(styles.refreshIcon, {
                  [styles.disabled]: isFetching,
                })}
              >
                <DocumentOutputRefreshIcon />
              </div>
            </Tooltip>
          </div>
        }
      />

      <div
        className={styles.body}
        data-testid="engine-monitoring-content"
      >
        {renderError()}
        {renderCharts()}
        {isLoading && <LoadingOverlap isLoading={true} />}
      </div>
    </>
  );
};

export default EngineMonitoring;
