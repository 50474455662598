import React from "react";

import WarningIcon24 from "assets/icons/WarningIcon24.svg?react";

import Tooltip from "components/Tooltip";

import styles from "./Warning.module.scss";

interface WarningProps {
  messages: JSX.Element[];
}

const Warning = (props: WarningProps) => {
  const { messages } = props;

  return (
    <Tooltip
      placement="top"
      title={
        <div>
          {messages.map((message, index) => {
            // eslint-disable-next-line react/no-array-index-key -- it's ok here
            return <div key={index}>{message}</div>;
          })}
        </div>
      }
    >
      <div className={styles.warning}>
        <WarningIcon24 data-testid="results-warning-icon" />
      </div>
    </Tooltip>
  );
};

export default Warning;
