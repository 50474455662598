import { privateApi } from "services/api/private";

export const getOrganizationCredit = async (
  billingVersion: 1 | 2
): Promise<{
  allocatedCredit?: number;
  usedCredit?: number;
  availableCredit: number;
  expirationDate?: string;
  units: "usd" | "fbu";
}> => {
  if (billingVersion === 1) {
    const response = await privateApi.get<{ balance: number }>(
      "/getTrialPromotionBalance"
    );
    return {
      availableCredit: response.data.balance,
      units: "fbu",
    };
  }

  // if billingVersion is 2
  const response = await privateApi.get<{
    allocatedCredit: number;
    usedCredit: number;
    expirationDate: string;
  }>("/getOrganizationCredit");

  const { allocatedCredit, usedCredit, expirationDate } = response.data;
  const availableCredit =
    allocatedCredit - usedCredit >= 0 ? allocatedCredit - usedCredit : 0;

  return {
    allocatedCredit,
    usedCredit,
    availableCredit,
    expirationDate,
    units: "usd",
  };
};
