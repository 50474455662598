import { TextFieldProps } from "@mui/material";
import classNames from "classnames";
import React, { useContext, useState } from "react";

import { InputDisabledContext } from "components/InputState/InputDisabledContext";

import styles from "./styles.module.scss";

type Props = {
  inputRootClassName?: string;
  testId?: string;
  showAsterisk?: boolean;
} & Omit<TextFieldProps, "variant">;

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      error,
      required,
      autoFocus,
      autoComplete,
      helperText,
      label,
      InputProps = {},
      onFocus,
      onBlur,
      onChange,
      onKeyDown,
      value,
      name,
      type,
      className,
      inputRootClassName,
      testId,
      placeholder,
      showAsterisk,
    } = props;
    const isDisabled = useContext(InputDisabledContext);
    const disabled = props.disabled || isDisabled;
    const [inputFocused, setInputFocused] = useState(false);
    const { startAdornment, endAdornment } = InputProps;

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(true);
      onFocus && onFocus(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(false);
      onBlur && onBlur(event);
    };

    const inputProps = {};
    if (value !== undefined) {
      Object.assign(inputProps, { value });
    }
    return (
      <div className={classNames(styles.root, className)}>
        {label && (
          <div className={styles.label}>
            {label}
            {showAsterisk && required && (
              <span className={styles.required}>*</span>
            )}
          </div>
        )}
        <div
          className={classNames(styles.inputRoot, inputRootClassName, {
            [styles.inputError]: !!error,
            [styles.focused]: inputFocused,
            [styles.disabled]: disabled,
            [styles.startAdornment]: !!startAdornment,
            [styles.endAdornment]: !!endAdornment,
          })}
        >
          <div className={styles.inputWrapper}>
            {startAdornment}
            <input
              className={styles.input}
              ref={ref}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={onChange}
              onKeyDown={onKeyDown}
              required={required}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              disabled={disabled}
              name={name}
              type={type}
              data-testid={testId}
              placeholder={placeholder}
              {...inputProps}
            />
            {endAdornment}
          </div>
          {error && (
            <p
              data-testid={`${testId}-error-message`}
              className={styles.helperText}
            >
              {helperText}
            </p>
          )}
        </div>
      </div>
    );
  }
);
