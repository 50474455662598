import { isMac } from "services/browser";

import { InteractionsConstants } from "../types";

export const isRightClick = ({ event }: { event: any }) => {
  if (isMac) {
    return event.ctrlKey || event.button === 2;
  }
  return event.button === 2;
};

export const KEY_CODES = {
  ESCAPE: 27,
  CONTROL: 17,
  COMMAND_LEFT: 91,
  COMMAND_RIGHT: 93,
  COMMAND_FIREFOX: 224,
};

export const isCtrlMetaKey = (keyCode: number) => {
  return [
    KEY_CODES.CONTROL,
    KEY_CODES.COMMAND_LEFT,
    KEY_CODES.COMMAND_RIGHT,
    KEY_CODES.COMMAND_FIREFOX,
  ].includes(keyCode);
};

export const onScrollbar = ({
  event,
  containerRef,
  constants,
}: {
  event: any;
  containerRef: {
    current: {
      getBoundingClientRect: () => {
        bottom: any;
        right: any;
      };
    };
  };
  constants: InteractionsConstants;
}) => {
  const { SCROLLBAR_WIDTH } = constants;
  const { bottom, right } = containerRef.current.getBoundingClientRect();

  const onRightScrollbar =
    event.clientX > right - SCROLLBAR_WIDTH && event.clientX < right;
  const onBottomScrollbar = event.clientY > bottom - SCROLLBAR_WIDTH;

  if (onBottomScrollbar || onRightScrollbar) {
    return true; // ignore on scrollbars
  }
};

export const getPointIndex = ({
  event,
  gridRef,
  containerRef,
  columnCount,
  rowCount,
  columnRightOffsets,
  getRowHeight,
  ignoreOutside = true,
  dropdownExpand,
  constants,
}: {
  event: any;
  gridRef: any;
  containerRef: any;
  columnCount: number;
  rowCount: number;
  columnRightOffsets: Array<number>;
  getRowHeight: (index: number) => number;
  ignoreOutside?: boolean;
  dropdownExpand?: boolean;
  constants: InteractionsConstants;
}) => {
  const { STICKY_EXPANDED_HEIGHT, STICKY_HEIGHT } = constants;
  const { top, left, right } = containerRef.current.getBoundingClientRect();
  const { scrollTop, scrollLeft } = gridRef.current.state;
  const { clientY, clientX } = event;

  const topOutside = clientY - top < 0;
  const leftOutside = clientX - left < 0;
  const rightOutside = clientX > right;

  const x = leftOutside ? 0 : clientX - left + scrollLeft;
  const y = topOutside ? 0 : clientY - top + scrollTop;

  const col = columnRightOffsets.findIndex(right => x < right);
  const stickyHeight = dropdownExpand ? STICKY_EXPANDED_HEIGHT : STICKY_HEIGHT;
  const row = Math.max(0, Math.floor((y - stickyHeight) / getRowHeight(0)));

  if (ignoreOutside && (topOutside || leftOutside || rightOutside)) {
    return null;
  }

  if (
    col === -1 ||
    row === -1 ||
    col > columnCount - 1 ||
    row > rowCount - 1 ||
    col == null ||
    row == null
  ) {
    // selection on short list
    return null;
  }

  return {
    row,
    col,
  };
};

export const getSelectionWidth = ({
  endCol,
  col,
  getColumnWidth,
}: {
  endCol: number;
  col: number;
  getColumnWidth: (index: number) => number;
}) => {
  const leftColumn = Math.min(endCol, col);
  const rightColumn = Math.max(endCol, col);
  let width = 0;
  for (let i = leftColumn; i <= rightColumn; i++) {
    width += getColumnWidth(i);
  }
  return width;
};

export const getTotalSelections = ({
  activeSelection,
  activeCell,
  selections,
}: {
  activeSelection: {
    row: number;
    col: number;
  };
  activeCell: {
    row: number;
    col: number;
  };
  selections: {
    start: {
      row: number;
      col: number;
    };
    end: {
      row: number;
      col: number;
    };
  }[];
}) => {
  return [
    ...(activeSelection
      ? [
          {
            start: activeSelection,
            end: activeCell,
          },
        ]
      : [{ start: activeCell, end: activeCell }]),
    ...selections,
  ];
};
