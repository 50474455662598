import React, { useRef } from "react";

import styles from "./styles.module.scss";

interface ErrorLineProps {
  lineMatch: string[];
}
export const ErrorLine = (props: ErrorLineProps) => {
  const { lineMatch } = props;
  const [, leading, highlight, trainling] = lineMatch;
  const line = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={styles.line}
      ref={line}
    >
      {leading}
      <span
        data-testid="error-highlight"
        className={styles.highlight}
      >
        {highlight}
      </span>
      {trainling}
    </div>
  );
};
