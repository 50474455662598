export type Column = {
  name: string;
  type: string;
  displayName?: string;
};

export type ColumnWithProps = Column & {
  sortConfig?: {
    sortOrder: string;
  } | null;
  width: number;
  initialWidth: number;
  textAlign?: string;
  _typeUpperCase: string;
};

export type Row = Record<string, string | number | null | Symbol>;

export const FAKE_SPACER = "FAKE_SPACER";

export type InteractionsConstants = {
  STICKY_HEIGHT: number;
  STICKY_EXPANDED_HEIGHT: number;
  SCROLLBAR_WIDTH: number;
};

export type InitialFieldWidthsConstants = {
  CELL_FONT: string;
  CELL_PADDING: number;
  DEFAULT_COLUMN_WIDTH: number;
  HEADER_FONT: string;
  MAX_TEXT_COLUMN_WIDTH: number;
  SORT_ICON: number;
  TIMESTAMP_WIDTH: number;
  DATE_WIDTH: number;
  TYPE_ICON: number;
  FILTER_ICON: number;
};
