export const downloadFile = (file: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
