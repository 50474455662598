import classNames from "classnames";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { WizardMapData } from "./types";

import styles from "../styles.module.scss";

export const EditingCell = (props: {
  data: WizardMapData[];
  editingRow: number;
  setEditingRow: Dispatch<SetStateAction<number | null>>;
  onChangeDestinationName: (row: number, name: string) => void;
}) => {
  const { editingRow, setEditingRow, onChangeDestinationName, data } = props;
  const ref = useRef<HTMLInputElement>(null);
  const row = data[editingRow];

  const [value, setValue] = useState(row.overrideName || row.name);

  const dependencies = useRef({
    setEditingRow,
    value,
    editingRow,
    onChangeDestinationName,
  });

  useEffect(() => {
    dependencies.current = {
      setEditingRow,
      value,
      editingRow,
      onChangeDestinationName,
    };
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const { setEditingRow, editingRow, onChangeDestinationName, value } =
        dependencies.current;
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        onChangeDestinationName(editingRow, value);
        setEditingRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, dependencies]);

  useEffect(() => {
    ref.current?.focus();
  }, [ref]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <input
      className={classNames(styles.column, styles.name, styles.editing)}
      value={value}
      onChange={handleChange}
      ref={ref}
    />
  );
};
