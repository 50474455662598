import { useCallback } from "react";

const onboardedValue = "onboarded";

export const useFirstTimeExperience = ({
  loginId,
  organizationName,
  hasExistingScripts,
}: {
  loginId: string;
  organizationName: string;
  hasExistingScripts: boolean;
}) => {
  const key = `${loginId}-${organizationName}-consumed-first-time-template`;

  if (hasExistingScripts && !localStorage.getItem(key)) {
    localStorage.setItem(key, onboardedValue);
  }

  const onboarded = localStorage.getItem(key);

  const isFirstTimeExperience = !onboarded;

  const onSampleScriptGenerated = useCallback(() => {
    localStorage.setItem(key, onboardedValue);
  }, [key]);

  return {
    shouldGenerateSampleScript: isFirstTimeExperience,
    onSampleScriptGenerated,
  };
};
