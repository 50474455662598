import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import DropdownArrowDownV2 from "assets/icons/DropdownArrowDownV2.svg?react";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Search } from "components/LeftSidebar/Search";
import Tooltip from "components/Tooltip/Tooltip";

import styles from "./BadgeSelect.module.scss";

export type BadgeSelectProps = {
  value: string[];
  onChange: (value: string[]) => void;
  options: {
    value: string;
    label: string;
    content?: React.ReactNode;
    isFallback?: boolean;
  }[];
  multiple?: boolean;
  testId: string;
  classes?: {
    popupMenu?: string;
  };
  emptyValueLabel?: string;
  tooltipText?: string;
  disabled?: boolean;
  enableSearch?: boolean;
  dropdownComponent?: React.FC<BadgeSelectProps>;
};

export const BadgeSelect = (props: BadgeSelectProps) => {
  const {
    value,
    onChange,
    options,
    testId,
    disabled,
    tooltipText,
    multiple = false,
    classes,
    enableSearch,
    dropdownComponent,
    emptyValueLabel,
  } = props;
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const intervalSelectorRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = React.useState<string>("");

  const labels = value.reduce((acc, v, idx) => {
    if (idx === 0) {
      const label = t(options.find(option => option.value === v)?.label ?? "");
      return acc + label;
    }

    const label = t(options.find(option => option.value === v)?.label ?? "");
    return acc + `, ${label}`;
  }, "");

  const fallbackOption = options.find(o => o.isFallback);
  const fallbackLabel =
    labels.length === 0 && fallbackOption ? t(fallbackOption.label) : "";

  const handleChange = (newValue: string) => {
    if (disabled) return;

    if (multiple) {
      if (!newValue) return onChange([]);

      const newSelectedValues = value.includes(newValue)
        ? value.filter(v => v !== newValue)
        : [...value, newValue];

      return onChange(newSelectedValues);
    }

    onChange(newValue ? [newValue] : []);
  };

  const optionsFiltered = enableSearch
    ? options.filter(option =>
        option.label.toLowerCase().includes(search.toLowerCase())
      )
    : options;

  const renderDropdownBody = () => {
    if (dropdownComponent) {
      const Component = dropdownComponent;
      return <Component {...props} />;
    }

    return (
      <>
        {enableSearch && !!options.length && (
          <div className={styles.search}>
            <Search
              testId="search"
              onChange={setSearch}
              value={search}
              autoFocus={true}
              size="small"
            />
          </div>
        )}

        <div className={styles.items}>
          {optionsFiltered.length === 0 && (
            <div className={styles.noResults}>
              {t("workspace.search.no_results")}
            </div>
          )}

          <ContextMenu className={cn(styles.popupMenu, classes?.popupMenu)}>
            {optionsFiltered.map(option => (
              <ContextMenuItem
                testId={`time-option-${option.value}`}
                key={option.value}
                text={option.content ? undefined : t(option.label)}
                content={option.content}
                checked={
                  value.includes(option.value) ||
                  (!!fallbackLabel && !!option.isFallback)
                }
                onClick={() => {
                  handleChange(option.value);
                  if (!multiple) {
                    setMenuOpen(false);
                  }
                }}
              />
            ))}
          </ContextMenu>
        </div>
      </>
    );
  };

  return (
    <>
      <Tooltip
        enterDelay={300}
        title={tooltipText}
        placement="top-start"
      >
        <div
          data-testid={testId || "badge-selector"}
          className={cn(styles.selector, {
            [styles.active]: menuOpen,
            [styles.disabled]: disabled,
            [styles.multiSelectNonDefault]: multiple && !!value.length,
          })}
          ref={intervalSelectorRef}
          onClick={() => {
            if (disabled) return;

            setMenuOpen(!menuOpen);
          }}
        >
          <div className={styles.text}>{labels || emptyValueLabel}</div>
          <div className={styles.icon}>
            <DropdownArrowDownV2 />
          </div>
        </div>
      </Tooltip>

      {menuOpen && (
        <Popover
          open={menuOpen}
          sx={{ transform: "translate(0px, 4px)" }}
          anchorEl={intervalSelectorRef.current}
          onClose={() => {
            setMenuOpen(false);
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ paper: classes?.popupMenu }}
        >
          {renderDropdownBody()}
        </Popover>
      )}
    </>
  );
};
