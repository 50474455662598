import React from "react";

import QueryStatementsTable from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentStatistics/QueryStatementsTable/QueryStatementsTable";
import { getExportData } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentStatistics/helpers/getExportData";
import ExportMenu from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/ExportMenu/ExportMenu";
import OutputSubPanel from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/OutputSubPanel/OutputSubPanel";
import {
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import styles from "./DocumentStatistics.module.scss";

interface DocumentStatisticsProps {
  document: WorkspaceDocument;
}

const DocumentStatistics = (props: DocumentStatisticsProps) => {
  const { document } = props;

  if (!document) {
    return <div>No document</div>;
  }

  if (!document.execution) {
    return <div />;
  }

  const queryStatements = document.execution.queryStatements.filter(
    queryStatement => {
      return queryStatement.status !== QueryStatementStatus.pending;
    }
  );

  const exportData = getExportData(queryStatements);

  return (
    <div className={styles.documentStatistics}>
      <OutputSubPanel
        body={<div />}
        controls={
          <div className={styles.controls}>
            <ExportMenu exportData={exportData} />
          </div>
        }
      />
      <div className={styles.statisticsContent}>
        <QueryStatementsTable
          queryStatements={queryStatements}
          documentId={document.id}
        />
      </div>
    </div>
  );
};

export default DocumentStatistics;
