import { systemEngineEnvironment } from "services/environment/systemEngine";

export const getDatabaseTables = async (database: string) => {
  const result = await systemEngineEnvironment.execute<{
    tableName: string;
    tableType: string;
  }>(
    `select tb.table_name, tb.table_type from information_schema.tables tb where tb.table_type IN ('BASE TABLE', 'FACT', 'DIMENSION')`,
    { database }
  );
  const [response] = result;
  return response.rows;
};
