import React, { useCallback, useMemo, useReducer } from "react";

import { EDIT_FINISH, SET_CONTEXT_MENU } from "../state/actions";
import { initialState, interactionStateReducer } from "../state/reducer";
import { InteractionsConstants } from "../types";
import { getPointIndex } from "../utils/interactions";
import { useCopy } from "./useCopy";
import { useKeypressObserving } from "./useKeypressObserving";
import { useMouseEvents } from "./useMouseEvents";
import { useScrollCell } from "./useScrollCell";

export const useInteractions = ({
  gridRef,
  containerRef,
  getRowHeight,
  getRawCell,
  columnCount,
  rowCount,
  dimentions,
  columnWidths,
  columnRightOffsets,
  dropdownExpand,
  constants,
}: {
  gridRef: React.RefObject<HTMLDivElement>;
  containerRef: React.RefObject<HTMLDivElement>;
  getRowHeight: (index: number) => number;
  getRawCell: (rowIndex: number, colIndex: number) => any;
  columnCount: number;
  rowCount: number;
  dimentions: React.MutableRefObject<{
    width: number;
    height: number;
  }>;
  columnWidths: number[];
  columnRightOffsets: number[];
  dropdownExpand: boolean;
  constants: InteractionsConstants;
}) => {
  const [state, dispatch] = useReducer(interactionStateReducer, initialState);

  const getCursorIndex = useCallback(
    (event: React.SyntheticEvent, ignoreOutside = true) => {
      if (!gridRef.current || !containerRef.current) {
        return null;
      }

      return getPointIndex({
        event,
        containerRef,
        gridRef,
        columnRightOffsets,
        columnCount,
        rowCount,
        getRowHeight,
        ignoreOutside,
        dropdownExpand,
        constants,
      });
    },
    [
      constants,
      columnCount,
      rowCount,
      columnRightOffsets,
      getRowHeight,
      containerRef,
      gridRef,
      dropdownExpand,
    ]
  );

  useScrollCell({
    gridRef,
    activeSelection: state.activeSelection,
    activeCell: state.activeCell,
    getRowHeight,
    columnWidths,
    columnRightOffsets,
    dimentions,
    dropdownExpand,
    constants,
  });

  useKeypressObserving();

  const { copySelection } = useCopy({
    state,
    getRawCell,
  });

  useMouseEvents({
    containerRef,
    dispatch,
    getCursorIndex,
    constants,
  });

  const setContextMenuPosition = useCallback(
    (contextMenuPostion: { x: number; y: number }) => {
      dispatch({ type: SET_CONTEXT_MENU, contextMenuPostion });
    },
    []
  );

  const finishEditing = useCallback(() => {
    dispatch({ type: EDIT_FINISH });
  }, []);

  const interactionsContextValue = useMemo(() => {
    return {
      state,
      getRowHeight,
      setContextMenuPosition,
      copySelection,
      finishEditing,
      getRawCell,
    };
  }, [
    state,
    getRowHeight,
    setContextMenuPosition,
    copySelection,
    finishEditing,
    getRawCell,
  ]);

  const interactionsActionsContextValue = useMemo(() => {
    return {
      dispatch,
      getCursorIndex,
    };
  }, [dispatch, getCursorIndex]);

  return {
    interactionsContextValue,
    interactionsActionsContextValue,
  };
};
