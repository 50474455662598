import { useCallback, useContext, useEffect, useRef } from "react";

import { DiagramActionContext, DiagramContext } from "../DiagramContext";
import { CLICK_MINIMAP, DRAG_FINISH, TRANSFORM } from "../actions";

export const useDragViewport = () => {
  const { transform, minimap } = useContext(DiagramContext);
  const dispatch = useContext(DiagramActionContext);
  const pointerStart = useRef<any>(null);

  const scale = transform.k / minimap.k;

  const handlePointerMove = useCallback(
    (event: any) => {
      if (!pointerStart.current) {
        return;
      }

      event.preventDefault();

      const dx = pointerStart.current[0] - event.pageX;
      const dy = pointerStart.current[1] - event.pageY;

      dispatch({
        type: TRANSFORM,
        transform: {
          x: dx * scale,
          y: dy * scale,
        },
      });
    },
    [dispatch, scale]
  );

  const handlePointerUp = useCallback(() => {
    pointerStart.current = null;
    dispatch({ type: DRAG_FINISH });
    document.removeEventListener("pointermove", handlePointerMove);
    document.removeEventListener("pointerup", handlePointerUp);
  }, [handlePointerMove, dispatch]);

  const handleViewportDown = useCallback(
    (event: any) => {
      if (event.pointerType === "mouse" && event.button !== 0) {
        return;
      }

      event.stopPropagation();

      pointerStart.current = [event.pageX, event.pageY];

      document.addEventListener("pointerup", handlePointerUp);
      document.addEventListener("pointermove", handlePointerMove);
    },
    [handlePointerMove, handlePointerUp]
  );

  const handleWrapperDown = useCallback(
    (event: any) => {
      if (event.pointerType === "mouse" && event.button !== 0) {
        return;
      }
      event.stopPropagation();

      const { currentTarget } = event;
      const { top, left } = currentTarget.getBoundingClientRect();

      const x = event.pageX - left;
      const y = event.pageY - top;

      pointerStart.current = [event.pageX, event.pageY];

      dispatch({
        type: CLICK_MINIMAP,
        transform: {
          x: -x * scale,
          y: -y * scale,
        },
      });

      document.addEventListener("pointerup", handlePointerUp);
      document.addEventListener("pointermove", handlePointerMove);
    },
    [dispatch, handlePointerMove, handlePointerUp, scale]
  );

  useEffect(() => {
    document.removeEventListener("pointermove", handlePointerMove);
    document.removeEventListener("pointerup", handlePointerUp);
  }, [handlePointerMove, handlePointerUp]);

  return {
    handleViewportDown,
    handleWrapperDown,
  };
};
