import { QueryClient } from "@tanstack/react-query";

import { ReactQueryKeysGlobal } from "services/queryKeys";

export const updateOrganization = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  try {
    await Promise.all([
      queryClient.cancelQueries({
        queryKey: [ReactQueryKeysGlobal.organization],
      }),
      queryClient.cancelQueries({
        queryKey: [ReactQueryKeysGlobal.organizationTrialBalance],
      }),
    ]);
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.organization],
      }),
      queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.organizationTrialBalance],
      }),
    ]);
  } catch (e) {
    console.error("Failed to update organization from WS: ", e);
  }
};
