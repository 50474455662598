import { useState } from "react";

import { Role } from "services/rbac/types";
import { useRbacRoles } from "services/rbac/useRbacRoles";

import { ViewModal } from "./Modals/ViewModal";

export const useViewActions = () => {
  const [viewRole, setViewRole] = useState<Role | null>(null);
  const rbacRoles = useRbacRoles();

  const handleView = (role: Role) => {
    setViewRole(role);
  };

  const closeView = () => {
    setViewRole(null);
  };

  const viewRoleMarkup = viewRole ? (
    <ViewModal
      viewRole={viewRole}
      onClose={closeView}
      roles={rbacRoles}
    />
  ) : null;

  return {
    viewRole,
    handleView,
    closeView,
    viewRoleMarkup,
  };
};
