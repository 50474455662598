import classNames from "classnames";
import { ReactElement, useContext } from "react";

import { InputDisabledContext } from "components/InputState/InputDisabledContext";
import Tooltip from "components/Tooltip";

import styles from "./styles.module.scss";

interface Props {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  icon?: ReactElement;
  iconChecked?: ReactElement;
  dataTestId?: string;
  classes?: { [key: string]: string };
  vertical?: boolean;
  size?: "sm";
  label?: string | null;
  tooltip?: string;
}

const Toggle = (props: Props) => {
  const {
    checked,
    size,
    onChange,
    vertical,
    icon,
    iconChecked,
    dataTestId,
    label,
    tooltip,
  } = props;
  const isDisabled = useContext(InputDisabledContext);
  const disabled = props.disabled || isDisabled;

  const handleChange = () => {
    if (onChange && !disabled) {
      onChange(!checked);
    }
  };

  const renderIcon = () => {
    if (iconChecked && checked) {
      return iconChecked;
    }

    if (icon) {
      return icon;
    }

    return null;
  };

  return (
    <span
      data-testid={`${vertical ? "vertical" : "horizontal"}-toggle`}
      className={classNames(styles.wrapper, props.classes?.wrapper)}
    >
      <Tooltip
        title={tooltip}
        placement="top"
        classes={{ tooltip: styles.tooltip }}
      >
        <div
          className={classNames(styles.switch, props?.classes?.switch, {
            [styles.vertical]: vertical,
            [styles.checked]: checked,
            [styles.disabled]: disabled,
            [styles.sm]: size === "sm",
          })}
          data-testid={dataTestId || "toggle-icon"}
          onClick={handleChange}
        >
          <input
            type="checkbox"
            className={classNames(styles.input, props?.classes?.input)}
            checked={checked}
            disabled={disabled}
            readOnly
          />
          <div className={classNames(styles.slider, props?.classes?.slider)}>
            {renderIcon()}
          </div>
        </div>
      </Tooltip>
      {label && (
        <div
          className={styles.label}
          onClick={handleChange}
        >
          {label}
        </div>
      )}
    </span>
  );
};

export default Toggle;
