import { autocompletion } from "@codemirror/autocomplete";
import { lineNumbers } from "@codemirror/view";

import { completeSQLynx } from "./sqlynx_completion.js";
import { SQLynxProcessor } from "./sqlynx_processor.js";

export const SQLynxExtensions = [
  lineNumbers(),
  SQLynxProcessor,
  // SQLynxDecorations,
  // SQLynxTooltips,
  // SQLynxGutters,
  autocompletion({
    override: [completeSQLynx],
  }),
];
