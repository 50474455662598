import cn from "classnames";
import React from "react";

import { ENGINE_STATUS_NAME } from "services/engines/constants";
import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";
import { useStartWorkspaceEngine } from "services/engines/useStartWorkspaceEngine";
import { SqlRbacAction } from "services/rbac/action";

import { SYSTEM_ENGINE } from "pages/DevelopWorkspace/workspace.constants";

import { useAccessManager } from "components/App/accessManager";
import Button, { ButtonSize, ButtonTemplate } from "components/Button";

import styles from "./EngineFallbackMessage.module.scss";

interface Props {
  engine: WorkspaceEngine;
  message: React.ReactNode;
  messageSystemEngine: React.ReactNode;
  noPermissionMessage: React.ReactNode;
}

const EngineFallbackMessage = (props: Props) => {
  const { engine, message, messageSystemEngine, noPermissionMessage } = props;
  const { isAllowedTo } = useAccessManager();
  const canOperateEngine = isAllowedTo(
    "engine",
    engine.engineName,
    SqlRbacAction.OPERATE,
    { owner: engine.engineOwner }
  );
  const { mutate } = useStartWorkspaceEngine();

  const getDescription = () => {
    if (engine.engineName === SYSTEM_ENGINE.engineName) {
      return messageSystemEngine;
    }

    if (!canOperateEngine) {
      return noPermissionMessage;
    }

    return message;
  };

  const renderButton = () => {
    if (engine.engineName === SYSTEM_ENGINE.engineName) {
      return null;
    }

    if (!canOperateEngine) {
      return null;
    }

    const engineStatus =
      ENGINE_STATUS_NAME[engine.status] || engine.status || "";
    const buttonText =
      engine.status === WorkspaceEngineStatus.STOPPED ? (
        <>
          Start <span className={styles.engineName}>{engine.engineName}</span>
        </>
      ) : (
        <>
          <span className={cn(styles.engineName, styles.disabled)}>
            {engine.engineName}
          </span>{" "}
          {!!engineStatus.length && `is ${engineStatus.toLowerCase()}...`}
        </>
      );
    const disabled = engine.status !== WorkspaceEngineStatus.STOPPED;

    return (
      <div className={styles.buttonContainer}>
        <Button
          template={ButtonTemplate.Tertiary}
          size={ButtonSize.Small}
          text={buttonText}
          disabled={disabled}
          onClick={() => {
            mutate(engine.engineName);
          }}
        />
      </div>
    );
  };

  const button = renderButton();

  return (
    <div className={styles.engineFallbackMessage}>
      <div
        className={cn(styles.description, {
          [styles.noButtonMode]: !button,
        })}
      >
        {getDescription()}
      </div>
      {button}
    </div>
  );
};

export default EngineFallbackMessage;
