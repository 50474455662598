import classNames from "classnames";
import isFunction from "lodash/isFunction";
import { useEffect, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListChildComponentProps, VariableSizeList } from "react-window";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";

import { TreeNode } from "./TreeNode";
import { ItemDataType, TreeNodeType } from "./types";
import { useFlattenTreeData } from "./useFlattenTreeData";
import { useGetTreeNodeChildren } from "./useGetTreeNodeChildren";
import {
  EMPTY_TYPE,
  getActiveItem,
  onTreeKeyDown,
  toggleExpand,
} from "./utils";

import styles from "./styles.module.scss";

type Props = {
  data: any;
  searchKeyword?: string;
  Node?: React.ElementType;
  nodeClassName?: string;
  treeClassName?: string;
  getChildren?: (
    node: ItemDataType
  ) => ItemDataType[] | Promise<ItemDataType[] | null> | null;
  renderLabel: (node: TreeNodeType, loading?: boolean) => React.ReactNode;
  emptyState: React.ReactElement;
  emptySearchState: React.ReactElement;
};

const itemSize = () => 30;

const SHIFT_CLICK_NODE_TYPES = [
  "database",
  "table",
  "column",
  "information_schema_table",
  "information_schema_column",
  "external_table",
  "external_table_column",
];

export const Tree = (props: Props) => {
  const {
    data,
    searchKeyword,
    getChildren,
    renderLabel,
    nodeClassName,
    treeClassName,
    emptySearchState,
    emptyState,
  } = props;

  const { insertTextAtCursorPosition } = useActiveEditorView();
  const treeViewRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<VariableSizeList>(null);

  const [focusItemValue, setFocusItemValue] = useState<string | null>(null);
  const [expandItemValues, setExpandItemValues] = useState<string[]>([]);

  const {
    data: treeData,
    setData: setTreeData,
    loadingNodeValues,
    loadChildren,
  } = useGetTreeNodeChildren(data);

  const { flattenNodes, forceUpdate, formatVirtualizedTreeData } =
    useFlattenTreeData({
      data: treeData,
      callback: () => {
        forceUpdate();
      },
    });

  const getFormattedNodes = () => {
    return formatVirtualizedTreeData(
      flattenNodes,
      treeData,
      expandItemValues,
      searchKeyword
    ).filter(n => n.visible);
  };

  const formattedNodes = getFormattedNodes();

  const focusNextItem = () => {
    const focusableItems = formattedNodes;

    const activeIndex = focusableItems.findIndex(
      item => item.value === focusItemValue
    );
    if (focusableItems.length === 0) {
      return;
    }
    const nextIndex =
      activeIndex === focusableItems.length - 1 ? 0 : activeIndex + 1;
    const nextFocusItemValue = focusableItems[nextIndex].value;
    setFocusItemValue(nextFocusItemValue);
  };

  const focusPreviousItem = () => {
    const focusableItems = formattedNodes;
    const activeIndex = focusableItems.findIndex(
      item => item.value === focusItemValue
    );

    if (focusableItems.length === 0 || activeIndex === 0) {
      return;
    }

    const prevIndex = activeIndex - 1;
    const prevFocusItemValue = focusableItems[prevIndex].value;
    setFocusItemValue(prevFocusItemValue);
  };

  const handleShiftClick = (node: TreeNodeType) => {
    if (node.label && node.type && SHIFT_CLICK_NODE_TYPES.includes(node.type)) {
      insertTextAtCursorPosition(node.label);
    }
  };

  const handleExpand = async (node: TreeNodeType) => {
    setFocusItemValue(node.value);
    const nextExpandItemValues = toggleExpand({
      node,
      isExpand: !node.expand,
      expandItemValues,
    });
    if (
      isFunction(getChildren) &&
      !node.expand &&
      Array.isArray(node.children) &&
      (node.children.length === 0 || node.children[0]?.type === EMPTY_TYPE)
    ) {
      await loadChildren(node, getChildren);
    }
    setExpandItemValues(nextExpandItemValues);
  };

  const handleLeftArrow = () => {
    if (!focusItemValue) {
      return;
    }
    const focusItem = getActiveItem(focusItemValue, flattenNodes);

    if (!focusItem) {
      return;
    }

    const expand = expandItemValues.includes(focusItem.value);

    if (expand) {
      handleExpand({ ...focusItem, expand });
    } else if (focusItem?.parent) {
      setFocusItemValue(focusItem?.parent?.value);
    }
  };

  const handleRightArrow = () => {
    if (!focusItemValue) {
      return;
    }
    const focusItem = getActiveItem(focusItemValue, flattenNodes);

    if (!focusItem || !Array.isArray(focusItem.children)) {
      return;
    }

    const expand = expandItemValues.includes(focusItem.value);

    if (!expand) {
      handleExpand({ ...focusItem, expand });
    } else {
      focusNextItem();
    }
  };

  const handleEnter = () => {
    if (!focusItemValue) {
      return;
    }
    const focusItem = getActiveItem(focusItemValue, flattenNodes);

    if (!focusItem || !Array.isArray(focusItem.children)) {
      return;
    }

    const expand = expandItemValues.includes(focusItem.value);

    handleExpand({ ...focusItem, expand });
  };

  const handleTreeBlur: React.FocusEventHandler = event => {
    if (!treeViewRef.current) {
      return;
    }
    const node: any = event.relatedTarget?.parentNode;

    // check if any parent of the parent is menu, go up to the root
    let parentNode = node;
    while (parentNode) {
      if (parentNode.role === "menu") {
        return;
      }
      parentNode = parentNode.parentNode;
    }

    setFocusItemValue(null);
  };

  const handleTreeKeyDown: React.KeyboardEventHandler<
    HTMLDivElement
  > = event => {
    if (!treeViewRef.current) {
      return;
    }

    if (!treeViewRef.current.contains(event.target as Node)) {
      return;
    }

    onTreeKeyDown(event, {
      down: () => focusNextItem(),
      up: () => focusPreviousItem(),
      left: handleLeftArrow,
      right: handleRightArrow,
      enter: handleEnter,
    });
  };

  useEffect(() => {
    setTreeData(data);
  }, [data, setTreeData]);

  const getTreeNodeProps = (node: any, layer: number, index?: number) => {
    return {
      value: node.value,
      label: node.label,
      index,
      layer,
      loading: loadingNodeValues.some(item => item === node.value),
      expand: node.expand,
      focus: node.value === focusItemValue,
      visible: node.visible,
      children: node.children,
      nodeData: node,
      onExpand: handleExpand,
      onShiftClick: handleShiftClick,
      renderLabel,
    };
  };

  const renderNode = ({ index, style, data }: ListChildComponentProps) => {
    const node = data[index];
    const { value, layer, visible } = node as TreeNodeType;

    const expand = expandItemValues.includes(value as string);

    if (!visible) {
      return null;
    }

    const nodeProps = {
      ...getTreeNodeProps({ ...node, expand }, layer),
      style,
      hasChildren: node.hasChildren,
    };

    return (
      <TreeNode
        key={node.value}
        className={nodeClassName}
        {...nodeProps}
      />
    );
  };

  if (searchKeyword && !formattedNodes.length) {
    return emptySearchState;
  }

  if (!searchKeyword && !formattedNodes.length && !treeData.length) {
    return emptyState;
  }

  return (
    <div
      ref={treeViewRef}
      className={classNames(styles.tree, treeClassName)}
      onKeyDown={handleTreeKeyDown}
      onBlur={handleTreeBlur}
      role="tree"
      tabIndex={0}
    >
      <div className={styles.nodes}>
        <AutoSizer
          style={{ height: "auto" }}
          disableWidth
        >
          {({ height = 300 }) => (
            <VariableSizeList
              ref={listRef}
              height={height}
              width="auto"
              itemSize={itemSize}
              itemCount={formattedNodes.length}
              itemData={formattedNodes}
            >
              {renderNode}
            </VariableSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
