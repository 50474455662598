import { Popover } from "@mui/material";
import classNames from "classnames";
import React from "react";

import StatementContentPreview from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentStatistics/QueryStatementsTable/StatementContentPreview/StatementContentPreview";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { QueryStatement } from "pages/DevelopWorkspace/workspace.types";

import StatisticsHeader from "./StatisticsHeader";
import StatisticsRow from "./StatisticsRow";

import styles from "./QueryStatementsTable.module.scss";

type QueryStatementsTableProps = {
  queryStatements: QueryStatement[];
  documentId: string;
};

const StatisticsHeaderValues = [
  {
    tCode: "statistics.header_no",
    className: classNames(styles.statementNr, styles.statementNrHeader),
  },
  { tCode: "statistics.header_statement", className: styles.statement },
  { tCode: "statistics.header_status", className: styles.status },
  { tCode: "statistics.header_duration", className: styles.duration },
  { tCode: "statistics.header_rows", className: styles.rows },
  { tCode: "statistics.header_scanned", className: styles.scanned },
  {
    tCode: "statistics.header_rowsPerSec",
    className: classNames(styles.rowsPerSec, styles.rowsPerSecHeader),
  },
];

const QueryStatementsTable = (props: QueryStatementsTableProps) => {
  const { queryStatements, documentId } = props;
  const {
    actions: { switchDocumentActiveQueryStatement },
  } = useDocuments();

  const [queryStatementToPreview, setQueryStatementToPreview] =
    React.useState<QueryStatement | null>(null);
  const previewTargetRef = React.useRef<HTMLDivElement | null>(null);

  const handleShowPreview = (
    queryStatement: QueryStatement,
    ref: HTMLDivElement
  ) => {
    setQueryStatementToPreview(queryStatement);
    previewTargetRef.current = ref;
  };

  const handleClosePreview = () => {
    setQueryStatementToPreview(null);
    previewTargetRef.current = null;
  };

  const queryStatementsReversed = [...queryStatements].reverse();

  return (
    <div className={styles.statisticsContainer}>
      <StatisticsHeader headers={StatisticsHeaderValues} />
      <div>
        {queryStatementsReversed.map((queryStatement, index, arr) => {
          return (
            <StatisticsRow
              key={queryStatement.id}
              queryStatement={queryStatement}
              switchDocumentActiveQueryStatement={
                switchDocumentActiveQueryStatement
              }
              onShowPreview={handleShowPreview}
              documentId={documentId}
              rowNr={arr.length - index}
            />
          );
        })}
      </div>
      {queryStatementToPreview && previewTargetRef.current && (
        <Popover
          id="mouse-over-popover"
          anchorEl={previewTargetRef.current}
          open={true}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleClosePreview}
          disableRestoreFocus
        >
          <StatementContentPreview content={queryStatementToPreview.content} />
        </Popover>
      )}
    </div>
  );
};

export default QueryStatementsTable;
