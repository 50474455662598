import { createContext, useContext, useEffect, useRef, useState } from "react";

import createLocalScriptStorage from "pages/DevelopWorkspace/contexts/ScriptsContext/localScriptsStorage";
import {
  LocalScript,
  RemoteScript,
  ScriptType,
} from "pages/DevelopWorkspace/workspace.types";

export type Script = LocalScript | RemoteScript;

export interface ScriptStorage {
  listScripts(): Promise<Script[]>;
  loadScript(id: string): Promise<Script>;
  updateScript(script: Script): Promise<Script>;
  deleteScript(id: string): Promise<boolean>;
  createScript(
    id: string,
    title: string,
    content: string
  ): Promise<LocalScript>;
}

export enum ScriptStatus {
  loading = "loading",
  error = "error",
  success = "success",
  creating = "creating",
}

interface ScriptState {
  script: Script | null;
  status: ScriptStatus;
  isUnsaved: boolean;
}

export interface ScriptsContextState {
  localScripts: {
    [key: string]: ScriptState;
  };
  remoteScripts: {
    [key: string]: ScriptState;
  };
}

interface ScriptsContextValue {
  state: ScriptsContextState;
  actions: {
    loadScript: (id: string, type: ScriptType) => void;
    updateScript: (
      id: string,
      type: ScriptType,
      data: {
        title?: string;
        content?: string;
      }
    ) => void;
    createScript: (
      newScriptId: string,
      type: ScriptType,
      data: { title: string; content: string }
    ) => Promise<LocalScript>;
    removeScript: (id: string, type: ScriptType) => void;
  };
}

interface ScriptsContextProviderProps {
  children: React.ReactNode;
}

const ScriptsContext = createContext<ScriptsContextValue | undefined>(
  undefined
);

const localScriptStorageInstance = createLocalScriptStorage();

export const ScriptsContextProvider = (props: ScriptsContextProviderProps) => {
  const { children } = props;

  const localScriptStorage = useRef(localScriptStorageInstance);

  const [state, setState] = useState<ScriptsContextState>({
    localScripts: {},
    remoteScripts: {},
  });

  // check unsaved scripts each 2 seconds, and run updateScript
  useEffect(() => {
    const interval = setInterval(() => {
      const unsavedScripts = Object.entries(state.localScripts).filter(
        ([, scriptState]) => {
          return scriptState.isUnsaved;
        }
      );

      unsavedScripts.forEach(async ([id, scriptState]) => {
        const script = scriptState.script;

        if (!script) {
          return;
        }

        try {
          const updatedScript = await localScriptStorage.current.updateScript(
            script
          );

          // check if script title/content was updated while we were saving
          // if yes, we don't update state (keep unsaved flag = true)

          const isTitleUpdated = script.title !== updatedScript.title;
          const isContentUpdated = script.content !== updatedScript.content;
          if (isTitleUpdated || isContentUpdated) {
            return;
          }

          setState(prevState => {
            return {
              ...prevState,
              localScripts: {
                ...prevState.localScripts,
                [id]: {
                  ...prevState.localScripts[id],
                  isUnsaved: false,
                },
              },
            };
          });
        } catch (e) {
          console.log("updateScript error", e);
        }
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [state]);

  const createScript = async (
    newScriptId: string,
    type: ScriptType,
    data: {
      title: string;
      content: string;
    }
  ): Promise<LocalScript> => {
    setState(prevState => {
      return {
        ...prevState,
        localScripts: {
          ...prevState.localScripts,
          [newScriptId]: {
            script: null,
            status: ScriptStatus.creating,
            isUnsaved: false,
          },
        },
      };
    });

    const script = await localScriptStorage.current.createScript(
      newScriptId,
      data.title,
      data.content
    );

    setState(prevState => {
      return {
        ...prevState,
        localScripts: {
          ...prevState.localScripts,
          [newScriptId]: {
            script,
            status: ScriptStatus.success,
            isUnsaved: false,
          },
        },
      };
    });

    return script;
  };

  const loadScript = async (id: string, type: ScriptType) => {
    if (type === ScriptType.local) {
      setState(prevState => {
        return {
          ...prevState,
          localScripts: {
            ...prevState.localScripts,
            [id]: {
              script: null,
              status: ScriptStatus.loading,
              isUnsaved: false,
            },
          },
        };
      });

      try {
        const script = await localScriptStorage.current.loadScript(id);

        setState(prevState => {
          return {
            ...prevState,
            localScripts: {
              ...prevState.localScripts,
              [id]: {
                script,
                status: ScriptStatus.success,
                isUnsaved: false,
              },
            },
          };
        });
      } catch (e) {
        setState(prevState => {
          return {
            ...prevState,
            localScripts: {
              ...prevState.localScripts,
              [id]: {
                script: null,
                status: ScriptStatus.error,
                isUnsaved: false,
              },
            },
          };
        });
      }
    }
  };

  const updateScript = async (
    id: string,
    type: ScriptType,
    data: {
      title?: string;
      content?: string;
    }
  ) => {
    if (type === ScriptType.local) {
      setState(prevState => {
        const prevScript = prevState.localScripts[id].script;

        if (!prevScript) {
          return prevState;
        }

        const newScriptData = {
          ...prevScript,
          title: data.title ?? prevScript.title,
          content: data.content ?? prevScript.content,
        };

        return {
          ...prevState,
          localScripts: {
            ...prevState.localScripts,
            [id]: {
              ...prevState.localScripts[id],
              script: newScriptData,
              isUnsaved: true,
            },
          },
        };
      });
    }
  };
  const removeScript = async (id: string, type: ScriptType) => {
    if (type === ScriptType.local) {
      const scriptState = state.localScripts[id];

      if (!scriptState) {
        return;
      }

      const script = scriptState.script;

      if (!script) {
        return;
      }

      setState(prevState => {
        const newState = {
          ...prevState,
          localScripts: {
            ...prevState.localScripts,
          },
        };

        delete newState.localScripts[id];

        return newState;
      });

      await localScriptStorage.current.deleteScript(id);
    }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values -- it's ok here
  const contextValue = {
    state,
    actions: {
      loadScript,
      updateScript,
      removeScript,
      createScript,
    },
  };

  return (
    <ScriptsContext.Provider value={contextValue}>
      {children}
    </ScriptsContext.Provider>
  );
};

export const useScripts = () => {
  const context = useContext(ScriptsContext);
  if (!context) {
    throw new Error(
      "useScriptsContext must be used within a ScriptsContextProvider"
    );
  }
  return context;
};
