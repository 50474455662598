import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { useHasAccountAdminRole } from "services/auth/useHasAccountAdminRole";
import { useHasAdminRole } from "services/auth/useHasAdminRole";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";

import { SupportAccessService } from "./index";

const ONE_MINUTE = 60 * 1000;

const useInvalidateOnExpire = ({
  data = [],
  refetch,
}: {
  data?: { expires_at: string }[];
  refetch: ReturnType<typeof useSuspenseQuery>["refetch"];
}) => {
  useEffect(() => {
    const timer = setInterval(() => {
      const expired = data.some(agent => {
        const expire = new Date(agent.expires_at);
        if (expire < new Date()) {
          return true;
        }
        return false;
      });

      if (expired) {
        refetch();
      }
    }, ONE_MINUTE);

    return () => {
      clearInterval(timer);
    };
  }, [data, refetch]);
};

export const useSupportAccess = () => {
  const currentAccount = useCurrentParamsAccount();
  const hasAccountAdminRole = useHasAccountAdminRole();
  const hasAdminRole = useHasAdminRole();
  const { data, refetch } = useSuspenseQuery({
    queryKey: [ReactQueryKeysAccount.supportAccess],
    queryFn: async () => {
      try {
        if (hasAdminRole || hasAccountAdminRole) {
          return await SupportAccessService.getSupportAccess(
            currentAccount.accountName
          );
        }
        return null;
      } catch (error) {
        return null;
      }
    },
  });
  useInvalidateOnExpire({ data: data ? [data] : [], refetch });
  return data;
};

export const useSupportAccessShort = () => {
  const currentAccount = useCurrentParamsAccount();
  const hasAccountAdminRole = useHasAccountAdminRole();
  const hasAdminRole = useHasAdminRole();
  const { data, refetch } = useSuspenseQuery({
    queryKey: [ReactQueryKeysAccount.supportAccessShort],
    queryFn: async () => {
      try {
        if (hasAdminRole || hasAccountAdminRole) {
          return await SupportAccessService.getSupportAccessShort(
            currentAccount.accountName
          );
        }
        return null;
      } catch (error) {
        return null;
      }
    },
  });
  useInvalidateOnExpire({ data: data ? [data] : [], refetch });
  return data;
};
