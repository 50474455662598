import {
  CREATE_NEW_DATABASE,
  CREATE_NEW_TABLE,
  IngestionStep,
  MapDataStep,
  MapExistingTableStep,
  WizardDataType,
} from "../types";
import { MapExistingTable } from "./MapExistingTable";
import { MapNewTable } from "./MapNewTable";

type Props = {
  onClose: () => void;
  onSubmit: (data: MapDataStep | MapExistingTableStep) => void;
  wizardData: WizardDataType;
  onPrevStep: () => void;
  activeStepIndex: number;
  initialData: MapDataStep | MapExistingTableStep;
};

export const MapData = (props: Props) => {
  const { wizardData } = props;
  const destination = wizardData[IngestionStep.selectDestination];
  const Component =
    destination?.type === CREATE_NEW_TABLE ||
    destination?.type === CREATE_NEW_DATABASE
      ? MapNewTable
      : MapExistingTable;
  return <Component {...props} />;
};
