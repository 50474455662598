import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import UserFriendlyAPIError from "services/environment/UserFriendlyAPIError";
import { systemEngineEnvironment } from "services/environment/systemEngine";
import { getUsers } from "services/users/getUsers";
import { CreateUserInput, User } from "services/users/user.types";

import { getCreateStatement, getGrantStatements } from "./utils";

export const createUser = async (
  accountId: string,
  input: CreateUserInput
): Promise<User> => {
  const { userName, roles = [] } = input;

  try {
    systemEngineEnvironment.setAccountContext(accountId);

    const statement = getCreateStatement(input);

    await systemEngineEnvironment.execute(statement);

    const grantStatements = getGrantStatements(userName, roles);

    for (const statement of grantStatements) {
      // eslint-disable-next-line no-await-in-loop
      await systemEngineEnvironment.execute(statement);
    }

    const users = await getUsers(accountId, userName);

    if (!users.length) {
      throw new ErrorWithTranslationKey("users.form.create.error");
    }

    return users[0];
  } catch (e) {
    if (e instanceof UserFriendlyAPIError) {
      throw e;
    }

    throw new ErrorWithTranslationKey("users.form.create.error");
  }
};
