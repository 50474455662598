import { useTranslation } from "react-i18next";

import DuplicateImageUrl from "assets/images/errorScreen/duplicate.svg?react";

import { ErrorScreen, ErrorScreenImagePosition } from "../ErrorScreen";

const AlreadyLinked = () => {
  const { t } = useTranslation();

  return (
    <ErrorScreen
      imagePosition={ErrorScreenImagePosition.Top}
      title={t("aws.already_linked_title")}
      image={<DuplicateImageUrl />}
    />
  );
};

export default AlreadyLinked;
