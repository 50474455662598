import React, { useContext } from "react";

type PrivilegesContextType = {
  privilegesState: any;
  privilegesDispatch: (action?: any) => void;
};

export const PrivilegesContext = React.createContext<PrivilegesContextType>({
  privilegesState: {
    engine: {},
    alias: {},
    account: {},
    database: {},
  },
  privilegesDispatch: () => {},
});

export const usePrivilegesState = () => {
  const context = useContext(PrivilegesContext);
  return context;
};
