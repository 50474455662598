import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import { systemEngineEnvironment } from "services/environment/systemEngine";

import { Login } from "./login.types";

export const getLogins = async (name?: string): Promise<Login[]> => {
  const sql =
    "select * from information_schema.logins" +
    (name ? ` where LOWER(login_name) = LOWER(:name)` : "");

  try {
    const result = await systemEngineEnvironment.execute<Login>(sql, {
      namedParameters: { name },
    });

    const [response] = result;
    return response.rows;
  } catch (e) {
    throw new ErrorWithTranslationKey("logins.fetch.error");
  }
};
