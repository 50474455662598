import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import z from "zod";

import { updateAccount } from "services/account/updateAccount";
import { ReactQueryKeysGlobal } from "services/queryKeys";

import ChevronRight from "assets/icons/ChevronRight.svg?react";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import Button, { ButtonSize, ButtonTemplate } from "components/Button";
import ListView from "components/ListView/ListView";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { TextInput } from "components/TextInput/TextInput";
import { queryClient } from "components/queryClient";

import { OnboardingDialog } from "../OnboardingDialog";
import { useOnboarding } from "../useOnboarding";
import EnterImage from "./enter.svg?react";

import styles from "./styles.module.scss";

const schema = z.object({
  accountName: z
    .string()
    .min(1, { message: "Must be at least 1 character long" })
    .max(30, { message: "Must be 30 or fewer characters long" })
    .regex(/[^0-9-]$/, { message: "Should end with a letter" })
    .regex(/^[^0-9-]/, { message: "Should start with a letter" })
    .regex(/^[a-zA-Z0-9-]+$/, {
      message: "Should contain only letters, numbers and -",
    }),
});

type Fields = z.infer<typeof schema>;

export const Welcome = () => {
  const currentAccount = useCurrentParamsAccount();
  const { putStatusMessage } = useStatusMessageQueue();
  const [isLoading, setIsloading] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Fields>({
    resolver: zodResolver(schema),
    defaultValues: {
      accountName: currentAccount?.accountName || "",
    },
  });

  const primaryButton = useRef<HTMLElement | null>(null);
  const handleDialogEnter = useCallback(() => {
    if (primaryButton.current) {
      primaryButton.current.focus();
    }
  }, []);

  const { nextStep } = useOnboarding();

  const handleSkip = () => {
    nextStep();
  };

  const handleContinue = async (data: Fields) => {
    const { accountName } = data;
    if (isLoading) {
      return;
    }
    try {
      setIsloading(true);
      if (currentAccount && currentAccount.accountName !== accountName) {
        await updateAccount(currentAccount.accountName, accountName);
        queryClient.removeQueries({
          queryKey: [ReactQueryKeysGlobal.myAccounts],
        });
      }
      nextStep();
    } catch (error) {
      putStatusMessage({
        message: (error as Error).message,
        type: StatusMessageType.Error,
      });
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const TransitionProps = useMemo(() => {
    return {
      onEntered: handleDialogEnter,
    };
  }, [handleDialogEnter]);

  return (
    <OnboardingDialog
      closeButton={false}
      dataTestId="onboarding-welcome-dialog"
      image={<EnterImage />}
      title={<>{t("onboarding.welcome.title")}</>}
      body={
        <>
          {t("onboarding.welcome.description")}
          <div className={styles.accountName}>
            <div className={styles.label}>
              {t("onboarding.welcome.account")}
            </div>
            <TextInput
              {...register("accountName")}
              type="text"
              error={!!errors?.accountName}
              helperText={errors?.accountName?.message}
              testId="account-name-field"
              inputRootClassName={styles.inputRoot}
            />
          </div>
        </>
      }
      actions={
        <ListView
          spacingPx={4}
          direction="horizontal"
          justifyContent
          equalElemWidth
        >
          <Button
            dataTestid="rename-first-account"
            template={ButtonTemplate.Primary}
            size={ButtonSize.Medium}
            text={t("onboarding.welcome.continue")}
            endIcon={<ChevronRight />}
            onClick={handleSubmit(handleContinue)}
            isLoading={isLoading}
            fullWidth
          />
          <Button
            template={ButtonTemplate.Secondary}
            fullWidth
            size={ButtonSize.Medium}
            text={t("onboarding.load_data.skip")}
            onClick={handleSkip}
            dataTestid="skip-load-welcome"
          />
        </ListView>
      }
      TransitionProps={TransitionProps}
    />
  );
};
