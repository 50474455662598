const getSystemParamErrorMessage = (param: string) => {
  if (param === "engine") {
    return "Could not set parameter. Set parameter 'engine' is not allowed. Try again with 'USE ENGINE' instead of SET.";
  }

  if (param === "database") {
    return "Could not set parameter. Set parameter 'database' is not allowed. Try again with 'USE DATABASE' instead of SET.";
  }

  return `Could not set parameter. Set parameter '${param}' is not allowed. Try again with a different parameter name.`;
};

export default getSystemParamErrorMessage;
