import { useTranslation } from "react-i18next";

import { SqlRbacAction } from "services/rbac/action";
import formatSql from "services/sqlFormat/formatter";

import DimensionTableIcon from "assets/icons/workspace/DimensionTable.svg?react";
import ExternalTableIcon from "assets/icons/workspace/ExternalTable.svg?react";
import FactTableIcon from "assets/icons/workspace/FactTable.svg?react";
import TableIcon from "assets/icons/workspace/Table.svg?react";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useMenu } from "components/ActionMenu/useMenu";
import { useAccessManager } from "components/App/accessManager";
import DefinitionCodePreview from "components/DefinitionCodePreview/DefinitionCodePreview";
import { IngestionStep } from "components/IngestionWizard/types";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import Spinner from "components/Spinner";
import { TreeNodeType } from "components/Tree/types";

import {
  RecordMenuItemGroup,
  RecordMenuItemSubGroup,
} from "../../RecordMenu/types";
import { DatabaseMenuActions } from "../types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
  loading?: boolean;
  actions: DatabaseMenuActions;
};

const iconMap = {
  EXTERNAL: ExternalTableIcon,
  DIMENSION: DimensionTableIcon,
  "BASE TABLE": FactTableIcon,
  default: TableIcon,
};

export const TableLabel = (props: Props) => {
  const { node, loading, actions } = props;
  const { openLoadData } = actions;
  const { label, payload } = node;
  const { columns } = payload;
  const menu = useMenu();
  const { insertTextAtCursorPosition } = useActiveEditorView();
  const { t } = useTranslation();
  const {
    actions: { createDocument },
  } = useDocuments();
  const activeDocument = useActiveDocument();
  const { isAllowedTo } = useAccessManager();
  const { getAccount } = useCurrentAccount();
  const account = getAccount();

  const canCreateDatabase = isAllowedTo(
    "account",
    account?.accountName as string,
    SqlRbacAction.DATABASE_CREATE
  );

  const tableColumns = columns || [];

  const generateSecondaryItems = (): RecordMenuItemGroup => {
    const items: RecordMenuItemSubGroup = [];

    if (canCreateDatabase) {
      items.push({
        key: "load-wizard-with-table",
        text: t("workspace.table_menu.load_data"),
        action: () => {
          openLoadData({
            [IngestionStep.selectDestination]: {
              type: null,
              database: node.databaseName,
              table: node.label,
            },
          });
        },
        testId: "load-wizard-with-table-item",
      });
    }

    return items.length ? [items] : [];
  };

  const items = [
    [
      {
        key: "insert",
        text: t("workspace.table_menu.insert"),
        shortcut: "Shift + click",
        action: () => {
          if (activeDocument) {
            insertTextAtCursorPosition(node.label);
          }
        },
        testId: "insert-table-in-editor-item",
      },
      {
        key: "insert_columns",
        disabled: !tableColumns.length,
        text: t("workspace.table_menu.insert_columns"),
        action: () => {
          if (activeDocument && tableColumns.length) {
            insertTextAtCursorPosition(tableColumns.join(", "));
          }
        },
        testId: "insert-table-columns-in-editor-item",
      },
      {
        key: "view",
        text: t("workspace.table_menu.view_definition"),

        renderContent: (props: { onContentClose: () => void }) => {
          const { payload } = node;
          return (
            <DefinitionCodePreview
              code={formatSql(payload.ddl)}
              onClose={props.onContentClose}
            />
          );
        },
        testId: "view-table-definition-item",
      },
      {
        key: "preview",
        text: t("workspace.table_menu.preview"),
        action: () => {
          const databaseName = node.databaseName;

          if (activeDocument) {
            createDocument(
              {
                title: node.label,
                content: `select * from ${node.label} limit 100`,
                context: {
                  databaseName,
                  engineName: activeDocument.context.engineName,
                },
              },
              {
                autoRun: true,
              }
            );
          }
        },
        testId: "table-preview-item",
      },
    ],
    ...generateSecondaryItems(),
  ];

  const IconComponent =
    iconMap[node.payload.tableType as keyof typeof iconMap] || iconMap.default;

  const icon = loading ? (
    <div className={styles.loading}>
      <Spinner size={12} />
    </div>
  ) : (
    <IconComponent />
  );

  return (
    <>
      <RecordLabelWithIcon
        noIndent
        icon={icon}
        label={<span className={styles.tableLabel}>{label}</span>}
        testId={`table-label-${label}`}
      />
      <Menu
        testId="table-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
