import { sql } from "@codemirror/lang-sql";
import memoize from "lru-memoize";

export interface Slice {
  pos: [number, number];
  text: string;
  isStandalone?: boolean; // always goes as a separate query. Comments cannot be attached to it
}

interface Node {
  pos: [number, number];
  type: string;
}

const groupComments = (comments: Slice[], sourceText: string): Slice => {
  const start = comments[0].pos[0];
  const end = comments[comments.length - 1].pos[1];
  return {
    pos: [start, end],
    text: sourceText.substring(start, end),
  };
};

const divideComments = (comments: Slice[], from: number, to: number) => {
  if (comments.length === 0) {
    return [[], []];
  }

  const distanceToStart = comments[0].pos[0] - from;
  let maxSpace = {
    distance: distanceToStart,
    commentIndex: 0,
  };

  for (let i = 1; i < comments.length; i++) {
    const distance = comments[i].pos[0] - comments[i - 1].pos[1];
    if (distance > maxSpace.distance) {
      maxSpace = {
        distance,
        commentIndex: i,
      };
    }
  }

  const distanceToEnd = to - comments[comments.length - 1].pos[1];
  if (distanceToEnd > maxSpace.distance) {
    maxSpace = {
      distance: distanceToEnd,
      commentIndex: comments.length,
    };
  }

  // split by start of the query
  if (maxSpace.commentIndex === 0) {
    return [[], comments];
  }

  // split by end of the query
  if (maxSpace.commentIndex === comments.length) {
    return [comments, []];
  }

  // split by the comment with the biggest space
  return [
    comments.slice(0, maxSpace.commentIndex),
    comments.slice(maxSpace.commentIndex),
  ];
};

const ALLOWED_TYPES = ["Statement", "LineComment", "BlockComment"];

export const isSetStatement = (sqlText: string) => {
  return sqlText.toLowerCase().startsWith("set") && sqlText.indexOf("=") > 0;
};

const getSQLStatements = (sqlText: string) => {
  const tree = sql().language.parser.parse(sqlText);

  const nodes: Node[] = [];
  tree.iterate({
    enter: node => {
      if (node.node.parent?.type.name === "Script") {
        if (ALLOWED_TYPES.includes(node.type.name)) {
          nodes.push({ pos: [node.from, node.to], type: node.type.name });
          return false;
        }
      }
    },
  });

  const commentsBuffer: Slice[] = [];
  const resultStatements: Slice[] = [];

  const processNode = (i: number) => {
    const node = nodes[i];
    const text = sqlText.slice(node.pos[0], node.pos[1]);

    if (node.type === "Statement") {
      // ignore empty statements
      if (text === ";") {
        return;
      }

      if (isSetStatement(text)) {
        if (commentsBuffer.length) {
          if (
            resultStatements.length &&
            !resultStatements[resultStatements.length - 1].isStandalone
          ) {
            // attach comments to the last statement
            const comment = groupComments(commentsBuffer, sqlText);
            const whitespace = sqlText.substring(
              resultStatements[resultStatements.length - 1].pos[1],
              comment.pos[0]
            );
            resultStatements[resultStatements.length - 1].text +=
              whitespace + comment.text;
            resultStatements[resultStatements.length - 1].pos[1] =
              comment.pos[1];
          } else {
            // put comment to a new statement if no statements yet or last statement is standalone
            const comment = groupComments(commentsBuffer, sqlText);
            resultStatements.push({
              pos: [comment.pos[0], comment.pos[1]],
              text: comment.text,
            });
          }
          commentsBuffer.length = 0;
        }

        resultStatements.push({
          pos: node.pos,
          text,
          isStandalone: true,
        });
        return;
      }

      if (commentsBuffer.length === 0) {
        // no comments, just add statement
        resultStatements.push({
          pos: node.pos,
          text,
        });
        return;
      }

      if (
        resultStatements.length === 0 ||
        resultStatements[resultStatements.length - 1].isStandalone
      ) {
        // put comment to a new statement if no statements yet, or last statement is standalone
        const comment = groupComments(commentsBuffer, sqlText);
        const whitespace = sqlText.substring(comment.pos[1], node.pos[0]);
        resultStatements.push({
          pos: [comment.pos[0], node.pos[1]],
          text: comment.text + whitespace + text,
        });
        commentsBuffer.length = 0;
        return;
      }

      const prevStatementEnd =
        resultStatements[resultStatements.length - 1].pos[1];
      const currentStatementStart = node.pos[0];

      const [commentsToPrev, commentsToCurrent] = divideComments(
        commentsBuffer,
        prevStatementEnd,
        currentStatementStart
      );

      if (commentsToPrev.length) {
        const commentBlock = groupComments(commentsToPrev, sqlText);

        const whitespace = sqlText.substring(
          resultStatements[resultStatements.length - 1].pos[1],
          commentBlock.pos[0]
        );
        resultStatements[resultStatements.length - 1].pos = [
          resultStatements[resultStatements.length - 1].pos[0],
          commentBlock.pos[1],
        ];

        resultStatements[resultStatements.length - 1].text +=
          whitespace + commentBlock.text;
      }

      if (commentsToCurrent.length) {
        const comment = groupComments(commentsToCurrent, sqlText);

        const whitespace = sqlText.substring(comment.pos[1], node.pos[0]);

        resultStatements.push({
          pos: [comment.pos[0], node.pos[1]],
          text: comment.text + whitespace + text,
        });
      } else {
        resultStatements.push({
          pos: node.pos,
          text,
        });
      }

      commentsBuffer.length = 0;

      return;
    }

    // if comment block (LineComment, BlockComment)
    commentsBuffer.push({
      pos: node.pos,
      text,
    });
  };

  for (let i = 0; i < nodes.length; i++) {
    processNode(i);
  }

  // process last comment block if exists
  if (commentsBuffer.length) {
    const comment = groupComments(commentsBuffer, sqlText);
    if (
      resultStatements.length &&
      !resultStatements[resultStatements.length - 1].isStandalone
    ) {
      const whitespace = sqlText.substring(
        resultStatements[resultStatements.length - 1].pos[1],
        comment.pos[0]
      );

      // add last comment block to the last statement
      resultStatements[resultStatements.length - 1].text +=
        whitespace + comment.text;
      resultStatements[resultStatements.length - 1].pos[1] = comment.pos[1];
    } else {
      // put comment to a separate statement
      resultStatements.push({
        pos: comment.pos,
        text: comment.text,
      });
    }

    commentsBuffer.length = 0;
  }

  return resultStatements;
};

const splitSQLWithDetails = (sqlText: string) => {
  return getSQLStatements(sqlText);
};

const splitSQLQuery = (sqlText: string): string[] => {
  return getSQLStatements(sqlText).map(statement => statement.text);
};

// stores 10 last recent results. Memoization is used to avoid lag when user erases characters
const memoizedSplitSQLWithDetails = memoize(10)(splitSQLWithDetails);

const createDebouncedSplitSQLWithDetails = (timeoutMs: number) => {
  let timerId: NodeJS.Timeout | null = null;

  return async (sql: string): Promise<Slice[]> => {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise(resolve => {
      // wrapped in setTimeout to avoid lag when user is typing
      timerId = setTimeout(() => {
        const result = memoizedSplitSQLWithDetails(sql);

        resolve(result);
      }, timeoutMs);
    });
  };
};

export {
  splitSQLQuery,
  splitSQLWithDetails,
  memoizedSplitSQLWithDetails,
  createDebouncedSplitSQLWithDetails,
};
