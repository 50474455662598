import { FileTree } from "services/fileObjects/getFilesByPath";

import { SortValues } from "./types";

export const sortItems = (items: FileTree[], sort: SortValues) => {
  switch (sort) {
    case SortValues.nameAsc:
      return [...items].sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    case SortValues.nameDesc:
      return [...items].sort((a, b) =>
        b.name.toLowerCase().localeCompare(a.name.toLowerCase())
      );
    case SortValues.typeAsc:
      return [...items].sort(
        (a, b) =>
          a.objectType?.toLowerCase().localeCompare(b.objectType?.toLowerCase())
      );
    case SortValues.typeDesc:
      return [...items].sort(
        (a, b) =>
          b.objectType?.toLowerCase().localeCompare(a.objectType?.toLowerCase())
      );
    case SortValues.sizeAsc:
      return [...items].sort(
        (a, b) => (a.objectBytes || 0) - (b.objectBytes || 0)
      );
    case SortValues.sizeDesc:
      return [...items].sort(
        (a, b) => (b.objectBytes || 0) - (a.objectBytes || 0)
      );
    case SortValues.modifiedAsc:
      return [...items].sort((a, b) =>
        (a.lastModified || "")
          .toLowerCase()
          .localeCompare((b.lastModified || "").toLowerCase())
      );
    case SortValues.modifiedDesc:
      return [...items].sort((a, b) =>
        (b.lastModified || "")
          .toLowerCase()
          .localeCompare((a.lastModified || "").toLowerCase())
      );
    default:
      return items;
  }
};
