import { Popover } from "@mui/material";
import classNames from "classnames";
import React, { RefObject, useEffect, useMemo } from "react";

import { useMenu } from "components/ActionMenu/useMenu";
import { ChevronRight } from "components/Icons";

import styles from "./ContextMenu.module.scss";

export type NestedContextMenuItemProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  value?: string;
  text?: string;
  disabled?: boolean;
  active?: boolean;
  availableForInteraction?: boolean;
  testId?: string;
  textClassName?: string;
  renderContent?: (props: { onContentClose: () => void }) => React.ReactNode;
  children?: React.ReactNode;
  anchorElement?: RefObject<HTMLElement>;
  onContentClose?: () => void;
  contentPaperStyle?: {
    width: number;
  };
};

const DEFAULT_PAPER_WIDTH = 240;

const NestedContextMenuItem = (props: NestedContextMenuItemProps) => {
  const {
    text,
    active,
    availableForInteraction = true,
    testId,
    disabled,
    textClassName,
    children,
    anchorElement,
    onContentClose,
    renderContent,
    contentPaperStyle,
  } = props;

  const { menuElement, openMenu, closeMenu } = useMenu();

  const paperWidth = contentPaperStyle?.width || DEFAULT_PAPER_WIDTH;

  const handleClick = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    openMenu(event);
  };

  const handleClose = () => {
    closeMenu();
    onContentClose?.();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyDown = (e: KeyboardEvent) => {
    if (active && !disabled && availableForInteraction) {
      e.stopPropagation();

      if (e.key === "Enter" && !menuElement) {
        e.stopPropagation();

        openMenu(e as unknown as React.MouseEvent);
      }

      if (e.key === "Escape" && menuElement) {
        e.stopPropagation();

        closeMenu();
        onContentClose?.();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const anchor = (anchorElement?.current || menuElement) as HTMLElement;

  // calculate paperPosition and transformOrigin based on the position of the anchor element
  // if enough space is available on the right side of the anchor element, open the menu to the right
  const paperPosition: {
    anchorOrigin: { vertical: "top"; horizontal: "left" | "right" };
    transformOrigin: { vertical: "top"; horizontal: "left" | "right" };
    marginLeft: number;
  } | null = useMemo(() => {
    if (!anchor) {
      return null;
    }

    const anchorRect = anchor.getBoundingClientRect();
    const availableSpace = window.innerWidth - anchorRect.right;
    const doesntFit = availableSpace < paperWidth;

    return {
      anchorOrigin: {
        vertical: "top",
        horizontal: doesntFit ? "left" : "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: doesntFit ? "right" : "left",
      },
      marginLeft: doesntFit ? -2 : 2,
    };
  }, [anchor, paperWidth]);

  return (
    <>
      <div
        data-testid={testId}
        className={classNames(styles.item, {
          [styles.active]: (active || menuElement) && availableForInteraction,
          [styles.interactionDisabled]: !availableForInteraction,
          [styles.disabled]: disabled,
        })}
        onClick={handleClick}
      >
        {text && (
          <div className={classNames(styles.text, textClassName)}>{text}</div>
        )}
        <div className={styles.checkedWrapper}>
          <ChevronRight />
        </div>
      </div>
      {!!menuElement && paperPosition && (
        <Popover
          open
          anchorEl={anchor}
          role="menu"
          classes={{ paper: styles.paper }}
          onClose={handleClose}
          anchorOrigin={paperPosition.anchorOrigin}
          transformOrigin={paperPosition.transformOrigin}
          PaperProps={{
            style: {
              marginLeft: paperPosition.marginLeft,
              minWidth: paperWidth,
              width: paperWidth,
              ...contentPaperStyle,
            },
          }}
        >
          {renderContent?.({
            onContentClose: handleClose,
          }) || children}
        </Popover>
      )}
    </>
  );
};

export default NestedContextMenuItem;
