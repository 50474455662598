import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";
import trimEnd from "lodash/trimEnd";
import trimStart from "lodash/trimStart";
import { QUERY_OUTPUT } from "types/outputFormat";

import { authService } from "services/auth";
import { WorkspaceEngine } from "services/engines/engine.types";

import { FIREBOLT_UI_USER_ENGINE_QUERY_LABEL } from "pages/DevelopWorkspace/services/constants";
import executeQueryStatement from "pages/DevelopWorkspace/services/helpers/executeQueryStatement";
import {
  QueryStatement,
  QueryStatementStatus,
} from "pages/DevelopWorkspace/workspace.types";

export type File = {
  objectName: string;
  objectType: "file" | "folder";
  objectBytes: number | null;
  lastModified: string | null;
};

export type FileTree = File & {
  url: string;
  type?: string;
  name: string;
  extension: string;
  depth: number;
};

export enum AuthType {
  NONE = "none",
  SECRET = "secret",
  IAM = "iam",
}

export type Auth = {
  authType: AuthType;
  key?: string;
  secret?: string;
  iamRole?: string;
};

export const getFilesByPath = async ({
  engine,
  path,
  bucketName,
  bucketProtocol,
  auth,
}: {
  engine: WorkspaceEngine | undefined;
  path: string;
  bucketName: string;
  bucketProtocol: string;
  auth: Auth;
}): Promise<File[]> => {
  const { key, secret, iamRole, authType } = auth;

  if (!engine) {
    return [];
  }
  const abortController = new AbortController();

  const fetchOptions = {
    signal: abortController.signal,
    headers: {},
  };

  const folder = trimEnd(trimStart(path, "/"), "/");
  const bucket = trimEnd(trimStart(bucketName, "/"), "/");

  const url = trimEnd(`${bucketProtocol}://${bucket}/${folder}`, "/") + "/";

  const credentials = (() => {
    if (authType === "none") {
      return "";
    }
    return authType === "secret"
      ? `, aws_key_id => '${key}', aws_secret_key => '${secret}'`
      : `, aws_arn => '${iamRole}'`;
  })();

  const queryStatement: QueryStatement = {
    content: `select * from list_objects(
url => '${url}'
${credentials}
)`,
    status: QueryStatementStatus.pending,
    id: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
    responseStatusCode: null,
    result: null,
    error: null,
    statistics: null,
    sourceDocLineNumber: 0,
  };

  const document: any = {
    context: {
      engineName: engine.engineName,
      settings: {
        query_label: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
        output_format: QUERY_OUTPUT.JSON,
      },
    },
  };

  const response = await executeQueryStatement(
    queryStatement,
    document,
    engine,
    fetchOptions,
    authService
  );
  const { responseBody } = response;
  const { data } = responseBody;
  const mapped = data.map((row: any) => {
    return mapKeys(row, (_value, key) => camelCase(key));
  });

  return mapped;
};
