export const withRetry = async <T extends (...arg0: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  maxRetries: number,
  delay: number
): Promise<Awaited<ReturnType<T>>> => {
  let retries = 0;
  while (retries < maxRetries) {
    try {
      // eslint-disable-next-line no-await-in-loop -- We want to await the result of the function
      return await fn(...args);
    } catch (e) {
      retries++;
      if (retries >= maxRetries) {
        throw e;
      }
      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return -- We want to wait for the delay
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  // This should never happen, but TypeScript doesn't know that
  throw new Error("Unreachable");
};
