import {
  SearchQuery,
  closeSearchPanel,
  findNext,
  findPrevious,
  replaceAll,
  replaceNext,
  setSearchQuery,
} from "@codemirror/search";
import { EditorView } from "@codemirror/view";
import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button, { ButtonSize, ButtonTemplate } from "components/Button/Button";
import Checkbox from "components/Checkbox";
import { ChevronLeft, ChevronRight, CloseIcon } from "components/Icons";
import { TextInput } from "components/TextInput/TextInput";
import Toggle from "components/Toggle";

import styles from "./SearchPanel.module.scss";

interface SearchQueryConfig {
  search: string;
  caseSensitive?: boolean;
  regexp?: boolean;
  replace?: string;
  wholeWord?: boolean;
}

interface Props {
  view: EditorView;
}

const SearchPanel = (props: Props) => {
  const { view } = props;

  const { t } = useTranslation();
  const [isReplaceMode, setIsReplaceMode] = useState(false);
  const [matchesCount, setMatchesCount] = useState(0);

  const [searchQueryConfig, setSearchQueryConfig] = useState<SearchQueryConfig>(
    {
      search: "",
      caseSensitive: false,
      regexp: false,
      replace: "",
      wholeWord: false,
    }
  );

  const updateMatchesCount = useCallback(() => {
    const sq = new SearchQuery({
      ...searchQueryConfig,
    });

    const cursor = sq.getCursor(view.state);

    let matchesCount = 0;
    let result = cursor.next();
    while (!result.done) {
      matchesCount++;
      result = cursor.next();
    }

    setMatchesCount(matchesCount);
  }, [searchQueryConfig, view]);

  useEffect(() => {
    const sq = new SearchQuery({
      ...searchQueryConfig,
    });

    updateMatchesCount();

    view.dispatch({ effects: setSearchQuery.of(sq) });
  }, [searchQueryConfig, view, updateMatchesCount]);

  return (
    <div className={styles.searchPanel}>
      <div
        className={styles.closeIcon}
        onClick={() => {
          closeSearchPanel(view);
        }}
      >
        <CloseIcon />
      </div>

      <div className={styles.controls}>
        <div className={styles.control}>
          <TextInput
            className={styles.searchInput}
            inputRootClassName={styles.searchInputRoot}
            value={searchQueryConfig.search}
            testId="search-input"
            placeholder={t("search_panel.search_placeholder")}
            autoFocus={true}
            InputProps={{
              endAdornment: searchQueryConfig.search && !!matchesCount && (
                <div
                  data-testid="matches-count"
                  className={styles.matchesCount}
                >
                  {matchesCount}
                </div>
              ),
            }}
            onChange={e => {
              setSearchQueryConfig(prevState => {
                return {
                  ...prevState,
                  search: e.target.value,
                };
              });
            }}
          />

          <div className={styles.buttonsGroup}>
            <div
              data-testid="find-previous-button"
              className={styles.button}
              onClick={() => {
                findPrevious(view);
              }}
            >
              <ChevronLeft />
            </div>

            <div
              data-testid="find-next-button"
              className={styles.button}
              onClick={() => {
                findNext(view);
              }}
            >
              <ChevronRight />
            </div>
          </div>
        </div>

        <div className={cn(styles.control, styles.checkboxControl)}>
          <Checkbox
            checked={!!searchQueryConfig.caseSensitive}
            testId="case-sensitive-checkbox"
            onClick={() => {
              setSearchQueryConfig(prevState => {
                return {
                  ...prevState,
                  caseSensitive: !prevState.caseSensitive,
                };
              });
            }}
          />
          <label>{t("search_panel.match_case")}</label>
        </div>

        <div className={cn(styles.control, styles.checkboxControl)}>
          <Checkbox
            testId="regexp-checkbox"
            checked={!!searchQueryConfig.regexp}
            onClick={() => {
              setSearchQueryConfig(prevState => {
                return {
                  ...prevState,
                  regexp: !prevState.regexp,
                };
              });
            }}
          />
          <label>{t("search_panel.regexp")}</label>
        </div>

        <div className={cn(styles.control, styles.checkboxControl)}>
          <Checkbox
            testId="whole-word-checkbox"
            checked={!!searchQueryConfig.wholeWord}
            onClick={() => {
              setSearchQueryConfig(prevState => {
                return {
                  ...prevState,
                  wholeWord: !prevState.wholeWord,
                };
              });
            }}
          />
          <label>{t("search_panel.by_word")}</label>
        </div>

        <div className={cn(styles.control, styles.replaceSwitcher)}>
          <label>{t("search_panel.replace_switcher_label")}</label>
          <Toggle
            dataTestId="replace-switcher"
            checked={isReplaceMode}
            classes={{
              wrapper: styles.replaceSwitcherWrapper,
            }}
            onChange={() => {
              setIsReplaceMode(prevState => !prevState);
            }}
          />
        </div>
      </div>

      {isReplaceMode && (
        <div className={cn(styles.controls, styles.replaceControls)}>
          <div className={styles.control}>
            <TextInput
              className={styles.searchInput}
              inputRootClassName={styles.searchInputRoot}
              value={searchQueryConfig.replace}
              testId="replace-input"
              placeholder={t("search_panel.replace_placeholder")}
              onChange={e => {
                setSearchQueryConfig(prevState => {
                  return {
                    ...prevState,
                    replace: e.target.value,
                  };
                });
              }}
            />

            <Button
              text={t("search_panel.replace")}
              template={ButtonTemplate.Secondary}
              size={ButtonSize.Small}
              dataTestid="replace-button"
              onClick={() => {
                replaceNext(view);
                updateMatchesCount();
              }}
            />

            <Button
              text={t("search_panel.replace_all")}
              template={ButtonTemplate.Secondary}
              size={ButtonSize.Small}
              dataTestid="replace-all-button"
              onClick={() => {
                replaceAll(view);
                updateMatchesCount();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchPanel;
