import { isDocumentRunning } from "pages/DevelopWorkspace/helpers/isDocumentRunning";
import {
  DocumentsState,
  QueryStatementStatus,
} from "pages/DevelopWorkspace/workspace.types";

import { documentHasErrors } from "../../../helpers/documentHasError";

const checkIfActiveDocumentIdValid = (
  state: DocumentsState,
  normalizedState: DocumentsState
) => {
  const document = state.documents.find(
    document => document.id === state.activeDocumentId
  );

  if (!document) {
    // set to first document
    if (state.documents.length > 0) {
      // eslint-disable-next-line no-param-reassign -- don't want to create new object
      normalizedState.activeDocumentId = state.documents[0].id;
    } else {
      // eslint-disable-next-line no-param-reassign -- don't want to create new object
      normalizedState.activeDocumentId = null;
    }
  }
};

const checkActiveQueryStatementIndex = (
  state: DocumentsState,
  normalizedState: DocumentsState
) => {
  state.documents.forEach(document => {
    if (!document.execution) {
      return;
    }

    const queryStatements = document.execution.queryStatements;

    if (
      document.execution.activeQueryStatementIndex >= queryStatements.length
    ) {
      // eslint-disable-next-line no-param-reassign -- don't want to create new object
      normalizedState.documents.find(
        normalizedDocument => normalizedDocument.id === document.id
      )!.execution!.activeQueryStatementIndex = 0;
    }
  });
};

const normalizeCancelledQueryStatements = (
  state: DocumentsState,
  normalizedState: DocumentsState
) => {
  state.documents.forEach(document => {
    if (!document.execution) {
      return;
    }

    const queryStatements = document.execution.queryStatements;

    const hasError = documentHasErrors(document);

    if (hasError) {
      // at least one query statement has failed
      const normalizedQueryStatements = queryStatements.map(queryStatement => {
        if (queryStatement.status === QueryStatementStatus.pending) {
          return {
            ...queryStatement,
            status: QueryStatementStatus.cancelled,
          };
        }

        return queryStatement;
      });

      // eslint-disable-next-line no-param-reassign -- don't want to create new object
      normalizedState.documents.find(
        normalizedDocument => normalizedDocument.id === document.id
      )!.execution!.queryStatements = normalizedQueryStatements;
    }
  });
};

const normalizeDocumentCancellationStatus = (
  state: DocumentsState,
  normalizedState: DocumentsState
) => {
  state.documents.forEach(document => {
    if (!document.execution) {
      return;
    }

    // reset cancellation status if document is not running
    if (isDocumentRunning(document)) {
      return;
    }

    // eslint-disable-next-line no-param-reassign -- don't want to create new object
    normalizedState.documents.find(
      normalizedDocument => normalizedDocument.id === document.id
    )!.execution!.cancellationStatus = undefined;
  });
};

const normalizeState = (state: DocumentsState): DocumentsState => {
  const normalizedState = { ...state };

  // cancel the rest of the query statements if one of them has failed (status = error)
  // TODO not sure that it is the best place for this logic
  normalizeCancelledQueryStatements(state, normalizedState);

  checkIfActiveDocumentIdValid(state, normalizedState);

  // check if valid activeQueryStatementIndex, otherwise set to null
  checkActiveQueryStatementIndex(state, normalizedState);

  // reset cancellation status if document is not running
  normalizeDocumentCancellationStatus(state, normalizedState);

  return normalizedState;
};

export { normalizeState };
