import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import { systemEngineEnvironment } from "services/environment/systemEngine";

import { ServiceAccount } from "./types";

export const getServiceAccounts = async (name: string | null = null) => {
  const sql =
    "select * from information_schema.service_accounts" +
    (name ? ` where service_account_name = :name` : "");

  try {
    const result = await systemEngineEnvironment.execute<ServiceAccount>(sql, {
      namedParameters: { name },
    });

    const [response] = result;
    return response.rows;
  } catch (e) {
    throw new ErrorWithTranslationKey("serviceAccounts.fetch.error");
  }
};
