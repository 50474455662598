import { useSuspenseQuery } from "@tanstack/react-query";

import { authService } from "services/auth";
import { ReactQueryKeysGlobal } from "services/queryKeys";

export const useLoginId = () => {
  const { data } = useSuspenseQuery({
    queryKey: [ReactQueryKeysGlobal.loginId],
    queryFn: async () => {
      return await authService.getLoginId();
    },
  });

  return {
    loginId: data,
  };
};
