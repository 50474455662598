import { max } from "d3-array";

import { Series, UtilMetrics } from "../types";
import { getTimeWindowDateFrom } from "./getTimeWindowDateFrom";

export const getRanges = ({
  series,
  timeWindow,
}: {
  series: Series<UtilMetrics>[];
  timeWindow: string | null;
}) => {
  const lastDate = series.reduce(
    (acc, s) => {
      if (s.data.length) {
        const last = s.data[s.data.length - 1].date;
        if (!acc || last > acc) {
          return last;
        }
      }

      return acc;
    },
    null as Date | null
  ) as Date;

  const dateFrom = getTimeWindowDateFrom(timeWindow, lastDate);

  const dateRange: [Date, Date] = [dateFrom as Date, lastDate];

  const allDataMax = max(series, s => max(s.data, d => d.value)) || 0;
  const maxWithPadding =
    allDataMax + allDataMax * 0.1 > 100 ? 100 : allDataMax + allDataMax * 0.1;

  const valuesRange: [number, number] = [0, maxWithPadding || 1];

  return { dateRange, valuesRange };
};
