import { TreeNodeType } from "components/Tree/types";

import { RecordLabel } from "../../RecordLabel";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
};

export const DefaultLabel = (props: Props) => {
  const { node } = props;
  const { label } = node;
  return <RecordLabel className={styles.generalLabel}>{label}</RecordLabel>;
};
