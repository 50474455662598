import { useCallback, useEffect } from "react";

import { isCtrlMetaKey } from "../utils/interactions";

const pressedKeys = new Set<number>();

export const isCtrlPressed = () => {
  const values = Array.from(pressedKeys.values());
  return values.some(keyCode => isCtrlMetaKey(keyCode));
};

export const useKeypressObserving = () => {
  const addKey = useCallback((event: any) => {
    if (!pressedKeys.has(event.keyCode)) {
      pressedKeys.add(event.keyCode);
    }
  }, []);

  const removeKey = useCallback((event: any) => {
    if (pressedKeys.has(event.keyCode)) {
      pressedKeys.delete(event.keyCode);
    }
  }, []);

  const handleBlur = useCallback(() => {
    pressedKeys.clear();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", addKey);
    document.addEventListener("keyup", removeKey);
    document.addEventListener("blur", handleBlur);
    return () => {
      pressedKeys.clear();
      document.removeEventListener("keydown", addKey);
      document.removeEventListener("keyup", removeKey);
      document.removeEventListener("blur", handleBlur);
    };
  }, [addKey, removeKey, handleBlur]);
};
