import React from "react";

import styles from "./TooltipMessage.module.scss";

const TooltipMessageDivider = () =>
  /* #__PURE__ */ React.createElement("div", {
    className: styles.divider,
  });

export default TooltipMessageDivider;
