import Button, { ButtonTemplate } from "components/Button/Button";
import ListView from "components/ListView/ListView";

import styles from "./styles.module.scss";

type Props = {
  confirmText: string;
  isLoading: boolean;
  isDisabled: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const Footer = (props: Props) => {
  const { confirmText, isLoading, isDisabled, onClose, onConfirm } = props;
  return (
    <div className={styles.side__footer}>
      <ListView
        spacingPx={16}
        direction="horizontal"
      >
        <Button
          dataTestid="submit"
          text={confirmText}
          onClick={onConfirm}
          disabled={isDisabled}
          isLoading={isLoading}
        />
        <Button
          dataTestid="cancel"
          onClick={onClose}
          text="Cancel"
          template={ButtonTemplate.Ghost}
          disabled={isLoading}
        />
      </ListView>
    </div>
  );
};
