import { useTranslation } from "react-i18next";

import styles from "../styles/NoData.module.scss";

const NoEngineMonitoringData = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.description}>{t("engine_monitoring.no_data")}</div>
    </div>
  );
};

export default NoEngineMonitoringData;
