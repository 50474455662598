import { useCallback, useMemo, useState } from "react";

export const useCandidateSearch = (
  items: Array<{ label: string; value: string; count: number }>
) => {
  const [search, setSearch] = useState("");

  const handleSearchChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const filteredItems = useMemo(() => {
    if (search) {
      return items.filter(item => {
        const { label } = item;
        return ("" + label).toLowerCase().includes(search.toLowerCase());
      });
    }
    return items;
  }, [items, search]);

  return {
    search,
    filteredItems,
    handleSearchChange,
  };
};
