import classNames from "classnames";
import { useTranslation } from "react-i18next";

import FailureImg from "assets/images/failure.svg?react";

import styles from "./styles.module.scss";

export enum ErrorScreenImagePosition {
  Top = "TOP",
  Bottom = "BOTTOM",
}

type Props = {
  title: string;
  description?: React.ReactElement | string;
  image?: React.ReactElement;
  footer?: React.ReactElement | string;
  copyright?: React.ReactElement | string;
  imagePosition?: ErrorScreenImagePosition;
  footerSmallMargin?: boolean;
  classes?: {
    footer?: string;
    description?: string;
    image?: string;
  };
};

export const ErrorScreen = ({
  title,
  description,
  image = <FailureImg />,
  footer,
  copyright,
  imagePosition = ErrorScreenImagePosition.Bottom,
  footerSmallMargin,
  classes = {},
}: Props) => {
  const { t } = useTranslation();

  const imageContainer = (
    <div
      data-testid="error-image-container"
      className={classNames(
        styles.imageContainer,
        styles[imagePosition],
        classes?.image
      )}
    >
      {image}
    </div>
  );

  const footerClassNames = classNames(styles.footer, classes?.footer, {
    [styles.smallMargin]: footerSmallMargin,
  });

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.root}
        data-testid="error-container"
      >
        {imagePosition === ErrorScreenImagePosition.Top && imageContainer}
        <div
          className={styles.title}
          data-testid="error-title"
        >
          {typeof title === "string" ? t(title as any) : title}
        </div>
        {description && (
          <div
            className={classNames(styles.description, classes.description)}
            data-testid="error-description"
          >
            {typeof description === "string"
              ? t(description as any)
              : description}
          </div>
        )}
        {imagePosition === ErrorScreenImagePosition.Bottom && imageContainer}
        {footer && (
          <div
            className={footerClassNames}
            data-testid="error-footer"
          >
            {footer}
          </div>
        )}
      </div>
      {copyright && <div className={styles.copyright}>{copyright}</div>}
    </div>
  );
};
