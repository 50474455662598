import copy from "copy-to-clipboard";
import { Trans, useTranslation } from "react-i18next";

import { CopyIcon } from "components/Icons";
import InlineLink from "components/IngestionWizard/Steps/InlineLink";
import { TextInput } from "components/TextInput/TextInput";

import styles from "./CreateRoleGuide.module.scss";

interface CreateRoleGuideProps {
  trustPolicy: string;
}

const permissions = [
  {
    label: "Permission name",
    value: "AmazonS3ReadOnlyAccess",
  },
];

const DOCS_S3_PERMISSIONS =
  "https://docs.firebolt.io/Guides/loading-data/creating-access-keys-aws.html#create-s3-access-permissions";

const CreateRoleGuide = ({ trustPolicy }: CreateRoleGuideProps) => {
  const { t } = useTranslation();

  const code = `{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Sid": "Statement1",
      "Effect": "Allow",
      "Principal": {
        "AWS": "${trustPolicy}"
      },
      "Action": "sts:AssumeRole"
    }
  ]
}`;

  return (
    <div className={styles.createRoleGuide}>
      <div className={styles.section}>
        <div className={styles.header}>
          {t("wizard.select_aws.iam_setup_guidance.setup_guidance.title")}
        </div>
        <div className={styles.body}>
          <ol>
            <Trans
              i18nKey="wizard.select_aws.iam_setup_guidance.setup_guidance.text"
              components={{
                a: <InlineLink href="https://console.aws.amazon.com/iam/" />,
                li: <li />,
              }}
            />
          </ol>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.header}>
          {t("wizard.select_aws.iam_setup_guidance.trust_policy.title")}
        </div>
        <div className={styles.body}>
          {t("wizard.select_aws.iam_setup_guidance.trust_policy.text")}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.code}>
          {code}

          <div
            className={styles.copyIcon}
            onClick={() => {
              copy(code);
            }}
          >
            <CopyIcon />
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.header}>
          {t("wizard.select_aws.iam_setup_guidance.permissions.title")}
        </div>
        <div className={styles.body}>
          <Trans
            i18nKey="wizard.select_aws.iam_setup_guidance.permissions.text"
            components={{
              a: <InlineLink href={DOCS_S3_PERMISSIONS} />,
            }}
          />
        </div>
      </div>

      <div className={styles.section}>
        {permissions.map(permission => {
          return (
            <div
              className={styles.formControl}
              key={permission.value}
            >
              <TextInput
                inputRootClassName={styles.inputRoot}
                label={permission.label}
                testId={`permission-${permission.label}`}
                value={permission.value}
                InputProps={{
                  endAdornment: (
                    <div
                      className={styles.inputCopyIcon}
                      onClick={() => {
                        copy(permission.value);
                      }}
                    >
                      <CopyIcon />
                    </div>
                  ),
                }}
                required
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CreateRoleGuide;
