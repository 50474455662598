import { statusCodes } from "utils/constants";
import { UnauthorizedError } from "components/ErrorBoundary/UnauthorizedError";
import UserFriendlyAPIError from "./UserFriendlyAPIError";

export function normalizeSystemEngineURL(url: string): string {
  if (url.startsWith("https")) {
    const urlInstance = new URL(url);
    return urlInstance.origin;
  }
  const urlInstance = new URL(`https://${url}`);
  return urlInstance.origin;
}

export const throwErrorMessage = (statusCode: number, errMessage: string) => {
  const message =
    "Query failed with status code: " + statusCode + "\n" + errMessage;

  console.error(message);
  if (statusCode === statusCodes.UNAUTHORIZED) {
    throw new UnauthorizedError(errMessage);
  }
  throw new UserFriendlyAPIError(errMessage);
}
