import React, { useState } from "react";

import { useRbacRoles } from "services/rbac/useRbacRoles";
import { useUsers } from "services/users/useUsers";

import { useViewActions } from "pages/govern/Roles/useViewActions";

import { Panel } from "../Panel";
import { Search, useSearchHandlers } from "../Search";
import { RoleList } from "./RoleList";
import { SectionHeader } from "./SectionHeader";
import { UserList } from "./UserList";
import { OrderBy, OrderDirection } from "./types";

import styles from "./styles.module.scss";

export const SecurityPanel = React.memo(() => {
  const { onChange, search } = useSearchHandlers();
  const roles = useRbacRoles();
  const users = useUsers();
  const [sections, setSections] = useState(["roles", "users"]);

  const [order, setOrder] = useState<{
    roles: { orderDirection: OrderDirection; orderBy: OrderBy };
    users: { orderDirection: OrderDirection; orderBy: OrderBy };
  }>({
    roles: {
      orderDirection: OrderDirection.ASC,
      orderBy: OrderBy.alphabetical,
    },
    users: {
      orderDirection: OrderDirection.ASC,
      orderBy: OrderBy.alphabetical,
    },
  });

  const handleChangeOrder = (
    section: string,
    orderParams: Record<string, string>
  ) => {
    setOrder(current => ({
      ...current,
      [section]: {
        ...current[section as keyof typeof order],
        ...orderParams,
      },
    }));
  };

  const toggleSection = (section: string) => {
    setSections(current => {
      if (current.includes(section)) {
        return current.filter(s => s !== section);
      }
      return [...current, section];
    });
  };

  const nameSortOrder = [
    {
      text: "A->Z",
      key: "asc",
      orderParams: {
        orderDirection: OrderDirection.ASC,
      },
      selected: (section: string) =>
        order[section as keyof typeof order].orderDirection ===
        OrderDirection.ASC,
      testId: "sort-by-name-asc",
    },
    {
      text: "Z->A",
      key: "desc",
      orderParams: {
        orderDirection: OrderDirection.DESC,
      },
      selected: (section: string) =>
        order[section as keyof typeof order].orderDirection ===
        OrderDirection.DESC,
      testId: "sort-by-name-desc",
    },
  ];

  const dateSortOrder = [
    {
      text: "Newest first",
      key: "asc",
      orderParams: {
        orderDirection: OrderDirection.ASC,
      },
      selected: (section: string) =>
        order[section as keyof typeof order].orderDirection ===
        OrderDirection.ASC,
      testId: "sort-by-date-asc",
    },
    {
      text: "Oldest first",
      key: "desc",
      orderParams: {
        orderDirection: OrderDirection.DESC,
      },
      selected: (section: string) =>
        order[section as keyof typeof order].orderDirection ===
        OrderDirection.DESC,
      testId: "sort-by-date-desc",
    },
  ];

  const sortByItems = [
    {
      text: "Date created",
      key: "created",
      orderParams: {
        orderBy: OrderBy.created,
      },
      selected: (section: string) =>
        order[section as keyof typeof order].orderBy === OrderBy.created,
      testId: "sort-by-created",
    },
    {
      text: "Alphabetical",
      key: "alphabetical",
      orderParams: {
        orderBy: OrderBy.alphabetical,
      },
      selected: (section: string) =>
        order[section as keyof typeof order].orderBy === OrderBy.alphabetical,
      testId: "sort-by-alphabetical",
    },
  ];

  const rolesSortOrderItems =
    order.roles.orderBy === OrderBy.alphabetical
      ? nameSortOrder
      : dateSortOrder;

  const usersSortOrderItems =
    order.users.orderBy === OrderBy.alphabetical
      ? nameSortOrder
      : dateSortOrder;

  const rolesItems = [sortByItems, rolesSortOrderItems];

  const usersItems = [sortByItems, usersSortOrderItems];

  const rolesExpanded = sections.includes("roles");
  const usersExpanded = sections.includes("users");

  const { viewRoleMarkup, handleView } = useViewActions();

  return (
    <>
      <Search
        onChange={onChange}
        value={search}
        testId="security-search-input"
      />
      <Panel testId="security-list-panel">
        <div className={styles.wrapper}>
          <SectionHeader
            title="Roles"
            key="roles"
            section="roles"
            testId="open-roles-sort-menu"
            handleChangeOrder={handleChangeOrder}
            menuItems={rolesItems}
            toggleSection={toggleSection}
            expanded={rolesExpanded}
          />
          {rolesExpanded && (
            <RoleList
              items={roles}
              order={order.roles}
              handleView={handleView}
              search={search}
            />
          )}
          <SectionHeader
            title="Users"
            section="users"
            key="users"
            testId="open-users-sort-menu"
            handleChangeOrder={handleChangeOrder}
            menuItems={usersItems}
            toggleSection={toggleSection}
            expanded={usersExpanded}
          />
          {usersExpanded && (
            <UserList
              items={users}
              order={order.users}
              search={search}
            />
          )}
          {viewRoleMarkup}
        </div>
      </Panel>
    </>
  );
});
