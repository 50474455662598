import { useState } from "react";
import { useTranslation } from "react-i18next";

import { createWorkspaceDatabase } from "services/databases/createWorkspaceDatabase";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { queryClient } from "components/queryClient";

import { DatabaseModal } from "../DatabaseModal/DatabaseModal";

export const useCreateDatabase = () => {
  const [database, setCreateDatabase] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const openCreateDatabase = () => {
    setCreateDatabase(true);
  };

  const closeCreateDatabase = () => {
    if (isLoading) {
      return;
    }
    setCreateDatabase(false);
  };

  const confirmCreate = async (database: {
    databaseName: string;
    description: string;
  }) => {
    try {
      setIsLoading(true);
      await createWorkspaceDatabase(database);

      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.databasesNames],
      });
      closeCreateDatabase();
      putStatusMessage({
        message: t("workspace.create_database.success"),
        type: StatusMessageType.Success,
      });
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createDatabaseMarkup = database ? (
    <DatabaseModal
      onClose={closeCreateDatabase}
      onSubmit={confirmCreate}
      isLoading={isLoading}
    />
  ) : null;

  return {
    createDatabaseMarkup,
    openCreateDatabase,
  };
};
