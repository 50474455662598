import classNames from "classnames";

import CaretDown from "assets/icons/CaretDown.svg?react";
import CaretUp from "assets/icons/CaretUp.svg?react";

import styles from "./styles.module.scss";

type Props = {
  onUp: (event: React.SyntheticEvent) => void;
  onDown: (event: React.SyntheticEvent) => void;
  upTestId: string;
  downTestId: string;
  disabled?: boolean;
};

export const Ticker = (props: Props) => {
  const { onUp, onDown, upTestId, downTestId, disabled } = props;
  return (
    <div className={classNames(styles.ticker, { [styles.disabled]: disabled })}>
      <button
        className={classNames(styles.tickerItem)}
        onClick={e => !disabled && onUp(e)}
        data-testid={upTestId}
      >
        <CaretUp />
      </button>
      <button
        className={styles.tickerItem}
        onClick={e => !disabled && onDown(e)}
        data-testid={downTestId}
      >
        <CaretDown />
      </button>
    </div>
  );
};
