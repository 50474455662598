import { privateApi } from "services/api/private";
import { publicApi } from "services/api/public";

import { getOrganization } from "./getOrganization";

const RESOLVE_ORGANIZATION = (organizationName: string) =>
  `/resolveOrganization/${organizationName}`;

const AVAILABLE_REGIONS = "/availableRegions";
const RESEND_VERIFICATION_EMAIL = "/resendVerificationEmail";

const REGISTER_ORGANIZATION = "/register";
const REGISTER_DELAYED = "/register/delayed/";
const ATTACH_ORGANIZATION_TO_BILLING = "/billingProfiles:attachToOrganization";
const RECOVER_LOGIN_URL = "/recoverLoginUrl";
const IS_ORGANIZATION_CREATOR = "/isOrganizationCreator";
const IS_ORGANIZATION_APPROVED = "/isOrganizationApproved";

export type ResolveOrganizationResponse = {
  organizationID?: string;
};

export interface Region {
  displayName: string;
  name: string;
}

export type AvailableRegions = {
  regions: Region[];
};

export interface RegistrationRequestResponse {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  region_name: string;
  company_name: string;
}

const getRegistrationRequest = async (requestId: string) => {
  const response = await publicApi.get<RegistrationRequestResponse>(
    `${REGISTER_DELAYED}${requestId}`
  );
  return response.data;
};

const continueRegistration = async (
  requestId: string,
  payload: {
    password: string;
    company_name_suffix: string;
    recaptcha_response: string;
  }
) => {
  const response = await publicApi.post<{
    account_id: string;
    organization_id: string;
    login_id: string;
  }>(`${REGISTER_DELAYED}${requestId}`, payload);
  return response.data;
};

const isOrganizationApproved = async () => {
  const response = await privateApi.get<{
    is_approved: boolean;
    welcome_completed: boolean;
  }>(IS_ORGANIZATION_APPROVED);
  return response.data;
};

const isOrganizationCreator = async () => {
  const response = await privateApi.get<{ is_creator: boolean }>(
    IS_ORGANIZATION_CREATOR
  );
  return response.data.is_creator;
};

const isSupported = async (organizationName: string) => {
  if (!organizationName) {
    return null;
  }
  const response = await publicApi.get<ResolveOrganizationResponse>(
    RESOLVE_ORGANIZATION(organizationName)
  );
  return response.data;
};

const availableRegions = async () => {
  const response = await publicApi.get<AvailableRegions>(AVAILABLE_REGIONS);
  return response.data;
};

const registerOrganization = async (
  {
    fields,
  }: {
    fields: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      region: string;
    };
  },
  captcha: string,
  bp_token?: string
) => {
  const payload = {
    first_name: fields.firstName,
    last_name: fields.lastName,
    email: fields.email,
    password: fields.password,
    region_name: fields.region,
    version: 2,
    bp_token,
    recaptcha_response: captcha,
  };

  const response = await publicApi.post<{
    account_id: string;
    organization_id: string;
    login_id: string;
  }>(REGISTER_ORGANIZATION, payload);
  return response.data;
};

const attachOrganizationToBillingProfile = async (body: {
  bp_token: string;
}) => {
  const response = await privateApi.post<{
    account_id: string;
    organization_id: string;
    login_id: string;
  }>(ATTACH_ORGANIZATION_TO_BILLING, body);
  return response.data;
};

const resendVerificationEmail = async (login_id: string) => {
  const response = await publicApi.post(RESEND_VERIFICATION_EMAIL, {
    login_id,
  });
  return response.data;
};

const recoverLoginUrl = async (email: string) => {
  const response = await publicApi.post(RECOVER_LOGIN_URL, { email });
  return response.data;
};

export const OrganizationService = {
  availableRegions,
  isSupported,
  isOrganizationCreator,
  isOrganizationApproved,
  registerOrganization,
  resendVerificationEmail,
  getOrganization,
  attachOrganizationToBillingProfile,
  recoverLoginUrl,
  getRegistrationRequest,
  continueRegistration,
};
