import { Privilege } from "pages/govern/Roles/PrivilegesTransaction";

import { RolePrivilege } from "./types";

export const getRoleDefinition = (
  roleName: string,
  privileges: RolePrivilege[]
) => {
  const createStatement = `CREATE ROLE ${roleName};`;
  const grantStatements = privileges
    .filter(privilege => {
      const { objectType, objectName, privilegeType } = privilege;
      if (!objectName || !objectType || !privilegeType) {
        return false;
      }

      return true;
    })
    .map(privilege => {
      const { objectType, objectName, privilegeType } = privilege;

      if (objectType === "account") {
        const statement = `GRANT ${privilegeType} on ACCOUNT "${objectName}" TO "${roleName}";\n`;
        return statement;
      }

      const statement = `GRANT ${privilegeType} ON ${objectType} "${objectName}" TO "${roleName}";\n`;
      return statement;
    })
    .join("\n");
  return `${createStatement}

${grantStatements}`;
};

export const getGrantStatements = (
  accountName: string,
  roleName: string,
  grant: Privilege
) => {
  const { actions, resource, type } = grant;
  const statements = actions.map(action => {
    if (type === "account") {
      const statement = `GRANT ${action} on ACCOUNT "${accountName}" TO "${roleName}";\n`;
      return statement;
    }

    const statement = `GRANT ${action} ON ${type} "${resource}" TO "${roleName}";\n`;
    return statement;
  });
  return statements;
};

export const getRevokeStatements = (
  accountName: string,
  roleName: string,
  revoke: Privilege
) => {
  const { actions, resource, type } = revoke;
  const statements = actions.map(action => {
    if (type === "account") {
      const statement = `REVOKE ${action} ON ACCOUNT "${accountName}" FROM "${roleName}";\n`;
      return statement;
    }

    const statement = `REVOKE ${action} ON ${type} "${resource}" FROM "${roleName}";\n`;
    return statement;
  });
  return statements;
};
