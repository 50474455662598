import { Suspense, useMemo } from "react";
import { Control, Controller, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useServiceAccounts } from "services/serviceAccounts/useServiceAccounts";
import { useUsers } from "services/users/useUsers";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import FormRow from "./components/FormRow";

import formRowStyles from "./components/FormRow/FormRow.module.scss";

const AssignServiceAccountList = (props: { control: Control<any> }) => {
  const { control } = props;
  const { t } = useTranslation();
  const { data: serviceAccounts } = useServiceAccounts();
  const users = useUsers();

  const takenAccounts = useMemo(() => {
    const logins = new Set();
    for (const user of users) {
      const { serviceAccountName } = user;
      if (serviceAccountName) {
        logins.add(serviceAccountName);
      }
    }
    return logins;
  }, [users]);

  const sortedServiceAccounts = [...serviceAccounts]
    .filter(({ serviceAccountName }) => !takenAccounts.has(serviceAccountName))
    .sort((a, b) =>
      a.serviceAccountName
        .toLocaleLowerCase()
        .localeCompare(b.serviceAccountName.toLocaleLowerCase())
    );

  const { errors } = useFormState({
    control,
  });

  return (
    <Controller
      control={control}
      name="serviceAccountName"
      render={({ field: { onChange, value } }) => (
        <OutlinedSelect
          searchOptions={{
            searchPlaceholder: t("workspace.create_user.search_sa"),
            noResultsText: t("workspace.create_user.no_sa_found"),
          }}
          testId="service-account-select"
          className={formRowStyles.selectInput}
          onSelect={(items: string[]) => {
            const [item] = items;
            onChange(item);
          }}
          initialSelected={[value]}
          error={(errors.loginName?.message as string) ?? ""}
        >
          {sortedServiceAccounts.map(item => {
            const { serviceAccountName } = item;
            return (
              <ContextMenuItem
                value={serviceAccountName}
                key={serviceAccountName}
                checked={value === item}
                testId={`service-account-item-${serviceAccountName}`}
                checkedIconPlaceholder={true}
                text={serviceAccountName}
              />
            );
          })}
        </OutlinedSelect>
      )}
    />
  );
};

export const AssignServiceAccount = (props: { control: Control<any> }) => {
  const { control } = props;
  const { t } = useTranslation();
  return (
    <FormRow
      label={t("workspace.create_user.assign_service_account")}
      input={
        <Suspense
          fallback={
            <OutlinedSelect
              className={formRowStyles.selectInput}
              testId="fallback-service-account-select"
              disabled
            />
          }
        >
          <AssignServiceAccountList control={control} />
        </Suspense>
      }
    />
  );
};
