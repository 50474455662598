import { SchemaDescriptor } from "pages/DevelopWorkspace/contexts/SQLynxContext/SQLynxLoader";
import { MAX_IDENTIFIERS_COUNT } from "pages/DevelopWorkspace/contexts/SQLynxContext/constants";

const prepareDescriptor = (
  descriptor: SchemaDescriptor
): SchemaDescriptor | null => {
  if (descriptor.schemaName === "information_schema") {
    // always keep information_schema as is
    return descriptor;
  }

  if (descriptor.tables.length > MAX_IDENTIFIERS_COUNT) {
    // too many tables, db schema is too big
    return null;
  }

  const identifierCount = descriptor.tables.reduce((acc, t) => {
    return acc + t.columns.length + 1; // +1 because table name is also an identifier
  }, 0);

  if (identifierCount > MAX_IDENTIFIERS_COUNT) {
    // remove all columns and keep only table names
    return {
      ...descriptor,
      tables: descriptor.tables.map(t => {
        return {
          tableName: t.tableName,
          columns: [],
        };
      }),
    };
  }

  return descriptor;
};

export { prepareDescriptor };
