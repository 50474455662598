import React, { Dispatch } from "react";

import { initialState } from "./useInteractions";

export const DiagramContext = React.createContext<any>({});
export const DiagramActionContext = React.createContext<any>({});

type DiagramProviderProps = {
  children: React.ReactNode;
  state: typeof initialState;
  dispatch: Dispatch<any>;
};

export const DiagramProvider = (props: DiagramProviderProps) => {
  const { children, state, dispatch } = props;
  return (
    <DiagramContext.Provider value={state}>
      <DiagramActionContext.Provider value={dispatch}>
        {children}
      </DiagramActionContext.Provider>
    </DiagramContext.Provider>
  );
};
