import { useState } from "react";

import { IngestionStep, MapDataStep, WizardDataType } from "../../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { Meta } from "./types";

import styles from "../styles.module.scss";

type Props = {
  wizardData: WizardDataType;
  data: MapDataStep["data"];
  indexes: number[];
  meta: Meta[];
  visibleColumns: string[];
  onChange: (rowIndex: number) => void;
  toggleNullable: (rowIndex: number) => void;
  togglePrimaryIndex: (rowIndex: number) => void;
  onChangeAll: (checked: boolean) => void;
  onChangeType: (rowIndex: number, type: string) => void;
  onChangeDestinationName: (row: number, name: string) => void;
};

export const Table = (props: Props) => {
  const {
    wizardData,
    data,
    meta,
    visibleColumns,
    onChange,
    onChangeAll,
    toggleNullable,
    togglePrimaryIndex,
    onChangeType,
    onChangeDestinationName,
    indexes,
  } = props;

  const [editingRow, setEditingRow] = useState<number | null>(null);
  const { files } = wizardData[IngestionStep.selectData] || {};
  const [file] = files || [];
  const isParquet = file?.extension === "parquet";

  return (
    <div className={styles.table}>
      <div className={styles.table__inner}>
        <Header
          meta={meta}
          allChecked={data.every(d => d.included)}
          someChecked={data.some(d => d.included)}
          onChange={onChangeAll}
        />
        <div className={styles.list}>
          {data.map((row, index) => {
            return (
              <Row
                isParquet={isParquet}
                row={row}
                testId={`map-data-row-${index}`}
                onChange={onChange}
                visibleColumns={visibleColumns}
                toggleNullable={toggleNullable}
                togglePrimaryIndex={togglePrimaryIndex}
                onChangeType={onChangeType}
                setEditingRow={setEditingRow}
                editingRow={editingRow}
                data={data}
                index={index}
                indexes={indexes}
                onChangeDestinationName={onChangeDestinationName}
                key={index} // eslint-disable-line react/no-array-index-key
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
