import styles from "./TooltipMessage.module.scss";

interface TooltipMessageProps {
  title: string;
  description?: string;
}

const TooltipMessage = (props: TooltipMessageProps) => {
  const { title, description } = props;

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      {!!description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default TooltipMessage;
