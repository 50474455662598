import { Dialog, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FIREBOLT_DOCS_URL } from "utils/constants";

import { CrossIcon } from "components/Icons";

import styles from "./styles.module.scss";

type Props = {
  title: string;
  subtitle: string;
  onClose: () => void;
  header?: React.ReactNode;
  body: React.ReactNode;
  testId: string;
};

export const Wizard = (props: Props) => {
  const { title, subtitle, onClose, header, body, testId } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      scroll="body"
      open
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.header__items}>
            <div className={styles.header__inner}>
              <div
                className={styles.header__title}
                data-testid={`${testId}-title`}
              >
                {title}
              </div>
              <div className={styles.header__subtitle}>
                {subtitle}{" "}
                <a
                  className={styles.link}
                  href={FIREBOLT_DOCS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  tabIndex={-1}
                  data-testid="firebolt-docs-link"
                >
                  {t("workspace.load_data.subtitle_link")}
                </a>
              </div>
            </div>
            {header}
          </div>
          <IconButton
            onClick={onClose}
            edge="start"
            size="small"
            className={styles.close}
          >
            <CrossIcon data-testid="close-modal-cross" />
          </IconButton>
        </div>
        <div className={styles.body}>{body}</div>
      </div>
    </Dialog>
  );
};
