import _mapValues from "lodash/mapValues";
import _groupBy from "lodash/groupBy";
import _pickBy from "lodash/pickBy";

export interface Column {
  row: number;
  col: number;
}

export interface Selection {
  start: Column;
  end: Column;
}

export interface SelectionSpec {
  minRow: number;
  maxRow: number;
  minCol: number;
  maxCol: number;
  table: {
    string?: number[][];
  };
}

export type GetCellData = (row: number, col: number) => string;

export interface Options {
  getCellData: GetCellData;
  delimiter?: string;
}

export const getSelectionBoundries = ({
  start,
  end,
}: {
  start: Column;
  end: Column;
}) => {
  const topRow = Math.min(start.row, end.row);
  const bottomRow = Math.max(start.row, end.row);
  const leftCol = Math.min(start.col, end.col);
  const rightCol = Math.max(start.col, end.col);
  return {
    topRow,
    bottomRow,
    leftCol,
    rightCol,
  };
};

export const getRangeData = ({
  start,
  end,
  getValue,
}: {
  start: Column;
  end: Column;
  getValue: GetCellData;
}) => {
  const items: string[] = [];
  const { topRow, bottomRow, leftCol, rightCol } = getSelectionBoundries({
    start,
    end,
  });

  for (let i = topRow; i <= bottomRow; i++) {
    const rowCells: string[] = [];
    for (let j = leftCol; j <= rightCol; j++) {
      const value = getValue(i, j);
      rowCells.push(value);
    }
    items.push(rowCells.join("\t"));
  }
  return items.join("\n");
};

const getTableIndexes = (selection: Selection): number[][] => {
  if (
    selection.end.col === selection.start.col &&
    selection.start.row === selection.end.row
  ) {
    // one cell selected
    return [
      [selection.start.row, selection.start.col], // cell
    ];
  }

  const results: number[][] = [];

  const { end, start } = selection;
  const { topRow, bottomRow, leftCol, rightCol } = getSelectionBoundries({
    start,
    end,
  });

  for (let rowIndex: number = topRow; rowIndex <= bottomRow; rowIndex++) {
    for (
      let columnIndex: number = leftCol;
      columnIndex <= rightCol;
      columnIndex++
    ) {
      results.push([rowIndex, columnIndex]);
    }
  }

  return results;
};

const mergeSelections = (selections: Selection[]): number[][] => {
  return selections
    .map(s => getTableIndexes(s))
    .reduce((r, s) => [...r, ...s], []);
};

export const getSelectionCells = (selections: Selection[]): SelectionSpec => {
  const table = mergeSelections(selections);
  let minRow = Number.MAX_SAFE_INTEGER;
  let maxRow = Number.MIN_SAFE_INTEGER;
  let minCol = Number.MAX_SAFE_INTEGER;
  let maxCol = Number.MIN_SAFE_INTEGER;

  for (const cell of table) {
    const [row, col] = cell;
    if (row >= maxRow) {
      maxRow = row;
    }
    if (row <= minRow) {
      minRow = row;
    }

    if (col >= maxCol) {
      maxCol = col;
    }
    if (col <= minCol) {
      minCol = col;
    }
  }

  const grouped = _mapValues(_groupBy(table, r => r[0]));

  return {
    minRow,
    maxRow,
    minCol,
    maxCol,
    table: grouped,
  };
};

export const getData = (
  selectionSpec: SelectionSpec,
  { getCellData, delimiter = "\t" }: Options
): string => {
  let buffer = "";
  let emptyColIndexes: number[] = [];
  let processedRows = 0;

  for (let i = selectionSpec.minRow; i <= selectionSpec.maxRow; i++) {
    const table = selectionSpec.table;
    const index = `${i}`;
    const row: number[][] | undefined = table[index as "string"];
    if (!row) {
      // eslint-disable-next-line no-continue -- legacy code
      continue;
    }
    processedRows++;
    let j = selectionSpec.minCol;
    for (; j <= selectionSpec.maxCol; j++) {
      const find = j;
      const col = row.find(col => col[1] === find);
      if (col) {
        buffer += `${getCellData(i, j)}`;
        buffer += delimiter;
        const find = j;
        emptyColIndexes = emptyColIndexes.filter(i => i !== find);
      } else {
        emptyColIndexes.push(j);
        if (j !== selectionSpec.maxCol) {
          buffer += `{markToRemove${j}}`;
        }
      }
    }
    const lastColMarkedToRemove =
      emptyColIndexes[emptyColIndexes.length - 1] === j - 1;
    if (!lastColMarkedToRemove) {
      buffer = buffer.slice(0, -1);
    }
    buffer += "\n";
  }

  const removeColumns = _pickBy(
    _mapValues(
      _groupBy(emptyColIndexes, i => i),
      v => {
        return v.length === processedRows;
      }
    ),
    v => v
  );

  Object.keys(removeColumns).forEach(k => {
    const str = `{markToRemove${k}}`;
    buffer = buffer.replace(new RegExp(str, "g"), "");
  });

  buffer = buffer.replace(/{markToRemove\d+}/g, delimiter);
  return buffer.slice(0, -1).trim();
};

export const getCopyText = ({
  selections,
  getValue,
}: {
  selections: Selection[];
  getValue: GetCellData;
}) => {
  if (selections.length === 1) {
    const [selectedArea] = selections;
    const text = getRangeData({
      start: selectedArea.start,
      end: selectedArea.end,
      getValue,
    });
    return text;
  }
  const tableDefinition = getSelectionCells(selections);
  const text = getData(tableDefinition, {
    getCellData: getValue,
    delimiter: "\t",
  });
  return text;
};
