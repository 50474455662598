const element = document.createElement("canvas");
const context = element.getContext("2d");

export const getTextWidth = (text: string, font: string): number => {
  if (context && typeof text === "string") {
    context.font = font;
    return context.measureText(text).width;
  }

  return 0;
};
