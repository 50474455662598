const template = `SELECT
  start_time,
  duration_us,
  status,
  query_id,
  query_text
FROM information_schema.engine_query_history
WHERE duration_us > 0
  AND query_text NOT LIKE '%query_history%'
ORDER BY start_time DESC
LIMIT 100;`;

export default template;
