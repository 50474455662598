import { useReducer, useState } from "react";

import { Role } from "services/rbac/types";

import { privilegesReducer } from "./privilegesReducer";
import { PrivilegesState } from "./types";

const getInitialState = (role?: Role) => {
  const privileges = role?.privileges || [];

  const privilegesByResource = privileges.reduce<any>(
    (acc, privilege) => {
      const { privilegeType, objectType } = privilege;

      if (!privilegeType) {
        return acc;
      }

      // const action = privilegeType as SqlRbacAction;
      // const actions = _get(acc, [objectType, 0, "toAssign"], {});
      // group by privilege if all objects has same privilege,
      // group by object if they have different privileges
      return {
        ...acc,
        [objectType]: [],
      };
    },
    {
      engine: [{ toAssign: [], toRevoke: [], objects: [] }],
      database: [{ toAssign: [], toRevoke: [], objects: [] }],
      account: [{ toAssign: [], toRevoke: [], objects: [] }],
      view: [{ toAssign: [], toRevoke: [], objects: [] }],
      schema: [{ toAssign: [], toRevoke: [], objects: [] }],
      table: [{ toAssign: [], toRevoke: [], objects: [] }],
      permissionErrors: {
        table: [],
        view: [],
        schema: [],
      },
    }
  );

  return privilegesByResource;
};

export const usePrivileges = (initialData: PrivilegesState | null) => {
  const [initialState] = useState<PrivilegesState>(
    () => initialData || getInitialState()
  );

  const [state, dispatch] = useReducer(privilegesReducer, initialState);

  return {
    state,
    dispatch,
  };
};
