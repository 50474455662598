import { useSuspenseQuery } from "@tanstack/react-query";

import { OrganizationService } from "services/organization";
import { ReactQueryKeysGlobal } from "services/queryKeys";

export const useOrganizationApproved = () => {
  const { data } = useSuspenseQuery({
    queryKey: [ReactQueryKeysGlobal.isOrganizationApproved],
    refetchOnMount: false,
    staleTime: Infinity,
    queryFn: () => {
      return OrganizationService.isOrganizationApproved();
    },
  });
  return data;
};
