// we use queryKeys.test.ts to ensure uniqueness between the 2 types of query-key

// organization level react-query keys
export enum ReactQueryKeysGlobal {
  handleLogin = "handleLogin",
  isLoggedIn = "isLoggedIn",
  initializeOrganization = "initializeOrganization",
  myAccounts = "myAccounts",
  accounts = "accounts",
  isOrganizationApproved = "isOrganizationApproved",
  regions = "regions",
  myTenants = "myTenants",
  logins = "logins",
  serviceAccounts = "serviceAccounts",
  organization = "organization",
  organizationTrialBalance = "organizationTrialBalance",
  loginId = "loginId",
  organizationSSOId = "organizationSSOId",
  engineMetrics = "engineMetrics",
  isOrganizationCreator = "isOrganizationCreator",
  networkPolicies = "networkPolicies",
  login = "login",
  claims = "claims",
  organizationCredit = "organizationCredit",
  billing_top_engines = "billing_top_engines",
  billing_engines = "billing_engines",
  billing_data = "billing_data",
  chartCosts = "chartCosts",
  costs = "costs",
}

// account level react-query keys
export enum ReactQueryKeysAccount {
  databasesNames = "databasesNames",
  workspaceEngines = "workspaceEngines",
  previewScript = "previewScript",
  ensureRunning = "ensureRunning",
  supportAccess = "supportAccess",
  supportAccessShort = "supportAccessShort",
  loadFiles = "loadFiles",
  user = "user",
  users = "users",
  accountAdminRole = "accountAdminRole",
  engineMonitoring = "engineMonitoring",
  queryHistory = "queryHistory",
  filePreview = "filePreview",
  demo_tool_charts_data = "demo_tool_charts_data",
  columns = "columns",
  tableNames = "tableNames",
  viewNames = "viewNames",
  schemaNames = "schemaNames",
  indexes = "indexes",
  currentUser = "currentUser",
  rbacRoles = "rbacRoles",
  demo_query_concurrency_stats = "demo_query_concurrency_stats",
  demo_query_history = "demo_query_history",
  demo_running_queries = "demo_running_queries",
  demo_running_queries_count = "demo_running_queries_count",
  demo_table_stats = "demo_table_stats",
}
