import { Dialog, DialogProps } from "@mui/material";
import React from "react";

import LoadingOverlap from "components/LoadingOverlap";

import styles from "./FormModal.module.scss";

export interface Props extends DialogProps {
  title?: any;
  dialogIsLoading?: boolean;
  bodyContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  children?: React.ReactNode;
}

const FormModal = (props: Props) => {
  const {
    title,
    bodyContent,
    footerContent,
    children,
    dialogIsLoading,
    ...rest
  } = props;

  return (
    <Dialog
      scroll="body"
      {...rest}
    >
      {dialogIsLoading && <LoadingOverlap isLoading />}
      {!dialogIsLoading &&
        (children || (
          <>
            {title && (
              <div className={styles.header}>
                <div
                  className={styles.title}
                  data-testid="dialog-title"
                >
                  {title}
                </div>
              </div>
            )}
            <div className={styles.body}>{bodyContent}</div>
            <div className={styles.footer}>{footerContent}</div>
          </>
        ))}
    </Dialog>
  );
};

export default FormModal;
