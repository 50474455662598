import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";

export const SelectItems = (props: { items?: React.ReactElement[] }) => {
  const { items } = props;
  return (
    <ContextMenu>
      <ContextMenuItemsGroup
        isGroup
        maxHeight={400}
      >
        {items}
      </ContextMenuItemsGroup>
    </ContextMenu>
  );
};
