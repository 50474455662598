import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchVersion } from "utils/maintenance";

import SnackLinkMessage from "components/SnackLinkMessage/SnackLinkMessage";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useAppVersionUpdateNotification = () => {
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();

  const [currentVersion, setCurrentVersion] = useState<string>(
    (document.getElementById("app-version") as HTMLMetaElement).content
  );

  useEffect(() => {
    const interval = setInterval(async () => {
      const version = await fetchVersion();

      if (!version) return;

      if (version !== currentVersion) {
        setCurrentVersion(version);

        putStatusMessage({
          message: (
            <SnackLinkMessage
              message={`${t("app_update.message")}, `}
              link={t("app_update.link")}
              href=""
            />
          ),
          type: StatusMessageType.Error,
          options: {
            autoRemove: false,
            insertToPosition: StatusMessagePosition.Bottom,
            id: "app-update",
            preventAutoClear: true,
          },
        });
      }
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentVersion, putStatusMessage, setCurrentVersion, t]);

  return null;
};
