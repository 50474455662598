// eslint-disable-next-line max-classes-per-file -- legacy code
class Node {
  type: any;

  value: any;

  match: any;

  newline: any;

  constructor(node: { type: any; value?: any; match?: any; newline?: any }) {
    this.type = node.type;
    if (node.value) this.value = node.value;
    if (node.match) this.match = node.match;
    this.newline = node.newline || "";
  }

  get protected() {
    return Boolean(this.match) && this.match[1] === "!";
  }
}

class Block extends Node {
  private nodes: any[];

  constructor(node: { type: any; nodes?: any[] }) {
    super(node);
    this.nodes = node.nodes || [];
  }

  push(node: any) {
    this.nodes.push(node);
  }

  get protected() {
    return this.nodes.length > 0 && this.nodes[0].protected === true;
  }
}

const constants = {
  ESCAPED_CHAR_REGEX: /^\\./,
  // QUOTED_STRING_REGEX:
  //   /^((['])((?:\\.|[^'])+?)(')|(['])(.*)(')|(["])((?:\\.|[^"])+?)(")|([`])((?:\\.|[^`])+?)(`))/,
  QUOTED_STRING_REGEX_PART_1: /^((['])((?:\\.|[^'])+?)('))/,
  QUOTED_STRING_REGEX_PART_2: /^((['])(.*)('))/,
  QUOTED_STRING_REGEX_PART_3: /^((["])((?:\\.|[^"])+?)("))/,
  QUOTED_STRING_REGEX_PART_4: /^(([`])((?:\\.|[^`])+?)(`))/,
  NEWLINE_REGEX: /^\r*\n/,
  BLOCK_OPEN_REGEX: /^\/\*\*?(!?)/,
  BLOCK_CLOSE_REGEX: /^\*\/(\n?)/,
  LINE_REGEX: /^--.*/,
  SKIP_C: /^[a-zABD-Z0-9\t ]+/,
};
const wordRegex = /\w$/;

const parse = (input: string, options: any = {}) => {
  if (typeof input !== "string") {
    throw new TypeError("Expected input to be a string");
  }

  const cst = new Block({ type: "root", nodes: [] });
  const stack: any[] = [cst];

  let block = cst;
  let remaining = input;
  let token: any;
  let prev: any;

  const source = [
    constants.BLOCK_OPEN_REGEX,
    constants.BLOCK_CLOSE_REGEX,
  ].filter(Boolean);
  let tripleQuotes = false;

  if (source.every(regex => regex.source === '^"""')) {
    tripleQuotes = true;
  }

  /**
   * Helpers
   */

  const consume = (value = remaining[0] || "") => {
    remaining = remaining.slice(value.length);
    return value;
  };

  const scanQuoted = () => {
    let match = constants.QUOTED_STRING_REGEX_PART_1.exec(remaining);

    if (!match) {
      match = constants.QUOTED_STRING_REGEX_PART_2.exec(remaining);
    }

    if (!match) {
      match = constants.QUOTED_STRING_REGEX_PART_3.exec(remaining);
    }

    if (!match) {
      match = constants.QUOTED_STRING_REGEX_PART_4.exec(remaining);
    }

    if (match) {
      remaining = remaining.slice(match[0].length);
      return { type: "text", value: match[0], match };
    }
  };

  const scan = (regex: RegExp, type = "text") => {
    const match = regex.exec(remaining);
    if (match) {
      remaining = remaining.slice(match[0].length);
      return { type, value: match[0], match };
    }
  };

  const push = (node: any) => {
    if (prev && prev.type === "text" && node.type === "text") {
      prev.value += node.value;
      return;
    }
    block.push(node);
    if (node.nodes) {
      stack.push(node);
      block = node as any;
    }
    prev = node;
  };

  const pop = () => {
    if (block.type === "root") {
      throw new SyntaxError("Unclosed block comment");
    }
    stack.pop();
    block = stack[stack.length - 1];
  };

  /**
   * Parse input string
   */

  while (remaining !== "") {
    // block comment open
    if (
      constants.BLOCK_OPEN_REGEX &&
      options.block &&
      !(tripleQuotes && block.type === "block")
    ) {
      token = scan(constants.BLOCK_OPEN_REGEX, "open");

      if (token) {
        const newBlock = new Block({ type: "block" });
        push(newBlock);
        push(new Node(token));
        // eslint-disable-next-line no-continue -- legacy code
        continue;
      }
    }

    // block comment close
    if (
      constants.BLOCK_CLOSE_REGEX &&
      block.type === "block" &&
      options.block
    ) {
      token = scan(constants.BLOCK_CLOSE_REGEX, "open");
      if (token) {
        token.newline = token.match[1] || "";
        push(new Node(token));
        pop();
        // eslint-disable-next-line no-continue -- legacy code
        continue;
      }
    }

    // line comment
    if (constants.LINE_REGEX && block.type !== "block" && options.line) {
      token = scan(constants.LINE_REGEX, "line");
      if (token) {
        push(new Node(token));
        // eslint-disable-next-line no-continue -- legacy code
        continue;
      }
    }

    // escaped characters
    token = scan(constants.ESCAPED_CHAR_REGEX, "text");
    if (token) {
      push(new Node(token));
      // eslint-disable-next-line no-continue -- legacy code
      continue;
    }

    // Plain text (skip "C" since some languages use "C" to start comments)
    token = scan(constants.SKIP_C, "text");
    if (token) {
      push(new Node(token));
      // eslint-disable-next-line no-continue -- legacy code
      continue;
    }

    // newlines
    token = scan(constants.NEWLINE_REGEX, "newline");
    if (token) {
      push(new Node(token));
      // eslint-disable-next-line no-continue -- legacy code
      continue;
    }

    // quoted strings
    if (
      block.type !== "block" &&
      (!prev || !wordRegex.test(prev.value)) &&
      !(tripleQuotes && remaining.startsWith('"""'))
    ) {
      token = scanQuoted();
      if (token) {
        push(new Node(token));
        // eslint-disable-next-line no-continue -- legacy code
        continue;
      }
    }

    push(new Node({ type: "text", value: consume(remaining[0]) }));
  }

  return cst;
};

const compile = (
  cst: {
    nodes: any[];
    type: string;
    protected?: boolean;
  },
  options: any = {}
) => {
  const keepProtected = options.safe === true || options.keepProtected === true;
  let firstSeen = false;

  const walk = (node: { nodes: any[]; type: string; protected?: boolean }) => {
    let output = "";
    let inner;
    let lines;

    for (const child of node.nodes) {
      switch (child.type) {
        case "block":
          if (options.first && firstSeen === true) {
            output += walk(child);
            break;
          }

          if (options.preserveNewlines === true) {
            inner = walk(child);
            lines = inner.split("\n");
            output += "\n".repeat(lines.length - 1);
            break;
          }

          if (keepProtected === true && child.protected === true) {
            output += walk(child);
            break;
          }

          firstSeen = true;
          break;
        case "line":
          if (options.first && firstSeen === true) {
            output += child.value;
            break;
          }

          if (keepProtected === true && child.protected === true) {
            output += child.value;
          }

          firstSeen = true;
          break;
        case "open":
        case "close":
        case "text":
        case "newline":
        default: {
          output += child.value || "";
          break;
        }
      }
    }

    return output;
  };

  return walk(cst);
};

export function stripComments(input: string) {
  const opts = { block: true, line: true };
  return compile(parse(input, opts) as any, opts);
}
