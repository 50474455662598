import { useQueryClient } from "@tanstack/react-query";
import { PublicationContext } from "centrifuge";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useOrganization } from "hooks/useOrganization";
import { authService } from "services/auth";
import { useHasAdminRole } from "services/auth/useHasAdminRole";
import { AWS_MARKETPLACE_URL } from "services/globals";
import { useOrganizationCredit } from "services/organization/useOrganizationCredit";

import Button, { ButtonSize, ButtonTemplate } from "components/Button/Button";
import { useWebsocket } from "components/topLevel/Websocket/useWebsocket";
import { EventTypes } from "components/topLevel/Websocket/websocket.types";

import { ReactQueryKeysGlobal } from "../../services/queryKeys";
import { getTrialRemainingDays } from "../MainSidebar/CreditIndicator/helpers";

import styles from "./styles.module.scss";

const Banner = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: organizationBalance } = useOrganizationCredit();
  const hasAdminRole = useHasAdminRole();

  const { getClient, getChannelTokens } = useWebsocket();
  const client = getClient();
  const tokens = getChannelTokens();
  const key = `org:${authService.organizationName}`;
  const channel = tokens[key]?.channel;

  useEffect(() => {
    if (hasAdminRole && client && channel) {
      const subscription = client.getSubscription(channel);
      if (subscription) {
        const handler = (ctx: PublicationContext) => {
          if (
            ctx?.data?.type === EventTypes.CreditsRunOut ||
            ctx?.data?.type === EventTypes.CreditExhausted
          ) {
            queryClient.invalidateQueries({
              queryKey: [ReactQueryKeysGlobal.organizationCredit],
            });
          }
        };
        subscription.addListener("publication", handler);
        return () => {
          subscription.removeListener("publication", handler);
        };
      }
    }
  }, [client, channel, hasAdminRole, queryClient]);

  if (!organizationBalance) {
    return null;
  }

  const consumedCreditText = t("billing.credit_consumed");
  const trialExpiredText = t("billing.trial_expired");
  const remainingDays = getTrialRemainingDays(
    organizationBalance?.expirationDate
  );

  if (
    organizationBalance &&
    organizationBalance.availableCredit > 0 &&
    remainingDays > 0
  ) {
    return null;
  }

  const text =
    organizationBalance.availableCredit <= 0
      ? consumedCreditText
      : trialExpiredText;

  return (
    <>
      <div className={styles.banner}>
        <div className={styles.banner__info}>
          {text} {t("onboarding.connect_marketplace.banner")}{" "}
          {hasAdminRole ? (
            <Button
              dataTestid="banner-connect-marketplace"
              text={t("onboarding.connect_marketplace.connect")}
              onClick={() => {
                document.location.href = AWS_MARKETPLACE_URL;
              }}
              size={ButtonSize.Medium}
              template={ButtonTemplate.Primary}
              className={styles.button}
            />
          ) : (
            t("onboarding.connect_marketplace.contact_admin")
          )}
        </div>
      </div>
    </>
  );
};

export const CreditsBanner = () => {
  const { data: organization } = useOrganization();
  if (organization?.isPaymentRegistered) {
    return null;
  }
  return <Banner />;
};
