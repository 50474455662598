import z from "zod";

import { AuthType } from "services/fileObjects/getFilesByPath";

export const parquetSchema = z.object({
  extension: z.literal("parquet"),
  useDefaultErrorSettings: z.boolean(),
  useDefaultFormatSettings: z.boolean(),
  formatSettings: z.object({
    allowColumnMismatch: z.boolean(),
    autoCreate: z.boolean(),
  }),
  errorSettings: z.object({
    errorFile: z.union([
      z.string().regex(/s3:\/\/.+/, "Storage url should start with s3://"),
      z.literal(""),
    ]),
    authType: z.enum([AuthType.IAM, AuthType.SECRET, AuthType.NONE]),
    key: z.string(),
    secret: z.string(),
    iamRole: z.string(),
    // onErrorAction: z.string(),
    // maxErrors: z.number(),
    maxErrorsPerFile: z
      .custom<number>()
      .refine(value => Number.isFinite(Number(value)), "Invalid number")
      .transform(value => Number(value)),
  }),
});

export const csvSchema = z.object({
  extension: z.literal("csv"),
  useDefaultErrorSettings: z.boolean(),
  useDefaultFormatSettings: z.boolean(),
  formatSettings: z.object({
    autoDetectHeader: z.boolean(),
    fieldDelimiter: z.string().min(1),
    quoteCharacter: z.string().min(1),
    escapeCharacter: z.string().min(1),
    newlineCharacter: z.string().min(1),
    nullCharacter: z.string().min(1),
    allowColumnMismatch: z.boolean(),
    autoCreate: z.boolean(),
    skipBlankLines: z.boolean(),
  }),
  errorSettings: z.object({
    errorFile: z.union([
      z.string().regex(/s3:\/\/.+/, "Storage url should start with s3://"),
      z.literal(""),
    ]),
    authType: z.enum([AuthType.IAM, AuthType.SECRET, AuthType.NONE]),
    key: z.string(),
    secret: z.string(),
    iamRole: z.string(),
    // onErrorAction: z.string(),
    // maxErrors: z.number(),
    maxErrorsPerFile: z
      .custom<number>()
      .refine(value => Number.isFinite(Number(value)), "Invalid number")
      .transform(value => Number(value)),
  }),
});

export const tsvSchema = csvSchema.extend({
  extension: z.literal("tsv"),
});

export const formatingSchema = z.discriminatedUnion("extension", [
  csvSchema,
  tsvSchema,
  parquetSchema,
]);

export const defaultCsvFormating = {
  extension: "csv",
  autoDetectHeader: true,
  fieldDelimiter: ",",
  escapeCharacter: "/",
  quoteCharacter: "DOUBLE_QUOTE",
  newlineCharacter: "\\n",
  nullCharacter: "NULL",
  allowColumnMismatch: false,
  autoCreate: false,
  skipBlankLines: false,
};

export const defaultTsvFormating = {
  ...defaultCsvFormating,
  extension: "tsv",
  fieldDelimiter: "\u0009",
};

export const defaultParquetFormating = {
  extension: "parquet",
  allowColumnMismatch: false,
  autoCreate: false,
};

export const defaultErrorSettings = {
  authType: AuthType.SECRET,
  key: "",
  secret: "",
  errorFile: "",
  iamRole: "",
  // onErrorAction: "continue",
  // maxErrors: 100,
  maxErrorsPerFile: 10,
};

export const defaultFormatingByType = {
  csv: defaultCsvFormating,
  tsv: defaultTsvFormating,
  parquet: defaultParquetFormating,
};

export type FormatingFields = z.infer<typeof formatingSchema>;
