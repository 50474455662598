export enum RbacActionObject {
  DATABASE = "DATABASE",
  ENGINE = "ENGINE",
}

export enum SqlRbacAction {
  USAGE = "USAGE",
  MODIFY = "MODIFY",
  OPERATE = "OPERATE",

  ENGINE_CREATE = "CREATE ENGINE",
  DATABASE_CREATE = "CREATE DATABASE",

  ENGINE_USAGE_ANY = "USAGE ANY ENGINE",
  ENGINE_MODIFY_ANY = "MODIFY ANY ENGINE",
  ENGINE_OPERATE_ANY = "OPERATE ANY ENGINE",

  DATABASE_USAGE_ANY = "USAGE ANY DATABASE",
  DATABASE_MODIFY_ANY = "MODIFY ANY DATABASE",

  USER_MODIFY_ANY = "MODIFY ANY USER",

  USER_CREATE = "CREATE USER",

  USER_CREATE_ANY = "CREATE ANY USER",
  USER_CONNECT_ANY = "CONNECT ANY USER",

  ROLE_CREATE = "CREATE ROLE",

  ROLE_MODIFY_ANY = "MODIFY ANY ROLE",
  ROLE_MANAGE_ANY = "MANAGE ANY ROLE",

  GRANTS_MANAGE = "MANAGE GRANTS",
}
