import { BYTES_HELPER } from "utils/helpers/Format";

export function getMaxBytes(bytes: number) {
  let max = BYTES_HELPER.kiloByte;
  while (bytes > max) {
    max *= BYTES_HELPER.kiloByte;
  }

  return max;
}
