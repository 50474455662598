import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorFileAuthSelector } from "components/IngestionWizard/AuthSelector/ErrorFileAuthSelector";
import { TextInput } from "components/TextInput/TextInput";
import Toggle from "components/Toggle";

import { FormatingFields, defaultErrorSettings } from "../schema";

import styles from "./styles.module.scss";

const CustomErrorSettings = (props: {
  form: UseFormReturn<FormatingFields>;
}) => {
  const { form } = props;
  const {
    register,
    formState: { errors },
  } = form;
  const { t } = useTranslation();

  // todo: add testids for actionable elements when uncommenting + ensure their uniqueness
  return (
    <div className={styles.settings}>
      {/* <div className={styles.row}>
          <div className={styles.row__label}>
          {t("wizard.format_data.action_on_error")}
          </div>
          <div className={styles.radioSelector}>
          <Controller
          control={control}
          name="errorSettings.onErrorAction"
          render={({ field: { onChange, value } }) => (
          <>
          <div
          onClick={() => onChange("continue")}
          className={styles.radioItem}
          >
          <RadioButton selected={value === "continue"} />
          <label className={styles.radioLabel}>
          {t("wizard.format_data.continue")}
          </label>
          </div>

          <div
          onClick={() => {
          onChange("abort");
          }}
          className={styles.radioItem}
          >
          <RadioButton selected={value === "abort"} />
          <label className={styles.radioLabel}>
          {t("wizard.format_data.abort")}
          </label>
          </div>
          </>
          )}
          />
          </div>
          </div> */}
      <div className={styles.row}>
        <span className={styles.row__label}>
          {t("wizard.format_data.error_file")}
        </span>
        <div className={styles.inputContainer}>
          <TextInput
            inputRootClassName={styles.nameInput}
            testId="format-data-error-file"
            {...register("errorSettings.errorFile")}
            error={!!errors?.errorSettings?.errorFile}
            helperText={errors?.errorSettings?.errorFile?.message}
            required
          />
        </div>
      </div>
      <ErrorFileAuthSelector form={form} />
      {/* <div className={styles.row}>
          <span className={styles.row__label}>
          {t("wizard.format_data.max_errors")}
          </span>
          <div className={styles.inputContainer}>
          <TextInput
          inputRootClassName={styles.nameInput}
          {...register("errorSettings.maxErrors")}
          error={!!errors?.errorSettings?.maxErrors}
          helperText={errors?.errorSettings?.maxErrors?.message}
          required
          />
          </div>
          </div> */}
      <div className={styles.row}>
        <span className={styles.row__label}>
          {t("wizard.format_data.max_errors_per_file")}
        </span>
        <div className={styles.inputContainer}>
          <TextInput
            inputRootClassName={styles.nameInput}
            testId="format-data-max-errors-per-file"
            {...register("errorSettings.maxErrorsPerFile")}
            error={!!errors?.errorSettings?.maxErrorsPerFile}
            helperText={errors?.errorSettings?.maxErrorsPerFile?.message}
            required
          />
        </div>
      </div>
    </div>
  );
};

export const ErrorSettings = (props: {
  form: UseFormReturn<FormatingFields>;
}) => {
  const { form } = props;
  const { control, watch, setValue } = form;
  return (
    <div className={styles.formatTab}>
      <Controller
        control={control}
        name="useDefaultErrorSettings"
        render={({ field: { onChange, value } }) => (
          <div className={styles.toggle}>
            <span className={styles.toggle__label}>
              Use default error handling
            </span>
            <Toggle
              size="sm"
              dataTestId="use-default-error"
              checked={value}
              classes={{ wrapper: styles.toggleWrapper }}
              onChange={checked => {
                if (checked) {
                  setValue(
                    "errorSettings",
                    defaultErrorSettings as FormatingFields["errorSettings"]
                  );
                }
                onChange(checked);
              }}
            />
          </div>
        )}
      />
      {!watch("useDefaultErrorSettings") && <CustomErrorSettings form={form} />}
    </div>
  );
};
