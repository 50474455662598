import { MyAccount } from "services/account/account.types";
import { getDatabaseTables } from "services/databases/getDatabaseTables";
import { getSchemaNames } from "services/databases/getSchemaNames";
import { getViews } from "services/databases/getViews";
import { Database } from "services/databases/useDatabasesNames";
import { WorkspaceEngine } from "services/engines/engine.types";

import {
  ALL_DATABASES,
  ALL_ENGINES,
  ALL_SCHEMAS,
  ALL_SCHEMAS_ALL_DATABASES,
  ALL_TABLES,
  ALL_TABLES_ALL_DATABASES,
  ALL_VIEWS,
  ALL_VIEWS_ALL_DATABASES,
  ANY_DATABASE,
  ANY_ENGINE,
  ANY_TABLE,
} from "../PrivilegesTable/constant";

const getName = (object: string | { name: string; catalogName: string }) => {
  if (typeof object === "string") {
    return object;
  }
  return object.name;
};

const getCatalogName = (
  object: string | { name: string; catalogName: string }
) => {
  if (typeof object === "string") {
    return undefined;
  }
  return object.catalogName;
};

export const mapPrivilegeObjects = async (
  type: string,
  actions: string[],
  objects: string[] | { name: string; catalogName: string }[],
  dependencies: {
    currentAccount: MyAccount;
    databases: Database[];
    engines: WorkspaceEngine[] | undefined;
  }
) => {
  const { currentAccount, databases, engines = [] } = dependencies;
  const privileges = [];
  const addDatabaseSchemas = (
    catalogName: string,
    databaseSchemas: { tableSchema: string }[]
  ) => {
    for (const view of databaseSchemas) {
      const { tableSchema } = view;
      privileges.push({
        type: "schema",
        resource: tableSchema,
        catalogName,
        actions,
      });
    }
  };

  const addDatabaseTables = (
    catalogName: string,
    databaseTables: { tableName: string }[]
  ) => {
    for (const table of databaseTables) {
      const { tableName } = table;
      privileges.push({
        type: "table",
        resource: tableName,
        catalogName,
        actions,
      });
    }
  };
  const addDatabaseViews = (
    catalogName: string,
    databaseViews: { tableName: string }[]
  ) => {
    for (const view of databaseViews) {
      const { tableName } = view;
      privileges.push({
        type: "view",
        resource: tableName,
        catalogName,
        actions,
      });
    }
  };

  const specialObjectMap = {
    [ANY_DATABASE]: (catalogName: string) => {
      privileges.push({
        type: "account",
        catalogName,
        resource: currentAccount.accountName as string,
        actions: actions.map(action => `${action} any ${type}`),
      });
    },
    [ANY_ENGINE]: (catalogName: string) => {
      privileges.push({
        type: "account",
        catalogName,
        resource: currentAccount.accountName as string,
        actions: actions.map(action => `${action} any ${type}`),
      });
    },
    [ANY_TABLE]: (catalogName: string) => {
      privileges.push({
        type: "schema",
        catalogName,
        resource: "public",
        actions: actions.map(action => `${action} any ${type}`),
      });
    },
    [ALL_DATABASES]: () => {
      for (const database of databases) {
        const { catalogName } = database;
        privileges.push({
          type: "database",
          catalogName,
          resource: catalogName,
          actions,
        });
      }
    },
    [ALL_ENGINES]: (catalogName: string) => {
      for (const engine of engines) {
        const { engineName } = engine;
        privileges.push({
          type: "engine",
          resource: engineName,
          catalogName,
          actions,
        });
      }
    },
    [ALL_TABLES]: async (catalogName: string) => {
      // eslint-disable-next-line no-await-in-loop
      const databaseTables = await getDatabaseTables(catalogName);
      addDatabaseTables(catalogName, databaseTables);
    },
    [ALL_VIEWS]: async (catalogName: string) => {
      // eslint-disable-next-line no-await-in-loop
      const databaseViews = await getViews(catalogName);
      addDatabaseViews(catalogName, databaseViews);
    },
    [ALL_SCHEMAS]: async (catalogName: string) => {
      // eslint-disable-next-line no-await-in-loop
      const databaseSchemas = await getSchemaNames(catalogName);
      addDatabaseSchemas(catalogName, databaseSchemas);
    },
    [ALL_SCHEMAS_ALL_DATABASES]: async () => {
      for (const database of databases) {
        const { catalogName } = database;
        // eslint-disable-next-line no-await-in-loop
        const databaseSchemas = await getSchemaNames(catalogName);
        addDatabaseSchemas(catalogName, databaseSchemas);
      }
    },
    [ALL_TABLES_ALL_DATABASES]: async () => {
      for (const database of databases) {
        const { catalogName } = database;
        // eslint-disable-next-line no-await-in-loop
        const databaseTables = await getDatabaseTables(catalogName);
        addDatabaseTables(catalogName, databaseTables);
      }
    },
    [ALL_VIEWS_ALL_DATABASES]: async () => {
      for (const database of databases) {
        const { catalogName } = database;
        // eslint-disable-next-line no-await-in-loop
        const databaseTables = await getViews(catalogName);
        addDatabaseViews(catalogName, databaseTables);
      }
    },
  };

  for (const object of objects) {
    const name = getName(object);
    const catalogName = getCatalogName(object);
    if (actions.length) {
      if (specialObjectMap[name as keyof typeof specialObjectMap]) {
        const handler = specialObjectMap[name as keyof typeof specialObjectMap];
        // eslint-disable-next-line no-await-in-loop
        await handler(catalogName as string);
      } else {
        privileges.push({
          type,
          resource: name,
          catalogName,
          actions,
        });
      }
    }
  }
  return privileges;
};
