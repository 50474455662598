import React, { Suspense, useEffect, useMemo } from "react";

import DocumentContent from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentContent";
import DocumentContextSelector from "pages/DevelopWorkspace/Editor/Document/DocumentContextSelector/DocumentContextSelector";
import DocumentOutput from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentOutput";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { isDocumentRunning } from "pages/DevelopWorkspace/helpers/isDocumentRunning";
import {
  CancellationStatus,
  ExecutionType,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import styles from "./Document.module.scss";

export interface DocumentStatus {
  isRunning: boolean;
  isUnknown: boolean;
  isCanceling: boolean;
}

interface DocumentProps {
  document: WorkspaceDocument;
}

const Document = (props: DocumentProps) => {
  const { document } = props;

  const {
    actions: { startDocumentExecution },
  } = useDocuments();

  const [executionType, setExecutionType] = React.useState<ExecutionType>(
    ExecutionType.Query
  );

  useEffect(() => {
    // reset execution type when document changes
    setExecutionType(ExecutionType.Query);
  }, [document.id]);

  const documentStatus: DocumentStatus = useMemo(() => {
    const isRunning = isDocumentRunning(document);
    const isUnknown = !!document.execution?.queryStatements?.some(
      queryStatement => queryStatement.status === QueryStatementStatus.unknown
    );
    const isCanceling =
      document.execution?.cancellationStatus === CancellationStatus.Initiated ||
      document.execution?.cancellationStatus === CancellationStatus.Pending;

    return {
      isRunning,
      isUnknown,
      isCanceling,
    };
  }, [document]);

  const runText = (content: string) => {
    if (documentStatus.isUnknown || documentStatus.isCanceling) {
      return;
    }
    if (!documentStatus.isRunning && content) {
      startDocumentExecution(document.id, content, executionType);
    }
  };

  return (
    <div className={styles.root}>
      <Suspense
        fallback={<div className={styles.contextSelectorPlaceholder} />}
      >
        <DocumentContextSelector
          executionType={executionType}
          onExecutionTypeChange={setExecutionType}
          document={document}
          documentStatus={documentStatus}
        />
      </Suspense>
      <DocumentContent
        key={document.id}
        document={document}
        runText={runText}
      />

      <DocumentOutput document={document} />
    </div>
  );
};

export default Document;
