import BigNumber from "bignumber.js";
import { QUERY_OUTPUT } from "types/outputFormat";

import { Meta, QueryResponse } from "./types";

/**
 * If the response of the query has duplicated column aliases,
 * we need to assign the unique names to each column, and add displayName with the original name of the alias.
 * @example
 * SELECT col1 as foo, col2 as foo, col3 as foo, col4 as bar FROM table;
 * Returns the meta array:
 * [
 * { name: "foo",
 *  displayName: "foo",
 * },
 * { name: "foo1",
 *  displayName: "foo",
 * },
 * { name: "foo2",
 *  displayName: "foo",
 * },
 * { name: "bar",
 *  displayName: "bar",
 * }
 * ]
 * @returns {Meta[]} Returns meta array with unique column names and displayNames
 */
function getNormalizedMeta(meta: Meta[]) {
  const metaNamesCounter = new Map();

  return meta.map((metaItem): Meta => {
    const normalizedName = metaNamesCounter.get(metaItem.name)
      ? metaItem.name + `${metaNamesCounter.get(metaItem.name)}`
      : metaItem.name;

    if (!metaNamesCounter.has(metaItem.name)) {
      metaNamesCounter.set(metaItem.name, 0);
    }

    metaNamesCounter.set(
      metaItem.name,
      metaNamesCounter.get(metaItem.name) + 1
    );

    return {
      ...metaItem,
      name: normalizedName,
      displayName: metaItem.name,
    };
  });
}

function getNormalizedData(response: any, querySettings: any) {
  return response.data
    ? response.data.map((item: any) => {
        const row: any = {};
        response.meta.forEach((meta: any, index: number) => {
          if (querySettings.output_format === QUERY_OUTPUT.JSON) {
            row[meta.name] = item[meta.name];
          } else if (
            querySettings.output_format === QUERY_OUTPUT.JSON_COMPACT ||
            querySettings.output_format === QUERY_OUTPUT.JSON_COMPACT_LIMITED ||
            !querySettings.output_format
          ) {
            row[meta.name] = item[index];
          }
        });

        return row;
      })
    : undefined;
}

function getNormalizedStatistics(statistics: any) {
  const keys = Object.keys(statistics);
  const normalizedStatistics: any = {};

  keys.forEach(key => {
    if (BigNumber.isBigNumber(statistics[key])) {
      normalizedStatistics[key] = statistics[key].toNumber();
      return;
    }
    normalizedStatistics[key] = Number(statistics[key]);
  });
  return normalizedStatistics;
}

/**
 * Normalize the response of a query execution
 *
 * @param response
 * @param querySettings
 *
 * @return {QueryResponse} in case of a sync query execution
 */
export function normalizeResponse({
  response,
  querySettings,
  responseStatusCode,
}: {
  response: any;
  querySettings: any;
  responseStatusCode: number;
}): QueryResponse {
  // eslint-disable-next-line no-param-reassign -- legacy code
  response = {
    ...response,
    responseStatusCode,
  };

  // normalize response meta
  if (response.meta) {
    // eslint-disable-next-line no-param-reassign -- legacy code
    response = {
      ...response,
      meta: getNormalizedMeta(response.meta),
    };
  }

  // normalize response data
  if (response.data) {
    // eslint-disable-next-line no-param-reassign -- legacy code
    response = {
      ...response,
      data: getNormalizedData(response, querySettings),
    };
  }

  if (response.statistics) {
    // eslint-disable-next-line no-param-reassign -- legacy code
    response = {
      ...response,
      statistics: getNormalizedStatistics(response.statistics),
    };
  }

  return response;
}
