import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export const Cost = (props: { cost: number }) => {
  const { cost } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.cost}>
      <div>{t("workspace.create_engine.consumption")}</div>
      <div className={styles.cost__value}>
        {cost}
        <span className={styles.cost__unit}> FBU</span>
      </div>
    </div>
  );
};
