import { ParentSize } from "@visx/responsive";
import { max } from "d3-array";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Formatter } from "utils/helpers/Format";

import { MeteringRecord } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/EngineMonitoring";
import {
  QUERY_METRICS,
  QUERY_METRICS_COLORS,
  TimeGrouping,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/constants";
import {
  AggregationFunctions,
  createSeries,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/helpers/createSeries";
import { getMaxBytes } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/helpers/getMaxBytes";
import { getTimeWindowDateFrom } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/helpers/getTimeWindowDateFrom";
import {
  getTooltipTimeFormater,
  getTooltipTimeLabel,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/helpers/getTooltipTimeFormater";
import { QueryMetrics } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/types";

import Chart, {
  ChartType,
  MainValueAxisPosition,
} from "components/reports/charts/Chart/Chart";

interface QueriesChartProps {
  result: MeteringRecord[];
  timeWindow: string | null;
  timeGrouping: TimeGrouping;
  clusters: string[];
}

const dataKeyValueFormatter = (
  key: QueryMetrics,
  value: number
): {
  value: string;
  unit: string;
  unitPosition: "before" | "after";
} => {
  if (key === QueryMetrics.SpilledBytes) {
    const formatted = Formatter.bytesFormatter(value, 0);
    return {
      value: formatted.size.toString(),
      unit: ` ${formatted.notation}`,
      unitPosition: "after",
    };
  }

  return {
    value: `${value}`,
    unit: "",
    unitPosition: "before",
  };
};

const getSum = (values: number[]) => values.reduce((acc, val) => acc + val, 0);

const AGGREGATION_FUNCTIONS: AggregationFunctions<QueryMetrics> = {
  [QueryMetrics.RunningQueries]: getSum,
  [QueryMetrics.SuspendedQueries]: getSum,
  [QueryMetrics.SpilledBytes]: getSum,
} as unknown as AggregationFunctions<QueryMetrics>;

const SUB_SERIES_METRIC = QueryMetrics.SpilledBytes;

const QueriesChart = (props: QueriesChartProps) => {
  const { result, timeWindow, timeGrouping, clusters } = props;
  const { t } = useTranslation();

  const [selectedDataKeys, setSelectedDataKeys] = React.useState<
    QueryMetrics[]
  >(Object.values(QueryMetrics));

  const keyNames = useMemo(() => {
    return Object.keys(QueryMetrics).reduce(
      (acc, key) => {
        acc[key as QueryMetrics] = t(QUERY_METRICS[key as QueryMetrics]);

        return acc;
      },
      {} as Record<QueryMetrics, string>
    );
  }, [t]);

  const handleToggleDataKey = (dataKey: QueryMetrics) => {
    setSelectedDataKeys(prev => {
      if (prev.includes(dataKey)) {
        const res = prev.filter(m => m !== dataKey);
        if (res.length === 0) {
          // At least one metric should be selected
          return prev;
        }

        return res;
      }

      return [...prev, dataKey];
    });
  };

  const series = createSeries(
    result,
    selectedDataKeys.filter(m => m !== SUB_SERIES_METRIC),
    AGGREGATION_FUNCTIONS,
    clusters
  );

  const subSeries = createSeries(
    result,
    selectedDataKeys.filter(m => m === SUB_SERIES_METRIC),
    AGGREGATION_FUNCTIONS,
    clusters
  );

  const allSeries = [...series, ...subSeries];

  const lastDate = allSeries.reduce(
    (acc, s) => {
      if (s.data.length) {
        const last = s.data[s.data.length - 1].date;
        if (!acc || last > acc) {
          return last;
        }
      }

      return acc;
    },
    null as Date | null
  ) as Date;

  const dateRange: [Date, Date] = [
    getTimeWindowDateFrom(timeWindow, lastDate) as Date,
    lastDate as Date,
  ];

  const valuesRange: [number, number] = [
    0,
    max(series, s => max(s.data, d => d.value)) || 1,
  ];

  const subSeriesValuesRange: [number, number] = [
    0,
    getMaxBytes(max(subSeries, s => max(s.data, d => d.value)) || 1),
  ];

  return (
    <ParentSize>
      {parent => {
        return (
          <Chart<QueryMetrics>
            chartType={ChartType.Line}
            dateRange={dateRange}
            valuesRange={valuesRange}
            series={series}
            subSeries={subSeries}
            subSeriesValuesRange={subSeriesValuesRange}
            width={parent.width}
            height={parent.height}
            selectedDataKeys={selectedDataKeys}
            toggleDataKey={handleToggleDataKey}
            dataKeys={QueryMetrics}
            dataKeyNames={keyNames}
            dataKeyColors={QUERY_METRICS_COLORS}
            tooltipTimeFormater={getTooltipTimeFormater(timeGrouping)}
            tooltipTimeLabel={getTooltipTimeLabel(timeGrouping, t)}
            dataKeyValueFormatter={dataKeyValueFormatter}
            mainValueAxisPosition={MainValueAxisPosition.Left}
          />
        );
      }}
    </ParentSize>
  );
};

export default QueriesChart;
