import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ReactQueryKeysAccount } from "services/queryKeys";
import { deleteUser } from "services/users/deleteUser";

import {
  ConfirmationModal,
  ObjectType,
} from "components/ConfirmationModal/ConfirmationModal";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const [deleteUserName, setDeleteUserName] = useState<string | null>(null);
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();

  const handleClose = () => {
    setDeleteUserName(null);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: deleteUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });
      handleClose();
    },
    onError: async (error: Error) => {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });
      handleClose();
    },
  });

  const handleConfirm = () => {
    if (isLoading) {
      return;
    }
    if (deleteUserName) {
      mutate(deleteUserName);
    }
  };

  const deleteUserMarkup = deleteUserName ? (
    <ConfirmationModal
      title={t("workspace.delete_user.title")}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isLoading={isLoading}
      confirmButtonOptions={{ text: t("workspace.delete_user.confirm") }}
      message={
        <Trans
          i18nKey="workspace.delete_user.message"
          components={{ br: <br /> }}
        />
      }
      objectName={deleteUserName}
      objectType={ObjectType.User}
    />
  ) : null;

  const openDeleteUser = (name: string) => {
    setDeleteUserName(name);
  };

  return {
    deleteUserMarkup,
    openDeleteUser,
  };
};
