import { useCallback } from "react";

import { Row } from "../types";
import { sortRows } from "../utils/sortRows";

export const useColumnSort = ({
  columns,
  setRows,
  setColumns,
  originalRows,
}: {
  columns: any[];
  setRows: any;
  setColumns: any;
  originalRows: React.MutableRefObject<Row[]>;
}) => {
  const handleSort = useCallback(
    (column: any, destinationSortConfig: any) => {
      const { name } = column;
      const newColumns = columns.map((column: any) => {
        const { ...rest } = column;
        if (column.name === name) {
          return {
            ...rest,
            sortConfig: destinationSortConfig,
          };
        }
        return {
          ...rest,
          sortConfig: undefined,
        };
      });

      setRows((rows: { [x: string]: string }[]) => {
        const newRows = destinationSortConfig
          ? (sortRows({
              rows,
              destinationSortConfig,
              column,
              originalRows,
            }) as Row[])
          : (rows as Row[]);
        return newRows;
      });
      setColumns(newColumns);
    },
    [columns, setColumns, setRows, originalRows]
  );

  return {
    handleSort,
  };
};
