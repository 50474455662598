import { useSuspenseQuery } from "@tanstack/react-query";

import { authService } from "services/auth";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { getUserByLogin } from "./getUserByLogin";
import { User } from "./user.types";

export const useCurrentUser = () => {
  const { data } = useSuspenseQuery<Partial<User>, Error>({
    queryKey: [ReactQueryKeysAccount.currentUser],
    queryFn: async () => {
      const auth0user = await authService.auth0client.getUser();
      const loginName = auth0user?.email;
      return getUserByLogin(loginName as string);
    },
  });

  return data;
};
