import { useContext } from "react";

import { DocumentsContext } from "pages/DevelopWorkspace/contexts/DocumentsContext/DocumentsContext";

const useDocuments = () => {
  const context = useContext(DocumentsContext);

  if (!context) {
    throw new Error(
      "useDocumentsContext must be used within a DocumentsContextProvider"
    );
  }

  return context;
};

export { useDocuments };
