import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ReactQueryKeysAccount,
  ReactQueryKeysGlobal,
} from "services/queryKeys";
import { updateUser } from "services/users/updateUser";
import { User } from "services/users/user.types";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { queryClient } from "components/queryClient";

import { Fields, UserModal } from "../UserModal/UserModal";

export const useEditUser = () => {
  const [user, setEditUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getAccount } = useCurrentAccount();
  const account = getAccount();

  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const openEditUser = (user: User) => {
    setEditUser(user);
  };

  const closeEditUser = () => {
    if (isLoading) {
      return;
    }
    setEditUser(null);
  };

  const getLoginName = (userInput: Fields, user: User) => {
    if (
      userInput.assignType === "login" &&
      userInput.loginName !== user.loginName
    ) {
      return userInput.loginName;
    }
    if (userInput.assignType === "unassigned") {
      return null;
    }
    return undefined;
  };

  const getServiceAccountName = (userInput: Fields, user: User) => {
    if (
      userInput.assignType === "serviceAccount" &&
      userInput.serviceAccountName !== user.serviceAccountName
    ) {
      return userInput.serviceAccountName;
    }
    if (userInput.assignType === "unassigned") {
      return null;
    }
    return undefined;
  };

  const confirmEdit = async (userInput: Fields) => {
    try {
      setIsLoading(true);
      if (!user) {
        return;
      }

      await updateUser(account?.id as string, user.userName, {
        defaultDatabase:
          userInput.defaultDatabase !== user.defaultDatabase
            ? userInput.defaultDatabase
            : undefined,
        defaultEngine:
          userInput.defaultEngine !== user.defaultEngine
            ? userInput.defaultEngine
            : undefined,
        userName:
          userInput.userName !== user.userName ? userInput.userName : undefined,
        loginName: getLoginName(userInput, user),
        serviceAccountName: getServiceAccountName(userInput, user),
        roles: userInput.roles,
        initialRoles: user.roles,
      });

      // triggers refetching of login's accounts for the case when changes affect the login
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.myAccounts],
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });
      closeEditUser();
      putStatusMessage({
        message: t("workspace.edit_user.success"),
        type: StatusMessageType.Success,
      });
    } catch (error: any) {
      // triggers refetching of login's accounts for the case when changes affect the login
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.myAccounts],
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });

      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const editUserMarkup = user ? (
    <UserModal
      onClose={closeEditUser}
      onSubmit={confirmEdit}
      isLoading={isLoading}
      user={user}
      title={t("workspace.edit_user.title")}
      primaryButton="workspace.edit_user.submit"
    />
  ) : null;

  return {
    editUserMarkup,
    openEditUser,
  };
};
