import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";

import CopyIcon from "assets/icons/CopyIcon.svg?react";

import { getHighlightedHTML } from "pages/DevelopWorkspace/Editor/TabsBar/components/HintTooltip/HintTooltip";

import styles from "./StatementContentPreview.module.scss";

interface StatementContentPreviewProps {
  content: string;
}

const StatementContentPreview = (props: StatementContentPreviewProps) => {
  const { content } = props;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return (
    <div className={styles.statementContentPreview}>
      <div
        className={styles.copyIcon}
        onClick={() => {
          copy(content);
          setCopied(true);
        }}
      >
        <CopyIcon />
      </div>

      <div
        className={styles.code}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: getHighlightedHTML(content),
        }}
      />

      {copied && (
        <div
          className={styles.copied}
          onClick={() => {
            setCopied(false);
          }}
        >
          <div className={styles.copiedMessage}>Copied to clipboard</div>
        </div>
      )}
    </div>
  );
};

export default StatementContentPreview;
