import importAggIndexScript from "../templates/agg_index";
import importDimensionTableScript from "../templates/dimension_table";
import importFactTableScript from "../templates/fact_table";
import importDataScript from "../templates/import_data";
import importQueryHistoryScript from "../templates/query_history";

export enum SCRIPT_ACTIONS {
  IMPORT = "IMPORT",
  NEW_FACT_TABLE = "NEW_FACT_TABLE",
  NEW_DIMENSION_TABLE = "NEW_DIMENSION_TABLE",
  CREATE_AGG_INDEX = "CREATE_AGG_INDEX",
  QUERY_HISTORY = "QUERY_HISTORY",
  IMPORT_FILE = "IMPORT_FILE",
}

export const QUERY_TEMPLATES = {
  [SCRIPT_ACTIONS.CREATE_AGG_INDEX]: importAggIndexScript,
  [SCRIPT_ACTIONS.IMPORT]: importDataScript,
  [SCRIPT_ACTIONS.NEW_DIMENSION_TABLE]: importDimensionTableScript,
  [SCRIPT_ACTIONS.NEW_FACT_TABLE]: importFactTableScript,
  [SCRIPT_ACTIONS.QUERY_HISTORY]: importQueryHistoryScript,
};
