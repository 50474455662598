import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";
import { getUsers } from "services/users/getUsers";

import { useCurrentAccount } from "components/Account/useCurrentAccount";

import { User } from "./user.types";

export const useUsers = (): User[] => {
  const { getAccount } = useCurrentAccount();
  const currentAccount = getAccount();
  const { data } = useSuspenseQuery<User[], Error>({
    queryKey: [ReactQueryKeysAccount.users],
    queryFn: async () => {
      if (!currentAccount) {
        return [];
      }
      return await getUsers(currentAccount.id);
    },
  });

  return data || [];
};
