import React from "react";

import styles from "./ContextMenu.module.scss";

interface Props {
  text: string;
}

const ContextMenuHeader = (props: Props) => {
  const { text } = props;

  return <div className={styles.header}>{text}</div>;
};

export default ContextMenuHeader;
