import {
  QueryStatement,
  QueryStatementStatus,
} from "pages/DevelopWorkspace/workspace.types";

const isQueryStatementChangesValid = (
  prevQueryStatement: QueryStatement,
  nextQueryStatement: Partial<QueryStatement>
) => {
  const prevStatus = prevQueryStatement.status;
  const nextStatus = nextQueryStatement.status;

  if (
    prevStatus === QueryStatementStatus.cancelled &&
    nextStatus !== QueryStatementStatus.cancelled
  ) {
    // you can't change status from cancelled to anything else
    return false;
  }

  // progress update
  if (nextQueryStatement.statistics && !nextQueryStatement.status) {
    // updates have statistics, but no status

    if (prevStatus === QueryStatementStatus.running) {
      // allow update statistics only for running statements
      return true;
    }

    return false;
  }

  return true;
};

export { isQueryStatementChangesValid };
