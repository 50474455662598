import { QueryClient } from "@tanstack/react-query";

import { ReactQueryKeysGlobal } from "services/queryKeys";

export const updateAccounts = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  await Promise.all([
    queryClient.cancelQueries({ queryKey: [ReactQueryKeysGlobal.myAccounts] }),
    queryClient.cancelQueries({ queryKey: [ReactQueryKeysGlobal.accounts] }),
  ]);
  await Promise.all([
    queryClient.invalidateQueries({
      queryKey: [ReactQueryKeysGlobal.myAccounts],
    }),
    queryClient.invalidateQueries({
      queryKey: [ReactQueryKeysGlobal.accounts],
    }),
  ]);
};
