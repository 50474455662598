import orderBy from "lodash/orderBy";

import { File } from "services/fileObjects/getFilesByPath";

import {
  CREATE_NEW_DATABASE,
  CREATE_NEW_TABLE,
  SelectDestinationStep,
  SupportedTypes,
} from "../types";

export const trimEnd = (s: string, ch: string): string =>
  s[s.length - 1] === ch ? trimEnd(s.substring(0, s.length - 1), ch) : s;

export const getLastPathPart = (path: string) => {
  const part = trimEnd(path, "/");
  const lastIndex = part.lastIndexOf("/");
  return part.substring(lastIndex + 1);
};

const extensionMap = {
  gz: "gzip",
  gzip: "gzip",
  snp: "snappy",
  snappy: "snappy",
};

export const getExtensionFromFileName = (name: string): string => {
  const [, ...extensions] = name.split(".");
  return extensions.join(".");
};

export const getCompressionFromFileName = (name: string): string => {
  const [, ...extension] = name.split(".");
  const compression =
    extensionMap[extension[0] as keyof typeof extensionMap] ||
    extensionMap[extension[1] as keyof typeof extensionMap] ||
    "";
  return compression;
};

export const getFileTypeFromExtension = (extension: string): SupportedTypes => {
  if (extension.includes("parquet")) {
    return "parquet";
  }
  if (extension.includes("tsv")) {
    return "tsv";
  }
  return "csv";
};

const getParentFolder = (path: string) => {
  const part = trimEnd(path, "/");
  const lastIndex = part.lastIndexOf("/");
  return part.substring(0, lastIndex);
};

export const mapObjects = ({
  objects,
  openedFolder,
  rootFolder,
  bucketProtocol,
  bucketName,
}: {
  objects: File[];
  openedFolder: string;
  rootFolder: string;
  bucketProtocol: string;
  bucketName: string;
}) => {
  const withNames = objects.map(object => {
    const name = getLastPathPart(object.objectName);
    const url = `${bucketProtocol}://${trimEnd(bucketName, "/")}/${
      object.objectName
    }`;

    const extension = getExtensionFromFileName(name);
    return {
      ...object,
      name,
      extension,
      url,
    };
  });

  const sortedObjects = orderBy(
    withNames,
    ["objectType", "name"],
    ["desc", "asc"]
  );

  if (openedFolder !== rootFolder) {
    const parentFolder = getParentFolder(openedFolder);
    return [
      {
        name: "../",
        objectName: parentFolder || "/",
        type: "parent",
      },
      ...sortedObjects,
    ];
  }
  return sortedObjects;
};

export const normalizeDestinationData = (
  data: SelectDestinationStep | undefined
): {
  type: string | null;
  databaseName: string;
  tableName: string;
} => {
  if (!data) {
    return {
      type: "",
      databaseName: "",
      tableName: "",
    };
  }

  if (data.type === CREATE_NEW_DATABASE) {
    return {
      type: data.type,
      databaseName: data?.createPayload?.databaseName ?? "",
      tableName: data?.createPayload?.tableName ?? "",
    };
  }

  return {
    type: data.type,
    databaseName: data?.database ?? "",
    tableName:
      data.type === CREATE_NEW_TABLE
        ? (data?.createPayload?.tableName ?? "")
        : (data.table ?? ""),
  };
};

export const generateBreadcrumbs = (
  rootFolderPath: string,
  openedFolderPath: string
): string[] => {
  if (rootFolderPath === openedFolderPath) {
    return [];
  }

  const breadcrumbs: string[] = [rootFolderPath];

  const restOfPath = openedFolderPath
    .replace(`${rootFolderPath}/`, "")
    .split("/");

  breadcrumbs.push(...restOfPath);

  return breadcrumbs.filter(b => !!b);
};
