import React, { useContext } from "react";

import { SqlRbacAction } from "services/rbac/action";

export const ReadOnlyContext = React.createContext<{
  readOnly: boolean;
  affectedOnly: boolean;
  isActionDisabled: (type: string, action: SqlRbacAction) => boolean;
}>({
  readOnly: false,
  affectedOnly: false,
  isActionDisabled: () => false,
});

export const useReadOnly = () => {
  const context = useContext(ReadOnlyContext);
  return context;
};
