/* eslint-disable */
import _uniqueId from "lodash/uniqueId";

import { Subject, SubscriptionToken } from "services/subject";
import FormatWebWorker from "workers/formatSql/format-sql.worker?worker";
import { ACTIONS } from "workers/workerActions";

type RejectFormat = {
  aborted: boolean;
  internal: boolean;
};

export default class FormatWorker {
  private workerSubject = new Subject();

  handleMessage(event: { data: { type: string } }) {
    const { type = null } = event.data;
    if (type === ACTIONS.FORMAT_FINISHED || type === "error") {
      this.workerSubject.notifyAsync(event?.data);
    }
  }

  format(
    query: string,
    config: any,
    signal?: AbortSignal
  ): Promise<string | RejectFormat> {
    if (!query) {
      return Promise.resolve(query);
    }

    const taskId = _uniqueId();
    const worker = new FormatWebWorker();

    let token: SubscriptionToken | null = null;
    let abortListener: () => void = () => {};
    worker.onmessage = this.handleMessage.bind(this);

    return new Promise((resolve, reject) => {
      if (signal) {
        if (!signal.aborted) {
          abortListener = () => {
            reject({ aborted: true });
          };

          signal?.addEventListener("abort", abortListener);
        }
      }
      token = this.workerSubject.subscribe(payload => {
        if (payload.taskId !== taskId) {
          return;
        }

        if (payload.type === "error") {
          reject({ aborted: true, internal: true });
        } else {
          resolve(payload.result);
        }
      });

      setTimeout(() => {
        worker.postMessage({
          type: ACTIONS.FORMAT_START,
          input: query,
          config,
          taskId,
        });
      });
    }).finally(() => {
      // console.warn(`abort worker task id ${taskId}`);
      signal?.removeEventListener("abort", abortListener);
      token && token.unsubscribe();
      worker.terminate();
    }) as Promise<string>;
  }
}
