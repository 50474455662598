import { zodResolver } from "@hookform/resolvers/zod";
import { Suspense } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FIREBOLT_DOCS_URL } from "utils/constants";
import z from "zod";

import { User } from "services/users/user.types";

import { InputDisabledContext } from "components/InputState/InputDisabledContext";
import LoadingOverlap from "components/LoadingOverlap";
import { Modal } from "components/Modal/Modal";
import { TextInput } from "components/TextInput/TextInput";

import { AssignType, assignTypes } from "./AssignType";
import { DefaultDatabase } from "./DefaultDatabase";
import { DefaultEngine } from "./DefaultEngine";
import { Role } from "./Role";
import FormRow from "./components/FormRow";

import formRowStyles from "./components/FormRow/FormRow.module.scss";
import styles from "./styles.module.scss";

const schema = z.object({
  userName: z
    .string()
    // .min(3, { message: "Must be at least 3 characters long" })
    .max(64, { message: "Must be 64 or fewer characters long" })
    .regex(/^[a-zA-Z0-9!@%*\-_.]*$/, {
      message: "Use letters, numbers or ! @ % * - _ .",
    }),
  defaultDatabase: z.string().optional(),
  defaultEngine: z.string().optional(),
  assignType: z.string(),
  roles: z.array(z.string()),
});

const unassignedSchema = z.object({
  assignType: z.literal("unassigned"),
});

const loginSchema = z.object({
  assignType: z.literal("login"),
  loginName: z.string(),
});

const serviceAccountSchema = z.object({
  assignType: z.literal("serviceAccount"),
  serviceAccountName: z.string(),
});

const schemaConditions = z.discriminatedUnion("assignType", [
  unassignedSchema,
  loginSchema,
  serviceAccountSchema,
]);

const validationSchema = z.intersection(schemaConditions, schema);

export type Fields = z.infer<typeof validationSchema>;

type Props = {
  onClose: () => void;
  onSubmit: (user: Fields) => void;
  isLoading: boolean;
  associationExists?: boolean;
  user?: User;
  title: string;
  primaryButton: string;
};

export const UserModal = (props: Props) => {
  const { onClose, isLoading, onSubmit, user, title, primaryButton } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
  } = useForm<Fields>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      /* eslint-disable no-nested-ternary */
      assignType: user?.loginName
        ? "login"
        : user?.serviceAccountName
        ? "serviceAccount"
        : "unassigned",
      loginName: user?.loginName ?? "",
      serviceAccountName: user?.serviceAccountName ?? "",
      roles: user?.roles ?? [],
      defaultEngine: user?.defaultEngine ?? "",
      defaultDatabase: user?.defaultDatabase ?? "",
      userName: user?.userName ?? "",
    },
  });

  const { t } = useTranslation();

  const onFormSubmit = (data: Fields) => {
    onSubmit(data);
  };

  const { component: AssignComponent } =
    assignTypes[watch("assignType") as keyof typeof assignTypes];

  return (
    <InputDisabledContext.Provider value={isLoading}>
      <Modal
        title={title}
        subtitle={
          <>
            {t("workspace.create_user.subtitle")}{" "}
            <a
              className={styles.link}
              href={FIREBOLT_DOCS_URL}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={-1}
            >
              {t("workspace.create_user.subtitle_link")}
            </a>
          </>
        }
        onSubmit={handleSubmit(onFormSubmit)}
        primaryButton={primaryButton}
        onClose={onClose}
        isLoading={isLoading}
        disabledSubmit={!isDirty || isLoading}
      >
        <div className={styles.wrapper}>
          <Suspense fallback={<LoadingOverlap isLoading />}>
            <FormRow
              label={t("workspace.create_user.name")}
              input={
                <TextInput
                  testId="user-name-input"
                  inputRootClassName={formRowStyles.nameInput}
                  className={formRowStyles.nameInputContainer}
                  {...register("userName")}
                  error={!!errors?.userName}
                  helperText={errors?.userName?.message}
                  required
                />
              }
            />

            <AssignType control={control} />
            <AssignComponent control={control} />
            <Role
              control={control}
              initialRoles={user?.roles}
            />
            <DefaultDatabase
              control={control}
              initialSelected={
                user?.defaultDatabase ? [user?.defaultDatabase] : undefined
              }
            />
            <DefaultEngine
              control={control}
              initialSelected={
                user?.defaultEngine ? [user?.defaultEngine] : undefined
              }
            />
          </Suspense>
        </div>
      </Modal>
    </InputDisabledContext.Provider>
  );
};
