import { useContext, useEffect, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";

import { ExplainType } from "pages/DevelopWorkspace/workspace.types";

import { AutoSizerRenderProps } from "../types";
import { DiagramActionContext, DiagramProvider } from "./DiagramContext";
import { ExplainSummary } from "./ExplainSummary/ExplainSummary";
import { Controls } from "./Sidebar/Controls";
import { Sidebar } from "./Sidebar/Sidebar";
import { Subqueries } from "./Subqueries/Subqueries";
import { FIT_MINIMAP } from "./actions";
import { Explain, Operator } from "./types";
import { useInteractions } from "./useInteractions";

import styles from "./styles.module.scss";

interface RefresherProps {
  width: number;
  height: number;
}

const Refresher = (props: RefresherProps) => {
  const { width, height } = props;
  const dispatch = useContext(DiagramActionContext);
  useEffect(() => {
    dispatch({
      type: FIT_MINIMAP,
    });
  }, [width, height, dispatch]);
  return null;
};

type QueryExplainProps = {
  explain: Explain;
  explainType: ExplainType;
  selectedNode: Operator | null;
  handleSelectNode: (node: Operator) => void;
  onExplainChange: (type: ExplainType) => void;
  isProfile?: boolean;
};

export const QueryExplain = (props: QueryExplainProps) => {
  const {
    explain,
    onExplainChange,
    explainType,
    selectedNode,
    handleSelectNode,
    isProfile,
  } = props;
  const container = useRef<HTMLDivElement | null>(null);
  const draggable = useRef<HTMLDivElement | null>(null);
  const sidebar = useRef<HTMLDivElement | null>(null);

  const { state, dispatch, handlePointerDown } = useInteractions({
    container,
    draggable,
    sidebar,
  });

  const { transform, cursor, initialized } = state;

  const style = {
    transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.k})`,
  };

  if (!initialized || !state.fit) {
    Object.assign(style, { visibility: "hidden" });
  }

  return (
    <DiagramProvider
      state={state}
      dispatch={dispatch}
    >
      <div
        className={styles.diagram}
        ref={container}
        data-testid="explain-diagram"
        data-mode={state.mode}
      >
        <Controls
          onExplainChange={onExplainChange}
          explainType={explainType}
          isProfile={isProfile}
        />
        <Sidebar
          explain={explain}
          ref={sidebar}
        />
        <div
          className={styles.diagram__inner}
          onPointerDown={handlePointerDown}
          style={{ cursor }}
        >
          <div
            style={style}
            className={styles.draggable}
            ref={draggable}
          >
            <Subqueries
              explain={explain}
              onSelectNode={handleSelectNode}
              selectedNode={selectedNode}
            />
          </div>
        </div>
        <ExplainSummary
          explain={explain}
          selectedNode={selectedNode}
        />
        <AutoSizer>
          {({ width, height }: AutoSizerRenderProps) => {
            return (
              <Refresher
                width={width as number}
                height={height as number}
              />
            );
          }}
        </AutoSizer>
      </div>
    </DiagramProvider>
  );
};
