import { useQuery } from "@tanstack/react-query";

import { ReactQueryKeysGlobal } from "../queryKeys";
import { getMyTenants } from "./getMyTenants";
import { MyTenant } from "./types";

export const useMyTenants = () => {
  const { data } = useQuery<MyTenant[], Error>({
    queryKey: [ReactQueryKeysGlobal.myTenants],
    queryFn: () => getMyTenants(),
    retryOnMount: false,
  });
  return data || [];
};
