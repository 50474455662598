import classNames from "classnames";
import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { AuthType } from "services/fileObjects/getFilesByPath";

import EyeOpenIcon from "assets/icons/EyeOpenIcon.svg?react";
import EyeShutIcon from "assets/icons/EyeShutIcon.svg?react";
import InfoIcon from "assets/icons/Info.svg?react";

import RadioButton from "components/RadioButton/RadioButton";
import { TextInput } from "components/TextInput/TextInput";
import CustomTooltip from "components/Tooltip";

import { Fields } from "../Steps/schema";

import styles from "./styles.module.scss";

type AuthPropsType = {
  register: UseFormReturn<Fields>["register"];
  errors: UseFormReturn<Fields>["formState"]["errors"];
  playgroundBucket: boolean;
  disabled?: boolean;
};

const ARN_DOCS_URL =
  "https://docs.aws.amazon.com/IAM/latest/UserGuide/reference-arns.html";

const ARNDocsLink = ({ children }: React.PropsWithChildren) => (
  <a
    href={ARN_DOCS_URL}
    target="_blank"
    className={styles.tooltipLink}
    rel="noreferrer"
  >
    {children}
  </a>
);

const SecretAuthType = (props: AuthPropsType) => {
  const { register, errors, playgroundBucket, disabled } = props;
  const { t } = useTranslation();
  const [showKey, setShowKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);

  const keyError = "key" in errors ? errors.key : null;
  const secretError = "secret" in errors ? errors.secret : null;

  const toggleShowKey = () => {
    setShowKey(showKey => !showKey);
  };

  const toggleShowSecret = () => {
    setShowSecret(showSecret => !showSecret);
  };

  return (
    <>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("wizard.select_aws.key")}
          testId="key-field"
          type={showKey ? "text" : "password"}
          {...register("key")}
          error={!!keyError}
          InputProps={{
            endAdornment: (
              <div
                className={classNames(styles.visibilityIcon)}
                aria-label="toggle key visibility"
                onClick={toggleShowKey}
              >
                {showKey ? <EyeShutIcon /> : <EyeOpenIcon />}
              </div>
            ),
          }}
          autoComplete="off"
          helperText={keyError?.message}
          disabled={playgroundBucket || disabled}
          required
        />
      </div>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("wizard.select_aws.secret")}
          testId="secret-field"
          type={showSecret ? "text" : "password"}
          {...register("secret")}
          error={!!secretError}
          InputProps={{
            endAdornment: (
              <div
                className={classNames(styles.visibilityIcon)}
                aria-label="toggle secret visibility"
                onClick={toggleShowSecret}
              >
                {showSecret ? <EyeShutIcon /> : <EyeOpenIcon />}
              </div>
            ),
          }}
          autoComplete="off"
          helperText={secretError?.message}
          disabled={playgroundBucket || disabled}
          required
        />
      </div>
    </>
  );
};

const IAMRoleAuthType = (props: AuthPropsType) => {
  const { register, errors, disabled } = props;
  const { t } = useTranslation();

  const iamRoleError = "iamRole" in errors ? errors.iamRole : null;

  return (
    <div className={styles.formControl}>
      <TextInput
        inputRootClassName={styles.inputRoot}
        label={
          <div className={styles.inputLabelWithTooltip}>
            <span>{t("wizard.select_aws.iam_role")}</span>
            <CustomTooltip
              placement="top-start"
              title={
                <Trans
                  i18nKey="wizard.select_aws.iam_role_tooltip"
                  components={{ a: <ARNDocsLink /> }}
                />
              }
            >
              <div className={styles.icon}>
                <InfoIcon />
              </div>
            </CustomTooltip>
          </div>
        }
        testId="aim-role-field"
        {...register("iamRole")}
        error={!!iamRoleError}
        helperText={iamRoleError?.message}
        disabled={disabled}
        required
      />
    </div>
  );
};

const authSettingsByType = {
  secret: SecretAuthType,
  iam: IAMRoleAuthType,
  none: () => null,
};

export const AuthSelector = (props: {
  form: UseFormReturn<Fields>;
  playgroundBucket: boolean;
  disabled?: boolean;
}) => {
  const { form, playgroundBucket, disabled } = props;
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = form;

  const AuthSettingsComponent =
    authSettingsByType[watch("authType") as keyof typeof authSettingsByType];

  return (
    <>
      <div className={styles.auth}>
        <div className={styles.label}>{t("wizard.select_aws.auth_label")}</div>
        <div className={styles.radioSelector}>
          <Controller
            control={control}
            name="authType"
            render={({ field: { onChange, value } }) => (
              <>
                <div
                  data-testid="auth-secret-option"
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    onChange(AuthType.SECRET);
                  }}
                  className={styles.radioSelectorItem}
                >
                  <RadioButton
                    disabled={disabled}
                    testId="auth-secret-radio"
                    selected={value === AuthType.SECRET}
                  />
                  <label className={styles.radioTitle}>
                    {t("wizard.select_aws.auth_secret")}
                  </label>
                </div>

                <div
                  data-testid="auth-iam-option"
                  onClick={() => {
                    if (disabled) {
                      return;
                    }
                    onChange(AuthType.IAM);
                  }}
                  className={styles.radioSelectorItem}
                >
                  <RadioButton
                    disabled={disabled}
                    testId="auth-iam-radio"
                    selected={value === AuthType.IAM}
                  />
                  <label className={styles.radioTitle}>
                    {t("wizard.select_aws.auth_iam")}
                  </label>
                </div>
              </>
            )}
          />
        </div>
      </div>

      <AuthSettingsComponent
        register={register}
        errors={errors}
        disabled={disabled}
        playgroundBucket={playgroundBucket}
      />
    </>
  );
};
