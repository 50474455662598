const template = `CREATE AGGREGATING INDEX IF NOT EXISTS <unique_agg_index_name> ON <fact_table_name>
(
    -- Add as many columns as required for the index
    -- Choose the columns that will be used in your group by statements
    <column_name1>
    <column_name2>
    -- Add aggregations
    -- Choose the aggregations which will be performed when running your queries
    sum(column_to_sum)
    count(distinct column_to_count) 
    -- add more aggregations, as required
);
`;

export default template;
