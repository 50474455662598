import CompassArrow from "assets/icons/CompassArrow.svg?react";
import CompassBase from "assets/icons/CompassBase.svg?react";

import styles from "./styles.module.scss";

const Compass = () => {
  return (
    <div className={styles.compass}>
      <CompassBase className={styles.base} />
      <CompassArrow className={styles.arrow} />
    </div>
  );
};

export default Compass;
