import { SUPPORT_EMAIL } from "utils/constants";

import { useOrganizationApproved } from "services/organization/useOrganizationApproved";

import InspectingIllustration from "assets/images/InspectingIllustration.svg?react";

import { ErrorScreen, ErrorScreenImagePosition } from "components/ErrorScreen";
import { MainSidebarWithoutAccounts } from "components/MainSidebar/MainSidebarWithoutAccounts";

import styles from "../App/styles.module.scss";

export const GatedOrganization = (props: React.PropsWithChildren) => {
  const { children } = props;
  const approved = useOrganizationApproved();
  const { is_approved } = approved || {};
  if (is_approved) {
    return <>{children}</>;
  }

  return (
    <div className={styles.app}>
      <MainSidebarWithoutAccounts />
      <div className={styles.content}>
        <ErrorScreen
          title="Thanks for registering with us"
          description="We need just a little more time to review your registration details and make sure everything is good to go. We will be in touch soon!"
          image={<InspectingIllustration />}
          imagePosition={ErrorScreenImagePosition.Top}
          copyright={
            <div>
              In the meantime you can always reach out to us at&nbsp;
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                className={styles.link}
              >
                {SUPPORT_EMAIL}
              </a>
            </div>
          }
        />
      </div>
    </div>
  );
};
