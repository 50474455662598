import { ClickAwayListener } from "@mui/base";
import { List } from "@mui/material";
import classNames from "classnames";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { clickOnSpaceOrEnterPress } from "utils/keyboardUtils";

import { MyAccount } from "services/account/account.types";
import { useAccounts } from "services/account/useAccounts";
import { authService } from "services/auth";
import { useUser } from "services/auth/useUser";
import { Login } from "services/login/login.types";
import { useLogin } from "services/login/useLogin";

import CheckIcon from "assets/icons/CheckIcon.svg?react";
import LogoutIcon from "assets/icons/Logout.svg?react";
import RedoIcon from "assets/icons/selectMenuIcons/RedoIcon.svg?react";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import ActionMenu from "components/ActionMenu/ActionMenu";
import ActionMenuItem from "components/ActionMenu/ActionMenuItem/ActionMenuItem";
import { useMenu } from "components/ActionMenu/useMenu";
import Spinner from "components/Spinner";
import Tooltip from "components/Tooltip";

import { AQA_AVATAR_NAME, AQA_LOGOUT_LINK } from "../AqaIds";
import { useResetPassword } from "./useResetPassword";

import sidebarStyles from "../MainSidebar.module.scss";
import styles from "../sidebarItemWithDropdown.module.scss";

const getAvatarAbbr = (name: string) => {
  return (name || "")
    .split(" ")
    .filter(Boolean)
    .map(c => c[0])
    .slice(0, 2)
    .join("");
};

type Props = {
  accounts: MyAccount[];
  currentAccount: MyAccount | undefined;
  login: Login | undefined;
};

const UserBar = (props: Props) => {
  const { accounts, currentAccount, login } = props;
  const user = useUser();
  const email = user?.email ?? "";
  const name =
    login?.firstName && login?.lastName
      ? `${login.firstName} ${login.lastName}`
      : email;

  const { menuElement, openMenu, closeMenu } = useMenu();
  const { handleResetPassword, isLoading: resetPasswordLoading } =
    useResetPassword({
      closeMenu,
      loginName: login ? login.loginName : "",
    });

  const handleLogout = useCallback(async () => {
    await authService.redirectToLogout();
    closeMenu();
  }, [closeMenu]);

  const handleSelectAccount = () => {
    closeMenu();
  };

  const header = accounts.length
    ? [
        <div
          className={styles.header}
          key="name"
        >
          <div
            className={styles.fullName}
            title=""
          >
            {name}
          </div>
          <div className={styles.accountName}>
            {" "}
            Accounts in{" "}
            <span className={styles.organizationName}>
              {authService.organizationName}
            </span>
          </div>
        </div>,
      ]
    : [];

  const mainItems = accounts.length
    ? [
        [
          <List
            className={styles.list}
            key="accounts"
          >
            {accounts.map(account => {
              return account.id === currentAccount?.id ? (
                <ActionMenuItem
                  key={account.id}
                  selected
                  text={account.accountName}
                  selectedIcon={<CheckIcon />}
                  classes={{ selected: styles.selectedAccount }}
                />
              ) : (
                <Link
                  to={`/${account.accountName}`}
                  key={account.id}
                  onClick={handleSelectAccount}
                >
                  <ActionMenuItem
                    text={account.accountName}
                    selectedIcon={<CheckIcon />}
                    classes={{ selected: styles.selectedAccount }}
                  />
                </Link>
              );
            })}
          </List>,
        ],
      ]
    : [];

  const secondaryItems = [
    ...(login
      ? [
          <ActionMenuItem
            text="Reset password"
            testId="reset-password"
            key="reset-password"
            onClick={event => {
              event.stopPropagation();
              handleResetPassword();
            }}
            icon={<RedoIcon />}
            endIcon={resetPasswordLoading ? <Spinner size={20} /> : undefined}
            disabled={resetPasswordLoading}
          />,
        ]
      : []),
    <ActionMenuItem
      text="Logout"
      testId={AQA_LOGOUT_LINK}
      key="log_out"
      onClick={handleLogout}
      icon={<LogoutIcon />}
    />,
  ];

  const menuItems = [header, mainItems, secondaryItems];

  return (
    <ClickAwayListener onClickAway={() => closeMenu()}>
      <div>
        <Tooltip
          title={name}
          placement="right"
          classes={{
            popper: classNames(
              sidebarStyles.menuItemTooltip,
              sidebarStyles.forced
            ),
          }}
        >
          <div
            className={styles.root}
            onClick={openMenu}
            tabIndex={0}
            onKeyUp={event => clickOnSpaceOrEnterPress(event)}
          >
            <div className={styles.container}>
              <span
                className={styles.name}
                data-testid={AQA_AVATAR_NAME}
              >
                {getAvatarAbbr(name)}
              </span>
            </div>
          </div>
        </Tooltip>
        {!!menuElement && (
          <ActionMenu
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            lists={menuItems.filter(list => list.length)}
            open={true}
            anchorEl={menuElement}
            onClose={closeMenu}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export const UserBarWithoutAccounts = () => {
  return (
    <UserBar
      accounts={[]}
      currentAccount={undefined}
      login={undefined}
    />
  );
};

export const UserBarWithAccounts = () => {
  const { getAccount } = useCurrentAccount();
  const currentAccount = getAccount();
  const accounts = useAccounts();
  const login = useLogin();

  return (
    <UserBar
      accounts={accounts}
      currentAccount={currentAccount}
      login={login}
    />
  );
};
