import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { bulkItemTitleMap } from "./constant";
import { visibleItemsCount } from "./utils";

import styles from "./styles.module.scss";

type Props = {
  expanded?: boolean;
  handleMore: (event: any) => void;
  title: string;
};

type SelectValueProps = Props & {
  items: string[];
};

type DatabaseValueProps = Props & {
  items: { name: string; catalogName?: string }[];
};

const getBulkItemTitle = (t: TFunction, value: string) => {
  return bulkItemTitleMap[value as keyof typeof bulkItemTitleMap]
    ? t(bulkItemTitleMap[value as keyof typeof bulkItemTitleMap])
    : null;
};

export const RenderSelectValue = (props: SelectValueProps) => {
  const { expanded, items, handleMore, title } = props;
  const { t } = useTranslation();

  if (!expanded && items.length > visibleItemsCount) {
    const moreCount = items.length - visibleItemsCount;
    const visibleItems = items.slice(0, visibleItemsCount);
    const value = visibleItems.join(" ");
    return (
      <div className={styles.value}>
        <div className={styles.value__items}>{value}</div>
        <div>
          <a
            data-testid="roles-wizard-show-more-items"
            onClick={handleMore}
          >
            {t("roles_wizard.privileges.more", { count: moreCount })}
          </a>
        </div>
      </div>
    );
  }
  if (expanded && items.length >= 1) {
    return title;
  }
  return items
    .map(value => {
      const bulkItemTitle = getBulkItemTitle(t, value);
      return bulkItemTitle || value;
    })
    .join(" ");
};

export const RenderDatabaseValue = (props: DatabaseValueProps) => {
  const { expanded, items, handleMore, title } = props;
  const { t } = useTranslation();
  if (!expanded && items.length > visibleItemsCount) {
    const moreCount = items.length - visibleItemsCount;
    const visibleItems = items.slice(0, 1);
    return (
      <div className={styles.value}>
        <div className={styles.value__items}>
          {visibleItems.map(item => {
            const { catalogName, name } = item;
            const bulkItemTitle = getBulkItemTitle(t, name);
            const resourceName = bulkItemTitle || name;
            if (catalogName) {
              return (
                <>
                  <span
                    key={resourceName}
                    className={styles.catalogName}
                  >
                    {catalogName}
                  </span>
                  /{resourceName}{" "}
                </>
              );
            }
            return name;
          })}
        </div>
        <div>
          <a
            data-testid="roles-wizard-show-more-items"
            onClick={handleMore}
          >
            {t("roles_wizard.privileges.more", { count: moreCount })}
          </a>
        </div>
      </div>
    );
  }
  if (expanded && items.length >= 1) {
    return title;
  }
  return items.map(item => {
    const { name, catalogName } = item;
    const bulkItemTitle = getBulkItemTitle(t, name);
    const resourceName = bulkItemTitle || name;
    if (catalogName) {
      return (
        <>
          <span className={styles.catalogName}>{catalogName}</span>/
          {resourceName}{" "}
        </>
      );
    }
    return resourceName;
  });
};
