import JSONbig from "json-bigint";

import * as fileHandler from "components/utils/file-handler";

export async function saveToFile(rows: any, type: string, delimiter: any) {
  let value;
  if (type === "CSV") {
    value = await fileHandler.stringifyResultToCSV(rows, delimiter);
  } else {
    value = JSONbig.stringify(rows, null, 2);
  }
  fileHandler.downloadResults(value, type);
}
