import { TFunction } from "i18next";

import { TimeGrouping } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/constants";

export const getTooltipTimeFormater = (timeGrouping: TimeGrouping) => {
  if (timeGrouping === TimeGrouping.Minute) {
    return Intl.DateTimeFormat(undefined, {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format;
  }

  if (timeGrouping === TimeGrouping.Hour) {
    return Intl.DateTimeFormat(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format;
  }

  if (timeGrouping === TimeGrouping.Day) {
    return Intl.DateTimeFormat(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format;
  }

  return Intl.DateTimeFormat(undefined, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format;
};

export const getTooltipTimeLabel = (
  timeGrouping: TimeGrouping,
  t: TFunction
): string => {
  if (timeGrouping === TimeGrouping.Minute) {
    return t("engine_monitoring.time");
  }

  if (timeGrouping === TimeGrouping.Hour) {
    return t("engine_monitoring.time");
  }

  if (timeGrouping === TimeGrouping.Day) {
    return t("engine_monitoring.date");
  }

  return t("engine_monitoring.time");
};
