import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Formatter } from "utils/helpers/Format";

import DurationCell from "components/Datagrid/components/DurationCell";
import StatusCell from "components/Datagrid/components/StatusCell";
import { ExplainIcon, ShowResultsIcon } from "components/Icons";
import CustomTooltip from "components/Tooltip";

import { SwitchDocumentActiveQueryStatementCallback } from "../../../../../contexts/DocumentsContext/types";
import {
  DocumentOutputTab,
  ExplainType,
  QueryStatement,
  QueryStatementStatus,
} from "../../../../../workspace.types";
import { useHover } from "../hooks/useHover";

import cellStyles from "../../components/styles/Cell.module.scss";
import styles from "./QueryStatementsTable.module.scss";

type Props = {
  queryStatement: QueryStatement;
  rowNr: number;
  documentId: string;
  switchDocumentActiveQueryStatement: SwitchDocumentActiveQueryStatementCallback;
  onShowPreview: (queryStatement: QueryStatement, ref: HTMLDivElement) => void;
};

type StatisticsCellProps = {
  value: string | number | React.ReactNode;
  cellClassNames?: string;
  extraContent?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const StatisticsCell = (props: StatisticsCellProps) => {
  const { value, cellClassNames, extraContent, onClick } = props;
  return (
    <div
      onClick={onClick}
      className={classNames(cellStyles.cell, cellClassNames, {
        [cellStyles.clickable]: !!onClick,
      })}
    >
      <div className={cellStyles.cell__value}>{value}</div>
      {extraContent}
    </div>
  );
};

type StatementCellProps = {
  hovered: boolean;
  hasResults: boolean;
  hasError: boolean;
  hasExplainAnalyze: boolean;
  content: string;
  openResults: () => void;
  openExplain: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const StatementCell = (props: StatementCellProps) => {
  const {
    content,
    hovered,
    hasResults,
    hasError,
    hasExplainAnalyze,
    openResults,
    openExplain,
    onClick,
  } = props;
  const { t } = useTranslation();

  const actions = hovered ? (
    <div className={styles.actionsContainer}>
      {hasExplainAnalyze && (
        <CustomTooltip
          title={t("statistics.show_query_profile")}
          placement="top-start"
        >
          <div
            className={styles.icon}
            onClick={openExplain}
          >
            <ExplainIcon />
          </div>
        </CustomTooltip>
      )}
      {(hasResults || hasError) && (
        <CustomTooltip
          title={t("statistics.show_results")}
          placement="top-start"
        >
          <div
            className={styles.icon}
            onClick={openResults}
            data-testid="hovered-controls-external"
          >
            <ShowResultsIcon />
          </div>
        </CustomTooltip>
      )}
    </div>
  ) : null;
  return (
    <StatisticsCell
      value={content}
      onClick={onClick}
      extraContent={actions}
      cellClassNames={styles.statement}
    />
  );
};

export const getStatisticsRowData = (queryStatement: QueryStatement) => {
  const rowCount = queryStatement.result?.rows ?? 0;
  const executionTimeSec = queryStatement.statistics?.executionTimeSec ?? 0;
  const scannedBytesStorage =
    queryStatement.statistics?.scannedBytesStorage ?? 0;
  const scannedBytesCache = queryStatement.statistics?.scannedBytesCache ?? 0;
  const bytesRead = queryStatement.statistics?.bytesRead ?? 0;
  const rowsRead = queryStatement.statistics?.rowsRead ?? 0;
  const rowsPerSec = executionTimeSec ? rowsRead / executionTimeSec : 0;

  const hasResults = !!queryStatement.result?.data;
  const hasError = !!queryStatement.error;
  const hasExplainAnalyze = queryStatement.explain?.[ExplainType.Analyze];

  return {
    rowCount,
    executionTimeSec,
    scannedBytesStorage,
    scannedBytesCache,
    bytesRead,
    rowsPerSec,
    hasResults,
    hasError,
    hasExplainAnalyze,
  };
};

const StatisticsRow = (props: Props) => {
  const {
    queryStatement,
    rowNr,
    documentId,
    switchDocumentActiveQueryStatement,
    onShowPreview,
  } = props;
  const { hovered, handleMouseOver, handleMouseLeave } = useHover();

  const {
    rowCount,
    executionTimeSec,
    bytesRead,
    rowsPerSec,
    hasResults,
    hasError,
    hasExplainAnalyze,
  } = getStatisticsRowData(queryStatement);

  const openResults = useCallback(() => {
    switchDocumentActiveQueryStatement({
      documentId,
      queryStatementIndex: rowNr - 1,
      userSelectedActiveQueryStatementIndexTimestamp: Date.now(),
      activeOutputTab: DocumentOutputTab.Results,
    });
  }, [switchDocumentActiveQueryStatement, documentId, rowNr]);

  const openExplain = useCallback(() => {
    switchDocumentActiveQueryStatement({
      documentId,
      queryStatementIndex: rowNr - 1,
      userSelectedActiveQueryStatementIndexTimestamp: Date.now(),
      activeOutputTab: DocumentOutputTab.Explain,
    });
  }, [switchDocumentActiveQueryStatement, documentId, rowNr]);

  const userFacingStatus = useMemo(() => {
    if (
      queryStatement.status === QueryStatementStatus.unknown &&
      queryStatement.prevStatus === QueryStatementStatus.running
    ) {
      // if transition from running to unknown, show running
      return QueryStatementStatus.running;
    }

    return queryStatement.status;
  }, [queryStatement.status, queryStatement.prevStatus]);

  return (
    <div
      className={styles.resultRow}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <StatisticsCell
        value={rowNr}
        cellClassNames={styles.statementNr}
      />
      <StatementCell
        content={queryStatement.content}
        hovered={hovered}
        hasResults={hasResults}
        hasError={hasError}
        hasExplainAnalyze={hasExplainAnalyze}
        openResults={openResults}
        openExplain={openExplain}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          onShowPreview(queryStatement, e.currentTarget);
          e.stopPropagation();
        }}
      />
      <StatisticsCell
        value={<StatusCell status={userFacingStatus} />}
        cellClassNames={styles.status}
      />
      <StatisticsCell
        value={<DurationCell duration={executionTimeSec} />}
        cellClassNames={styles.duration}
      />
      <StatisticsCell
        value={rowCount}
        cellClassNames={styles.rows}
      />
      <StatisticsCell
        value={Formatter.bytesFormatter(bytesRead).formattedString}
        cellClassNames={styles.scanned}
      />
      <StatisticsCell
        value={Formatter.nFormatter(rowsPerSec, 2)}
        cellClassNames={styles.rowsPerSec}
      />
    </div>
  );
};

export default StatisticsRow;
