import classNames from "classnames";
import React from "react";

import Chevron from "assets/icons/workspace/Chevron.svg?react";

import Spinner from "components/Spinner";

import { IndentLine } from "./IndentLine";
import { TreeNodeType } from "./types";
import { getTreeNodeIndent } from "./utils";

import styles from "./styles.module.scss";

type Props = {
  layer: number;
  focus?: boolean;
  loading?: boolean;
  expand?: boolean;
  active?: boolean;
  nodeData: TreeNodeType;
  hasChildren?: boolean;
  className?: string;
  style?: React.CSSProperties;

  onExpand: (nodeData: TreeNodeType) => void;
  onShiftClick?: (nodeData: TreeNodeType) => void;
  renderLabel: (node: TreeNodeType, loading?: boolean) => React.ReactNode;
};

type ExpandIconProps = {
  hasChildren?: boolean;
  expand?: boolean;
  loading?: boolean;
  hidden?: boolean;
  handleExpand: () => void;
};

const ExpandIcon = (props: ExpandIconProps) => {
  const { hasChildren, hidden, handleExpand, expand, loading } = props;
  if (!hasChildren || hidden) {
    return null;
  }
  if (loading) {
    return (
      <div className={styles.expandWrapper}>
        <div className={styles.loading}>
          <Spinner size={12} />
        </div>
      </div>
    );
  }
  return (
    <div
      role="button"
      tabIndex={-1}
      className={styles.expandWrapper}
      onClick={handleExpand}
    >
      <Chevron
        className={classNames(styles.expandIcon, {
          [styles.expanded]: expand,
        })}
      />
    </div>
  );
};

export const TreeNode = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      active,
      className,
      expand,
      focus,
      loading,
      style,
      layer,
      nodeData,
      onExpand,
      onShiftClick,
      hasChildren,
      renderLabel,
    } = props;

    const handleExpand = () => {
      if (hasChildren) {
        onExpand(nodeData);
      }
    };

    const offset = nodeData.getTreeNodeIndent
      ? nodeData.getTreeNodeIndent(layer)
      : getTreeNodeIndent(layer - 1);

    return (
      <div
        role="treeitem"
        aria-expanded={expand}
        aria-level={layer}
        aria-selected={active}
        style={style}
        className={classNames(
          {
            [styles.hasChildren]: hasChildren,
            focus,
          },
          className
        )}
        ref={ref}
      >
        <div
          className={styles.node}
          onClick={e => {
            if (e.shiftKey && onShiftClick) {
              onShiftClick(nodeData);
              return;
            }

            handleExpand();
          }}
          style={{ paddingLeft: offset }}
        >
          <IndentLine layer={layer} />
          <ExpandIcon
            hasChildren={hasChildren}
            expand={expand}
            loading={loading}
            handleExpand={handleExpand}
            hidden={nodeData.hideExpand}
          />
          {renderLabel(nodeData, loading)}
        </div>
      </div>
    );
  }
);
