import { useEffect, useRef } from "react";

interface Props {
  addSpacer: () => void;
  removeSpacer: () => void;
  gridRef: React.RefObject<any>;
  width: number;
  lastIndex: number;
  columnCount?: number;
  spacerIsVisible: boolean;
}

export const Refresher = (props: Props) => {
  const {
    addSpacer,
    removeSpacer,
    gridRef,
    width,
    lastIndex,
    spacerIsVisible,
    columnCount,
  } = props;
  const addSpacerRef = useRef(addSpacer);
  const removeSpacerRef = useRef(removeSpacer);
  const lastIndexRef = useRef(lastIndex);

  addSpacerRef.current = addSpacer;
  removeSpacerRef.current = removeSpacer;
  lastIndexRef.current = lastIndex;

  useEffect(() => {
    spacerIsVisible ? addSpacerRef.current() : removeSpacerRef.current();
    gridRef.current.resetAfterColumnIndex(lastIndexRef.current);
  }, [spacerIsVisible, gridRef]);

  useEffect(() => {
    gridRef.current.resetAfterColumnIndex(lastIndexRef.current);
  }, [width, gridRef]);

  useEffect(() => {
    gridRef.current.resetAfterColumnIndex(0);
  }, [gridRef, columnCount]);
  return null;
};
