import { FormControl } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { AutoStopTimeout } from "components/AutoStopTimeout/AutoStopTimeout";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import styles from "./styles.module.scss";

const CUSTOM_TIMEOUT = "custom";

const popoverProps = {
  onKeyPress: (event: React.KeyboardEvent) => {
    event.stopPropagation();
  },
};

type Props = {
  autoStop: string;
  handleChange: (name: string, value: number) => void;
};

const AUTO_STOP_OPTIONS = [
  { value: 5, text: "5 minutes" },
  { value: 10, text: "10 minutes" },
  { value: 20, text: "20 minutes" },
  { value: 60, text: "60 minutes" },
];

export const AutoStop = (props: Props) => {
  const { autoStop, handleChange } = props;
  const { t } = useTranslation();
  const [custom, setCustom] = useState(false);

  const onClose = useCallback(() => {
    setTimeout(() => {
      const activeElement = document.activeElement;
      (activeElement as HTMLInputElement)?.blur();
    }, 0);
    setCustom(false);
  }, []);

  const handleCustomChange = useCallback(
    (_name: string, value: number) => {
      handleChange("autoStop", value / 60);
    },
    [handleChange]
  );

  const renderCustomMenu = useCallback(
    (closeMenu: () => void) => {
      return (
        <AutoStopTimeout
          closeMenu={closeMenu}
          handleChange={handleCustomChange}
          autoStopAfter={+autoStop}
        />
      );
    },
    [handleCustomChange, autoStop]
  );

  const handleSelectChange = useCallback(
    (items: string[]) => {
      const [value] = items;
      if (value === CUSTOM_TIMEOUT) {
        setCustom(true);
        return;
      }
      handleChange("autoStop", Number(value));
    },
    [handleChange]
  );

  const selectedOption = AUTO_STOP_OPTIONS.find(
    option => option.value === +autoStop
  );

  const items = [
    ...AUTO_STOP_OPTIONS.map(item => (
      <ContextMenuItem
        key={`${item.value}`}
        value={`${item.value}`}
        testId={`autostop-value-${item.value}`}
        text={item.text}
      />
    )),
    <ContextMenuItem
      key="custom"
      value={CUSTOM_TIMEOUT}
      testId="autostop-value-custom"
      disableClose
      text="Custom"
    />,
    <ContextMenuItem
      key="Off"
      value="0"
      testId="autostop-value-off"
      text={t("workspace.create_engine.autostop_off")}
    />,
  ];

  const handleKeypress = (event: React.KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      event.stopPropagation();
    }
  };

  return (
    <FormControl
      fullWidth
      onKeyPress={handleKeypress}
    >
      <OutlinedSelect
        className={styles.select}
        initialSelected={[autoStop]}
        onSelect={handleSelectChange}
        onClose={onClose}
        popoverProps={popoverProps}
        renderMenu={custom ? renderCustomMenu : undefined}
        testId="engine-autostop-select"
        renderValue={() => {
          if (autoStop === "0") {
            return "Off";
          }
          if (selectedOption) {
            return selectedOption?.text.replace("minutes", "min");
          }
          return `${autoStop} min`;
        }}
      >
        {items}
      </OutlinedSelect>
    </FormControl>
  );
};
