const template = `CREATE FACT TABLE IF NOT EXISTS <firebolt_table_name>
(
    <column_name> <column_type> 
    -- add more columns using the above syntax  
)
/*
Primary index speeds up query processing. 
Use the list of fields you are most likely to query by
*/
PRIMARY INDEX <column_name>, <another_column_name>, ... <add_as_many_columns_as_needed>
`;

export default template;
