import { CreateUserInput, User } from "./user.types";

export const getGrantStatement = (userName: string, roleName: string) => {
  return `GRANT ROLE "${roleName}" TO USER "${userName}";\n`;
};

const getRevokeStatement = (userName: string, roleName: string) => {
  return `REVOKE ROLE "${roleName}" FROM USER "${userName}";\n`;
};

export const getGrantStatements = (userName: string, roles: string[]) => {
  return roles.map(role => {
    const statement = getGrantStatement(userName, role);
    return statement;
  });
};

const getRevokeStatements = (userName: string, roles: string[]) => {
  return roles.map(role => {
    const statement = getRevokeStatement(userName, role);
    return statement;
  });
};

const splitRoles = (initialRoles: string[] | undefined, roles: string[]) => {
  const toRevoke = [];
  const toGrant = [];

  const normalizedInitialRoles = initialRoles || [];

  const initialRolesMap = new Map(
    normalizedInitialRoles.map(role => [role, true])
  );
  const currentRolesMap = new Map(roles.map(role => [role, true]));

  const keys: string[] = Array.from(
    new Set([...normalizedInitialRoles, ...roles])
  );

  for (const role of keys) {
    if (initialRolesMap.has(role) && !currentRolesMap.has(role)) {
      toRevoke.push(role);
    }

    if (!initialRolesMap.has(role) && currentRolesMap.has(role)) {
      toGrant.push(role);
    }
  }

  return {
    toGrant,
    toRevoke,
  };
};

export const getRolesStatement = (
  userName: string,
  initialRoles: string[],
  roles: string[]
) => {
  const { toRevoke, toGrant } = splitRoles(initialRoles, roles);

  const grantStatements = getGrantStatements(userName, toGrant);
  const revokeStatements = getRevokeStatements(userName, toRevoke);
  return [...grantStatements, ...revokeStatements];
};

export const getCreateStatement = (
  user: CreateUserInput | User,
  withRoles: boolean = false
) => {
  const {
    userName,
    loginName,
    serviceAccountName,
    defaultDatabase,
    defaultEngine,
    roles,
  } = user;
  const withStatement =
    (loginName ? `\nLOGIN = "${loginName}"` : "") +
    (serviceAccountName ? `\nSERVICE_ACCOUNT = "${serviceAccountName}"` : "") +
    (defaultDatabase ? `\nDEFAULT_DATABASE = "${defaultDatabase}"` : "") +
    (defaultEngine ? `\nDEFAULT_ENGINE = "${defaultEngine}"` : "") +
    (withRoles && roles?.length
      ? `\nROLE = (${roles.map(r => `"${r}"`).join(",")})`
      : "");

  const statement =
    `CREATE USER "${userName}"` +
    (withStatement ? ` WITH ${withStatement}` : "") +
    ";";

  return statement;
};
