import { GutterMarker } from "@codemirror/view";

class MarkerSpacer extends GutterMarker {
  toDOM() {
    const div = document.createElement("div");
    div.className = "statement-marker";

    return div;
  }
}

export default MarkerSpacer;
