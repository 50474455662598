import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type Props = {
  selector: string;
  children: React.ReactNode;
};

export default function PortalOnMount({ children, selector }: Props) {
  const ref = useRef<Element | null>();
  const [mount, setMount] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMount(true);
  }, [selector]);

  return mount && ref.current ? createPortal(children, ref.current) : null;
}
