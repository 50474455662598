import { systemEngineEnvironment } from "services/environment/systemEngine";

import { WorkspaceEngine, WorkspaceEngineType } from "./engine.types";

export type CreateEngineInput = {
  engineName: string;
  type: WorkspaceEngineType;
  nodes: number;
  autoStop: string;
  initiallyStopped: boolean;
  autoStart: boolean;
  clusters: number;
};

export const getEngineSql = (engine: CreateEngineInput | WorkspaceEngine) => {
  const engineSql = systemEngineEnvironment.formatter.formatQuery(
    `CREATE ENGINE "${engine.engineName}" WITH
TYPE = "${engine.type}"
NODES = ?
AUTO_STOP = ?
INITIALLY_STOPPED = ?
AUTO_START = ?
CLUSTERS = ?`,
    [
      Number(engine.nodes),
      Number(engine.autoStop),
      engine.initiallyStopped,
      engine.autoStart,
      Number(engine.clusters),
    ]
  );
  return engineSql;
};

export const createWorkspaceEngine = async (engine: CreateEngineInput) => {
  const sql = getEngineSql(engine);
  await systemEngineEnvironment.execute(sql);
};
