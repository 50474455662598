import Logo from "assets/icons/Logo.svg?react";

import styles from "./MainSidebar.module.scss";

export const MainSidebarSkeleton = () => {
  return (
    <div
      className={styles.root}
      data-testid="sidebar-skeleton"
    >
      <div className={styles.sidebarHead}>
        <div className={styles.logo}>
          <Logo />
        </div>
      </div>
      <div className={styles.sidebarFooter} />
    </div>
  );
};
