import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import { AssignLogin } from "./AssignLogin";
import { AssignServiceAccount } from "./AssignServiceAccount";
import FormRow from "./components/FormRow";

import formRowStyles from "./components/FormRow/FormRow.module.scss";

export const assignTypes = {
  unassigned: {
    text: "Unassigned",
    component: () => null,
  },
  login: {
    text: "Login",
    component: AssignLogin,
  },
  serviceAccount: {
    text: "Service Account",
    component: AssignServiceAccount,
  },
};

export const AssignType = (props: { control: Control<any> }) => {
  const { control } = props;
  const { t } = useTranslation();
  return (
    <FormRow
      label={t("workspace.create_user.assign_to")}
      input={
        <Controller
          control={control}
          name="assignType"
          render={({ field: { onChange, value } }) => (
            <OutlinedSelect
              allowUncheck={false}
              className={formRowStyles.selectInput}
              testId="user-assign-type-select"
              initialSelected={[value]}
              onSelect={(items: string[]) => {
                if (!items.length) {
                  return;
                }
                const [type] = items;
                onChange(type);
              }}
              renderValue={(selectedItems: string[]) => {
                const [type] = selectedItems;
                const { text } = assignTypes[type as keyof typeof assignTypes];
                return text;
              }}
            >
              {Object.keys(assignTypes).map(type => {
                const { text } = assignTypes[type as keyof typeof assignTypes];
                return (
                  <ContextMenuItem
                    value={type}
                    key={type}
                    testId={`assign-type-item-${type}`}
                    checked={type === value}
                    checkedIconPlaceholder={true}
                    text={text}
                  />
                );
              })}
            </OutlinedSelect>
          )}
        />
      }
    />
  );
};
