import { useSuspenseQuery } from "@tanstack/react-query";

import { getLogins } from "services/login/getLogins";
import { Login } from "services/login/login.types";

import { ReactQueryKeysGlobal } from "../queryKeys";

export const useLogins = () => {
  const { data, isLoading, error } = useSuspenseQuery<Login[], Error>({
    queryKey: [ReactQueryKeysGlobal.logins],
    queryFn: () => getLogins(),
    retry: false,
  });
  return { data: data || [], isLoading, error };
};
