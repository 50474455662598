import {
  VISIBLE_QUERY_STATEMENTS_STATUSES,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const getLastVisibleQueryStatementIndex = (document: WorkspaceDocument) => {
  const index = document.execution?.queryStatements.findLastIndex(
    queryStatement =>
      VISIBLE_QUERY_STATEMENTS_STATUSES.includes(queryStatement.status)
  );

  if (index === -1) {
    return undefined;
  }

  return index;
};

const getNextDocumentWithFocusTabs = (
  document: WorkspaceDocument
): WorkspaceDocument => {
  const lastUserSwitchTimestamp =
    document.execution?.userSelectedActiveQueryStatementIndexTimestamp;

  const executionTimestamp = document.execution?.executionTimestamp;

  if (
    lastUserSwitchTimestamp &&
    executionTimestamp &&
    lastUserSwitchTimestamp > executionTimestamp
  ) {
    // user switched tabs after execution started
    // keep user selected tab
    return document;
  }

  const lastVisibleQueryStatementIndex =
    getLastVisibleQueryStatementIndex(document);

  if (lastVisibleQueryStatementIndex === undefined) {
    return document;
  }

  return {
    ...document,
    execution: document.execution
      ? {
          ...document.execution,
          activeQueryStatementIndex: lastVisibleQueryStatementIndex,
        }
      : null,
  };
};

export default getNextDocumentWithFocusTabs;
