import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  SupportAccessAgentType,
  SupportAccessService,
} from "services/api/supportAccess";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import Button, { ButtonTemplate } from "components/Button/Button";
import FormModal from "components/FormModal/FormModal";
import { CloseIcon } from "components/Icons";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { shouldInvalidateSupportAgent } from "./helpers";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  setEditStep: () => void;
};

export const SupportAgentMenu = (props: Props) => {
  const { onClose, setEditStep } = props;
  const queryClient = useQueryClient();
  const agent = queryClient.getQueryData<SupportAccessAgentType | undefined>([
    ReactQueryKeysAccount.supportAccessShort,
  ]);
  const { getAccount } = useCurrentAccount();
  const { putStatusMessage } = useStatusMessageQueue();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const account = getAccount();

  const handleRevoke = async () => {
    try {
      if (account) {
        const { accountName } = account;
        setLoading(true);
        await SupportAccessService.revokeSupportAccess(accountName);
        await queryClient.invalidateQueries({
          predicate: query => {
            return shouldInvalidateSupportAgent(query.queryKey[0]);
          },
        });
        setLoading(false);
      }
      onClose();
    } catch (error: any) {
      putStatusMessage({
        message: error.response?.data?.detail || error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setEditStep();
  };

  const expire = new Date(agent?.expires_at || "");
  const expireMessage = agent?.expires_at ? (
    <Trans
      i18nKey="supportAgent.menu.expire"
      values={{
        month: expire.toLocaleString("default", {
          month: "long",
        }),
        day: expire.getDate(),

        time: expire.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
      }}
      components={{ bold: <strong /> }}
    />
  ) : (
    t("supportAgent.menu.connected")
  );

  return (
    <FormModal
      open
      onClose={onClose}
      classes={{ paper: styles.paper }}
    >
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>
            {t("supportAgent.menu.title", { accountName: account.accountName })}
          </h2>
          <CloseIcon
            onClick={onClose}
            className={styles.icon}
          />
        </div>
        <div className={styles.message}>{expireMessage}</div>
        <div className={styles.footer}>
          <div className={styles.leftFooter}>
            <Button
              text={t("supportAgent.done")}
              template={ButtonTemplate.Primary}
              onClick={props.onClose}
              disabled={loading}
            />
            <Button
              text={t("supportAgent.menu.edit")}
              className={styles.secondaryBtn}
              template={ButtonTemplate.Ghost}
              onClick={handleEdit}
              disabled={loading}
            />
          </div>

          <Button
            text={t("supportAgent.menu.submit")}
            isLoading={loading}
            template={ButtonTemplate.Danger}
            onClick={handleRevoke}
            id="submit"
          />
        </div>
      </div>
    </FormModal>
  );
};
