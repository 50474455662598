import { Popover } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Duplicate from "assets/icons/selectMenuIcons/CopyIcon.svg?react";

import ActionMenuItem from "components/ActionMenu/ActionMenuItem/ActionMenuItem";
import { InteractionsContext } from "components/Datagrid/context";

type Props = {
  left: number;
  top: number;
  setContextMenuPosition: (
    position: { top: number; left: number } | null
  ) => void;
};

export const ContextMenu: React.FC<Props> = props => {
  const { left, top, setContextMenuPosition } = props;
  const { copySelection } = useContext(InteractionsContext);
  const { t } = useTranslation();

  const closeMenu = () => {
    setContextMenuPosition(null);
  };

  const handleCopy = () => {
    copySelection();
    setContextMenuPosition(null);
  };

  return (
    <Popover
      keepMounted
      onClose={closeMenu}
      open={true}
      anchorPosition={{ top, left }}
      anchorReference="anchorPosition"
      onContextMenu={(event: React.SyntheticEvent) => event.preventDefault()}
    >
      <div>
        <div
          data-testid="context-item-copy"
          onMouseDown={event => event.nativeEvent.stopImmediatePropagation()}
        >
          <ActionMenuItem
            onClick={handleCopy}
            testId="copy-context"
            icon={<Duplicate />}
            text={t("datagrid.copy")}
          />
        </div>
      </div>
    </Popover>
  );
};
