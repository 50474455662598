import cn from "classnames";
import React, { useRef } from "react";

import {
  QueryStatement,
  QueryStatementStatus,
} from "pages/DevelopWorkspace/workspace.types";

import styles from "./TabsBar.module.scss";

interface TabsBarProps {
  queryStatements: QueryStatement[];
  activeQueryStatementIndex: number;
  setActiveTab: (index: number) => void;
  isHidden?: (statement: QueryStatement) => boolean;
}

const TabsBar = (props: TabsBarProps) => {
  const { queryStatements, activeQueryStatementIndex, setActiveTab, isHidden } =
    props;

  const tabRefs = useRef<Record<string, HTMLDivElement>>({});

  const tabs = queryStatements.map((statement, index) => {
    return (
      <div
        key={statement.id}
        onClick={() => {
          setActiveTab(index);
        }}
        ref={ref => {
          if (!ref) {
            return;
          }

          tabRefs.current[index] = ref;
        }}
        data-testid={`query-result-tab-${index + 1}`}
        className={cn(styles.tab, {
          [styles.error]: statement.status === QueryStatementStatus.error,
          [styles.active]: activeQueryStatementIndex === index,
          [styles.hidden]: isHidden ? isHidden(statement) : false,
        })}
      >
        Query {index + 1}
      </div>
    );
  });

  tabs.reverse();

  return (
    <div className={styles.tabsBar}>{queryStatements.length > 1 && tabs}</div>
  );
};

export default TabsBar;
