import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Button, { ButtonTemplate } from "components/Button/Button";

import CheckIcon from "./CheckIcon.svg?react";

import styles from "./styles.module.scss";

type Props<T> = {
  activeStep: T;
  activeStepIndex: number;
  handleSelectStep: (step: T) => void;
  handleShowScript?: () => void;
  showScript?: boolean;
  steps: {
    id: T;
    title: string;
  }[];
};

export function Sidebar<T extends string>(props: Props<T>) {
  const {
    activeStep,
    showScript,
    activeStepIndex,
    handleSelectStep,
    handleShowScript,
    steps,
  } = props;

  const { t } = useTranslation();
  return (
    <div className={styles.sidebar}>
      <div className={styles.steps}>
        {steps.map((step, index) => {
          return (
            <div
              className={classNames(styles.step, {
                [styles.active]: activeStep === step.id,
                [styles.completed]: activeStepIndex > index,
              })}
              onClick={() => handleSelectStep(step.id)}
              key={step.id}
              data-testid={`step-${step.id}`}
            >
              {activeStepIndex > index ? (
                <div className={styles.order}>
                  <CheckIcon />
                </div>
              ) : (
                <div className={styles.order}>{index + 1}</div>
              )}
              <div className={styles.title}>{t(step.title)}</div>
            </div>
          );
        })}
      </div>
      {showScript !== undefined ? (
        <div className={styles.showScript}>
          <Button
            text={
              showScript
                ? t("wizard.sidebar.hide_script")
                : t("wizard.sidebar.show_script")
            }
            template={ButtonTemplate.Tertiary}
            onClick={handleShowScript}
            dataTestid="show-script"
          />
        </div>
      ) : null}
    </div>
  );
}
