import { useCallback, useEffect } from "react";

import { CLEAR, MOUSEMOVE, RIGHT_CLICK } from "../state/actions";
import { InteractionsConstants } from "../types";
import { getEventType } from "../utils/getEventType";
import { isRightClick, onScrollbar } from "../utils/interactions";

export const useMouseEvents = ({
  containerRef,
  dispatch,
  getCursorIndex,
  constants,
}: {
  containerRef: any;
  dispatch: React.Dispatch<any>;
  getCursorIndex: any;
  constants: InteractionsConstants;
}) => {
  const handleMouseMove = useCallback(
    (event: any) => {
      const cursorIndex = getCursorIndex(event, false);
      if (cursorIndex) {
        dispatch({ type: MOUSEMOVE, cursorIndex });
      }
    },
    [dispatch, getCursorIndex]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener("mousemove", handleMouseMove);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (event: any) => {
      if (
        !containerRef.current ||
        onScrollbar({ event, containerRef, constants })
      ) {
        return;
      }

      // prevent triggering on right click outside the container (e.g. on modals/overlays)
      if (!containerRef.current.contains(event.target)) {
        return;
      }

      const cursorIndex = getCursorIndex(event);

      if (!cursorIndex) {
        dispatch({ type: CLEAR });
        return;
      }

      if (isRightClick({ event })) {
        event.preventDefault();
        const { clientX, clientY } = event;
        dispatch({
          type: RIGHT_CLICK,
          cursorIndex,
          clientX,
          clientY,
        });
        return;
      }

      const type = getEventType(event);
      dispatch({ type, cursorIndex });
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [
      containerRef,
      getCursorIndex,
      handleMouseMove,
      handleMouseUp,
      dispatch,
      constants,
    ]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);
};
