import { FormatData } from "../FormatData/FormatData";
import { MapData } from "../MapData/MapData";
import { PreviewScript } from "../PreviewScript/PreviewScript";
import { IngestionStep } from "../types";
import { Review } from "./Review";
import { SelectAWS } from "./SelectAWS";
import { SelectData } from "./SelectData";
import { SelectDestination } from "./SelectDestination";
import { SelectEngine } from "./SelectEngine";

const stepsByType = {
  [IngestionStep.selectAWS]: SelectAWS,
  [IngestionStep.selectData]: SelectData,
  [IngestionStep.selectDestination]: SelectDestination,
  [IngestionStep.formatData]: FormatData,
  [IngestionStep.mapData]: MapData,
  [IngestionStep.selectEngine]: SelectEngine,
  [IngestionStep.review]: Review,
  [IngestionStep.previewScript]: PreviewScript,
};

export default stepsByType;
