import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  SupportAccessAgentType,
  SupportAccessService,
} from "services/api/supportAccess";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import FormModal from "components/FormModal/FormModal";
import LoadingOverlap from "components/LoadingOverlap";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { SupportAgent } from "./SupportAgent";
import { shouldInvalidateSupportAgent } from "./helpers";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onSuccess?: (agent: SupportAccessAgentType) => void;
};

export const SupportAgentCreate = (props: Props) => {
  const { onClose, onSuccess } = props;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const { getAccount } = useCurrentAccount();
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const account = getAccount();

  const handleSubmit = async (formFields: any) => {
    if (!account) {
      return;
    }

    const input = {
      account_role_names: formFields.roles,
    };

    if (formFields.duration) {
      const now = new Date();
      const expires_at = new Date(
        now.getTime() + 1000 * 60 * 60 * formFields.duration
      ).toISOString();

      Object.assign(input, {
        expires_at,
      });
    }

    try {
      setSubmitting(true);
      const agent = await SupportAccessService.createSupportAccess(
        account.accountName,
        input
      );
      await queryClient.invalidateQueries({
        predicate: query => {
          return shouldInvalidateSupportAgent(query.queryKey[0]);
        },
      });
      onSuccess?.(agent);
    } catch (error: any) {
      putStatusMessage({
        message: error.response?.data?.detail || error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FormModal
      open
      onClose={onClose}
      classes={{ paper: classNames(styles.paper, styles.paper__large) }}
    >
      <Suspense fallback={<LoadingOverlap isLoading />}>
        <SupportAgent
          onClose={onClose}
          onSubmit={handleSubmit}
          submitting={submitting}
          revoking={false}
          title={t("supportAgent.title", { accountName: account.accountName })}
          submit={t("supportAgent.submit")}
        />
      </Suspense>
    </FormModal>
  );
};
