import { useCallback, useEffect, useReducer, useRef } from "react";

import {
  DRAG_FINISH,
  INIT,
  RESTRICT_MINIMAP,
  SCROLL,
  TRANSFORM,
  ZOOM,
} from "./actions";
import { ZOOM_INTENSITY } from "./constants";
import {
  interactionStateReducer,
  minimapStartPosition,
} from "./interactionStateReducer";

export const initialState = {
  initialized: false,
  startPosition: { k: 1, x: 0, y: 0 },
  transform: { k: 1, x: 0, y: 0 },
  cursor: "default",
  expandedNodes: {},
  minimap: {
    collapsed: false,
    transform: minimapStartPosition,
    startPosition: minimapStartPosition,
  },
};

export const useInteractions = ({
  container,
  draggable,
  sidebar,
}: {
  container: any;
  draggable: any;
  sidebar: any;
}) => {
  const pointerStart = useRef<any>(null);
  const panning = useRef(false);

  const [state, dispatch] = useReducer(interactionStateReducer, initialState);

  useEffect(() => {
    if (!container.current || !draggable.current) {
      return;
    }

    dispatch({
      type: INIT,
      container,
      draggable,
      sidebar,
    });
  }, [container, draggable, sidebar]);

  useEffect(() => {
    if (!container.current) {
      return;
    }

    const observer = new ResizeObserver(() => {
      dispatch({
        type: RESTRICT_MINIMAP,
      });
    });

    observer.observe(container.current);

    return () => {
      observer.disconnect();
    };
  }, [container]);

  const handlePointerMove = useCallback(
    (event: any) => {
      if (!pointerStart.current || !container.current) {
        return;
      }
      panning.current = true;

      event.preventDefault();

      const zoom =
        container.current.getBoundingClientRect().width /
        container.current.offsetWidth;

      const x = (event.pageX - pointerStart.current[0]) / zoom;
      const y = (event.pageY - pointerStart.current[1]) / zoom;

      dispatch({
        type: TRANSFORM,
        transform: { x, y },
      });
    },
    [container]
  );

  const handlePointerUp = useCallback(() => {
    pointerStart.current = null;
    panning.current = false;
    dispatch({
      type: DRAG_FINISH,
    });
    document.removeEventListener("pointermove", handlePointerMove);
    document.removeEventListener("pointerup", handlePointerUp);
  }, [handlePointerMove]);

  const handlePointerDown = useCallback(
    (event: any) => {
      if (event.pointerType === "mouse" && event.button !== 0) {
        return;
      }
      event.stopPropagation();

      pointerStart.current = [event.pageX, event.pageY];

      document.addEventListener("pointerup", handlePointerUp);
      document.addEventListener("pointermove", handlePointerMove);
    },
    [handlePointerMove, handlePointerUp]
  );

  const handleWheel = useCallback(
    (event: any) => {
      if (!draggable.current || !container.current) {
        return;
      }
      if (!container.current.contains(event.target) || panning.current) {
        return;
      }
      event.preventDefault();
      if (event.ctrlKey) {
        const rect = draggable.current.getBoundingClientRect();
        const delta = -event.deltaY * ZOOM_INTENSITY;

        const ox = (rect.left - event.clientX) * delta;
        const oy = (rect.top - event.clientY) * delta;

        dispatch({
          type: ZOOM,
          delta,
          ox,
          oy,
        });
      } else if (event.shiftKey) {
        dispatch({
          type: SCROLL,
          transform: {
            x: -event.deltaX,
            y: 0,
          },
        });
      } else {
        dispatch({
          type: SCROLL,
          transform: {
            x: -event.deltaX,
            y: -event.deltaY,
          },
        });
      }
    },
    [draggable, container]
  );

  useEffect(() => {
    document.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, [handleWheel]);

  useEffect(() => {
    return () => {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, [handlePointerUp, handlePointerMove]);

  return {
    state,
    dispatch,
    handlePointerDown,
    handleWheel,
  };
};
