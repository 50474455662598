import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

type Props = {
  icon: React.ReactElement;
  active?: boolean;
  selected?: boolean;
};

export const HeaderIcon = React.forwardRef<HTMLSpanElement, Props>(
  (props: Props, ref) => {
    const { icon, active, selected, ...rest } = props;
    return (
      <span
        {...rest}
        className={classNames(styles.icon, {
          [styles.active]: active,
          [styles.selected]: selected,
        })}
        ref={ref}
      >
        {icon}
      </span>
    );
  }
);
