import { Popover } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import DocumentFlagIcon from "assets/icons/DocumentFlagIcon.svg?react";
import SubstractIcon from "assets/icons/SubstractIcon.svg?react";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuHeader from "components/ContextMenu/ContextMenuHeader";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";

import styles from "./FlagsMenu.module.scss";

interface Props {
  settings: Record<string, string>;
  isDocumentRunning: boolean;
  onRemoveAll: () => void;
  onRemove: (flag: string) => void;
}

export const FlagsMenu = (props: Props) => {
  const { settings, isDocumentRunning, onRemoveAll, onRemove } = props;

  const menuElement = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const flags = Object.keys(settings);

  useEffect(() => {
    if (!flags.length) {
      setMenuOpen(false);
    }
  }, [flags.length, setMenuOpen]);

  if (!flags.length) {
    return null;
  }

  return (
    <>
      <div
        data-testid="flags-menu"
        className={classNames(styles.flag, {
          [styles.flag__active]: menuOpen,
        })}
        ref={menuElement}
        onClick={() => {
          setMenuOpen(true);
        }}
      >
        <DocumentFlagIcon className={styles.icon} />
      </div>

      <Popover
        open={menuOpen}
        anchorEl={menuElement.current}
        onClose={() => {
          setMenuOpen(false);
        }}
        onClick={e => {
          e.stopPropagation();
        }}
        sx={{ transform: "translate(0px, 4px)" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ContextMenu>
          <ContextMenuHeader text="Active flags:" />

          <ContextMenuItemsGroup isGroup={true}>
            {flags.map(flag => {
              const value = settings[flag];
              return (
                <ContextMenuItem
                  key={flag}
                  availableForInteraction={false}
                  text={`${flag} = ${value}`}
                  content={
                    isDocumentRunning ? null : (
                      <div
                        className={styles.substractIcon}
                        onKeyDown={e => {
                          if (e.key === "Enter" && !isDocumentRunning) {
                            onRemove(flag);
                          }
                        }}
                        aria-label="Remove flag"
                        data-testid={`remove-flag-item-${value}`}
                        tabIndex={0}
                        onClick={() => {
                          if (!isDocumentRunning) {
                            onRemove(flag);
                          }
                        }}
                      >
                        <SubstractIcon />
                      </div>
                    )
                  }
                  onClick={() => {}}
                />
              );
            })}
          </ContextMenuItemsGroup>

          <ContextMenuDivider />

          <ContextMenuItem
            key="remove-all-flags"
            text="Remove all flags"
            disabled={isDocumentRunning}
            testId="remove-all-flags-item"
            onClick={() => {
              if (!isDocumentRunning) {
                onRemoveAll();
              }
            }}
          />
        </ContextMenu>
      </Popover>
    </>
  );
};
