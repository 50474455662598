import z from "zod";

import { AuthType } from "services/fileObjects/getFilesByPath";

const schema = z.object({
  playgroundBucket: z.boolean().default(false),
  storageUrl: z
    .string()
    .regex(/^s3:\/\/.+/, "Storage url should start with s3://"),
});

const schemaWithSecret = z.object({
  authType: z.enum([AuthType.SECRET]),
  key: z.string(),
  secret: z.string(),
});

const schemaWithRole = z.object({
  authType: z.enum([AuthType.IAM]),
  iamRole: z
    .string()
    .min(1, "IAM Role is required")
    .regex(/^arn:aws:iam::[0-9]{12}:role\/.+/, "Invalid IAM Role ARN"),
});

const schemaWithNone = z.object({
  authType: z.enum([AuthType.NONE]),
});

const schemaConditions = z.discriminatedUnion("authType", [
  schemaWithSecret,
  schemaWithRole,
  schemaWithNone,
]);

export const validationSchema = z.intersection(schemaConditions, schema);

export type Fields = z.infer<typeof validationSchema>;
