import React, { ReactElement } from "react";

import CheckIcon from "assets/icons/CheckIcon.svg?react";

import ActionMenuItem, {
  ActionMenuItemProps,
} from "components/ActionMenu/ActionMenuItem/ActionMenuItem";
import Highlighter from "components/Highligher";

interface Props extends ActionMenuItemProps {
  value: string;
  selected?: boolean;
  selectedIcon?: ReactElement;
  children?: any;
  title?: string;
  searchOptions?: {
    textForSearch: string;
    searchQuery?: string;
    renderContent: (formattedText: React.ReactNode) => React.ReactElement;
  };
  className?: string;
  disableClose?: boolean;
}
/* wrapped component in React.forwardRef to get rid of javascript ref error
   the ref attribute is not currently used
 */
const SelectMenuItem = React.forwardRef((props: Props, _ref) => {
  const { searchQuery, textForSearch, renderContent } =
    props.searchOptions || {};

  const formattedText =
    searchQuery && textForSearch && renderContent ? (
      <Highlighter
        searchWords={[searchQuery]}
        textToHighlight={textForSearch}
      />
    ) : (
      textForSearch
    );

  return (
    <ActionMenuItem
      selected={props.selected}
      selectedIcon={props.selectedIcon}
      className={props.className}
      text={renderContent ? renderContent(formattedText) : props.children}
      {...props}
    />
  );
});

SelectMenuItem.defaultProps = {
  selectedIcon: <CheckIcon />,
};

export default SelectMenuItem;
