import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SupportAccessAgentType } from "services/api/supportAccess";

import Button, { ButtonTemplate } from "components/Button/Button";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { CrossIcon } from "components/Icons";
import FormRow from "components/LeftSidebar/UserModal/components/FormRow";
import formRowStyles from "components/LeftSidebar/UserModal/components/FormRow/FormRow.module.scss";
import RoleSelect from "components/LeftSidebar/UserModal/components/RoleSelect";
import OutlinedSelect from "components/OutlinedSelect";

import styles from "./styles.module.scss";

type FormFields = {
  roles: string[];
  duration: number;
};

type Props = {
  onClose: () => void;
  onSubmit: (fields: FormFields) => void;
  onRevoke?: () => void;
  submitting: boolean;
  revoking: boolean;
  title: string;
  submit: string;
  agent?: SupportAccessAgentType | null;
};

const durationOptions = [
  {
    name: "supportAgent.options.30min",
    value: 0.5,
  },
  {
    name: "supportAgent.options.3",
    value: 3,
  },
  {
    name: "supportAgent.options.6",
    value: 6,
  },
  {
    name: "supportAgent.options.12",
    value: 12,
  },
  {
    name: "supportAgent.options.24",
    value: 24,
  },
  {
    name: "supportAgent.options.unlimited",
    value: 0,
  },
];

const canSubmit = (fields: FormFields) => {
  return !!fields.roles.length;
};

export const SupportAgent = (props: Props) => {
  const {
    submitting,
    revoking,
    onSubmit,
    title,
    submit,
    onRevoke,
    agent,
    onClose,
  } = props;
  const { t } = useTranslation();

  const [formFields, setFormFields] = useState<FormFields>({
    roles: agent ? agent.account_role_names : [],
    duration: 24,
  });

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!canSubmit(formFields) || submitting) {
      return;
    }
    onSubmit(formFields);
  };

  const handleRevoke = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (revoking) {
      return;
    }

    onRevoke?.();
  };

  return (
    <div className={styles.contentContainer}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{title}</h2>
        <CrossIcon
          className={styles.icon}
          onClick={onClose}
        />
      </div>

      <form
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        name="supportAccessForm"
      >
        <div className={styles.content}>
          <FormRow
            label={t("supportAgent.duration")}
            helperText={t("supportAgent.durationHelp")}
            input={
              <OutlinedSelect
                testId="duration"
                className={formRowStyles.selectInput}
                initialSelected={[`${formFields.duration}`]}
                onSelect={([value]) => {
                  setFormFields(fields => ({
                    ...fields,
                    duration: +value,
                  }));
                }}
                renderValue={items => {
                  const [item] = items;
                  const option = durationOptions.find(
                    option => option.value === +item
                  );
                  if (!option) {
                    return "";
                  }
                  return <>{t(option.name)}</>;
                }}
              >
                {durationOptions.map(option => {
                  const { value, name } = option;
                  return (
                    <ContextMenuItem
                      value={`${value}`}
                      key={name}
                      checked={formFields.duration === value}
                      text={t(name)}
                    />
                  );
                })}
              </OutlinedSelect>
            }
          />

          <FormRow
            label={t("users.form.assign_roles")}
            input={
              <RoleSelect
                initialRoles={formFields.roles}
                value={formFields.roles}
                onChange={roles => {
                  setFormFields({
                    ...formFields,
                    roles,
                  });
                }}
              />
            }
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.leftFooter}>
            <Button
              text={submit}
              isLoading={submitting}
              disabled={!canSubmit(formFields) || revoking}
              template={ButtonTemplate.Primary}
              onClick={handleSubmit}
              id="submit"
            />
            <Button
              text={t("supportAgent.cancel")}
              template={ButtonTemplate.Ghost}
              className={styles.secondaryBtn}
              onClick={onClose}
              disabled={submitting || revoking}
            />
          </div>

          {!!onRevoke && (
            <Button
              text={t("supportAgent.menu.submit")}
              isLoading={revoking}
              disabled={submitting}
              template={ButtonTemplate.Danger}
              onClick={handleRevoke}
            />
          )}
        </div>
      </form>
    </div>
  );
};
