import { AuthService } from "services/auth";
import { WorkspaceEngine } from "services/engines/engine.types";

import { FIREBOLT_UI_USER_ENGINE_QUERY_LABEL } from "pages/DevelopWorkspace/services/constants";
import { buildEngineUrl } from "pages/DevelopWorkspace/services/helpers/buildEngineUrl";
import { FetchOptions } from "pages/DevelopWorkspace/services/types";

const cancelQuery = async (
  engine: WorkspaceEngine,
  serverQueryId: string,
  fetchOptions: FetchOptions,
  authService: AuthService
) => {
  const token = await authService.getToken();
  if (!token) {
    throw new Error("Failed to cancel the query: token is not available");
  }

  const { headers, signal } = fetchOptions;

  const engineURL = new URL(`https://${engine.url}`);
  const url = buildEngineUrl(engineURL, {
    query_label: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
    auto_start_stop_control: "ignore",
  });

  const response = await fetch(url, {
    signal,
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    body: `CANCEL QUERY WHERE query_id = '${serverQueryId}';`,
  });

  const json = await response.json();

  if (json.errors) {
    throw new Error(json.errors[0].description);
  }

  const res = json.data[0];

  return res;
};

export default cancelQuery;
