import moment from "moment/moment";

export const getTimeWindowDateFrom = (
  timeWindow: string | null,
  dateTo: Date
) => {
  const momentDate = moment(dateTo);

  switch (timeWindow) {
    case "5 minutes":
      return momentDate.subtract(5, "minutes").toDate();
    case "1 hour":
      return momentDate.subtract(1, "hour").toDate();
    case "1 day":
      return momentDate.subtract(1, "day").toDate();
    case "1 week":
      return momentDate.subtract(1, "week").toDate();
    case "1 month":
      return momentDate.subtract(1, "month").toDate();
    default:
      return null;
  }
};
