import { isMac } from "services/browser";

export const ZOOM_INTENSITY = isMac ? 0.008 : 0.002;
export const PADDING = 20;

export const MIN_ZOOM = 0.3;
export const MAX_ZOOM = 4;
export const MINIMAP_WIDTH = 100;
export const MINIMAP_HEIGHT = 118;

export const SCROLL_RESTRICT_GAP = -200;
