const END_TOKEN = ";";

const PARENS = [`'`, '"', "`"];

const stripOuterChars = (value: string) => {
  // eslint-disable-next-line no-param-reassign -- legacy code
  value = value.trim();

  if (value[value.length - 1] === END_TOKEN) {
    // eslint-disable-next-line no-param-reassign -- legacy code
    value = value.substring(0, value.length - 1);
  }

  for (const char of PARENS) {
    if (value[0] === char && value[value.length - 1] === char) {
      // eslint-disable-next-line no-param-reassign -- legacy code
      value = value.substring(1, value.length - 1);
      break;
    }
  }

  return value.trim();
};

export const parseQuerySetting = (
  sql: string
): { key: string; value: string } | null => {
  const match = sql.match(/^\s*set\s+(.+?)\s*=(.+)/ims);
  if (match) {
    const [, key, value] = match;

    return {
      key,
      value: stripOuterChars(value),
    };
  }
  return null;
};
