import { isDateType, isNumberType } from "./data";

export const getFieldTextAlign = ({
  field,
}: {
  field: {
    _typeUpperCase: string;
  };
}) => {
  const { _typeUpperCase } = field;
  if (isNumberType(_typeUpperCase) || isDateType(_typeUpperCase)) {
    return "right";
  }
  return "left";
};
