import { Divider, Popover, PopoverOrigin } from "@mui/material";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _omit from "lodash/omit";
import _pick from "lodash/pick";
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import { SELECT_ALL } from "components/Datagrid/Filters/actions";
import { FiltersContext, FiltersType } from "components/Datagrid/context";
import { ColumnWithProps } from "components/Datagrid/types";

import { FilterModalContext } from "./Context";
import { Footer } from "./Footer";
import { FiltersContent } from "./Tabs/FiltersContent";
import { SelectorsContent } from "./Tabs/SelectorsContent";
import { filtersReducer } from "./reducer";

import styles from "./styles.module.scss";

const MODAL_HEIGHT = 420;
const stopImmediate = (event: React.SyntheticEvent) => {
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

const getDefaultFilters = () => {
  return {
    include: {
      items: [],
      itemsSet: new Set(),
    },
  };
};

const omitHidden = (filters: any) => {
  const withoutHidden = _omit(filters, "filters.hidden");
  return Object.keys(withoutHidden).reduce(
    (
      acc: {
        [key: string]: {
          [key: string]: string[];
        };
      },
      key
    ) => {
      const filter = withoutHidden[key];
      if (!_isEmpty(filter)) {
        acc[key] = filter;
      }
      return acc;
    },
    {}
  );
};

const getFilterStats = ({
  localFilters,
  columnFilters,
}: {
  localFilters: {
    include: {
      type: string;
      items: {
        value: string;
        label: string;
        count: number;
      }[];
      itemsSet: Set<string>;
    };
  };
  columnFilters: {
    [key: string]: any;
  };
}) => {
  const isPristine = _isEqual(
    _pick(omitHidden(localFilters)),
    _pick(columnFilters.current)
  );

  return {
    isPristine,
  };
};

const FiltersInner: React.FC<{ column: ColumnWithProps }> = props => {
  const { column } = props;
  const [expandedItem, setExpandedItem] = useState<string>("");
  const {
    getColumnCandidates,
    getColumnFilters,
    setColumnFilters,
    closeFilters,
  } = useContext(FiltersContext);

  const columnFilters = useRef({ ...getColumnFilters(column) });

  const [localFilters, dispatch] = useReducer(filtersReducer, {
    ...getDefaultFilters(),
    ...columnFilters.current,
  });

  const { items, itemsCount } = useMemo(() => {
    const items = getColumnCandidates(column, localFilters.filters);
    const itemsCount = items.reduce((acc, item) => acc + item.count, 0);
    return {
      items,
      itemsCount,
    };
  }, [getColumnCandidates, column, localFilters]);

  const { isPristine } = getFilterStats({
    localFilters,
    columnFilters,
  });

  const filterContextValue = useMemo(() => {
    return {
      column,
      items,
      itemsCount,
      localFilters,
      dispatch,
      isPristine,
    };
  }, [column, items, itemsCount, localFilters, dispatch, isPristine]);

  const pruneFilters = (state: any) => {
    const { include, filters } = state;

    const result = {};

    const prunedItems = filters
      ? filters.items.filter(
          (item: { value: string; right: string; left: string }) =>
            item.value || (item.right && item.left)
        )
      : [];

    if (prunedItems.length) {
      Object.assign(result, { filters });
    }

    if (include?.itemsSet?.size) {
      Object.assign(result, { include });
    }

    return result as FiltersType;
  };

  const applyFilters = useCallback(() => {
    const pruned = pruneFilters(omitHidden(localFilters));
    setColumnFilters(column, pruned);
    columnFilters.current = localFilters;
    closeFilters();
  }, [column, localFilters, setColumnFilters, closeFilters]);

  const handleClearAll = useCallback(() => {
    if (!localFilters.include.items.length) {
      return;
    }

    dispatch({ type: SELECT_ALL });
  }, [dispatch, localFilters.include.items.length]);

  const showClearAll = !expandedItem && !!localFilters.include.itemsSet.size;
  const showApply = !!expandedItem;

  return (
    <FilterModalContext.Provider value={filterContextValue}>
      <div
        className={styles.wrapper}
        data-testid="column-filters"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <SelectorsContent
          expandedItem={expandedItem}
          setExpandedItem={setExpandedItem}
          handleClearAll={handleClearAll}
        />
        <FiltersContent
          expandedItem={expandedItem}
          setExpandedItem={setExpandedItem}
        />
        {(showClearAll || showApply) && (
          <>
            <Divider className={styles.divider} />
            <Footer
              applyFilters={applyFilters}
              localFilters={localFilters}
              itemsCount={itemsCount}
              showClearAll={showClearAll}
              showApply={showApply}
              handleClearAll={handleClearAll}
            />
          </>
        )}
      </div>
    </FilterModalContext.Provider>
  );
};

type Props = {
  anchorEl: HTMLElement | null;
  opened: boolean;
  column: ColumnWithProps;
};

const getPlacement = (
  anchorEl: HTMLElement | null
): { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin } => {
  const rect = anchorEl?.getBoundingClientRect();

  if (rect && rect.bottom + MODAL_HEIGHT > window.innerHeight) {
    if (rect.top - MODAL_HEIGHT < 0) {
      // doesn't fit on top of header
      return {
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      };
    }
    return {
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
    };
  }

  return {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };
};

export const Filters: React.FC<Props> = props => {
  const { anchorEl, opened, column } = props;
  const { closeFilters } = useContext(FiltersContext);

  const { anchorOrigin, transformOrigin } = getPlacement(anchorEl);

  return (
    <Popover
      open={opened}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{ paper: styles.paper }}
      anchorEl={anchorEl}
      onClose={(e: SyntheticEvent) => {
        e.stopPropagation();
        closeFilters();
      }}
      onMouseDown={stopImmediate}
    >
      <FiltersInner column={column} />
    </Popover>
  );
};
