import { useSuspenseQuery } from "@tanstack/react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";

export type Database = {
  catalogName: string;
  description: string;
};

export const useDatabasesNames = (): Database[] => {
  const { data } = useSuspenseQuery<Database[], Error>({
    queryKey: [ReactQueryKeysAccount.databasesNames],
    queryFn: async () => {
      const result = await systemEngineEnvironment.execute<Database>(
        `SELECT catalog_name, description, catalog_owner FROM information_schema.catalogs order by LOWER(catalog_name)`
      );

      const [response] = result;
      return response.rows;
    },
  });
  return data || [];
};
