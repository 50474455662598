import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";

import { getRoles } from "./getRoles";
import { Role } from "./types";

export const useRbacRoles = (): Role[] => {
  const { data } = useSuspenseQuery<Role[], Error>({
    queryKey: [ReactQueryKeysAccount.rbacRoles],
    queryFn: () => {
      return getRoles();
    },
  });

  return data || [];
};
