import { Controller, FormState, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import z from "zod";

import { useEnableReadCsvNewParams } from "featureFlags/hooks/useEnableReadCsvNewParams";

import Checkbox from "components/Checkbox";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";
import { TextInput } from "components/TextInput/TextInput";
import Toggle from "components/Toggle";

import { SupportedQuotes } from "../../types";
import { FormatingFields, csvSchema, defaultFormatingByType } from "../schema";

import styles from "./styles.module.scss";

const SUPPORTED_QUOTES: SupportedQuotes[] = ["DOUBLE_QUOTE", "SINGLE_QUOTE"];

const SelectQuote = (props: { form: UseFormReturn<FormatingFields> }) => {
  const { form } = props;
  const { watch, control } = form;
  const { t } = useTranslation();
  const quoteCharacter = watch("formatSettings.quoteCharacter");

  const items = SUPPORTED_QUOTES.map(item => {
    return (
      <ContextMenuItem
        value={item}
        key={item}
        checked={item === quoteCharacter}
        checkedIconPlaceholder
        testId={`quote-item-${item}`}
        text={t(`wizard.format_data.quote_${item}`)}
      />
    );
  });
  return (
    <div className={styles.row}>
      <span className={styles.row__label}>
        {t("wizard.format_data.quote_character")}
      </span>
      <Controller
        control={control}
        name="formatSettings.quoteCharacter"
        render={({ field: { onChange, value } }) => (
          <OutlinedSelect
            testId="quote-select"
            wrapperClassName={styles.wrapper}
            className={styles.inputRoot}
            initialSelected={[value]}
            renderValue={([item]) => {
              return t(`wizard.format_data.quote_${item}`);
            }}
            onSelect={([name]) => {
              onChange(name);
            }}
          >
            {items}
          </OutlinedSelect>
        )}
      />
    </div>
  );
};

const CustomCSVSettings = (props: { form: UseFormReturn<FormatingFields> }) => {
  const { form } = props;
  const { control, register, formState } = form;
  const { errors } = formState as FormState<z.infer<typeof csvSchema>>;
  const { t } = useTranslation();
  const enableReadCsvNewParams = useEnableReadCsvNewParams();

  return (
    <div className={styles.settings}>
      <div className={styles.row}>
        <Controller
          control={control}
          name="formatSettings.autoDetectHeader"
          render={({ field: { onChange, value } }) => (
            <>
              <span className={styles.row__label}>
                {t("wizard.format_data.autodetect_header_row")}
              </span>
              <Toggle
                size="sm"
                dataTestId="format-header-row"
                checked={value}
                classes={{ wrapper: styles.toggleWrapper }}
                onChange={checked => {
                  onChange(checked);
                }}
              />
            </>
          )}
        />
      </div>
      <div className={styles.row}>
        <span className={styles.row__label}>
          {t("wizard.format_data.field_delimiter")}
        </span>
        <div className={styles.inputContainer}>
          <Controller
            control={control}
            name="formatSettings.fieldDelimiter"
            render={({ field: { onChange, ...rest } }) => (
              <TextInput
                inputRootClassName={styles.nameInput}
                testId="field-delimiter-input"
                error={!!errors?.formatSettings?.fieldDelimiter!}
                helperText={errors?.formatSettings?.fieldDelimiter?.message}
                required
                {...rest}
                onKeyDown={event => {
                  if (event.key === "Tab") {
                    event.preventDefault();
                    onChange("\u0009");
                    return false;
                  }
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>

      <SelectQuote form={form} />

      <div className={styles.row}>
        <span className={styles.row__label}>
          {t("wizard.format_data.escape_character")}
        </span>
        <div className={styles.inputContainer}>
          <TextInput
            inputRootClassName={styles.nameInput}
            testId="escape-character-input"
            {...register("formatSettings.escapeCharacter")}
            error={!!errors?.formatSettings?.escapeCharacter}
            helperText={errors?.formatSettings?.escapeCharacter?.message}
            required
          />
        </div>
      </div>
      {!enableReadCsvNewParams && (
        <div className={styles.row}>
          <span className={styles.row__label}>
            {t("wizard.format_data.newline_character")}
          </span>
          <div className={styles.inputContainer}>
            <TextInput
              inputRootClassName={styles.nameInput}
              testId="newline-character-input"
              {...register("formatSettings.newlineCharacter")}
              error={!!errors?.formatSettings?.newlineCharacter}
              helperText={errors?.formatSettings?.newlineCharacter?.message}
              disabled
              required
            />
          </div>
        </div>
      )}
      <div className={styles.row}>
        <span className={styles.row__label}>
          {t("wizard.format_data.null_character")}
        </span>
        <div className={styles.inputContainer}>
          <TextInput
            inputRootClassName={styles.nameInput}
            testId="null-character-input"
            {...register("formatSettings.nullCharacter")}
            error={!!errors?.formatSettings?.nullCharacter}
            helperText={errors?.formatSettings?.nullCharacter?.message}
            required
          />
        </div>
      </div>
      <div className={styles.checkboxes}>
        <div className={styles.checkboxes__column}>
          {/* todo add back when BE allows it */}
          {/* <Controller */}
          {/*  control={control} */}
          {/*  name="formatSettings.allowColumnMismatch" */}
          {/*  render={({ field: { onChange, value } }) => ( */}
          {/*    <div className={styles.checkbox}> */}
          {/*      <Checkbox */}
          {/*        checked={value} */}
          {/*        onChange={() => onChange(!value)} */}
          {/*        testId="allow-column-missmatch-checkbox" */}
          {/*      /> */}
          {/*      <span className={styles.row__label}> */}
          {/*        {t("wizard.format_data.allow_column_missmatch")} */}
          {/*      </span> */}
          {/*    </div> */}
          {/*  )} */}
          {/* /> */}

          <Controller
            control={control}
            name="formatSettings.skipBlankLines"
            render={({ field: { onChange, value } }) => (
              <div className={styles.checkbox}>
                <Checkbox
                  checked={value}
                  onChange={() => onChange(!value)}
                  testId="skip-blank-checkbox"
                />
                <span className={styles.row__label}>
                  {t("wizard.format_data.skip_blank_lines")}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

// todo: maybe remove parquet settings, as they are not supported atm
const CustomParquetSettings = (props: {
  form: UseFormReturn<FormatingFields>;
}) => {
  const { form } = props;
  const { control } = form;
  const { t } = useTranslation();
  return (
    <div className={styles.settings}>
      <div className={styles.row}>
        <div className={styles.checkboxes__column}>
          <Controller
            control={control}
            name="formatSettings.allowColumnMismatch"
            render={({ field: { onChange, value } }) => (
              <div className={styles.checkbox}>
                <Checkbox
                  checked={value}
                  onChange={() => onChange(!value)}
                  testId="parquet-allow-column-missmatch-checkbox"
                />
                <span className={styles.row__label}>
                  {t("wizard.format_data.allow_column_missmatch")}
                </span>
              </div>
            )}
          />
          <Controller
            control={control}
            name="formatSettings.autoCreate"
            render={({ field: { onChange, value } }) => (
              <div className={styles.checkbox}>
                <Checkbox
                  checked={value}
                  onChange={() => onChange(!value)}
                  testId="auto-create-checkbox"
                />
                <span className={styles.row__label}>
                  {t("wizard.format_data.auto_create")}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const settingsByType = {
  csv: CustomCSVSettings,
  tsv: CustomCSVSettings,
  parquet: CustomParquetSettings,
};

type FormatSettingsProps = {
  form: UseFormReturn<FormatingFields>;
};

export const FormatSettings = (props: FormatSettingsProps) => {
  const { form } = props;
  const { control, watch, setValue } = form;
  const extension = watch("extension");

  const CustomFormatSettings = settingsByType[extension] || (() => null);

  return ["csv", "tsv"].includes(extension) ? (
    <div className={styles.formatTab}>
      <Controller
        control={control}
        name="useDefaultFormatSettings"
        render={({ field: { onChange, value } }) => (
          <div className={styles.toggle}>
            <span className={styles.toggle__label}>Use default formatting</span>
            <Toggle
              size="sm"
              dataTestId="use-default-formatting"
              checked={value}
              classes={{ wrapper: styles.toggleWrapper }}
              onChange={checked => {
                if (checked) {
                  setValue("formatSettings", defaultFormatingByType[extension]);
                }
                onChange(checked);
              }}
            />
          </div>
        )}
      />

      {!watch("useDefaultFormatSettings") && (
        <CustomFormatSettings form={form} />
      )}
    </div>
  ) : null;
};
