import { useTranslation } from "react-i18next";
import { Formatter } from "utils/helpers/Format";

import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";

import { getStatisticsRowData } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentStatistics/QueryStatementsTable/StatisticsRow";
import { QueryStatement } from "pages/DevelopWorkspace/workspace.types";

import styles from "./RunningQuery.module.scss";

interface RunningQueryProps {
  engine: WorkspaceEngine;
  queryStatement: QueryStatement;
}

const RunningQuery = (props: RunningQueryProps) => {
  const { queryStatement, engine } = props;
  const { t } = useTranslation();

  const renderMessage = () => {
    if (engine.status === WorkspaceEngineStatus.STARTING) {
      return (
        <div className={styles.message}>
          {t("running_query.engine_starting")}
        </div>
      );
    }

    if (engine.status !== WorkspaceEngineStatus.RUNNING) {
      return null;
    }

    if (engine.engineName === "system") {
      return (
        <div className={styles.richMessage}>
          <div className={styles.message}>
            {t("running_query.executing_query")}.
          </div>
        </div>
      );
    }

    const stats = getStatisticsRowData(queryStatement);

    const parsedElapsedTime = Formatter.secondsToTimeString(
      stats.executionTimeSec,
      false,
      false,
      true
    );

    const scanned = Formatter.bytesFormatter(stats.bytesRead).formattedString;

    const rowsPerSec = Formatter.nFormatter(stats.rowsPerSec, 2);

    return (
      <div className={styles.richMessage}>
        <div className={styles.message}>
          {t("running_query.executing_query")}
        </div>
        <div className={styles.divider} />
        <div className={styles.progressContainer}>
          <div className={styles.stat}>
            <div className={styles.label}>
              {t("running_query.stats.duration")}
            </div>
            <div className={styles.value}>{parsedElapsedTime}</div>
          </div>

          <div className={styles.stat}>
            <div className={styles.label}>
              {t("running_query.stats.scanned")}
            </div>
            <div className={styles.value}>{scanned}</div>
          </div>

          <div className={styles.stat}>
            <div className={styles.label}>
              {t("running_query.stats.rows_per_sec")}
            </div>
            <div className={styles.value}>{rowsPerSec}</div>
          </div>
        </div>
      </div>
    );
  };

  return <div className={styles.runningQuery}>{renderMessage()}</div>;
};

export { RunningQuery };
