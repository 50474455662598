import classNames from "classnames";

import EmptyState from "./EmptyState.svg?react";

import styles from "./styles.module.scss";

interface CandidatesEmptyStateProps {
  testId: string;
  className?: string;
  message: string;
}

export const CandidatesEmptyState = (props: CandidatesEmptyStateProps) => {
  const { testId, className, message } = props;
  return (
    <div
      className={classNames(styles.empty, className)}
      data-testid={testId}
    >
      <EmptyState />
      <div className={styles.empty__message}>{message}</div>
    </div>
  );
};
