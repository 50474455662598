import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";

import { authService } from "./index";

export const useUser = () => {
  const { data } = useSuspenseQuery({
    queryKey: [ReactQueryKeysAccount.user],
    queryFn: () => {
      return authService.auth0client.getUser();
    },
  });
  return data || {};
};
