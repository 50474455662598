import { isMac } from "services/browser";

export const getShortcutModifiers = () => {
  const ctrlKey = isMac ? "Cmd" : "Ctrl";
  const altKey = isMac ? "Opt" : "Alt";
  const shiftKey = isMac ? "Opt" : "Shift";
  return {
    ctrlKey,
    altKey,
    shiftKey,
  };
};
