import cn from "classnames";

import styles from "./OutputSubPanel.module.scss";

interface OutputSubPanelProps {
  body: JSX.Element;
  controls: JSX.Element;
  className?: string;
}

const OutputSubPanel = (props: OutputSubPanelProps) => {
  const { body, controls, className } = props;

  return (
    <div className={cn(styles.outputSubPanel, className)}>
      <div className={styles.body}>{body}</div>
      <div className={styles.controls}>{controls}</div>
    </div>
  );
};

export default OutputSubPanel;
