import classNames from "classnames";
import { Suspense, useEffect, useState } from "react";
import { Resizable, ResizeCallbackData } from "react-resizable";

import LoadingOverlap from "components/LoadingOverlap";

import { useLayoutToggles } from "../LayoutToggles/context";
import { DatabasesPanel } from "./DatabasesPanel/DatabasesPanel";
import { EnginesPanel } from "./EnginesPanel/EnginesPanel";
import { Header } from "./Header";
import { SecurityPanel } from "./SecurityPanel/SecurityPanel";
import { LeftSidebarTabs } from "./types";

import styles from "./styles.module.scss";

const tabPanels = {
  [LeftSidebarTabs.DATABASES]: DatabasesPanel,
  [LeftSidebarTabs.ENGINES]: EnginesPanel,
  [LeftSidebarTabs.SECURITY]: SecurityPanel,
};

const LEFT_MAX_SIDEBAR = 500;
const LEFT_MIN_SIDEBAR = 320;
const LEFT_DEFAULT_SIDEBAR = 400;
const ENGINES_EXPANDED_WIDTH = 600;

const LeftSidebar = () => {
  const [isResizing, setIsResizing] = useState(false);
  const [tab, setTab] = useState(LeftSidebarTabs.DATABASES);

  const { layout, setLayout } = useLayoutToggles();

  const [width, setWidth] = useState(
    layout.leftSidebar?.width || LEFT_DEFAULT_SIDEBAR
  );

  useEffect(() => {
    if (tab === LeftSidebarTabs.ENGINES && layout.engines?.expanded) {
      setWidth(ENGINES_EXPANDED_WIDTH);
    } else {
      setWidth(layout.leftSidebar?.width || LEFT_DEFAULT_SIDEBAR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.engines?.expanded, tab]);

  const TabPanel = tabPanels[tab];

  const maxConstraint = layout.engines?.expanded
    ? ENGINES_EXPANDED_WIDTH
    : LEFT_MAX_SIDEBAR;

  const minConstraint = layout.engines?.expanded
    ? ENGINES_EXPANDED_WIDTH
    : LEFT_MIN_SIDEBAR;

  const handleResize = (
    event: React.SyntheticEvent,
    { size }: ResizeCallbackData
  ) => {
    if (layout.engines?.expanded) {
      return;
    }
    if (!isResizing) {
      setIsResizing(true);
    }
    setWidth(size.width);
  };

  const handleResizeStop = (
    event: React.SyntheticEvent,
    data: ResizeCallbackData
  ) => {
    if (layout.engines?.expanded) {
      return;
    }
    if (isResizing) {
      setIsResizing(false);
    }
    setLayout({
      ...layout,
      leftSidebar: {
        ...layout.leftSidebar,
        width: data.size.width,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <Resizable
        className={classNames(styles.drawer, {
          [styles.isResizing]: isResizing,
          [styles.hidden]: !layout.leftSidebar.expanded,
        })}
        width={width}
        height={window.innerHeight}
        onResize={handleResize}
        onResizeStop={handleResizeStop}
        minConstraints={[minConstraint, LEFT_DEFAULT_SIDEBAR]}
        maxConstraints={[maxConstraint, LEFT_MAX_SIDEBAR]}
        resizeHandles={["e"]}
        handle={
          <span
            data-testid="aqa-resize-handle-left"
            className={classNames(styles.customHandle, styles.customHandleW)}
          />
        }
      >
        <div style={{ width }}>
          <Header
            activeTab={tab}
            setTab={setTab}
          />

          <Suspense fallback={<LoadingOverlap isLoading />}>
            <TabPanel />
          </Suspense>
        </div>
      </Resizable>
    </div>
  );
};

export default LeftSidebar;
