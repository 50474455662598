import classNames from "classnames";

import { MapExistingTableStep, WizardDataType } from "../../types";
import { Header } from "./Header";
import { Row } from "./Row";
import { HeaderColumn } from "./types";

import styles from "../styles.module.scss";

type Props = {
  wizardData: WizardDataType;
  data: MapExistingTableStep["data"];
  indexes: number[];
  columns: HeaderColumn[];
  visibleColumns: string[];
  onChangeSource: (index: number, sourceName: string) => void;
  errors: Record<string, boolean>;
};

export const ExistingTable = (props: Props) => {
  const {
    wizardData,
    data,
    columns,
    visibleColumns,
    onChangeSource,
    indexes,
    errors,
  } = props;

  return (
    <div className={classNames(styles.table, styles.table__existing)}>
      <div className={styles.table__inner}>
        <Header columns={columns} />
        <div className={styles.list}>
          {data.map((row, index) => {
            return (
              <Row
                errors={errors}
                row={row}
                testId={`map-data-row-${index}`}
                onChangeSource={onChangeSource}
                visibleColumns={visibleColumns}
                wizardData={wizardData}
                index={index}
                indexes={indexes}
                key={index} // eslint-disable-line react/no-array-index-key
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
