export const INIT = "INIT";
export const DRAG_FINISH = "DRAG_FINISH";
export const ZOOM = "ZOOM";
export const TRANSFORM = "TRANSFORM";
export const FIT_MINIMAP = "FIT_MINIMAP";
export const CLICK_MINIMAP = "CLICK_MINIMAP";
export const DRAG_MINIMAP = "DRAG_MINIMAP";
export const DRAG_MINIMAP_FINISH = "DRAG_MINIMAP_FINISH";
export const TOGGLE_MINIMAP = "TOGGLE_MINIMAP";
export const FIT_CENTER = "FIT_CENTER";
export const TOGGLE_EXPAND = "TOGGLE_EXPAND";
export const RESTRICT_MINIMAP = "RESTRICT_MINIMAP";
export const SCROLL = "SCROLL";
