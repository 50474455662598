import { Select, SelectProps } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import ChevronDown from "assets/icons/ChevronDown.svg?react";

import { CustomMenu } from "./CustomMenu";
import { EmptyState } from "./EmptyState";
import { MenuWithSearch } from "./MenuWithSearch";
import { MIN_WIDTH, SearchOptions } from "./types";
import { useKeyboardFocus } from "./useKeyboardFocus";

import styles from "./Select.module.scss";

export interface InitialConfig {
  initialOpen?: boolean;
  initialDelay?: number;
  onDone?: () => void;
}

interface Props extends SelectProps {
  width?: "MD";
  paperWidth?: "MD";
  initialOpenConfig?: InitialConfig | null;
  searchOptions?: SearchOptions;
  renderMenu?: (closeMenu: () => void) => React.ReactNode;
  renderMenuAutoWidth?: boolean;
  testId?: string;
  pageSize?: number;
  customMenuProps?: any;
  placeholderIcon?: React.ReactNode;
  onClose?: () => void;
  itemsListTestId?: string;
}

const renderChildren = (props: {
  searchQuery: string;
  children: React.ReactElement[];
}) => {
  const { searchQuery, children } = props;

  if (!searchQuery || !children) {
    return children || [];
  }

  const filteredItems = children
    .filter(item => {
      return item?.props?.searchOptions?.textForSearch
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
    })
    .map(item => {
      return {
        ...item,
        props: {
          ...item.props,
          searchOptions: {
            ...item.props?.searchOptions,
            searchQuery,
          },
        },
      };
    });

  return filteredItems;
};

const renderValue =
  ({
    placeholder,
    children,
  }: {
    placeholder?: string;
    children: React.ReactElement[];
  }) =>
  (value: unknown) => {
    if (!value) {
      return <span data-testid="placeholder">{placeholder}</span>;
    }

    const item = children.find(item => item?.props?.value === value);

    if (item) {
      return item.props.children;
    }

    return value;
  };

const renderValueWithIcon =
  ({
    placeholderIcon,
    children,
  }: {
    placeholderIcon: React.ReactNode;
    children: React.ReactElement[];
  }) =>
  (value: unknown) => {
    if (!value) return;

    const item = children.find(item => item.props.value === value);

    if (item) {
      return (
        <div className={styles.selectedValueWithIcon}>
          <div className={styles.placeholderIcon}>{placeholderIcon}</div>
          {item.props.children}
        </div>
      );
    }

    return value;
  };

const CustomSelect: React.FC<Props> = props => {
  const {
    className,
    width,
    paperWidth,
    classes = {},
    MenuProps = {},
    searchOptions,
    renderMenu,
    renderMenuAutoWidth,
    initialOpenConfig,
    testId,
    children,
    placeholder,
    pageSize,
    customMenuProps = {},
    placeholderIcon,
    onClose,
    onOpen,
    multiple,
    itemsListTestId,
    variant = "standard",
    ...restProps
  } = props;
  const customizedMenuProps = { ...MenuProps };

  if (!customizedMenuProps.anchorEl) {
    customizedMenuProps.anchorOrigin = {
      vertical: "bottom",
      horizontal: "center",
    };
  }

  const selectRef = useRef<HTMLElement | null>(null);
  const menuMinWidth = useRef<number>(0);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { listRef, searchRef, handleKeyDown } = useKeyboardFocus(pageSize);

  const setInitialWidth = () => {
    if (renderMenuAutoWidth || searchOptions) {
      const { width = 0 } = selectRef.current?.getBoundingClientRect() || {};
      menuMinWidth.current = width;
    }
  };

  const dependencies = useRef({
    setInitialWidth,
  });

  useEffect(() => {
    dependencies.current = {
      setInitialWidth,
    };
  });

  useEffect(() => {
    if (initialOpenConfig?.initialOpen) {
      setTimeout(() => {
        const { setInitialWidth } = dependencies.current;
        setInitialWidth();
        setOpen(!!initialOpenConfig.initialOpen);
        onOpen && onOpen({} as any);

        initialOpenConfig?.onDone?.();
      }, initialOpenConfig.initialDelay);
    }
  }, [initialOpenConfig, onOpen]);

  useEffect(() => {
    if (open === false) {
      setSearchQuery("");
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleOpen = (event: React.SyntheticEvent) => {
    const { setInitialWidth } = dependencies.current;
    setInitialWidth();
    props.onOpen && props.onOpen(event);
    setOpen(true);
  };

  const handleChangeSearch = (query: string) => {
    setSearchQuery(query);
  };

  const onChange = (event: any, child?: React.ReactNode) => {
    if (event.target.value !== null && event.target.value !== undefined) {
      if (
        !props.multiple &&
        !(child as React.ReactElement).props.disableClose
      ) {
        handleClose();
      }

      if (props.onChange) {
        props.onChange(event, child);
      }
    }
  };

  const menuProps = {
    ...customizedMenuProps,
    classes: {
      ...customizedMenuProps?.classes,
      paper: classNames(
        styles.paper,
        styles[`paperWidth${paperWidth}`],
        customizedMenuProps?.classes?.paper
      ),
      list: classNames(styles.list, styles[`listWidth${width}`]),
    },
    open: renderMenu || searchOptions ? false : open,
    onClose: handleClose,
    autoFocus: !searchOptions,
  };

  if (renderMenuAutoWidth) {
    Object.assign(menuProps, {
      PaperProps: {
        style: {
          minWidth: menuMinWidth.current,
          width: Math.max(menuMinWidth.current, MIN_WIDTH),
        },
      },
    });
  }

  const childrenItems = renderChildren({
    searchQuery,
    children: children as React.ReactElement[],
  });

  const renderMenuWithSearch = () =>
    searchOptions ? (
      <MenuWithSearch
        handleClose={handleClose}
        open={open}
        childrenItems={childrenItems}
        searchQuery={searchQuery}
        searchOptions={searchOptions}
        handleChangeSearch={handleChangeSearch}
        multiple={multiple}
        value={restProps.value}
        onChange={onChange}
        menuMinWidth={menuMinWidth.current}
        paperWidth={paperWidth}
        selectRef={selectRef}
        listRef={listRef}
        searchRef={searchRef}
        itemsListTestId={itemsListTestId}
      />
    ) : null;

  const menuElement = renderMenu ? (
    <CustomMenu
      anchorEl={selectRef.current}
      handleClose={handleClose}
      open={open}
      renderMenu={renderMenu}
      {...customMenuProps}
    />
  ) : (
    renderMenuWithSearch()
  );

  const childrenElement = childrenItems.length ? (
    childrenItems
  ) : (
    <EmptyState searchOptions={searchOptions} />
  );

  const handleRenderValue = (placeholderIcon: React.ReactNode) => {
    return placeholderIcon
      ? renderValueWithIcon({
          placeholderIcon,
          children: children as React.ReactElement[],
        })
      : renderValue({
          placeholder,
          children: children as React.ReactElement[],
        });
  };

  return (
    <div
      className={styles.container}
      onKeyDown={handleKeyDown}
    >
      <Select
        ref={selectRef}
        data-testid={testId}
        className={classNames(styles.selectRoot, className, {
          [styles.disabled]: props.disabled,
          [styles.open]: open,
          [styles.outlined]: variant === "outlined",
        })}
        classes={{
          icon: classNames(styles.icon, classes?.icon),
          standard: classNames(styles.select, classes?.standard),
          select: classNames(classes?.select),
        }}
        MenuProps={menuProps}
        renderValue={
          Array.isArray(children)
            ? handleRenderValue(placeholderIcon)
            : undefined
        }
        IconComponent={ChevronDown}
        multiple={multiple}
        {...restProps}
        onChange={onChange}
        open={open}
        onOpen={handleOpen}
        variant={variant}
      >
        {childrenElement}
      </Select>
      {menuElement}
    </div>
  );
};

export default CustomSelect;
