import { zodResolver } from "@hookform/resolvers/zod";
import { Suspense, useState } from "react";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import z from "zod";

import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { useTableNames } from "services/databases/useTableNames";
import { systemEngineEnvironment } from "services/environment/systemEngine";

import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";
import { TextInput } from "components/TextInput/TextInput";
import { Step } from "components/Wizard/Step/Step";

import {
  CREATE_NEW_DATABASE,
  CREATE_NEW_TABLE,
  SelectDestinationStep,
} from "../types";

import styles from "./styles.module.scss";

const tableNameRegex = /^[a-zA-Z0-9_-]+$/;

const selectExistedTable = z.object({
  type: z.literal(null),
  database: z.string(),
  table: z.string(),
  createPayload: z
    .object({
      databaseName: z.string().optional(),
      tableName: z.string().optional(),
    })
    .optional(),
});

const createDatabaseSchema = z.object({
  type: z.literal(CREATE_NEW_DATABASE),
  createPayload: z.object({
    databaseName: z.string().min(2),
    tableName: z.string().regex(tableNameRegex, {
      message: "wizard.select_destination.table_name_validation",
    }),
  }),
});

const createTableSchema = z.object({
  type: z.literal(CREATE_NEW_TABLE),
  database: z.string(),
  createPayload: z.object({
    tableName: z.string().regex(tableNameRegex, {
      message: "wizard.select_destination.table_name_validation",
    }),
    databaseName: z.string().optional(),
  }),
});

const schemaConditions = z.discriminatedUnion("type", [
  selectExistedTable,
  createDatabaseSchema,
  createTableSchema,
]);

type Fields = z.infer<typeof schemaConditions>;

type Props = {
  onClose: () => void;
  initialData: SelectDestinationStep;
  onSubmit: (data: SelectDestinationStep) => void;
  onPrevStep: () => void;
  activeStepIndex: number;
};

const getTables = async (database: string) => {
  const result = await systemEngineEnvironment.execute<{
    tableName: string;
  }>(
    `select tb.table_name, tb.table_type from information_schema.tables tb where tb.table_type IN ('BASE TABLE', 'FACT', 'DIMENSION')`,
    { database }
  );
  const [response] = result;
  return response.rows || [];
};

const SelectDatabase = (props: { form: UseFormReturn<Fields> }) => {
  const { form } = props;
  const { watch, control, setValue } = form;
  const databases = useDatabasesNames();
  const { t } = useTranslation();
  const database = watch("database");
  const type = watch("type");

  const createNew =
    type === CREATE_NEW_DATABASE || (!database && type === CREATE_NEW_TABLE);
  const items = [
    <ContextMenuItem
      value={CREATE_NEW_DATABASE}
      text="Create new database"
      checked={createNew}
      checkedIconPlaceholder
      key={CREATE_NEW_DATABASE}
      testId="destination-new-database-item"
      skipFilter
    />,
    <ContextMenuDivider key="divider" />,
    ...databases.map((item, index) => {
      const { catalogName } = item;
      return (
        <ContextMenuItem
          value={catalogName}
          key={catalogName}
          checked={catalogName === database}
          checkedIconPlaceholder
          testId={`destination-database-item-${index}`}
          text={catalogName}
        />
      );
    }),
  ];
  return (
    <div className={styles.row}>
      <div className={styles.label}>
        {t("wizard.select_destination.database_name")}
      </div>
      <Controller
        control={control}
        name="database"
        render={({ field: { onChange, value } }) => (
          <OutlinedSelect
            testId="wizard-database-select"
            wrapperClassName={styles.inputRoot}
            initialSelected={[value]}
            searchOptions={{ searchPlaceholder: "Search databases" }}
            renderValue={([item]) => {
              if (createNew) {
                return "Create new database";
              }
              return item;
            }}
            onSelect={([name]) => {
              setValue("createPayload", { databaseName: "", tableName: "" });
              if (name === CREATE_NEW_DATABASE) {
                setValue("type", CREATE_NEW_DATABASE);
                onChange(null);
              } else {
                setValue("type", CREATE_NEW_TABLE);
                setValue("table", "");
                onChange(name);
              }
            }}
          >
            {items}
          </OutlinedSelect>
        )}
      />
    </div>
  );
};

const SelectTable = (props: { form: UseFormReturn<Fields> }) => {
  const { form } = props;
  const { watch, setValue } = form;
  const { t } = useTranslation();
  const table = watch("table");
  const type = watch("type");

  // use when existing column map date step will be available
  const database = watch("database");
  const tables = useTableNames({ database });

  const items = [
    <ContextMenuItem
      value={CREATE_NEW_TABLE}
      text="Create new table"
      checked={type === CREATE_NEW_TABLE}
      checkedIconPlaceholder
      key={CREATE_NEW_TABLE}
      testId="destination-new-table-item"
    />,
    <ContextMenuDivider key="divider" />,
    ...tables.map((item, index) => {
      const { tableName } = item;
      return (
        <ContextMenuItem
          value={tableName}
          key={tableName}
          checked={tableName === table}
          checkedIconPlaceholder
          testId={`destination-table-item-${index}`}
          text={tableName}
        />
      );
    }),
  ];

  return (
    <>
      <div className={styles.row}>
        <div className={styles.label}>
          {t("wizard.select_destination.table_name")}
        </div>
        <OutlinedSelect
          testId="table-select"
          wrapperClassName={styles.inputRoot}
          controlledValue={[table]}
          searchOptions={{ searchPlaceholder: "Search tables" }}
          renderValue={([item]) => {
            if (type === CREATE_NEW_TABLE) {
              return "Create new table";
            }
            return item;
          }}
          onSelect={([name]) => {
            setValue("createPayload", { databaseName: "", tableName: "" });
            if (name === CREATE_NEW_TABLE) {
              setValue("type", CREATE_NEW_TABLE);
              setValue("table", "");
            } else {
              setValue("type", null);
              setValue("table", name);
            }
          }}
        >
          {items}
        </OutlinedSelect>
      </div>
    </>
  );
};

export const SelectDestination = (props: Props) => {
  const { onClose, onSubmit, initialData, activeStepIndex, onPrevStep } = props;
  const { t } = useTranslation();

  const form = useForm<Fields>({
    resolver: zodResolver(schemaConditions),
    defaultValues: initialData as Fields,
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
  } = form;

  const [isLoading, setIsLoading] = useState(false);
  const type = watch("type");
  const database = watch("database");
  const databases = useDatabasesNames();

  const onFormSubmit = async (data: Fields) => {
    try {
      setIsLoading(true);
      if (
        data.createPayload?.databaseName &&
        databases.find(
          database => database.catalogName === data.createPayload?.databaseName
        )
      ) {
        setError("createPayload.databaseName", {
          message: "Database name already exists",
        });
        return;
      }
      if (type === CREATE_NEW_TABLE && data.createPayload?.tableName) {
        const tables = await getTables(database);
        if (
          tables.find(
            table => table.tableName === data.createPayload?.tableName
          )
        ) {
          setError("createPayload.tableName", {
            message: "Table name already exists",
          });
          return;
        }
      }
      onSubmit(data);
    } finally {
      setIsLoading(false);
    }
  };

  const body = (
    <div className={styles.rows}>
      <SelectDatabase form={form} />
      {database && (
        <Suspense fallback={null}>
          <SelectTable form={form} />
        </Suspense>
      )}

      {(type === CREATE_NEW_DATABASE ||
        (!database && type === CREATE_NEW_TABLE)) && (
        <div className={styles.formControl}>
          <TextInput
            inputRootClassName={styles.inputRoot}
            label={t("wizard.select_destination.create_database_name")}
            testId="database-name-field"
            {...register("createPayload.databaseName")}
            error={!!errors?.createPayload?.databaseName}
            helperText={t(errors?.createPayload?.databaseName?.message ?? "")}
          />
        </div>
      )}
      {(type === CREATE_NEW_DATABASE || type === CREATE_NEW_TABLE) && (
        <div className={styles.formControl}>
          <TextInput
            inputRootClassName={styles.inputRoot}
            label={t("wizard.select_destination.create_table_name")}
            testId="table-name-field"
            {...register("createPayload.tableName")}
            error={!!errors?.createPayload?.tableName}
            helperText={t(errors?.createPayload?.tableName?.message ?? "")}
          />
        </div>
      )}
    </div>
  );

  return (
    <Step
      title={t("wizard.select_destination.title")}
      subtitle={t("wizard.select_destination.subtitle")}
      body={body}
      disabledSubmit={type === undefined}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
      isLoading={isLoading}
    />
  );
};
