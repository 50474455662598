import { Divider } from "@mui/material";
import classNames from "classnames";
import { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import z from "zod";

import { useEngineClusterFlag } from "featureFlags/hooks/useEngineClusterFlag";
import { useLargeInstanceTypes } from "featureFlags/hooks/useLargeInstanceTypes";
import { CreateEngineInput } from "services/engines/createWorkspaceEngine";
import { WorkspaceEngineType } from "services/engines/engine.types";

import {
  Collapsible,
  CollapsibleHeader,
} from "components/Collapsible/Collapsible";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { NumberInput } from "components/NumberInput/NumberInput";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";
import { TextInput } from "components/TextInput/TextInput";
import Toggle from "components/Toggle";

import { InputDisabledContext } from "../InputState/InputDisabledContext";
import { AutoStop } from "./AutoStop/AutoStop";
import { nodeTypeOptions } from "./constants";

import styles from "./styles.module.scss";

export const initialEngine: CreateEngineInput = {
  engineName: "",
  type: WorkspaceEngineType.M,
  autoStop: "" + 20,
  initiallyStopped: true,
  autoStart: true,
  nodes: 2,
  clusters: 1,
};

const largeInstanceTypes = [WorkspaceEngineType.L, WorkspaceEngineType.XL];

export const engineCost = {
  [WorkspaceEngineType.S]: 8,
  [WorkspaceEngineType.M]: 16,
  [WorkspaceEngineType.L]: 32,
  [WorkspaceEngineType.XL]: 64,
  [WorkspaceEngineType.None]: 0,
};

export const schema = z.object({
  engineName: z
    .string()
    .max(64, { message: "Must be 64 or fewer characters long" })
    .regex(/^[0-9a-zA-Z_-]+$/, {
      message: "Use letters, numbers, underscore",
    })
    .regex(/^[^0-9_-]/, {
      message: "Name can not start with number or underscore",
    })
    .regex(/[^_-]$/, {
      message: "Name can not end with underscore or hypen",
    }),
  // description: z
  // .string()
  // .max(1024, { message: "Must be 1024 or fewer characters long" }),
  type: z.nativeEnum(WorkspaceEngineType).default(WorkspaceEngineType.M),
  autoStart: z.boolean(),
  autoStop: z.string().default("20"),
  initiallyStopped: z.boolean(),
  nodes: z.number().max(128).default(2),
  clusters: z.number().max(10).default(1),
});

export type Fields = z.infer<typeof schema>;

// todo: props should not be any
export const CreateEngine = (props: any) => {
  const {
    register,
    errors,
    control,
    showStartEngineImmediately = true,
  } = props;
  const isDisabled = useContext(InputDisabledContext);
  const [advancedSettings, setAdvancedSettings] = useState(false);

  const { t } = useTranslation();

  const showEngineClusterFlag = useEngineClusterFlag();
  const showLargeInstanceTypes = useLargeInstanceTypes();

  const instanceTypesOptions = showLargeInstanceTypes
    ? nodeTypeOptions
    : nodeTypeOptions.filter(
        nodeType => !largeInstanceTypes.includes(nodeType.value)
      );

  const handleToggleAdvancedSettings = () => {
    setAdvancedSettings(advanced => !advanced);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.label}>{t("workspace.create_engine.name")}</div>
        <div className={styles.inputContainer}>
          <TextInput
            inputRootClassName={styles.nameInput}
            testId="engine-name-input"
            {...register("engineName")}
            error={!!errors?.engineName}
            helperText={errors?.engineName?.message}
            required
          />
        </div>
      </div>

      {/* <div className={styles.row}>
          <div className={styles.label}>
          {t("workspace.create_engine.description")}
          </div>
          <div className={styles.inputContainer}>
          <TextInput
          className={styles.nameInput}
          {...register("description")}
          error={!!errors?.description}
          helperText={errors?.description?.message}
          fullWidth
          />
          </div>
          </div> */}

      <div className={styles.row}>
        <div className={styles.label}>
          {t("workspace.create_engine.node_type")}
        </div>
        <div className={styles.inputContainer}>
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value } }) => (
              <OutlinedSelect
                testId="node-type-select"
                className={styles.select}
                initialSelected={[value]}
                onSelect={items => {
                  const [type] = items;
                  onChange(type);
                }}
                renderValue={items => {
                  const [item] = items;
                  const option = nodeTypeOptions.find(
                    option => option.value === item
                  );
                  if (!option) {
                    return "";
                  }
                  return <>{t(option.name)}</>;
                }}
              >
                {instanceTypesOptions.map(option => {
                  const { value, name } = option;
                  const price = engineCost[value];
                  return (
                    <ContextMenuItem
                      value={`${value}`}
                      key={name}
                      text={t(name)}
                      testId={`node-item-${name}`}
                      secondaryText={`${price} FBU / hour`}
                    />
                  );
                })}
              </OutlinedSelect>
            )}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={classNames(styles.label, styles.multiline)}>
          <div>{t("workspace.create_engine.nodes")}</div>
          <div className={styles.label__description}>
            {t("workspace.create_engine.nodes_description")}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <Controller
            control={control}
            name="nodes"
            render={({ field: { name, onChange, value } }) => (
              <NumberInput
                disabled={isDisabled}
                name={name}
                testId="number-of-nodes-input"
                value={Number(value)}
                onChange={input => onChange(input)}
                maxValue={128}
                errorMessage={t("validation.engine.nodes")}
              />
            )}
          />
        </div>
      </div>
      {showEngineClusterFlag && (
        <div className={styles.row}>
          <div className={classNames(styles.label, styles.multiline)}>
            <div>{t("workspace.create_engine.clusters")}</div>
            <div className={styles.label__description}>
              {t("workspace.create_engine.clusters_description")}
            </div>
          </div>

          <div className={styles.inputContainer}>
            <Controller
              control={control}
              name="clusters"
              render={({ field: { name, onChange, value } }) => (
                <NumberInput
                  disabled={isDisabled}
                  name={name}
                  value={Number(value)}
                  onChange={input => onChange(input)}
                  maxValue={10}
                  errorMessage={t("validation.engine.clusters")}
                />
              )}
            />
          </div>
        </div>
      )}
      <Divider />
      <div>
        <CollapsibleHeader
          open={advancedSettings}
          testId="engine-modal-collapsible-header"
          onClick={handleToggleAdvancedSettings}
          title="Advanced settings"
        />
        <Collapsible open={advancedSettings}>
          <div className={styles.advanced}>
            <div className={styles.row}>
              <div className={classNames(styles.label, styles.multiline)}>
                <div>{t("workspace.create_engine.autostart")}</div>
                <div className={styles.label__description}>
                  {t("workspace.create_engine.autostart_description")}
                </div>
              </div>
              <div className={styles.inputContainer}>
                <Controller
                  control={control}
                  name="autoStart"
                  render={({ field: { onChange, value } }) => (
                    <Toggle
                      size="sm"
                      dataTestId="auto-start-toggle"
                      checked={value}
                      onChange={onChange}
                      classes={{ wrapper: styles.toggleWrapper }}
                    />
                  )}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={classNames(styles.label, styles.multiline)}>
                <div>{t("workspace.create_engine.autostop")}</div>
                <div className={styles.label__description}>
                  {t("workspace.create_engine.autostop_description")}
                </div>
              </div>
              <div className={styles.inputContainer}>
                <Controller
                  control={control}
                  name="autoStop"
                  render={({ field: { onChange, value } }) => (
                    <AutoStop
                      autoStop={value}
                      handleChange={(name, value) => onChange(`${value}`)}
                    />
                  )}
                />
              </div>
            </div>
            {!props.engine && showStartEngineImmediately && (
              <div className={styles.row}>
                <div className={styles.label}>
                  {t("workspace.create_engine.immediate_start")}
                </div>
                <div className={styles.inputContainer}>
                  <Controller
                    control={control}
                    name="initiallyStopped"
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        size="sm"
                        checked={!value}
                        dataTestId="initially-stopped-toggle"
                        onChange={checked => {
                          onChange(!checked);
                        }}
                        classes={{ wrapper: styles.toggleWrapper }}
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </Collapsible>
      </div>
    </>
  );
};
