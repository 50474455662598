import { FileTree } from "services/fileObjects/getFilesByPath";

import ArrowRightShort from "assets/icons/v2/ArrowRightShort.svg?react";

import Checkbox from "components/Checkbox";

import styles from "./styles.module.scss";

type Props = {
  item: FileTree;
  isSelected: boolean;
  toggleSelectItem: (path: string) => void;
  index: number;
};

export const ColumnAction = (props: Props) => {
  const { item, isSelected, toggleSelectItem, index } = props;

  if (item.objectType === "file") {
    return (
      <div className={styles.column__action}>
        <Checkbox
          onChange={() => {
            toggleSelectItem(item.objectName);
          }}
          checked={isSelected}
          testId={`file-row-checkbox-${index}`}
        />
      </div>
    );
  }
  return (
    <div className={styles.column__action}>
      <ArrowRightShort />
    </div>
  );
};
