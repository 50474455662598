import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { useScripts } from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";

import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";

interface Modal {
  title: string;
  message: string;
  confirmButtonText: string;
  onConfirm: () => void;
}

const useDocumentsActionConfirmation = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<Modal | null>(null);
  const {
    state: { documents },
    actions: { removeDocument, removeAllDocuments },
  } = useDocuments();
  const { state: scriptsState } = useScripts();

  const handleDeleteAllDocuments = () => {
    const someDocumentHasContent = documents.some(document => {
      const script = getDocumentScript(document, scriptsState);

      if (!script) {
        return false;
      }

      if (script.content === "") {
        return false;
      }

      return true;
    });

    if (!someDocumentHasContent) {
      removeAllDocuments();
      return;
    }

    setModal({
      title: t("delete_document_confirmation.all.title"),
      message: t("delete_document_confirmation.all.message"),
      confirmButtonText: t("delete_document_confirmation.all.confirm"),
      onConfirm: () => {
        setModal(null);
        removeAllDocuments();
      },
    });
  };

  const handleDeleteDocument = (documentId: string) => {
    const document = documents.find(doc => doc.id === documentId);

    if (!document) {
      return;
    }

    const script = getDocumentScript(document, scriptsState);

    if (!script) {
      removeDocument(document.id);
      return;
    }

    if (script.content === "") {
      removeDocument(document.id);
      return;
    }

    setModal({
      title: t("delete_document_confirmation.single.title"),
      message: t("delete_document_confirmation.single.message"),
      confirmButtonText: t("delete_document_confirmation.single.confirm"),
      onConfirm: () => {
        setModal(null);
        removeDocument(document.id);
      },
    });
  };

  const renderModal = () => {
    if (!modal) {
      return null;
    }

    return (
      <ConfirmationModal
        onClose={() => {
          setModal(null);
        }}
        onConfirm={modal.onConfirm}
        title={modal.title}
        message={modal.message}
        confirmButtonOptions={{
          text: modal.confirmButtonText,
        }}
      />
    );
  };

  const modalMarkup = renderModal();

  return {
    handleDeleteDocument,
    handleDeleteAllDocuments,
    modalMarkup,
  };
};

export { useDocumentsActionConfirmation };
