import { TFunction } from "i18next";
import _find from "lodash/find";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Minus from "assets/icons/Minus.svg?react";

import CustomAccordion from "components/Accordion";
import { CHANGE_FILTERS } from "components/Datagrid/Filters/actions";

import { FilterModalContext } from "../Context";
import AccordionInput from "./AccordionInput";
import { InnerAccordionSummary } from "./AccordionSummary";

import styles from "./styles.module.scss";

export enum Options {
  GT = "GT",
  GTE = "GTE",
  LT = "LT",
  LTE = "LTE",
  BETWEEN = "BETWEEN",
}

const getNumberOptions = (t: TFunction) => [
  { value: Options.GT, text: t("filters.gt") },
  { value: Options.GTE, text: t("filters.gte") },
  { value: Options.LT, text: t("filters.lt") },
  { value: Options.LTE, text: t("filters.lte") },
  { value: Options.BETWEEN, text: t("filters.between") },
];

const notValidNumber = (value: string) => Number.isNaN(+value) && value !== "-";

interface DefaultInputProps {
  name: string;
  expanded: boolean;
}
const DefaultInput = (props: DefaultInputProps) => {
  const { name, expanded } = props;
  const { dispatch, localFilters } = useContext(FilterModalContext);
  const { filters = {} } = localFilters;
  const initialValue = _find(filters.items, { type: name })?.value ?? "";
  const [inputValue, setInputValue] = useState(initialValue);

  const handleChangeInput = (filterType: string, value: string) => {
    if (notValidNumber(value)) {
      return;
    }

    setInputValue(value);

    const existingItems: { type: string; value: string }[] =
      filters?.items || [];
    const remainingItems = existingItems.filter(
      ({ type }) => type !== filterType
    );
    if (!Number.isNaN(+value)) {
      dispatch({
        type: CHANGE_FILTERS,
        filters: {
          ...filters,
          items: [...remainingItems, { type: filterType, value }],
        },
      });
    }
  };

  return (
    <AccordionInput
      value={inputValue}
      name={name}
      onChange={handleChangeInput}
      expanded={expanded}
    />
  );
};

interface BetweenInputProps {
  name: string;
  expanded: boolean;
}

const BetweenInput = (props: BetweenInputProps) => {
  const { name, expanded } = props;
  const { dispatch, localFilters } = useContext(FilterModalContext);
  const { filters = {} } = localFilters;
  const initialValue = _find(filters.items, { type: name }) ?? {
    left: "",
    right: "",
  };
  const [inputValue, setInputValue] = useState({
    left: initialValue.left || "",
    right: initialValue.right || "",
  });

  const handleChangeInput = (inputName: string, value: string) => {
    if (notValidNumber(value)) {
      return;
    }

    setInputValue((state: any) => {
      return { ...state, [inputName]: value };
    });

    const existingItems: { type: string; value: string }[] =
      filters?.items || [];
    const remainingItems = existingItems.filter(({ type }) => type !== name);
    dispatch({
      type: CHANGE_FILTERS,
      filters: {
        ...filters,
        items: [
          ...remainingItems,
          {
            type: name,
            ...inputValue,
            [inputName]: value,
          },
        ],
      },
    });
  };

  return (
    <div className={styles.betweenInputContainer}>
      <AccordionInput
        value={inputValue.left}
        name="left"
        onChange={handleChangeInput}
        expanded={expanded}
      />
      <div className={styles.minus}>
        <Minus />
      </div>
      <AccordionInput
        value={inputValue.right}
        name="right"
        onChange={handleChangeInput}
        expanded={false}
      />
    </div>
  );
};

const inputComponents = {
  [Options.GT]: DefaultInput,
  [Options.GTE]: DefaultInput,
  [Options.LT]: DefaultInput,
  [Options.LTE]: DefaultInput,
  [Options.BETWEEN]: BetweenInput,
};

export const NumberFilter = () => {
  const [expandedItem, setExpandedItem] = useState<string>("");
  const { t } = useTranslation();
  const { localFilters } = useContext(FilterModalContext);
  const { filters = {} } = localFilters;

  const numberOptions = useMemo(() => getNumberOptions(t), [t]);

  return (
    <div
      className={styles.filter}
      data-testid="filter-number"
    >
      {numberOptions.map(item => {
        const InputComponent = inputComponents[item.value];

        const inputValue =
          _find(filters.items, { type: item.value })?.value ?? "";

        return (
          <CustomAccordion
            key={item.value}
            expanded={item.value === expandedItem}
            customizations={{ noBorder: true }}
            collapsedSummary={
              <InnerAccordionSummary
                testId={`filter-${item.value}`}
                itemText={item.text}
                onClick={() => setExpandedItem(item.value)}
                hasIcon={!!inputValue}
              />
            }
            expandedSummary={
              <InnerAccordionSummary
                hasIcon={!!inputValue}
                itemText={item.text}
                testId={`filter-${item.value}`}
                onClick={() => setExpandedItem("")}
              />
            }
            accordionDetails={
              <InputComponent
                name={item.value}
                expanded={item.value === expandedItem}
              />
            }
          />
        );
      })}
    </div>
  );
};
