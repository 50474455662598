import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Column } from "components/Datagrid/types";
import { VerticalBarsIcon } from "components/Icons";

import { Search } from "../Filters/Search";
import MenuItem from "./MenuItem";
import ShowAllToggle from "./ShowAllToggle";

import styles from "./ColumnFilters.module.scss";

type Props = {
  columns: Column[];
  visibleColumns: string[];
  handleColumnClick: (_c: string) => void;
  toggleShowAllColumns?: () => void;
};

const ColumnFilters = (props: Props) => {
  const { columns, visibleColumns, handleColumnClick, toggleShowAllColumns } =
    props;
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorEl = useRef<HTMLDivElement | null>(null);
  const [columnSearchValue, setColumnSearchValue] = useState("");

  const filteredColumnsBySearch = columnSearchValue.length
    ? columns.filter(
        c =>
          c.name
            .toLocaleLowerCase()
            .includes(columnSearchValue.toLocaleLowerCase()) ||
          (!!c.displayName &&
            t(c.displayName)
              ?.toLocaleLowerCase()
              ?.includes(columnSearchValue.toLocaleLowerCase()))
      )
    : columns;

  return (
    <>
      <div
        className={cn(styles.iconContainer, {
          [styles.active]: menuOpen,
        })}
        ref={menuAnchorEl}
        onClick={() => {
          setMenuOpen(true);
        }}
        data-testid="map-data-filter-columns-toggle"
      >
        <VerticalBarsIcon />
        {visibleColumns.length > 0 && (
          <div className={styles.appliedColumnFilterIndicator} />
        )}
      </div>
      {menuOpen && (
        <Popover
          open={menuOpen}
          anchorEl={menuAnchorEl.current}
          onClose={() => {
            setMenuOpen(false);
          }}
          sx={{ transform: "translate(0px, -5px)" }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div className={styles.columnFiltersPopup}>
            <Search
              classes={{
                root: styles.search,
                search: styles.search_container,
              }}
              placeholder={t("columns.select_columns")}
              value={columnSearchValue}
              onChange={value => setColumnSearchValue(value)}
            />
            {!!toggleShowAllColumns && (
              <ShowAllToggle
                checked={
                  visibleColumns.length === 0 ||
                  visibleColumns.length === columns.length
                }
                text={t("columns.show_all")}
                handleClick={toggleShowAllColumns}
              />
            )}
            <div className={styles.columns}>
              {filteredColumnsBySearch.map((c, index) => (
                <MenuItem
                  name={c.name}
                  title={c.displayName ? t(c.displayName) : c.name}
                  key={c.name}
                  testId={`map-data-filter-column-${index}`}
                  visibleColumns={visibleColumns}
                  handleClick={handleColumnClick}
                />
              ))}
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

export default ColumnFilters;
