import { useSuspenseQuery } from "@tanstack/react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";

export type Index = {
  indexName: string;
  indexType: string;
  tableName: string;
  indexDefinition: string;
};

export const useIndexes = (database: string, tableName: string) => {
  const { data } = useSuspenseQuery<Index[], Error>({
    queryKey: [database, tableName, ReactQueryKeysAccount.indexes],
    queryFn: async () => {
      const result = await systemEngineEnvironment.execute<Index>(
        `select index_name, index_type, table_name, index_definition from information_schema.indexes where table_name = '${tableName}' and index_type = 'primary'`,
        { database }
      );

      const [response] = result;
      return response.rows;
    },
  });
  return data || [];
};
