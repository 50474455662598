import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormRow from "./components/FormRow";
import RoleSelect from "./components/RoleSelect/RoleSelect";

export const Role = (props: {
  control: Control<any>;
  initialRoles?: string[];
}) => {
  const { control, initialRoles = [] } = props;
  const { t } = useTranslation();

  return (
    <FormRow
      label={t("workspace.create_user.role")}
      input={
        <Controller
          control={control}
          name="roles"
          render={({ field: { onChange, value } }) => (
            <RoleSelect
              initialRoles={initialRoles}
              value={value}
              onChange={onChange}
            />
          )}
        />
      }
    />
  );
};
