import { useState } from "react";

import { IngestionWizard } from "components/IngestionWizard/IngestionWizard";

import { WizardDataType } from "../../IngestionWizard/types";

export const useLoadData = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<
    Partial<WizardDataType> | undefined
  >(undefined);

  const openLoadData = (wizardInitialData?: Partial<WizardDataType>) => {
    setInitialData(wizardInitialData);
    setOpen(true);
  };

  const closeCreateUser = () => {
    setOpen(false);
  };

  const loadDataMarkup = open ? (
    <IngestionWizard
      onClose={closeCreateUser}
      wizardInitialData={initialData}
    />
  ) : null;

  return {
    loadDataMarkup,
    openLoadData,
  };
};
