import React, { useCallback, useRef, useState } from "react";

import { MIN_COLUMN_WIDTH } from "../constant";

type ResizingColumn = {
  index: number;
  width: number;
  dragOffset: number;
};

export const useResize = ({
  onResizeFinish,
}: {
  onResizeFinish: (column: any, offset: number) => void;
}) => {
  const [resizingColumn, setResizingColumn] = useState<ResizingColumn | null>(
    null
  );

  const onResizeStart = useCallback((column: any) => {
    setResizingColumn({ ...column, dragOffset: 0 });
  }, []);

  const onResize = useCallback((dragOffset: number) => {
    setResizingColumn(column => {
      if (column) {
        return { ...column, dragOffset };
      }
      return column;
    });
  }, []);

  const handleResizeFinish = useCallback(() => {
    if (resizingColumn) {
      const { width, index, dragOffset } = resizingColumn;
      const newWidth = Math.max(width + dragOffset, MIN_COLUMN_WIDTH);
      onResizeFinish(index, newWidth);
      setResizingColumn(null);
    }
  }, [resizingColumn, onResizeFinish]);

  const resizeFinish = useRef(handleResizeFinish);
  const resizeStart = useRef(onResizeStart);
  resizeFinish.current = handleResizeFinish;
  resizeStart.current = onResizeStart;

  const onPointerDown = useCallback(
    (column: any) => (event: React.PointerEvent<HTMLDivElement>) => {
      if (event.pointerType === "mouse" && event.buttons !== 1) {
        return;
      }

      const { currentTarget, pointerId } = event;
      const { right } = currentTarget.getBoundingClientRect();
      const startOffset = right - event.clientX;

      function onPointerMove(event: PointerEvent) {
        if (event.pointerId !== pointerId) {
          return;
        }
        if (event.pointerType === "mouse" && event.buttons !== 1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define -- this is a recursive call
          onPointerUp();
          return;
        }
        onResize(event.clientX - right + startOffset);
      }

      function onPointerUp() {
        window.removeEventListener("pointermove", onPointerMove);
        window.removeEventListener("pointerup", onPointerUp);
        resizeFinish.current();
      }

      event.preventDefault();
      event.stopPropagation();
      resizeStart.current(column);
      window.addEventListener("pointermove", onPointerMove);
      window.addEventListener("pointerup", onPointerUp);
    },
    [onResize]
  );

  const getItemProps = useCallback(
    (column: any) => {
      const isDragging = column.index === resizingColumn?.index;
      const dragOffset = resizingColumn?.dragOffset ?? 0;
      return {
        isDragging,
        dragOffset,
        onPointerDown: onPointerDown(column),
      };
    },
    [onPointerDown, resizingColumn]
  );

  return {
    getItemProps,
  };
};
