import classNames from "classnames";
import { useTranslation } from "react-i18next";

import InfoIcon from "assets/icons/Info.svg?react";

import Checkbox from "../../../Checkbox";
import CustomTooltip from "../../../Tooltip";
import { Meta } from "./types";

import styles from "../styles.module.scss";

type HeaderProps = {
  meta: Meta[];
  allChecked: boolean;
  someChecked: boolean;
  onChange: (checked: boolean) => void;
};

export const Header = (props: HeaderProps) => {
  const { meta, allChecked, someChecked, onChange } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      <div className={styles.checkboxCol}>
        <Checkbox
          checked={allChecked}
          indeterminate={!allChecked && someChecked}
          onChange={() => onChange(!(allChecked || someChecked))}
          testId="map-data-check-all-columns"
        />
      </div>
      {meta.map(column => {
        return (
          <div
            className={classNames(styles.headerCol, styles[column.name])}
            key={column.name}
          >
            <div className={styles.text}>{t(column.displayName)}</div>
            {!!column.info && (
              <CustomTooltip title={t(column.info)}>
                <div className={styles.iconContainer}>
                  <InfoIcon />
                </div>
              </CustomTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};
