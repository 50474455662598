import localForage from "localforage";

import {
  Script,
  ScriptStorage,
} from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { LocalScript } from "pages/DevelopWorkspace/workspace.types";

const LOCAL_SCRIPTS_KEY_PREFIX = "FIREBOLT_LOCAL_SCRIPT|";

/**
 * Store is based on localForage
 * https://localforage.github.io/localForage/#settings-api-config
 *
 * TODO: add garbage collection
 * All keys are stored in localForage.keys() and we can use it to check if key is valid
 */
const localForageInstance = localForage.createInstance({
  driver: localForage.INDEXEDDB,
  name: "firebolt",
  storeName: "scripts",
  description: "Scripts storage",
});

const createLocalScriptStorage = (): ScriptStorage => {
  // TODO do garbage collection: check all scripts that don't exist in documents and remove them
  // TODO would be nice to add debounce to make all changes in one batch

  const listScripts = async (): Promise<LocalScript[]> => {
    // TODO return all scripts from storage
    // needed for garbage collection only
    return [];
  };

  const loadScript = async (id: string) => {
    const item = await localForageInstance.getItem<Script>(
      LOCAL_SCRIPTS_KEY_PREFIX + id
    );

    if (!item) {
      throw new Error("not found");
    }

    return item;
  };

  const updateScript = async (script: LocalScript) => {
    return localForageInstance.setItem(
      LOCAL_SCRIPTS_KEY_PREFIX + script.id,
      script
    );
  };

  const deleteScript = async (id: string): Promise<boolean> => {
    await localForageInstance.removeItem(LOCAL_SCRIPTS_KEY_PREFIX + id);

    return true;
  };

  const createScript = async (
    id: string,
    title: string,
    content: string
  ): Promise<LocalScript> => {
    const script = {
      id,
      title,
      content,
      createdAt: new Date().toISOString(),
    };

    await localForageInstance.setItem(LOCAL_SCRIPTS_KEY_PREFIX + id, script);

    return script;
  };

  return {
    listScripts,
    loadScript,
    updateScript,
    deleteScript,
    createScript,
  };
};

export default createLocalScriptStorage;
