type NotifyCallback = (payload: any, id: number) => void;

export type SubscriptionToken = {
  id: number;
  unsubscribe: () => void;
};

type Observers = {
  [id: number]: NotifyCallback;
};

const subscriptionToken = (
  observers: Observers,
  id: number
): SubscriptionToken => {
  return {
    id,
    unsubscribe: () => {
      // eslint-disable-next-line no-param-reassign
      delete observers[id];
    },
  };
};

const executeCallbackFn = (
  callback: NotifyCallback,
  payload: any,
  id: number
): (() => void) => {
  return () => callback(payload, id);
};

export class Subject {
  private observers: Observers;

  private nextId: number;

  constructor() {
    this.observers = Object.create(null);
    this.nextId = 0;
  }

  public subscribe = (callback: NotifyCallback): SubscriptionToken => {
    const id = this.nextId++;
    this.observers[id] = callback;
    return subscriptionToken(this.observers, id);
  };

  public notify = (payload: any) => {
    for (const id in this.observers) {
      const callback = this.observers[id];
      callback(payload, Number(id));
    }
  };

  public notifyAsync = (payload: any, delay = 0) => {
    for (const id in this.observers) {
      const callback = this.observers[id];
      setTimeout(executeCallbackFn(callback, payload, Number(id)), delay);
    }
  };
}
