const LOCAL_STORAGE_SYSTEM_KEY_PREFIXES = ["ld:", "accountName"];

export const LOCAL_STORAGE_CLEAR_MESSAGE_ID = "local-storage-quota-exceeded";

const clearLocalStorage = () => {
  // clear all except system keys

  const keys = Object.keys(localStorage);

  for (const key of keys) {
    if (
      key &&
      !LOCAL_STORAGE_SYSTEM_KEY_PREFIXES.some(prefix => key.startsWith(prefix))
    ) {
      localStorage.removeItem(key);
    }
  }
};

export default clearLocalStorage;
