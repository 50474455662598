import Highlighter from "components/Highligher/Highlighter";

export const highlightText = ({
  searchWord,
  textToHighlight,
}: {
  searchWord?: string;
  textToHighlight: string;
}) => {
  return searchWord ? (
    <Highlighter
      searchWords={[searchWord]}
      textToHighlight={textToHighlight}
    />
  ) : (
    textToHighlight
  );
};
