export enum OrderDirection {
  ASC = "asc",
  DESC = "desc",
}

export enum OrderBy {
  name = "name",
  status = "status",
  type = "type",
  nodes = "nodes",
  clusters = "clusters",
}
