import { Statistics } from "../../workspace.types";

export const getStatisticsFromResponse = (response: any): Statistics => {
  return {
    executionTimeSec: response.statistics.elapsed,
    bytesRead: response.statistics.bytes_read,
    rowsRead: response.statistics.rows_read,
    scannedBytesCache: response.statistics.scanned_bytes_cache,
    scannedBytesStorage: response.statistics.scanned_bytes_storage,
    timeBeforeExecution: response.statistics.time_before_execution,
    timeToExecute: response.statistics.time_to_execute,
  };
};
