import { useQuery } from "@tanstack/react-query";

import { useBillingV2Flag } from "featureFlags/hooks/useBillingV2Flag";
import { getOrganizationCredit } from "services/organization/getOrganizationCredit";
import { ReactQueryKeysGlobal } from "services/queryKeys";

export const useOrganizationCredit = () => {
  const billingV2Flag = useBillingV2Flag();
  const { data, isFetching } = useQuery({
    queryKey: [ReactQueryKeysGlobal.organizationCredit, billingV2Flag],
    queryFn: async () => {
      try {
        return await getOrganizationCredit(billingV2Flag ? 2 : 1);
      } catch (error) {
        return null;
      }
    },
  });
  return { data, isFetching };
};
