import copy from "copy-to-clipboard";
import { useCallback, useEffect } from "react";

import { formatCell } from "../utils/formatCell";
import { getTotalSelections } from "../utils/interactions";
import { getCopyText } from "../utils/tableUtils";

export type UseCopyProps = {
  state: {
    selections: any;
    activeSelection: any;
    activeCell: any;
    editingCell: any;
  };
  getRawCell: (
    rowIndex: number,
    columnIndex: number
  ) => {
    row: any;
    column: any;
  };
};

export type UseCopyReturn = {
  copySelection: () => void;
};

export const useCopy = ({ state, getRawCell }: UseCopyProps): UseCopyReturn => {
  const getTextToCopy = useCallback(() => {
    const { selections, activeSelection, activeCell, editingCell } = state;

    if (!activeCell || editingCell) {
      return;
    }

    const totalSelections = getTotalSelections({
      activeSelection,
      activeCell,
      selections,
    });

    const getValue = (rowIndex: number, columnIndex: number) => {
      const { row, column } = getRawCell(rowIndex, columnIndex);
      const { value } = formatCell({
        row,
        column,
        shouldLimitValueLength: false,
      });
      return value;
    };

    const text = getCopyText({
      selections: totalSelections,
      getValue,
    });

    return text;
  }, [state, getRawCell]);

  // for copying by clicking on the button
  const copySelection = useCallback(() => {
    const text = getTextToCopy();

    if (text) {
      copy(text);
    }
  }, [getTextToCopy]);

  const copyEventHandler = useCallback(() => {
    if (document.activeElement !== document.body) {
      // do not interfere with the copy event if the focus is not on the body
      // probably the focus is on an input or textarea
      return;
    }

    const text = getTextToCopy();

    if (!text) {
      return;
    }

    // timeout is a workaround for Firefox
    setTimeout(() => {
      navigator.clipboard.writeText(text);
    }, 0);
  }, [getTextToCopy]);

  useEffect(() => {
    document.addEventListener("copy", copyEventHandler);
    return () => {
      document.removeEventListener("copy", copyEventHandler);
    };
  }, [copyEventHandler]);

  return {
    copySelection,
  };
};
