import { Popover } from "@mui/material";
import classNames from "classnames";
import { useMemo } from "react";

import ArrowRight from "assets/icons/ArrowRight.svg?react";
import SortWithArrowsIcon from "assets/icons/SortWithArrows.svg?react";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";

import styles from "./styles.module.scss";

export const SectionHeader = (props: {
  title: string;
  section: string;
  menuItems?: {
    key: string;
    text: string;
    orderParams: Record<string, string>;
    selected: (section: string) => boolean;
    testId?: string;
  }[][];
  handleChangeOrder: (
    section: string,
    orderParams: Record<string, string>
  ) => void;
  toggleSection: (section: string) => void;
  expanded: boolean;
  testId?: string;
}) => {
  const {
    section,
    title,
    menuItems = [],
    handleChangeOrder,
    toggleSection,
    expanded,
    testId,
  } = props;
  const menu = useMenu();
  const { openMenu, menuElement, closeMenu } = menu;

  const items = useMemo(() => {
    const elements = [];
    for (let index = 0; index < menuItems.length; index++) {
      const group = menuItems[index];
      if (index > 0) {
        elements.push(<ContextMenuDivider key="divider" />);
      }
      for (const item of group) {
        const { key, text, orderParams, selected, testId } = item;
        elements.push(
          <ContextMenuItem
            text={text}
            key={key}
            testId={testId}
            onClick={() => {
              handleChangeOrder(section, orderParams);
              closeMenu();
            }}
            checked={selected(section)}
          />
        );
      }
    }
    return elements;
  }, [closeMenu, handleChangeOrder, section, menuItems]);

  const handleToggleSection = () => {
    toggleSection(section);
  };

  return (
    <div className={styles.section__header}>
      <span
        className={styles.section__title}
        onClick={handleToggleSection}
      >
        {title}
      </span>
      {expanded ? (
        <button
          className={classNames(styles.sort, {
            [styles.sort__active]: menuElement,
          })}
          onClick={openMenu}
          data-testid={testId}
        >
          <SortWithArrowsIcon />
        </button>
      ) : (
        <button
          className={classNames(styles.sort)}
          onClick={handleToggleSection}
          data-testid="toggle-section"
        >
          <ArrowRight className={styles.arrow} />
        </button>
      )}
      {!!menuElement && (
        <Popover
          onClose={closeMenu}
          open
          anchorEl={menuElement}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ContextMenu>{items}</ContextMenu>
        </Popover>
      )}
    </div>
  );
};
