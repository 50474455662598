import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
  WorkspaceEngineType,
} from "services/engines/engine.types";

export const SYSTEM_ENGINE: WorkspaceEngine = {
  engineName: "system",
  status: WorkspaceEngineStatus.RUNNING,
  type: WorkspaceEngineType.None,
  clusters: 0,
  nodes: 0,
  description: "",
  url: "",
  autoStop: "",
  autoStart: false,
  initiallyStopped: false,
  lastStarted: "",
};
