import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useEffect,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import clearLocalStorage, {
  LOCAL_STORAGE_CLEAR_MESSAGE_ID,
} from "utils/localStorageCleaner";

import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

const createPersistedReducer = () => {
  const usePersistedReducer = <R extends Reducer<any, any>>(
    key: string,
    reducer: R,
    initialState: ReducerState<R>,
    persistFilter?: (state: ReducerState<R>) => ReducerState<R>, // run before persisting to storage
    normalize?: (rawState: any) => ReducerState<R> // run before setting state from local storage (simple alternative to migrations)
  ): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
    const { putStatusMessage, removeStatusMessage } = useStatusMessageQueue();
    const { t } = useTranslation();

    const [state, dispatch] = useReducer(
      reducer,
      initialState,
      initialState => {
        const persistedState = localStorage.getItem(key);

        if (persistedState) {
          try {
            const rawState = JSON.parse(persistedState);

            if (normalize) {
              const normalizedState = normalize(rawState);

              return normalizedState;
            }

            return rawState;
          } catch (e) {
            console.error('State for key "' + key + '" is invalid');
            return initialState;
          }
        }
        return initialState;
      }
    );

    useEffect(() => {
      const filteredState = persistFilter ? persistFilter(state) : state;

      try {
        localStorage.setItem(key, JSON.stringify(filteredState));
      } catch (e) {
        if (e instanceof DOMException && e.name === "QuotaExceededError") {
          putStatusMessage({
            type: StatusMessageType.Error,
            message: (
              <span>
                {t("local_storage.quota_exceeded")}
                <a
                  onClick={e => {
                    e.preventDefault();
                    clearLocalStorage();
                    removeStatusMessage(LOCAL_STORAGE_CLEAR_MESSAGE_ID);
                    putStatusMessage({
                      type: StatusMessageType.Success,
                      message: t("local_storage.clear_success"),
                    });
                  }}
                >
                  {t("local_storage.clear_message")}
                </a>
              </span>
            ),
            options: {
              insertToPosition: StatusMessagePosition.Top,
              autoRemove: true,
              id: LOCAL_STORAGE_CLEAR_MESSAGE_ID,
            },
          });
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on state change
    }, [state]);

    return [state, dispatch];
  };

  return usePersistedReducer;
};

export default createPersistedReducer;
