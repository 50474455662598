import { useState } from "react";

export const useVisibleColumns = (
  tableHeader: {
    displayName: string;
    name: string;
    type: string;
    disabled?: boolean;
  }[]
) => {
  const [columns, setColumns] = useState(() => [...tableHeader]);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const filterFromVisibleColumns = (columnName: string) => {
    return visibleColumns.includes(columnName)
      ? visibleColumns.filter(visibleColName => visibleColName !== columnName)
      : [...visibleColumns, columnName];
  };

  const filterColumns = (columnName: string) => {
    const newVisibleColumns = visibleColumns.length
      ? filterFromVisibleColumns(columnName)
      : columns.filter(col => col.name !== columnName).map(col => col.name);
    setVisibleColumns(newVisibleColumns);

    if (!newVisibleColumns.length) {
      setColumns([...tableHeader]);
      return;
    }

    const filteredFields = tableHeader.filter(field =>
      newVisibleColumns.includes(field.name)
    );

    setColumns(filteredFields);
  };

  return {
    columns,
    visibleColumns,
    filterColumns,
  };
};
