import { Navigate, Route, useLocation } from "react-router-dom";
import {
  GUEST_ROUTES,
  RESERVED_URL_SEARCH_PARAMS,
} from "utils/routes.constants";

import { useLoggedInUser } from "hooks/useLoggedInUser";

import {
  AsyncCreateOrganization,
  AsyncFindOrganization,
  AsyncLogin,
  AsyncLoginError,
  AsyncSignupContinue,
} from "pages/pages";

import { withGoogleTagManager } from "components/common/withGoogleTagManager";
import { withHubspot } from "components/common/withHubspot";

const getRedirectPath = (search: string) => {
  const params = new URLSearchParams(search);
  const redirectPath = params.get(RESERVED_URL_SEARCH_PARAMS.REDIRECT) || "/";

  params.delete(RESERVED_URL_SEARCH_PARAMS.REDIRECT);

  const query = params.toString() ? `?${params.toString()}` : "";

  return `${redirectPath}${query}`;
};

const guestRoute =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: React.ComponentProps<typeof Component>) => {
    const location = useLocation();
    const { isLoggedIn } = useLoggedInUser();

    if (isLoggedIn) {
      const to = getRedirectPath(location.search);
      return <Navigate to={to} />;
    }

    return <Component {...props} />;
  };

const ProtectedLogin = guestRoute(AsyncLogin);
const ProtectedCreateOrganization = withGoogleTagManager(
  withHubspot(guestRoute(AsyncCreateOrganization))
);
const ProtectedAsyncSignupContinue = withGoogleTagManager(
  withHubspot(guestRoute(AsyncSignupContinue))
);
const ProtectedFindOrganization = guestRoute(AsyncFindOrganization);

export const guestRoutes = [
  <Route
    key={GUEST_ROUTES.LOGIN_ERROR}
    path={GUEST_ROUTES.LOGIN_ERROR}
    element={<AsyncLoginError />}
  />,
  <Route
    key={GUEST_ROUTES.LOGIN}
    path={GUEST_ROUTES.LOGIN}
    element={<ProtectedLogin />}
  />,
  <Route
    key={GUEST_ROUTES.CREATE_ORGANIZATION}
    path={GUEST_ROUTES.CREATE_ORGANIZATION}
    element={<ProtectedCreateOrganization />}
  />,
  <Route
    key={GUEST_ROUTES.REGISTER_CONTINUE}
    path={GUEST_ROUTES.REGISTER_CONTINUE}
    element={<ProtectedAsyncSignupContinue />}
  />,
  <Route
    key={GUEST_ROUTES.FIND_ORGANIZATION}
    path={GUEST_ROUTES.FIND_ORGANIZATION}
    element={<ProtectedFindOrganization />}
  />,
];
