import omit from "lodash/omit";
import { useCallback, useEffect, useRef, useState } from "react";

import { ItemDataType, TreeNodeType, TreeNodesType } from "./types";
import { getNodeParentKeys, shouldShowNodeByParentExpanded } from "./utils";

const attachParent = <T>(data: T, parent: T): T => {
  Object.defineProperty(data, "parent", {
    value: parent,
    writable: false,
    enumerable: false,
    configurable: true,
  });
  return data;
};

export const flattenTree = <TItem>(
  tree: TItem[],
  executor: (node: any, index: number) => any
): TItem[] => {
  const flattenData: TItem[] = [];
  const traverse = (data: any[], parent: any | null) => {
    if (!Array.isArray(data)) {
      return;
    }

    data.forEach((item: any, index: number) => {
      const node = executor(item, index);

      flattenData.push(attachParent(node, parent));

      if (item.children) {
        traverse(item.children, item);
      }
    });
  };

  traverse(tree, null);
  return flattenData;
};

export const useFlattenTreeData = ({
  data,
  callback,
}: {
  data: ItemDataType[];
  callback?: (nodes: TreeNodesType) => void;
}) => {
  const [, dispatch] = useState(Object.create(null));

  const forceUpdate = useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);

  const flattenNodes = useRef<TreeNodesType>({});

  const flattenTreeData = useCallback(
    (treeData?: ItemDataType[] | null, parent?: ItemDataType, layer = 1) => {
      if (!Array.isArray(treeData) || treeData.length === 0) {
        return [];
      }

      treeData.forEach(node => {
        const { type, value } = node;

        const refKey = `${type}_${value}`;
        // eslint-disable-next-line no-param-reassign
        (node as TreeNodeType).refKey = refKey;

        flattenNodes.current[refKey] = {
          layer,
          ...node,
        } as TreeNodeType;

        if (parent) {
          flattenNodes.current[refKey].parent = omit(
            parent,
            "parent",
            "children"
          ) as TreeNodeType;
        }
        flattenTreeData(node.children, node, layer + 1);
      });

      callback?.(flattenNodes.current);
    },
    [callback]
  );

  const formatVirtualizedTreeData = (
    nodes: TreeNodesType,
    data: any[],
    expandItemValues: string[],
    searchKeyword?: string
  ): TreeNodeType[] => {
    const filtered = data.filter(
      node => node.label?.toLowerCase().includes(searchKeyword?.toLowerCase())
    );

    return flattenTree(filtered, (node: any) => {
      let formatted = {};
      const curNode = nodes?.[node.refKey];
      const parentKeys = getNodeParentKeys(nodes, curNode);

      const visible = curNode?.parent
        ? shouldShowNodeByParentExpanded(expandItemValues, parentKeys)
        : true;

      if (curNode) {
        formatted = {
          ...node,
          hasChildren: !!node.children,
          layer: curNode.layer,
          parent: curNode.parent,
          visible,
        };
      }
      return formatted;
    });
  };

  useEffect(() => {
    flattenNodes.current = {};
    flattenTreeData(data);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    forceUpdate,
    flattenNodes: flattenNodes.current,
    flattenTreeData,
    formatVirtualizedTreeData,
  };
};
