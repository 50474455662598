import React from "react";

import { getSelectionWidth } from "../utils/interactions";

import styles from "./styles.module.scss";

export const SelectionRect = ({
  activeCell,
  selectionCell,
  getRowHeight,
  getColumnWidth,
  columnLeftOffsets,
}: {
  activeCell: {
    col: number;
    row: number;
  };
  selectionCell: {
    start: {
      row: number;
      col: number;
    };
    end: {
      row: number;
      col: number;
    };
  };
  getRowHeight: () => number;
  getColumnWidth: (index: number) => number;
  columnLeftOffsets: number[];
}) => {
  const {
    start: { row, col },
    end: { row: endRow, col: endCol },
  } = selectionCell;

  if (row === activeCell?.row && col === activeCell?.col) {
    return null;
  }

  const columnIndex = Math.min(endCol, col);
  const height = (Math.abs(row - endRow) + 1) * getRowHeight();
  const left = columnLeftOffsets[columnIndex];
  const top = Math.min(endRow, row) * getRowHeight();
  const width = getSelectionWidth({ endCol, col, getColumnWidth });

  const style = {
    height,
    width,
    left,
    top,
  };

  return (
    <div
      className={styles.selection}
      style={style}
      data-testid="selection-rect"
    >
      <div className={styles.selection__rect}>
        <div className={styles.selection__backdrop} />
      </div>
    </div>
  );
};
