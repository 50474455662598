import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import FormRow from "./components/FormRow";

import formRowStyles from "./components/FormRow/FormRow.module.scss";

export const DefaultEngine = (props: {
  control: Control<any>;
  initialSelected?: string[];
}) => {
  const { control, initialSelected } = props;
  const { data = [] } = useWorkspaceEngines();
  const { t } = useTranslation();
  return (
    <FormRow
      label={t("workspace.create_user.default_engine")}
      input={
        <Controller
          control={control}
          name="defaultEngine"
          render={({ field: { onChange, value } }) => (
            <OutlinedSelect
              initialSelected={initialSelected}
              className={formRowStyles.selectInput}
              onSelect={(items: string[]) => {
                const [type = ""] = items;
                onChange(type);
              }}
              testId="default-engine-select"
              allowUncheck
              searchOptions={{
                searchPlaceholder: t("workspace.create_user.search_engine"),
                noResultsText: t("workspace.create_user.no_engine_found"),
              }}
            >
              {data.map(engine => {
                const { engineName } = engine;
                return (
                  <ContextMenuItem
                    value={engineName}
                    key={engineName}
                    checked={engineName === value}
                    checkedIconPlaceholder={true}
                    testId={`default-engine-item-${engineName}`}
                    text={engineName}
                  />
                );
              })}
            </OutlinedSelect>
          )}
        />
      }
    />
  );
};
