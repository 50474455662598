import classNames from "classnames";
import { CSSProperties, ChangeEvent, FocusEvent, forwardRef , useState } from "react";

import styles from "./styles.module.scss";

interface Props {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  style?: CSSProperties;
  testId?: string;
  value?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { testId, className, onFocus, onBlur, ...rest } = props;
  const [inputFocused, setInputFocused] = useState(false);

  const textareaClassName = classNames(styles.root, className);

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setInputFocused(true);
    onFocus && onFocus(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setInputFocused(false);
    onBlur && onBlur(event);
  };

  return (
    <textarea
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
      className={classNames(textareaClassName, {
        [styles.focused]: inputFocused,
      })}
      data-testid={testId}
      {...rest}
    />
  );
});

export default Textarea;
