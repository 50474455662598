import classNames from "classnames";
import React, { useCallback, useContext, useMemo, useRef } from "react";

import {
  FiltersContext,
  HeightContext,
  StickyGridContext,
} from "components/Datagrid/context";

import { CommonHeaderName } from "../../../../components/HeaderName";
import { Filters } from "../../Filters/Filters";
import { Sort } from "../Sort";

import styles from "./styles.module.scss";

interface Props {
  column: any;
  index: number;
  isDragging: boolean;
  dragOffset: number;
  onPointerDown: (event: React.PointerEvent<HTMLDivElement>) => void;
  handleDoubleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}
export const HeaderName = (props: Props) => {
  const {
    column,
    index,
    isDragging,
    dragOffset,
    onPointerDown,
    handleDoubleClick,
  } = props;
  const { getFilters, handleSort, filtersOpened } = useContext(FiltersContext);
  const { rowCount } = useContext(StickyGridContext);
  const headerItem = useRef(null);
  const height = useContext(HeightContext);

  const opened = filtersOpened && filtersOpened.index === index;

  const appliedFilters = useMemo(() => {
    const filters = getFilters();
    const columnFilters = filters[column.name];
    const appliedFilters = columnFilters
      ? !!Object.keys(columnFilters).length
      : false;
    return appliedFilters;
  }, [getFilters, column.name]);

  const handleColumnSort = useCallback(() => {
    if (!column.sortConfig || column.sortConfig.sortOrder === "desc") {
      handleSort(column, { sortOrder: "asc" });
      return;
    }

    if (column.sortConfig.sortOrder === "asc") {
      handleSort(column, { sortOrder: "desc" });
    }
  }, [column, handleSort]);

  const showFilter = !!column.sortConfig || rowCount > 1 || appliedFilters;

  return (
    <CommonHeaderName
      text={column.displayName}
      tooltip={
        column.displayName ? (
          <>
            <span>{column.displayName}</span>
            <span className={styles.tooltipSubText}>
              {column._typeUpperCase}
            </span>
          </>
        ) : undefined
      }
      additionalText={column.displayName ? column._typeUpperCase : ""}
      headerItemRef={headerItem}
      onClick={handleColumnSort}
      iconComponent={
        showFilter ? (
          <span className={styles.header__icon}>
            <Sort
              column={column}
              index={index}
              appliedFilters={appliedFilters}
            />
          </span>
        ) : null
      }
      filtersDropdown={
        <Filters
          anchorEl={headerItem.current}
          opened={opened}
          column={column}
        />
      }
      draggingComponents={
        <>
          <div
            style={{ right: -dragOffset }}
            className={classNames(styles.draggable, {
              [styles.draggable__active]: isDragging,
            })}
            onPointerDown={onPointerDown}
            onClick={event => event.stopPropagation()}
            onDoubleClick={handleDoubleClick}
          />
          {isDragging && (
            <div
              style={{ right: -dragOffset, height: height - 10 }}
              className={classNames(styles.line, styles.line__active)}
            />
          )}
        </>
      }
    />
  );
};
