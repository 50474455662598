import React, { SetStateAction, useContext, useMemo } from "react";

import usePersistedState from "hooks/usePersistedState";

const defaultLayoutState = {
  leftSidebar: {
    expanded: true,
  },
  engines: {
    expanded: false,
  },
  results: {
    expanded: true,
  },
};

export type Layout = {
  leftSidebar: {
    expanded: boolean;
    width?: number;
  };
  engines: {
    expanded: boolean;
  };
  results: {
    expanded: boolean;
  };
};

const LayoutTogglesContext = React.createContext<{
  layout: Layout;
  setLayout: React.Dispatch<SetStateAction<Layout>>;
}>({
  layout: defaultLayoutState,
  setLayout: () => {},
});

export const useLayoutToggles = () => {
  const context = useContext(LayoutTogglesContext);
  return context;
};

export const LayoutTogglesProvider = (props: React.PropsWithChildren) => {
  const { children } = props;

  const [layout, setLayout] = usePersistedState<Layout>(
    "LAYOUT",
    defaultLayoutState
  );

  const context = useMemo(() => {
    return {
      layout,
      setLayout,
    };
  }, [layout, setLayout]);

  return (
    <LayoutTogglesContext.Provider value={context}>
      {children}
    </LayoutTogglesContext.Provider>
  );
};
