import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";

import { DocumentExecutionError } from "pages/DevelopWorkspace/workspace.types";

export const getExecutionStartError = (
  engine: WorkspaceEngine
): DocumentExecutionError | null => {
  // ignore checking if engine has autoStart
  if (engine.autoStart) {
    return null;
  }

  if (engine.status === WorkspaceEngineStatus.STOPPED) {
    return {
      title: "The engine is stopped.",
      description: "Please start the engine first.",
    };
  }

  if (
    engine.status === WorkspaceEngineStatus.STOPPING ||
    engine.status === WorkspaceEngineStatus.DRAINING
  ) {
    return {
      title: "The engine is stopping.",
      description: "Please start the engine first.",
    };
  }

  return null;
};
