import isEqual from "lodash/isEqual";

import { getSelectionBoundries } from "../utils/tableUtils";

export const isPointInside = ({
  box,
  point,
}: {
  box: {
    start: any;
    end: any;
  };
  point: {
    row: any;
    col: any;
  };
}) => {
  const { start, end } = box;
  const { topRow, bottomRow, leftCol, rightCol } = getSelectionBoundries({
    start,
    end,
  });

  const isInsideBox =
    point.row >= topRow &&
    point.row <= bottomRow &&
    point.col <= rightCol &&
    point.col >= leftCol;

  return isInsideBox;
};

export const isInsideSelection = ({
  activeSelection,
  activeCell,
  cursorIndex,
  selections,
}: {
  activeSelection: {
    row: number;
    col: number;
  };
  activeCell: {
    row: number;
    col: number;
  };
  cursorIndex: {
    row: number;
    col: number;
  };
  selections: Array<{
    start: number;
    end: number;
  }>;
}) => {
  const isInsideActiveSelection = activeSelection
    ? isPointInside({
        box: {
          start: activeSelection,
          end: activeCell,
        },
        point: cursorIndex,
      })
    : false;

  const isInsideSelections = selections.some(selection => {
    const { start, end } = selection;
    return isPointInside({ box: { start, end }, point: cursorIndex });
  });

  return isInsideActiveSelection || isInsideSelections;
};

export const addSelections = (state: {
  activeSelection: {
    row: number;
    col: number;
  };
  activeCell: {
    row: number;
    col: number;
  };
  selections: Array<{
    start: number;
    end: number;
  }>;
}) => {
  if (state.activeSelection) {
    const newSelection = {
      start: state.activeSelection,
      end: state.activeCell,
    };
    const isNewSelection = state.selections.every(selection => {
      return !isEqual(selection, newSelection);
    });
    if (isNewSelection) {
      return [...state.selections, newSelection];
    }
  }
  if (state.activeCell) {
    // one cell selection
    const newSelection = { start: state.activeCell, end: state.activeCell };
    const isNewSelection = state.selections.every(selection => {
      return !isEqual(selection, newSelection);
    });
    if (isNewSelection) {
      return [...state.selections, newSelection];
    }
  }

  return state.selections;
};

export const getActiveSelection = ({
  columnSelection,
  cursorIndex,
  defaultValue,
}: {
  columnSelection: {
    start: number;
    end: number;
  };
  cursorIndex: {
    row: number;
    col: number;
  };
  defaultValue: number | null;
}) => {
  return columnSelection
    ? { row: columnSelection.end, col: cursorIndex.col, noScrollY: true }
    : defaultValue;
};
