import { useTranslation } from "react-i18next";

import CloseIcon from "assets/icons/Close.svg?react";

import Button, { ButtonSize, ButtonTemplate } from "components/Button/Button";

import styles from "./styles.module.scss";

type Props = {
  addRelatedPermissions: () => void;
  ignorePermissionError: () => void;
  message: string;
  primaryActionText: string;
};

export const MissingPermissionError = (props: Props) => {
  const {
    addRelatedPermissions,
    ignorePermissionError,
    message,
    primaryActionText,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t("roles_wizard.missing_permission.title")}
        <button className={styles.closeButton}>
          <CloseIcon
            onClick={ignorePermissionError}
            data-testid="roles-wizard-ignore-permission-icon"
          />
        </button>
      </div>
      <div className={styles.message}>{message}</div>
      <div className={styles.footer}>
        <Button
          text={primaryActionText}
          template={ButtonTemplate.Primary}
          onClick={addRelatedPermissions}
          dataTestid="roles-wizard-add-permission"
          size={ButtonSize.Small}
        />
        <Button
          text={t("roles_wizard.missing_permission.ignore")}
          template={ButtonTemplate.Ghost}
          onClick={ignorePermissionError}
          dataTestid="roles-wizard-ignore-permission-error"
          size={ButtonSize.Small}
        />
      </div>
    </div>
  );
};
