import copy from "copy-to-clipboard";
import { useCallback, useContext, useEffect, useRef } from "react";

import { StickyGridContext } from "components/Datagrid/context";
import Textarea from "components/TextArea/TextArea";

import { formatCellForEditing, limitValue } from "../utils/formatCell";
import { KEY_CODES } from "../utils/interactions";

import styles from "./styles.module.scss";

export const EditingCell = ({
  editingCell,
  getRowHeight,
  getColumnWidth,
  finishEditing,
  columnLeftOffsets,
}: {
  editingCell: { row: number; col: number };
  getRowHeight: () => number;
  getColumnWidth: (index: number) => number;
  finishEditing: () => void;
  columnLeftOffsets: number[];
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { getRawCell } = useContext(StickyGridContext);
  const { row: rowIndex, col: columnIndex } = editingCell;

  const { row, column } = getRawCell(rowIndex, columnIndex);
  const value = formatCellForEditing(row, column);
  const limitedValue = limitValue(value);

  const width = getColumnWidth(columnIndex);
  const left = columnLeftOffsets[columnIndex];
  const top = rowIndex * getRowHeight();

  const style = {
    top,
    left,
    width,
    height: "auto",
  };

  useEffect(() => {
    setTimeout(() => {
      textareaRef.current?.focus();
    }, 0);
  }, []);

  const getSelection = useCallback(() => {
    const activeElement = document.activeElement;
    const selection =
      activeElement && activeElement instanceof HTMLTextAreaElement
        ? activeElement.value.substring(
            activeElement.selectionStart,
            activeElement.selectionEnd
          )
        : "";
    return selection;
  }, []);

  const handleFocus = () => {
    const selection = getSelection();
    if (!selection) {
      textareaRef.current?.select();
    }
  };

  const handleKeydown = useCallback(
    (event: any) => {
      if (event.keyCode === KEY_CODES.ESCAPE) {
        finishEditing();
      }
    },
    [finishEditing]
  );

  const handleCopy = useCallback(
    (event: any) => {
      event.preventDefault();

      const selection = getSelection();

      if (selection.length >= limitedValue.length) {
        copy(value);
      } else {
        copy(selection);
      }
    },
    [value, limitedValue, getSelection]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, false);
    return () => {
      document.removeEventListener("keydown", handleKeydown, false);
    };
  }, [handleKeydown]);

  useEffect(() => {
    textareaRef.current?.addEventListener("copy", handleCopy);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps -- should work on every render
      textareaRef.current?.removeEventListener("copy", handleCopy);
    };
  });

  return (
    <div
      className={styles.editingBox}
      style={style}
      onMouseDown={event => event.nativeEvent.stopImmediatePropagation()}
      data-testid="editing-cell"
    >
      <div className={styles.editingBox__inner}>
        <div className={styles.editingBox__dummy}>
          {value?.toString().slice(0, 1000)}
        </div>
        <Textarea
          ref={textareaRef}
          readOnly
          onFocus={handleFocus}
          value={limitedValue}
          className={styles.editingBox__textarea}
        />
      </div>
    </div>
  );
};
