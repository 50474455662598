export type HeaderColumn = {
  name: string;
  displayName: string;
  info?: string;
  disabled?: boolean;
};

export type ExistingTableMapData = {
  name: string;
  type: string;
  isNullable: boolean;
  dataPreview: string;
  source_name: string;
  source_type: string;
};

export const NULL_TYPE = "__NULL__";

export type NullColumn = {
  name: string;
  text: string;
  className: string;
};
