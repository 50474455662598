import classNames from "classnames";
import React, { useCallback, useMemo, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from "react-window";

import { Refresher } from "components/Datagrid/Refresher";
import {
  FiltersContext,
  HeightContext,
  InteractionsActionsContext,
  InteractionsContext,
  StickyGridContext,
  WidthContext,
} from "components/Datagrid/context";
import { useColumnWidths } from "components/Datagrid/hooks/useColumnWidths";
import { useInteractions } from "components/Datagrid/hooks/useInteractions";
import { Column, Row } from "components/Datagrid/types";
import { formatCell } from "components/Datagrid/utils/formatCell";
import PortalOnMount from "components/PortalOnMount";

import { AutoSizerRenderProps } from "../../types";
import ColumnFilters from "./ColumnFilters";
import innerGridElementType from "./GridInner";
import {
  CELL_PADDING,
  ROW_HEIGHT,
  SCROLLBAR_WIDTH,
  STICKY_EXPANDED_HEIGHT,
  STICKY_HEIGHT,
} from "./constant";
import { useTable } from "./hooks/useTable";

import cellStyles from "../../components/styles/Cell.module.scss";
import styles from "./Datagrid.module.scss";

const getRowHeight = () => ROW_HEIGHT;

type Props = {
  fields: Column[];
  data: Row[];
  hiddenColumnsByDefault?: string[];
  isActive?: boolean;
};

interface WidthProviderProps {
  width: number;
  getColumnWidth: (index: number) => number;
  children: React.ReactNode;
}

const WidthProvider = (props: WidthProviderProps) => {
  const { width, getColumnWidth, children } = props;
  const contextValue = useMemo(() => {
    return {
      width,
      getColumnWidth,
    };
  }, [width, getColumnWidth]);
  return (
    <WidthContext.Provider value={contextValue}>
      {children}
    </WidthContext.Provider>
  );
};

const INTERACTION_CONSTANTS = {
  SCROLLBAR_WIDTH,
  STICKY_EXPANDED_HEIGHT,
  STICKY_HEIGHT,
};

export const Datagrid: React.FC<Props> = props => {
  const { fields, data, hiddenColumnsByDefault, isActive = true } = props;
  const gridRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dimentions = useRef<any>({
    width: 0,
    height: 0,
  });

  const {
    rows,
    columns,
    rowCount,
    columnCount,
    handleResize,
    getColumnWidth,
    leadingWidth,
    addSpacer,
    removeSpacer,
    filtersContext,

    visibleColumns,
    toggleShowAllColumns,
    filterTableColumns,
  } = useTable({
    fields,
    data,
    gridRef,
    dimentions,
    hiddenColumnsByDefault,
  });

  const { columnWidths, columnRightOffsets, columnLeftOffsets } =
    useColumnWidths({
      columns,
      getColumnWidth,
    });

  const getRawCell = useCallback(
    (rowIndex: number, columnIndex: number) => {
      const row = rows[rowIndex];
      const column = columns[columnIndex];
      return { row, column };
    },
    [rows, columns]
  );

  const { interactionsContextValue, interactionsActionsContextValue } =
    useInteractions({
      gridRef,
      containerRef,
      getRowHeight,
      getRawCell,
      columnCount,
      rowCount,
      columnWidths,
      columnRightOffsets,
      dimentions,
      dropdownExpand: false,
      constants: INTERACTION_CONSTANTS,
    });

  const GridCell = useCallback(
    (props: {
      rowIndex: number;
      columnIndex: number;
      style: React.CSSProperties;
    }) => {
      const { rowIndex, columnIndex, style } = props;
      const cell = getRawCell(rowIndex, columnIndex);
      const { row, column } = cell;
      const { value } = formatCell({ row, column });
      const columnValue = row[column.name];

      const cellStyle = { ...style, textAlign: column.textAlign as any };

      if (columnIndex !== 0) {
        Object.assign(cellStyle, { borderLeft: "none" });
      }

      return (
        <div
          data-role="cell"
          className={cellStyles.cell}
          style={cellStyle}
        >
          <span
            data-testid={`${column.name}-${rowIndex}-${columnIndex}`}
            className={classNames(cellStyles.cell__value, {
              [cellStyles.cell__null]: columnValue === null,
            })}
          >
            {value}
          </span>
        </div>
      );
    },
    [getRawCell]
  );

  const stickyGridContext = useMemo(() => {
    return {
      rowCount,
      columnLeftOffsets,
      columns,
      dropdownExpand: false,
      getRawCell,
      handleResize,
    };
  }, [rowCount, columnLeftOffsets, columns, getRawCell, handleResize]);

  return (
    <StickyGridContext.Provider value={stickyGridContext}>
      <FiltersContext.Provider value={filtersContext}>
        {isActive && (
          <PortalOnMount selector="#results-column-filter-icon">
            <ColumnFilters
              columns={fields}
              visibleColumns={visibleColumns}
              handleColumnClick={filterTableColumns}
              toggleShowAllColumns={toggleShowAllColumns}
            />
          </PortalOnMount>
        )}
        <div className={styles.wrapper}>
          <div
            data-testid="datagrid"
            className={styles.grid}
            ref={containerRef}
          >
            <AutoSizer>
              {({ width, height }: AutoSizerRenderProps) => {
                dimentions.current.width = width;
                dimentions.current.height = height;
                const spacerIsVisible =
                  leadingWidth < (width as number) - CELL_PADDING;
                return (
                  <WidthProvider
                    width={width as number}
                    getColumnWidth={getColumnWidth}
                  >
                    <HeightContext.Provider value={height as number}>
                      <InteractionsActionsContext.Provider
                        value={interactionsActionsContextValue as any}
                      >
                        <InteractionsContext.Provider
                          value={interactionsContextValue}
                        >
                          <Refresher
                            width={width as number}
                            gridRef={gridRef}
                            lastIndex={columnCount - 1}
                            spacerIsVisible={spacerIsVisible}
                            addSpacer={addSpacer}
                            removeSpacer={removeSpacer}
                            columnCount={columnCount}
                          />
                          <Grid
                            ref={gridRef}
                            columnWidth={getColumnWidth}
                            rowHeight={getRowHeight}
                            estimatedRowHeight={ROW_HEIGHT}
                            innerElementType={innerGridElementType}
                            columnCount={columnCount}
                            rowCount={rowCount}
                            width={width as number}
                            height={height as number}
                            overscanRowCount={2}
                          >
                            {GridCell}
                          </Grid>
                        </InteractionsContext.Provider>
                      </InteractionsActionsContext.Provider>
                    </HeightContext.Provider>
                  </WidthProvider>
                );
              }}
            </AutoSizer>
          </div>
        </div>
      </FiltersContext.Provider>
    </StickyGridContext.Provider>
  );
};
