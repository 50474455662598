import {
  isDateType,
  isNumberType,
  isTimestampType,
} from "components/Datagrid/utils/data";

import { InitialFieldWidthsConstants } from "../types";
import { formatCell } from "./formatCell";
import { getTextWidth } from "./getTextWidth";

export const getInitialFieldWidth = ({
  field,
  rowSample,
  constants,
}: {
  field: {
    name: string;
    _typeUpperCase: string;
  };
  rowSample: any[];
  constants: InitialFieldWidthsConstants;
}) => {
  const {
    CELL_FONT,
    CELL_PADDING,
    DEFAULT_COLUMN_WIDTH,
    HEADER_FONT,
    MAX_TEXT_COLUMN_WIDTH,
    SORT_ICON,
    TIMESTAMP_WIDTH,
    DATE_WIDTH,
    FILTER_ICON,
  } = constants;
  const { name, _typeUpperCase } = field;
  const headerTextWidth = getTextWidth(name, HEADER_FONT);

  const headerWidth = headerTextWidth + CELL_PADDING + SORT_ICON + FILTER_ICON;

  if (isTimestampType(_typeUpperCase)) {
    return Math.max(headerWidth, TIMESTAMP_WIDTH);
  }

  if (isDateType(_typeUpperCase)) {
    return Math.max(headerWidth, DATE_WIDTH);
  }

  const maxSampleWidth =
    rowSample
      .map(row => {
        const { value = "" } = formatCell({ row, column: field });
        return value;
      })
      .map(rowValue => {
        return rowValue.length > MAX_TEXT_COLUMN_WIDTH
          ? MAX_TEXT_COLUMN_WIDTH
          : getTextWidth(rowValue, CELL_FONT);
      })
      .sort((a, b) => a - b)
      .slice(-1)
      .pop() || 0;

  if (isNumberType(_typeUpperCase)) {
    return Math.max(
      headerWidth,
      maxSampleWidth + CELL_PADDING,
      DEFAULT_COLUMN_WIDTH
    );
  }

  return Math.min(
    Math.max(headerWidth, maxSampleWidth + CELL_PADDING, DEFAULT_COLUMN_WIDTH),
    MAX_TEXT_COLUMN_WIDTH
  );
};
