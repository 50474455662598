import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CreateEngineInput,
  createWorkspaceEngine,
} from "services/engines/createWorkspaceEngine";
import { EditEngineInput } from "services/engines/editWorkspaceEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { queryClient } from "components/queryClient";

import { EngineModal } from "../EngineModal/EngineModal";

export const useCreateEngine = () => {
  const [engine, setCreateEngine] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const openCreateEngine = () => {
    setCreateEngine(true);
  };

  const closeCreateEngine = () => {
    if (isLoading) {
      return;
    }
    setCreateEngine(false);
  };

  const confirmCreate = async (engine: CreateEngineInput | EditEngineInput) => {
    try {
      setIsLoading(true);
      await createWorkspaceEngine(engine as CreateEngineInput);

      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.workspaceEngines],
      });
      closeCreateEngine();
      putStatusMessage({
        message: t("workspace.create_engine.success"),
        type: StatusMessageType.Success,
      });
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createEngineMarkup = engine ? (
    <EngineModal
      onClose={closeCreateEngine}
      onSubmit={confirmCreate}
      isLoading={isLoading}
      title={t("workspace.create_engine.title")}
      primaryButton="workspace.create_engine.submit"
    />
  ) : null;

  return {
    createEngineMarkup,
    openCreateEngine,
  };
};
