import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import ArrowRight from "assets/icons/ArrowRight.svg?react";

import styles from "./styles.module.scss";

type Transition = {
  duration?: string;
  timingFunction?: string;
};

type CollapsibleProps = {
  open: boolean;
  transition?: Transition;
  children?: React.ReactNode;
};

type AnimationState = "idle" | "measuring" | "animating";

export const CollapsibleHeader = (props: {
  title: string;
  testId?: string;
  onClick: () => void;
  open: boolean;
}) => {
  const { open, title, onClick, testId } = props;
  return (
    <div
      className={styles.header}
      onClick={onClick}
      data-testid={testId}
    >
      {title}
      <ArrowRight
        className={classNames(styles.icon, { [styles.expanded]: open })}
      />
    </div>
  );
};

export const Collapsible = ({
  open,
  transition,
  children,
}: CollapsibleProps) => {
  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(open);
  const [animationState, setAnimationState] = useState<AnimationState>("idle");
  const collapisbleContainer = useRef<HTMLDivElement>(null);

  const isFullyOpen = animationState === "idle" && open && isOpen;
  const isFullyClosed = animationState === "idle" && !open && !isOpen;
  const content = !isFullyClosed ? children : null;

  const wrapperClassName = classNames(
    styles.collapsible,
    styles.animating,
    isFullyClosed && styles.isFullyClosed
  );

  const collapsibleStyles = {
    ...(transition && {
      transitionDuration: `${transition.duration}`,
      transitionTimingFunction: `${transition.timingFunction}`,
    }),
    maxHeight: isFullyOpen ? "none" : height,
    overflow: isFullyOpen ? "auto" : "hidden",
  };

  const handleCompleteAnimation = useCallback(() => {
    setAnimationState("idle");
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (open !== isOpen) {
      setAnimationState("measuring");
    }
  }, [open, isOpen]);

  useEffect(() => {
    if (!open || !collapisbleContainer.current) return;
    // If collapsible defaults to open, set an initial height
    setHeight(collapisbleContainer.current.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!collapisbleContainer.current) return;

    switch (animationState) {
      case "idle":
        break;
      case "measuring":
        setHeight(collapisbleContainer.current.scrollHeight);
        setAnimationState("animating");
        break;
      case "animating":
        setHeight(open ? collapisbleContainer.current.scrollHeight : 0);
        break;
      default:
        break;
    }
  }, [animationState, open, isOpen]);

  return (
    <div
      style={collapsibleStyles}
      ref={collapisbleContainer}
      className={wrapperClassName}
      onTransitionEnd={handleCompleteAnimation}
    >
      {content}
    </div>
  );
};
