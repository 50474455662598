import { Centrifuge, PublicationContext } from "centrifuge";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { authService } from "services/auth";
import { useUser } from "services/auth/useUser";
import { getWSChannelTokens } from "services/ws/getWsAuthToken";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";

import { WebsocketContext } from "./WebsocketContext";
import { clearSubscriptions } from "./clearSubscriptions";
import { createChannelNamesFromEntities } from "./helpers";
import { initializeWSClient } from "./initializeWSClient";
import { updateChannelEntity } from "./updates/updateChannelEntity";
import { ChannelTokens, SubscriptionEntityTypes } from "./websocket.types";

export const WebsocketProvider = (props: React.PropsWithChildren) => {
  const [wsClient, setWsClient] = useState<Centrifuge | undefined>(undefined);
  const subscribedChannelNames = useRef<string[]>([]); // stores channel names
  const tokens = useRef<ChannelTokens>({});
  const user = useUser();
  const { children } = props;
  const currentAccount = useCurrentParamsAccount();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (wsClient) return;

    if (currentAccount) {
      initializeWSClient().then(client => {
        setWsClient(client);
      });
    }
  }, [currentAccount, wsClient]);

  const handlePublication = useCallback(
    async (ctx: PublicationContext) => {
      await updateChannelEntity({
        queryClient,
        data: {
          type: ctx.data.type,
        },
      });
    },
    [queryClient]
  );

  const subscribeToChannels = useCallback(
    (
      channelsTokens: ChannelTokens,
      callback?: (ctx: PublicationContext) => void
    ) => {
      if (!wsClient) return;

      const channelsNames: string[] = Object.keys(channelsTokens);
      channelsNames.forEach(channelName => {
        if (!wsClient) return;
        try {
          const existingSubscription = wsClient.getSubscription(
            channelsTokens[channelName].channel
          );

          if (existingSubscription) return;

          const sub = wsClient.newSubscription(
            channelsTokens[channelName].channel,
            {
              token: channelsTokens[channelName].token,
              getToken: async () => {
                try {
                  const tokens = await getWSChannelTokens([channelName]);

                  return tokens[channelName].token || "";
                } catch (e) {
                  // return empty string to unsubscribe
                  // see: https://github.com/centrifugal/centrifuge-js/blob/master/src/subscription.ts#L619
                  return "";
                }
              },
            }
          );

          sub.on("publication", async ctx => {
            await handlePublication(ctx);
            if (callback) {
              callback(ctx);
            }
          });
          sub.on("unsubscribed", () => {
            subscribedChannelNames.current =
              subscribedChannelNames.current.filter(s => s !== channelName);
          });

          sub.on("error", err => {
            console.log(`Subscription error: ${channelName}`, err);
          });

          sub.subscribe();

          subscribedChannelNames.current.push(channelName);
        } catch (e) {
          console.warn(e);
        }
      });
    },
    [handlePublication, wsClient]
  );

  const attemptSubscriptionOfEntities = useCallback(
    async (
      subscriptionEntities: SubscriptionEntityTypes,
      callback?: (ctx?: PublicationContext) => void
    ) => {
      if (!currentAccount.accountName) return;
      const channelNames = createChannelNamesFromEntities(subscriptionEntities);

      const filteredChannelNames = channelNames.filter(
        c => !subscribedChannelNames.current.find(s => s === c)
      );

      if (!filteredChannelNames.length) return;

      const channelTokens = await getWSChannelTokens(filteredChannelNames);
      tokens.current = channelTokens;

      subscribeToChannels(channelTokens, callback);
    },
    [currentAccount.accountName, subscribeToChannels]
  );

  useEffect(() => {
    clearSubscriptions(wsClient);

    const loginNames: string[] = [];
    const loginName = user?.email;
    if (loginName) {
      loginNames.push(loginName);
    }

    if (wsClient && currentAccount && authService.organizationName) {
      const subscriptionEntity: SubscriptionEntityTypes = {
        accountNames: [currentAccount.accountName],
        organizationNames: [authService.organizationName],
        loginNames,
      };
      attemptSubscriptionOfEntities(subscriptionEntity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentAccount,
    attemptSubscriptionOfEntities,
    wsClient,
    authService.organizationName,
    user,
  ]);

  useEffect(() => {
    return () => {
      // clear on unmount
      clearSubscriptions(wsClient);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on unmount
  }, []);

  const getClient = useCallback(() => {
    return wsClient;
  }, [wsClient]);

  const getChannelTokens = useCallback(() => {
    return tokens.current;
  }, []);

  const context = useMemo(() => {
    return {
      getClient,
      getChannelTokens,
    };
  }, [getClient, getChannelTokens]);

  return (
    <WebsocketContext.Provider value={context}>
      {children}
    </WebsocketContext.Provider>
  );
};
