import debounce from "lodash/debounce";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";

export const useScroll = ({
  onScrolledToBottom,
  wrapper,
}: {
  onScrolledToBottom?: () => void;
  wrapper: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const dependencies = useRef({
    onScrolledToBottom,
  });

  useLayoutEffect(() => {
    dependencies.current = {
      onScrolledToBottom,
    };
  });

  const handleScroll: React.EventHandler<any> = useCallback(event => {
    const element = event.target;
    const { onScrolledToBottom } = dependencies.current;

    if (
      element.scrollHeight - element.scrollTop - 300 <=
      element.clientHeight
    ) {
      onScrolledToBottom && onScrolledToBottom();
    }
  }, []);

  const debouncedHandleScroll = useMemo(() => {
    return debounce(handleScroll, 100);
  }, [handleScroll]);

  useEffect(() => {
    const elementRef = wrapper.current;
    elementRef?.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      elementRef?.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [debouncedHandleScroll, wrapper]);

  return {};
};
