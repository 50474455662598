import {
  WorkspaceEngine,
  WorkspaceEngineType,
} from "services/engines/engine.types";

import { EngineStatusToggle } from "components/EngineStatusToggle/EngineStatusToggle";
import { RecordNode } from "components/LeftSidebar/RecordNode";

import { EngineMenu, EngineMenuActions } from "./EngineMenu";

import styles from "./styles.module.scss";

type Props = {
  engine: WorkspaceEngine;
  actions: EngineMenuActions;
};

const typeMap = {
  [WorkspaceEngineType.S]: "Small",
  [WorkspaceEngineType.M]: "Medium",
  [WorkspaceEngineType.L]: "Large",
  [WorkspaceEngineType.XL]: "Xlarge",
  [WorkspaceEngineType.None]: "",
};

export const ExpandedEngineRow = (props: Props) => {
  const { engine, actions } = props;
  const type = typeMap[engine.type] || engine.type;

  return (
    <RecordNode className={styles.expanded}>
      <div className={styles.expanded__inner}>
        <div className={styles.column__engines}>
          <span className={styles.column__name}>{engine.engineName}</span>
          <span className={styles.column__description}>
            {engine.description}
          </span>
        </div>
        <div className={styles.columns}>
          <EngineStatusToggle engine={engine} />
          <div className={styles.column}>{type}</div>
          <div className={styles.column}>{engine.nodes}</div>
          <div className={styles.column}>{engine.clusters}</div>
        </div>
        <EngineMenu
          engine={engine}
          actions={actions}
        />
      </div>
    </RecordNode>
  );
};
