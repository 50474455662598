import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AuthType } from "services/fileObjects/getFilesByPath";

import RadioButton from "components/RadioButton/RadioButton";
import { TextInput } from "components/TextInput/TextInput";

import { FormatingFields } from "../FormatData/schema";

import styles from "./styles.module.scss";

type AuthPropsType = {
  register: UseFormReturn<FormatingFields>["register"];
  errors: UseFormReturn<FormatingFields>["formState"]["errors"];
};

const SecretAuthType = (props: AuthPropsType) => {
  const { register, errors } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={
            <span className={styles.errorHandlingAuthLabel}>
              {t("wizard.select_aws.key")}
            </span>
          }
          testId="key-field"
          {...register(`errorSettings.key`)}
          error={!!errors?.errorSettings?.key}
          helperText={errors?.errorSettings?.key?.message}
          required
        />
      </div>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={
            <span className={styles.errorHandlingAuthLabel}>
              {t("wizard.select_aws.secret")}
            </span>
          }
          testId="secret-field"
          {...register(`errorSettings.secret`)}
          error={!!errors?.errorSettings?.secret}
          helperText={errors?.errorSettings?.secret?.message}
          required
        />
      </div>
    </>
  );
};

const IAMRoleAuthType = (props: AuthPropsType) => {
  const { register, errors } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.formControl}>
      <TextInput
        inputRootClassName={styles.inputRoot}
        label={t("wizard.select_aws.iam_role")}
        testId="aim-role-field"
        {...register(`errorSettings.iamRole`)}
        error={!!errors?.errorSettings?.iamRole}
        helperText={errors?.errorSettings?.iamRole?.message}
        required
      />
    </div>
  );
};

const authSettingsByType = {
  secret: SecretAuthType,
  iam: IAMRoleAuthType,
};

export const ErrorFileAuthSelector = (props: {
  form: UseFormReturn<FormatingFields>;
}) => {
  const { form } = props;
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = form;

  const AuthSettingsComponent =
    authSettingsByType[
      watch("errorSettings.authType") as keyof typeof authSettingsByType
    ];
  return (
    <>
      <div className={styles.auth}>
        <div className={styles.label}>{t("wizard.select_aws.auth_label")}</div>
        <div className={styles.radioSelector}>
          <Controller
            control={control}
            name="errorSettings.authType"
            render={({ field: { onChange, value } }) => (
              <>
                <div
                  onClick={() => {
                    onChange(AuthType.SECRET);
                  }}
                  className={styles.radioSelectorItem}
                >
                  <RadioButton selected={value === AuthType.SECRET} />
                  <label className={styles.radioTitle}>
                    {t("wizard.select_aws.auth_secret")}
                  </label>
                </div>

                <div
                  onClick={() => {
                    onChange(AuthType.IAM);
                  }}
                  className={styles.radioSelectorItem}
                >
                  <RadioButton selected={value === AuthType.IAM} />
                  <label className={styles.radioTitle}>
                    {t("wizard.select_aws.auth_iam")}
                  </label>
                </div>
              </>
            )}
          />
        </div>
      </div>
      <AuthSettingsComponent
        register={register}
        errors={errors}
      />
    </>
  );
};
