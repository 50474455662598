export const STICKY_HEIGHT = 30;
export const STICKY_EXPANDED_HEIGHT = 120;
export const ROW_HEIGHT = 30;
export const DEFAULT_COLUMN_WIDTH = 100;
export const SAMPLE_SIZE = 30;
export const SCROLLBAR_WIDTH = 12;
export const MAX_TEXT_COLUMN_WIDTH = 400;
export const DATE_WIDTH = 180;
export const TIMESTAMP_WIDTH = 260;
export const CELL_PADDING = 30;
export const SORT_ICON = 24;
export const TYPE_ICON = 24;
export const FILTER_ICON = 24;
export const HEADER_FONT = `bold 11px Roboto Mono`;
export const CELL_FONT = `normal 11px Roboto Mono`;
