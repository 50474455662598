export const runWhenIdle = (fn: () => void, forceTimeoutMs: number): void => {
  // safari doesn't support requestIdleCallback, so we use setTimeout
  if ("requestIdleCallback" in window) {
    window.requestIdleCallback(fn, {
      timeout: forceTimeoutMs,
    });
  } else {
    setTimeout(fn, 0);
  }
};
