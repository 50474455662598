import { TFunction } from "i18next";
import _find from "lodash/find";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomAccordion from "components/Accordion";
import { CHANGE_FILTERS } from "components/Datagrid/Filters/actions";

import { FilterModalContext } from "../Context";
import AccordionInput from "./AccordionInput";
import { InnerAccordionSummary } from "./AccordionSummary";

import styles from "./styles.module.scss";

const getStringOptions = (t: TFunction) => [
  { value: "contains", text: t("filters.text_contains") },
  {
    value: "starts",
    text: t("filters.text_starts"),
  },
  { value: "ends", text: t("filters.text_ends") },
  {
    value: "not_contains",
    text: t("filters.text_not_contains"),
  },
  { value: "not_starts", text: t("filters.text_not_starts") },
  {
    value: "not_ends",
    text: t("filters.text_not_ends"),
  },
];

export const StringFilter = () => {
  const [expandedItem, setExpandedItem] = useState<string>("");
  const { dispatch, localFilters } = useContext(FilterModalContext);
  const { t } = useTranslation();
  const { filters = {} } = localFilters;
  const stringOptions = useMemo(() => getStringOptions(t), [t]);

  const handleChangeInput = (filterType: string, inputValue: string) => {
    const existingItems: { type: string; value: string }[] =
      filters?.items || [];
    const remainingItems = existingItems.filter(
      ({ type }) => type !== filterType
    );
    dispatch({
      type: CHANGE_FILTERS,
      filters: {
        ...filters,
        items: [...remainingItems, { type: filterType, value: inputValue }],
      },
    });
  };

  return (
    <div
      className={styles.filter}
      data-testid="filter-string"
    >
      {stringOptions.map(item => {
        const inputValue =
          _find(filters.items, { type: item.value })?.value ?? "";
        return (
          <CustomAccordion
            key={item.value}
            expanded={item.value === expandedItem}
            customizations={{ noBorder: true }}
            collapsedSummary={
              <InnerAccordionSummary
                itemText={item.text}
                testId={`filter-${item.value}`}
                onClick={() => setExpandedItem(item.value)}
                hasIcon={!!inputValue}
              />
            }
            expandedSummary={
              <InnerAccordionSummary
                hasIcon={!!inputValue}
                itemText={item.text}
                testId={`filter-${item.value}`}
                onClick={() => setExpandedItem("")}
              />
            }
            accordionDetails={
              <AccordionInput
                value={inputValue}
                name={item.value}
                onChange={handleChangeInput}
                expanded={item.value === expandedItem}
              />
            }
          />
        );
      })}
    </div>
  );
};
