import {
  DialectOptions,
  expandPhrases,
  formatDialect,
  postgresql,
} from "sql-formatter";

const setFlagClauses = ["SET SOME_SET", "SET FIREBOLT_ADVANCED_EXPLAIN"];

const onelineClauses = expandPhrases([
  ...postgresql.formatOptions.onelineClauses,
  ...setFlagClauses,
]);

const fireboltDialect: DialectOptions = {
  name: "firebolt",
  tokenizerOptions: {
    ...postgresql.tokenizerOptions,
    variableTypes: [
      { regex: "[$][1-9]\\d*" },
      { regex: "[$][_a-zA-Z][_a-zA-Z0-9$]*" },
    ],
    stringTypes: [
      { quote: "''-qq-bs", prefixes: ["X"], requirePrefix: true },
      { quote: '""-raw', prefixes: ["U", "X"], requirePrefix: true },
      { quote: "''-raw", prefixes: ["U", "X", "e"], requirePrefix: true },
      '""-bs',
      "''-bs",
    ],
    reservedClauses: [
      ...postgresql.tokenizerOptions.reservedClauses,
      ...onelineClauses,
    ],
    postProcess: tokens => {
      const newTokens: any = [];

      let setFlagClauseOpen = false;

      tokens.forEach((token, index) => {
        if (
          token.type === "RESERVED_CLAUSE" &&
          setFlagClauses.includes(token.text)
        ) {
          setFlagClauseOpen = true;
          newTokens.push(token);
          return;
        }

        if (setFlagClauseOpen) {
          if (token.type === "DELIMITER" && token.text === ";") {
            setFlagClauseOpen = false;

            if (!setFlagClauses.includes(tokens[index + 1]?.text)) {
              newTokens.push(token);
              return;
            }
          }
          newTokens[newTokens.length - 1].text +=
            (token.precedingWhitespace || "") + token.text;
          newTokens[newTokens.length - 1].raw +=
            (token.precedingWhitespace || "") + token.raw;
          return;
        }

        newTokens.push(token);
      });

      return newTokens;
    },
  },
  formatOptions: {
    ...postgresql.formatOptions,
  },
};

type Config = {
  KeywordCase?: string;
  linesBetweenQueries?: number;
};

export default function formatSql(sql: string, config: Config = {}): string {
  return formatDialect(sql, { ...config, dialect: fireboltDialect });
}
