import { CreateEngineInput } from "../../../services/engines/createWorkspaceEngine";
import {
  CREATE_NEW_DATABASE,
  CREATE_NEW_TABLE,
  IngestionStep,
  MapDataStep,
  SupportedExtensions,
  WizardDataType,
} from "../types";
import {
  generateCSVOptionsSql,
  generateColumnsSql,
  generateColumnsSqlForCopy,
  generateCredentialsSql,
  generateErrorSql,
  generatePrimaryIndexesSql,
} from "./sql";
import { normalizeDestinationData } from "./utils";

export const steps = [
  {
    id: IngestionStep.selectEngine,
    title: "Select engine",
    generateScript: (wizardData: WizardDataType) => {
      const { engine } = wizardData[IngestionStep.selectEngine] as {
        engine: CreateEngineInput;
        type: string;
      };

      return `USE ENGINE "${engine.engineName}";\n\n`;
    },
  },
  {
    id: IngestionStep.selectAWS,
    title: "Set up AWS connection",
  },
  {
    id: IngestionStep.selectData,
    title: "Select data to ingest",
  },
  {
    id: IngestionStep.selectDestination,
    title: "Set up destination",
    generateScript: (wizardData: WizardDataType) => {
      const data = wizardData[IngestionStep.selectDestination];
      const { type, databaseName } = normalizeDestinationData(data);
      let sql = "";
      if (type === CREATE_NEW_DATABASE) {
        sql += `CREATE DATABASE "${databaseName}";\n`;
        sql += `USE DATABASE "${databaseName}";\n\n`;
      } else {
        sql += `USE DATABASE "${databaseName}";\n`;
      }
      return sql;
    },
  },
  {
    id: IngestionStep.formatData,
    title: "Format data",
  },
  {
    id: IngestionStep.mapData,
    title: "Map data",
    generateScript: (wizardData: WizardDataType) => {
      const awsData = wizardData[IngestionStep.selectAWS];
      const destination = wizardData[IngestionStep.selectDestination];
      const { tableName, type } = normalizeDestinationData(destination);
      const filesData = wizardData[IngestionStep.selectData];
      const mapData = wizardData[IngestionStep.mapData];
      const formatData = wizardData[IngestionStep.formatData];
      const file = filesData?.files?.[0];
      const columns = generateColumnsSqlForCopy(wizardData);
      const errorSql = generateErrorSql(formatData);

      let sql = "";

      if (type === CREATE_NEW_TABLE || type === CREATE_NEW_DATABASE) {
        const columns = generateColumnsSql(mapData as MapDataStep);
        const primaryIndexes = generatePrimaryIndexesSql(
          mapData as MapDataStep
        );
        sql += `CREATE TABLE "${tableName}"${columns}${primaryIndexes};`;
      }

      const credentialsSql = generateCredentialsSql({
        auth: awsData,
      });

      const optionsSql = ["csv", "tsv"].includes(formatData?.extension!)
        ? generateCSVOptionsSql({ formatData })
        : "TYPE = parquet";

      const withStatement =
        credentialsSql || errorSql || optionsSql
          ? `WITH ${credentialsSql} ${errorSql} ${optionsSql}`
          : "";

      sql += `
COPY INTO "${tableName}" ${columns} FROM '${file?.url}' ${withStatement};
SELECT * FROM "${tableName}" LIMIT 100;
`;
      return sql;
    },
  },
  {
    id: IngestionStep.review,
    title: "Review configuration",
  },
];

export const SUPPORTED_EXTENSIONS: SupportedExtensions[] = [
  "csv",
  "csv.gz",
  "csv.snp",
  "csv.snappy",
  "gz.csv",
  "snp.csv",
  "snappy.csv",
  "parquet",
  "parquet.gz",
  "parquet.snp",
  "parquet.snappy",
  "gz.parquet",
  "snp.parquet",
  "snappy.parquet",
];
