import { TimeGrouping } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/constants";

export const createEngineMonitoringSQLQuery = (
  interval: string | null,
  timeGrouping: TimeGrouping
): string => {
  let sql = `SELECT 
  cluster_ordinal,
  round(avg(emh.cpu_used) * 100) as cpu_avg,
  round(avg(emh.memory_used) * 100) as memory_avg,
  round(avg(emh.disk_used) * 100) as disk_avg,
  round(avg(emh.cache_hit_ratio) * 100) as cache_avg,
  sum(emh.running_queries) as running_queries,
  sum(emh.suspended_queries) as suspended_queries,
  sum(emh.spilled_bytes) as spilled_bytes,
  `;

  switch (timeGrouping) {
    case TimeGrouping.Default:
      sql += `
        emh.event_time as timestamp_c`;
      break;
    case TimeGrouping.Minute:
      sql += `
EXTRACT(year FROM emh.event_time) as year_c,
EXTRACT(month FROM emh.event_time) as month_c,  
EXTRACT(day FROM emh.event_time) as day_c,
EXTRACT(hour FROM emh.event_time) as hour_c,
EXTRACT(minute FROM emh.event_time) as minute_c,
TO_TIMESTAMP(
  CONCAT(
  EXTRACT(year FROM emh.event_time),
  '-',
  EXTRACT(month FROM emh.event_time),
  '-',
  EXTRACT(day FROM emh.event_time),
  ' ',
  EXTRACT(hour FROM emh.event_time),
  ':',
  EXTRACT(minute FROM emh.event_time)
  ),
  'YYYY MM DD HH24:MI') as timestamp_c
`;
      break;
    case TimeGrouping.Hour:
      sql += `
EXTRACT(year FROM emh.event_time) as year_c,
EXTRACT(month FROM emh.event_time) as month_c,
EXTRACT(day FROM emh.event_time) as day_c,
EXTRACT(hour FROM emh.event_time) as hour_c,
TO_TIMESTAMP(
  CONCAT(
  EXTRACT(year FROM emh.event_time),
  '-',
  EXTRACT(month FROM emh.event_time),
  '-',
  EXTRACT(day FROM emh.event_time),
  ' ',
  EXTRACT(hour FROM emh.event_time)
  ),
  'YYYY MM DD HH24') as timestamp_c
  `;
      break;
    case TimeGrouping.Day:
      sql += `
        EXTRACT(year FROM emh.event_time) as year_c,
EXTRACT(month FROM emh.event_time) as month_c,
EXTRACT(day FROM emh.event_time) as day_c,
TO_TIMESTAMP(
  CONCAT(
  EXTRACT(year FROM emh.event_time),
  '-',
  EXTRACT(month FROM emh.event_time),
  '-',
  EXTRACT(day FROM emh.event_time)
  ),
  'YYYY MM DD') as timestamp_c
        `;
      break;

    default:
      break;
  }

  sql += `
FROM
    information_schema.engine_metrics_history as emh`;

  if (interval) {
    sql += ` WHERE event_time > now() - INTERVAL '${interval}'`;
  }

  switch (timeGrouping) {
    case TimeGrouping.Default:
      sql += `
GROUP BY
    emh.event_time, cluster_ordinal`;
      break;
    case TimeGrouping.Minute:
      sql += `
GROUP BY
    year_c,
    month_c,
    day_c,
    hour_c,
    minute_c, cluster_ordinal`;
      break;
    case TimeGrouping.Hour:
      sql += `
GROUP BY
    year_c,
    month_c,
    day_c,
    hour_c, cluster_ordinal`;
      break;
    case TimeGrouping.Day:
      sql += `
GROUP BY
    year_c,
    month_c,
    day_c, cluster_ordinal`;
      break;

    default:
      break;
  }

  sql += `
ORDER BY
    timestamp_c ASC, cluster_ordinal ASC;`;

  return sql;
};
