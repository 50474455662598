import { PermissionError } from "../types";
import { MissingDatabasePermission } from "./MissingDatabasePermission";
import { MissingSchemaPermission } from "./MissingSchemaPermission";

const errorTypes = {
  database: MissingDatabasePermission,
  schema: MissingSchemaPermission,
};

type Props = {
  errors: PermissionError[];
  resource: string;
};

export const MissingPermission = (props: Props) => {
  const { errors, resource } = props;
  const filteredErrors = errors.filter(error => !error.ignored);
  return (
    <>
      {filteredErrors.map(error => {
        const Component = errorTypes[error.resource as keyof typeof errorTypes];
        return (
          <Component
            key={error.resource}
            error={error}
            resource={resource}
          />
        );
      })}
    </>
  );
};
