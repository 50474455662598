import { Portal, Snackbar } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import CloseIcon from "assets/icons/Close.svg?react";
import Exclamation from "assets/icons/Exclamation.svg?react";
import SuccessIcon from "assets/icons/SucessIcon.svg?react";

import Spinner from "components/Spinner";

import styles from "./styles.module.scss";

type MessageT = string | React.ReactElement;

type Props = {
  vertical?: any;
  horizontal?: any;
  openSnackBar?: boolean;
  closeSnackBar?: () => void;
  type?: "success" | "error" | "loading";
  message: MessageT;
  closeIcon?: boolean;
  displayInline?: boolean;
  classes?: any;
  snackBarKey?: string;
  autoHideDuration?: number | null;
  dimmed?: boolean;
};

const MessageType: React.FC<{ type?: string }> = props => {
  const { type } = props;
  switch (type) {
    case "error":
      return <Exclamation className={styles.messageIcon} />;
    case "success":
      return <SuccessIcon className={styles.messageIcon} />;
    case "loading":
      return <Spinner size={22} />;
    default:
      return <Exclamation className={styles.messageIcon} />;
  }
};

const Message: React.FC<{ message: MessageT }> = props => {
  const { message } = props;
  const { t } = useTranslation();
  if (typeof message === "string") {
    return <span className={styles.messageText}>{t(message as string)}</span>;
  }

  return <div className={styles.messageText}>{message}</div>;
};

export const SnackBar: React.FC<Props> = props => {
  const {
    openSnackBar,
    vertical,
    horizontal,
    closeSnackBar,
    type,
    closeIcon,
    snackBarKey,
    autoHideDuration = null,
    displayInline,
    classes,
    dimmed = false,
    message,
  } = props;

  const component = (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={snackBarKey}
      open={openSnackBar}
      autoHideDuration={autoHideDuration}
      onClose={closeSnackBar}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: {
          root: classNames(styles.snackBarContent, classes?.snackbar, {
            [styles.errorSnackBar]: type === "error",
            [styles.successSnackBar]: type === "success",
            [styles.loadingSnackBar]: type === "loading",
          }),
        },
        onClick: event => {
          event.stopPropagation();
        },
      }}
      classes={{
        root: classNames(styles.root, classes?.root),
        anchorOriginTopCenter: classNames(styles.anchorOriginTopCenter, {
          [styles.dimmed]: dimmed,
        }),
      }}
      onClick={closeSnackBar}
      message={
        <div className={styles.messageWrap}>
          <MessageType type={type} />
          <Message message={message} />
        </div>
      }
      action={
        closeIcon
          ? [
              <CloseIcon
                key="close-icon"
                onClick={closeSnackBar}
                className={styles.closeImg}
              />,
            ]
          : undefined
      }
    />
  );

  return displayInline ? component : <Portal>{component}</Portal>;
};
