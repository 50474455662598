import _orderBy from "lodash/orderBy";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
  WorkspaceEngineType,
} from "services/engines/engine.types";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import { useLayoutToggles } from "components/LayoutToggles/context";

import { useCreateEngine } from "../CreateMenu/useCreateEngine";
import { EmptySearch } from "../EmptyState/EmptySearch";
import { EmptyState, EngineIcon } from "../EmptyState/EmptyState";
import { Search, useSearchHandlers } from "../Search";
import { CollapsedPanel } from "./CollapsedPanel";
import { useDeleteEngine } from "./EngineRow/useDeleteEngine";
import { useEditEngine } from "./EngineRow/useEditEngine";
import { ExpandedPanel } from "./ExpandedPanel";
import { OrderBy, OrderDirection } from "./types";

const workspaceEngineStatusOrder = {
  [WorkspaceEngineStatus.STOPPED]: 1,
  [WorkspaceEngineStatus.STOPPING]: 2,
  [WorkspaceEngineStatus.DRAINING]: 3,

  [WorkspaceEngineStatus.RESIZING]: 4,

  [WorkspaceEngineStatus.STARTING]: 5,
  [WorkspaceEngineStatus.RUNNING]: 6,
};

const workspaceEngineTypeOrder = {
  [WorkspaceEngineType.None]: 1,
  [WorkspaceEngineType.S]: 2,
  [WorkspaceEngineType.M]: 3,
  [WorkspaceEngineType.L]: 4,
  [WorkspaceEngineType.XL]: 5,
};

export const EnginesPanel = React.memo(() => {
  const { t } = useTranslation();
  const { onChange, search } = useSearchHandlers();
  const { data: engines } = useWorkspaceEngines();
  const { createEngineMarkup, openCreateEngine } = useCreateEngine();

  const { openEditEngine, editEngineMarkup } = useEditEngine();
  const { openDeleteEngine, deleteEngineMarkup } = useDeleteEngine();
  const [order, setOrder] = useState<{
    orderDirection: OrderDirection;
    orderBy: OrderBy;
  }>({
    orderDirection: OrderDirection.ASC,
    orderBy: OrderBy.name,
  });

  const { layout } = useLayoutToggles();

  const filtered = (engines || []).filter(engine => {
    return engine.engineName.toLowerCase().includes(search.toLowerCase());
  });

  const sorted = _orderBy(
    filtered,
    [
      (engine: WorkspaceEngine) => {
        if (order.orderBy === OrderBy.type) {
          const weight = workspaceEngineTypeOrder[engine.type];
          return weight;
        }
        if (order.orderBy === OrderBy.status) {
          const weight = workspaceEngineStatusOrder[engine.status];
          return weight;
        }
        if (order.orderBy === OrderBy.clusters) {
          return Number(engine.clusters);
        }
        if (order.orderBy === OrderBy.nodes) {
          return Number(engine.nodes);
        }
        return engine.engineName.toLowerCase();
      },
      "engineName",
    ],
    [order.orderDirection]
  );

  const handleChangeOrder = (orderParams: { orderBy: OrderBy }) => {
    setOrder(current => {
      if (current.orderBy === orderParams.orderBy) {
        return {
          ...orderParams,
          orderDirection:
            current.orderDirection === OrderDirection.ASC
              ? OrderDirection.DESC
              : OrderDirection.ASC,
        };
      }
      return {
        ...orderParams,
        orderDirection: OrderDirection.ASC,
      };
    });
  };

  if (!engines?.length) {
    return (
      <>
        <Search
          onChange={onChange}
          value={search}
          testId="engines-search-input"
        />
        <EmptyState
          icon={<EngineIcon />}
          title={t("workspace.engines.empty")}
          subtitle={t("workspace.engines.empty_subtitle")}
          button={t("workspace.engines.empty_button")}
          onClick={openCreateEngine}
          testId="engines-empty-list"
        />
        {createEngineMarkup}
      </>
    );
  }

  if (search && !filtered.length) {
    return (
      <>
        <Search
          onChange={onChange}
          value={search}
          testId="engines-search-input"
        />
        <EmptySearch
          search={search}
          testId="empty-engines-search"
        />
      </>
    );
  }

  const actions = {
    openEditEngine,
    openDeleteEngine,
  };

  return (
    <>
      <Search
        onChange={onChange}
        value={search}
        testId="engines-search-input"
      />
      {layout.engines?.expanded ? (
        <ExpandedPanel
          items={sorted}
          onChangeOrder={handleChangeOrder}
          order={order}
          actions={actions}
          testId="engines-expanded-list-panel"
        />
      ) : (
        <CollapsedPanel
          items={sorted}
          actions={actions}
          testId="engines-list-panel"
        />
      )}
      {editEngineMarkup}
      {deleteEngineMarkup}
    </>
  );
});
