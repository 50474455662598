import { WorkspaceEngine } from "services/engines/engine.types";

import { Panel } from "../Panel";
import { EngineMenuActions } from "./EngineRow/EngineMenu";
import { ExpandedEngineRow } from "./EngineRow/ExpandedEngineRow";
import { ExpandedHeader } from "./ExpandedHeader";
import { OrderBy, OrderDirection } from "./types";

import styles from "./styles.module.scss";

export const ExpandedPanel = (props: {
  items: WorkspaceEngine[];
  onChangeOrder: (order: { orderBy: OrderBy }) => void;
  order: {
    orderDirection: OrderDirection;
    orderBy: OrderBy;
  };
  actions: EngineMenuActions;
  testId?: string;
}) => {
  const { items, onChangeOrder, order, actions, testId } = props;
  return (
    <Panel testId={testId}>
      <ExpandedHeader
        onChangeOrder={onChangeOrder}
        order={order}
      />
      <div className={styles.enginesList}>
        {items.map(engine => {
          return (
            <ExpandedEngineRow
              key={engine.engineName}
              engine={engine}
              actions={actions}
            />
          );
        })}
      </div>
    </Panel>
  );
};
