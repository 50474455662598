import { systemEngineEnvironment } from "services/environment/systemEngine";

import { Role, SqlRole } from "./types";

export const getRoles = async (): Promise<Role[]> => {
  const result = await systemEngineEnvironment.execute<SqlRole>(
    `
SELECT
  er.role_name,
  er.created,

  ARRAY_DISTINCT(ARRAY_AGG(
    ar.grantee
  )) as grantees,

  ARRAY_DISTINCT(ARRAY_AGG(
    CASE
      WHEN op.privilege_type IS NOT NULL
    THEN
      [
        op.privilege_type,
        op.object_type,
        op.object_name,
        op.created::text
      ]
    ELSE
      NULL
    END
)) as privileges

FROM
information_schema.enabled_roles er

LEFT JOIN
information_schema.applicable_roles ar
ON
ar.role_name = er.role_name

LEFT JOIN
  information_schema.object_privileges op
ON
op.grantee = er.role_name

GROUP BY ALL
`
  );
  const [response] = result;

  return response.rows.map(role => {
    const privileges = role.privileges
      ? role.privileges.map(privilege => {
          if (!privilege)
            return {
              privilegeType: "",
              objectType: "",
              objectName: "",
              created: "",
            };
          const [privilegeType, objectType, objectName, created] = privilege;
          return {
            privilegeType,
            objectType,
            objectName,
            created,
          };
        })
      : [];

    return { ...role, privileges, grantees: role.grantees || [] };
  });
};
