import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import MoreVerticalIcon from "assets/icons/MoreVertical.svg?react";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import Tooltip from "components/Tooltip/Tooltip";

import { saveToFile } from "./saveToFile";

import styles from "./ExportMenu.module.scss";

interface ExportData {
  rows: Record<string, unknown>[];
}

interface ExportMenuProps {
  exportData: ExportData;
}

const ExportMenu = (props: ExportMenuProps) => {
  const { exportData } = props;

  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorEl = useRef<HTMLDivElement | null>(null);

  if (!exportData.rows.length) {
    return null;
  }

  return (
    <>
      <Tooltip
        enterDelay={300}
        title={t("document_output.menu.more_options")}
        placement="top-start"
      >
        <div
          className={cn(styles.iconContainer, {
            [styles.active]: menuOpen,
          })}
          ref={menuAnchorEl}
          onClick={() => {
            setMenuOpen(true);
          }}
          data-testid="export-results-icon"
        >
          <MoreVerticalIcon />
        </div>
      </Tooltip>

      {menuOpen && (
        <Popover
          open={menuOpen}
          anchorEl={menuAnchorEl.current}
          onClose={() => {
            setMenuOpen(false);
          }}
          sx={{ transform: "translate(0px, -5px)" }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ContextMenu>
            <ContextMenuItem
              key="export-as-json"
              text={t("export_menu.export_json")}
              testId="export-as-json"
              onClick={() => {
                setMenuOpen(false);
                saveToFile(exportData.rows, "JSON", ",")
                  .then(() => {})
                  .catch(e => {
                    console.log(e);
                  });
              }}
            />
            <ContextMenuItem
              key="export-as-csv"
              text={t("export_menu.export_csv")}
              testId="export-as-csv"
              onClick={() => {
                setMenuOpen(false);
                saveToFile(exportData.rows, "CSV", ",")
                  .then(() => {})
                  .catch(e => {
                    console.log(e);
                  });
              }}
            />
          </ContextMenu>
        </Popover>
      )}
    </>
  );
};

export default ExportMenu;
