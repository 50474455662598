import _startCase from "lodash/startCase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";
import { RenderSelectValue } from "./SelectValue";
import { ALL_DATABASES, ANY_DATABASE } from "./constant";
import { usePrivilegesHandlers } from "./usePrivilegesHandlers";
import { getOtherGroupsObjects } from "./utils";

import styles from "./styles.module.scss";

const any_object_privileges = ["MODIFY", "USAGE"];
const privileges = ["MODIFY", "USAGE", "USAGE ANY SCHEMA", "VACUUM ANY"];

type Props = {
  index: number;
  resource: string;
};

export const DatabasesGroup = (props: Props) => {
  const { index, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const menu = useMenu();
  const databases = useDatabasesNames();
  const { t } = useTranslation();

  const groupState = privilegesState.database[index];

  const otherGroupsObjects = getOtherGroupsObjects(
    privilegesState.database,
    index
  );

  const { expanded, objects, toAssign, toRevoke } = groupState;

  const dependencies = useRef({
    index,
    privilegesDispatch,
    resource,
    objects,
  });

  const {
    handleMore,
    onGrantPrivileges,
    onRevokePrivileges,
    onSelectResourceObjects,
    deleteGroup,
  } = usePrivilegesHandlers({ index, resource });

  useEffect(() => {
    const { index, privilegesDispatch, resource, objects } =
      dependencies.current;
    if (!objects.length) {
      privilegesDispatch({
        type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
        resource,
        objects: [ALL_DATABASES],
        index,
      });
    }
  }, [dependencies]);

  const items = [
    [
      {
        key: "add_remaining",
        text: t("roles_wizard.privileges.menu.add_remaining_databases"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
            resource,
            allObjects: databases.map(({ catalogName }) => catalogName),
          });
        },
        testId: "roles-wizard-add-remaining-databases",
      },
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
            objects: [ALL_DATABASES],
          });
        },
        testId: "roles-wizard-add-databases-group",
      },
    ],

    ...(privilegesState.database.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: deleteGroup,
              testId: "roles-wizard-delete-databases-group",
            },
          ],
        ]
      : []),
  ];

  const selectedItems = [
    <ContextMenuItem
      value={ALL_DATABASES}
      key={ALL_DATABASES}
      checked={objects.includes(ALL_DATABASES)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.all_databases")}
      direction="left"
      bulkItem
      skipFilter
    />,
    <ContextMenuItem
      value={ANY_DATABASE}
      key={ANY_DATABASE}
      checked={objects.includes(ANY_DATABASE)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.any_database")}
      direction="left"
      bulkItem
      skipFilter
    />,
    <ContextMenuDivider key="divider" />,
    ...databases.map(database => {
      const { catalogName } = database;
      const disabled = otherGroupsObjects[catalogName];
      const disabledTooltip = disabled
        ? t("roles_wizard.privileges.disabled_database_tooltip", {
            database: catalogName,
          })
        : undefined;
      return (
        <ContextMenuItem
          value={catalogName}
          key={catalogName}
          checked={objects.includes(catalogName)}
          disabled={disabled}
          disabledTooltip={disabledTooltip}
          checkedIconPlaceholder={true}
          text={catalogName}
          direction="left"
        />
      );
    }),
  ];

  const privilegesOptions = (
    objects.includes(ANY_DATABASE) ? any_object_privileges : privileges
  ).map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase().replace("usage", "use")),
    };
  });

  const renderSpecialValue = (value: string) => {
    if (value === ALL_DATABASES) {
      return t("roles_wizard.privileges.all_databases");
    }
    if (value === ANY_DATABASE) {
      return t("roles_wizard.privileges.any_database");
    }
    return value;
  };

  const handleSelectResourceObjects = (items: string[]) => {
    if (items.includes(ANY_DATABASE)) {
      const filteredAssign = toAssign.filter(privilege =>
        any_object_privileges.includes(privilege)
      );
      const filteredRevoke = toRevoke.filter(privilege =>
        any_object_privileges.includes(privilege)
      );
      onGrantPrivileges(filteredAssign);
      onRevokePrivileges(filteredRevoke);
    }
    onSelectResourceObjects(items);
  };

  return (
    <>
      <div className={styles.group}>
        <OutlinedSelect
          wrapperClassName={styles.select__objects}
          multiple
          onSelect={handleSelectResourceObjects}
          controlledValue={objects}
          renderValue={items => {
            return (
              <RenderSelectValue
                items={items}
                expanded={expanded}
                handleMore={handleMore}
                title={t("roles_wizard.privileges.selected_databases")}
              />
            );
          }}
          allowUncheck
          searchOptions={{
            searchPlaceholder: t("roles_wizard.privileges.search_database"),
          }}
          noBorder
          testId={`roles-wizard-select-${resource}-${index}`}
        >
          {selectedItems}
        </OutlinedSelect>
        <GrantPrivilegesSelector
          onChange={onGrantPrivileges}
          privileges={privilegesOptions}
          resource={resource}
          index={index}
        />

        <RevokePrivilegeSelector
          onChange={onRevokePrivileges}
          privileges={privilegesOptions}
          resource={resource}
          index={index}
        />
        <Menu
          items={items}
          menu={menu}
          testId={`roles-wizard-${resource}-${index}-menu`}
          alwaysVisible
        />
      </div>

      {expanded && (
        <div className={styles.objects}>
          {objects.map(object => {
            const value = renderSpecialValue(object);
            return (
              <div
                className={styles.object}
                key={object}
              >
                {value}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
