import { SqlRbacAction } from "services/rbac/action";

export const ADD_ACCOUNT_RESOURCE_PRIVILEGE = "ADD_ACCOUNT_RESOURCE_PRIVILEGE";
export const ADD_ENGINE_RESOURCE_PRIVILEGE = "ADD_ENGINE_RESOURCE_PRIVILEGE";
export const ADD_ENGINE_RESOURCE_PRIVILEGE_BULK =
  "ADD_ENGINE_RESOURCE_PRIVILEGE_BULK";
export const ADD_DATABASE_RESOURCE_PRIVILEGE =
  "ADD_DATABASE_RESOURCE_PRIVILEGE";
export const ADD_DATABASE_RESOURCE_PRIVILEGE_BULK =
  "ADD_DATABASE_RESOURCE_PRIVILEGE_BULK";
export const DELETE_ACCOUNT_RESOURCE_PRIVILEGE =
  "DELETE_ACCOUNT_RESOURCE_PRIVILEGE";
export const DELETE_ENGINE_RESOURCE_PRIVILEGE =
  "DELETE_ENGINE_RESOURCE_PRIVILEGE";
export const DELETE_ENGINE_RESOURCE_PRIVILEGE_BULK =
  "DELETE_ENGINE_RESOURCE_PRIVILEGE_BULK";
export const DELETE_DATABASE_RESOURCE_PRIVILEGE =
  "DELETE_DATABASE_RESOURCE_PRIVILEGE";
export const DELETE_DATABASE_RESOURCE_PRIVILEGE_BULK =
  "DELETE_DATABASE_RESOURCE_PRIVILEGE_BULK";

type ResourcePrivilages = Record<string, Record<SqlRbacAction, boolean>>;

type BulkAction = {
  resources: string[];
  privilege: SqlRbacAction;
};

export const privilegesReducer = (state: any, action: any) => {
  switch (action.type) {
    case ADD_ACCOUNT_RESOURCE_PRIVILEGE: {
      const { resource, privilege } = action;
      const { id } = resource;
      const accountPrivileges = state.account[id] || {};

      return {
        ...state,
        account: {
          ...state.account,
          [id]: { ...accountPrivileges, [privilege]: true },
        },
      };
    }
    case ADD_ENGINE_RESOURCE_PRIVILEGE: {
      const { resource, privilege } = action;
      const { id } = resource;
      const enginePrivileges = state.engine[id] || {};

      return {
        ...state,
        engine: {
          ...state.engine,
          [id]: { ...enginePrivileges, [privilege]: true },
        },
      };
    }
    case ADD_ENGINE_RESOURCE_PRIVILEGE_BULK: {
      const { resources, privilege } = action as BulkAction;

      const engine = resources.reduce<ResourcePrivilages>((acc, resource) => {
        const oldEnginePrivileges = state.engine[resource] || {};
        acc[resource] = {
          ...oldEnginePrivileges,
          [privilege]: true,
        };
        return acc;
      }, {});

      return {
        ...state,
        engine: {
          ...state.engine,
          ...engine,
        },
      };
    }
    case ADD_DATABASE_RESOURCE_PRIVILEGE: {
      const { resource, privilege } = action;
      const { id } = resource;
      const databasePrivileges = state.database[id] || {};

      const newState = {
        ...state,
        database: {
          ...state.database,
          [id]: {
            ...databasePrivileges,
            [privilege]: true,
          },
        },
      };

      return newState;
    }
    case ADD_DATABASE_RESOURCE_PRIVILEGE_BULK: {
      const { resources, privilege } = action as BulkAction;

      const database = resources.reduce<
        Record<string, Record<SqlRbacAction, boolean>>
      >((acc, resource) => {
        const oldDatabasePrivileges = state.database[resource];
        acc[resource] = {
          ...oldDatabasePrivileges,
          [privilege]: true,
        };
        return acc;
      }, {});

      const newState = {
        ...state,
        database: {
          ...state.database,
          ...database,
        },
      };

      return newState;
    }
    case DELETE_ACCOUNT_RESOURCE_PRIVILEGE: {
      const { resource, privilege } = action;
      const { id } = resource;
      const accountPrivileges = state.account[id] || {};
      return {
        ...state,
        account: {
          ...state.account,
          [id]: { ...accountPrivileges, [privilege]: false },
        },
      };
    }
    case DELETE_ENGINE_RESOURCE_PRIVILEGE: {
      const { resource, privilege } = action;
      const { id } = resource;
      const enginePrivileges = state.engine[id];
      return {
        ...state,
        engine: {
          ...state.engine,
          [id]: { ...enginePrivileges, [privilege]: false },
        },
      };
    }
    case DELETE_ENGINE_RESOURCE_PRIVILEGE_BULK: {
      const { privilege } = action;

      const engine = Object.keys(state.engine).reduce<ResourcePrivilages>(
        (acc, id) => {
          if (id === "*") {
            acc[id] = state.engine[id];
          } else {
            acc[id] = {
              ...state.engine[id],
              [privilege]: false,
            };
          }
          return acc;
        },
        {}
      );

      return {
        ...state,
        engine,
      };
    }
    case DELETE_DATABASE_RESOURCE_PRIVILEGE: {
      const { resource, privilege } = action;
      const { id } = resource;
      const databasePrivileges = state.database[id];
      return {
        ...state,
        database: {
          ...state.database,
          [id]: { ...databasePrivileges, [privilege]: false },
        },
      };
    }
    case DELETE_DATABASE_RESOURCE_PRIVILEGE_BULK: {
      const { privilege } = action;

      const database = Object.keys(state.database).reduce<ResourcePrivilages>(
        (acc, id) => {
          if (id === "*") {
            acc[id] = state.database[id];
          } else {
            acc[id] = {
              ...state.database[id],
              [privilege]: false,
            };
          }
          return acc;
        },
        {}
      );

      return {
        ...state,
        database,
      };
    }
    default: {
      return state;
    }
  }
};
