import { useTranslation } from "react-i18next";

import { Role } from "services/rbac/types";

import { Modal } from "./Modal/Modal";

type Props = {
  viewRole: Role;
  onClose: () => void;
  roles: Role[];
};

export const ViewModal = (props: Props) => {
  const { onClose, viewRole, roles } = props;
  const { t } = useTranslation();
  return (
    <Modal
      title={t("roles.view_modal.title")}
      onClose={onClose}
      role={viewRole}
      roles={roles}
      onConfirm={() => {}}
      testId="view-role-modal"
      readOnly
      affectedOnly
    />
  );
};
