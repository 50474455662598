import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { WorkspaceEngine } from "services/engines/engine.types";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { Auth, File, getFilesByPath } from "./getFilesByPath";

export const useRootFiles = ({
  bucketName,
  bucketProtocol,
  rootFolder,
  engine,
  auth,
}: {
  engine: WorkspaceEngine | undefined;
  bucketName: string;
  bucketProtocol: string;
  rootFolder: string;
  auth: Auth;
}): File[] => {
  const authKeySegment = (() => {
    if (auth.authType === "none") {
      return "";
    }

    if (auth.authType === "secret") {
      return `${auth.key}-${auth.secret}`;
    }

    return auth.iamRole;
  })();

  const { data } = useSuspenseQuery({
    queryKey: [
      ReactQueryKeysAccount.loadFiles,
      bucketName,
      rootFolder,
      auth.authType,
      authKeySegment,
      engine?.engineName,
    ],
    queryFn: () =>
      getFilesByPath({
        engine,
        bucketName,
        bucketProtocol,
        path: rootFolder,
        auth,
      }),
    gcTime: 0,
  });
  return data || [];
};

export const useFiles = ({
  engine,
  bucketName,
  bucketProtocol,
  auth,
}: {
  engine: WorkspaceEngine | undefined;
  bucketName: string;
  bucketProtocol: string;
  auth: Auth;
}) => {
  const queryClient = useQueryClient();

  const authKeySegment = (() => {
    if (auth.authType === "none") {
      return "";
    }

    if (auth.authType === "secret") {
      return `${auth.key}-${auth.secret}`;
    }

    return auth.iamRole;
  })();

  const loadFiles = async (path: string) => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: [
          ReactQueryKeysAccount.loadFiles,
          bucketName,
          path,
          auth.authType,
          authKeySegment,
          engine?.engineName,
        ],
        retry: false,
        queryFn: () =>
          getFilesByPath({ engine, bucketName, bucketProtocol, path, auth }),
        gcTime: 0,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return {
    loadFiles,
  };
};
