import classNames from "classnames";

import styles from "./styles.module.scss";

export const RecordNode = (
  props: React.PropsWithChildren<{ className?: string }>
) => {
  const { children, className } = props;
  return <div className={classNames(styles.node, className)}>{children}</div>;
};
