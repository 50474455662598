import { useContext } from "react";

import { ActiveEditorViewContext } from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/ActiveEditorViewContext";

const useActiveEditorView = () => {
  const {
    activeEditorView,
    setActiveEditorView,
    insertTextAtCursorPosition,
    formatText,
  } = useContext(ActiveEditorViewContext);
  return {
    activeEditorView,
    setActiveEditorView,
    insertTextAtCursorPosition,
    formatText,
  };
};

export default useActiveEditorView;
