import { systemEngineEnvironment } from "services/environment/systemEngine";

type Schema = {
  tableSchema: string;
};
export const getSchemaNames = async (database: string) => {
  const result = await systemEngineEnvironment.execute<Schema>(
    `select table_schema from information_schema.tables group by table_schema`,
    { database }
  );

  const [response] = result;
  return response.rows;
};
