import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";

import { bulkItemTitleMap } from "../PrivilegesTable/constant";
import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_IGNORE_ERROR,
  ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
} from "../privilegesReducer";
import { PermissionError, PrivilegesState } from "../types";
import { usePrivilegesState } from "../usePrivilegesContext";
import { MissingPermissionError } from "./MissingPermissionError";

type Props = {
  error: PermissionError;
  resource: string;
};

export const MissingSchemaPermission = (props: Props) => {
  const { t } = useTranslation();
  const { error, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const addRelatedPermissions = () => {
    const { resource, action, object, catalogName } = error;
    const resourceGroups = privilegesState[resource as keyof PrivilegesState];
    privilegesDispatch({
      type: ROLES_WIZARD_ADD_GROUP_EMPTY,
      resource: "schema",
      objects: [{ name: object, catalogName }],
    });

    privilegesDispatch({
      type: ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
      resource: "schema",
      privileges: [action],
      index: resourceGroups.length,
    });
  };

  const ignorePermissionError = () => {
    privilegesDispatch({
      type: ROLES_WIZARD_IGNORE_ERROR,
      resource,
      error,
    });
  };

  const { errorSource } = error;
  const { name, actions } = errorSource;
  const resourceName = bulkItemTitleMap[name as keyof typeof bulkItemTitleMap]
    ? t(bulkItemTitleMap[name as keyof typeof bulkItemTitleMap])
    : name;
  return (
    <MissingPermissionError
      addRelatedPermissions={addRelatedPermissions}
      ignorePermissionError={ignorePermissionError}
      primaryActionText={t("roles_wizard.missing_permission.add")}
      message={t("roles_wizard.missing_permission.message_schema", {
        permission: actions
          .map(action => _startCase(action.toLocaleLowerCase()))
          .join(", "),
        resource: resourceName,
      })}
    />
  );
};
