import styles from "components/IngestionWizard/Steps/styles.module.scss";

const InlineLink = ({
  children,
  href,
}: React.PropsWithChildren & {
  href: string;
}) => {
  return (
    <a
      href={href}
      target="_blank"
      className={styles.inlineLink}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default InlineLink;
