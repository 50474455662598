import { useSuspenseQuery } from "@tanstack/react-query";

import { ServiceAccount } from "services/serviceAccounts/types";

import { ReactQueryKeysGlobal } from "../queryKeys";
import { getServiceAccounts } from "./getServiceAccounts";

export const useServiceAccounts = () => {
  const { data, isLoading, error } = useSuspenseQuery<ServiceAccount[], Error>({
    queryKey: [ReactQueryKeysGlobal.serviceAccounts],
    queryFn: () => getServiceAccounts(),
    retry: false,
  });
  return { data: data || [], isLoading, error };
};
