import { useCallback, useContext } from "react";

import {
  InteractionsActionsContext,
  StickyGridContext,
} from "components/Datagrid/context";

import { MOUSEMOVE } from "../state/actions";
import { getEventType } from "../utils/getEventType";
import { isRightClick } from "../utils/interactions";

export const useHeaderMouseEvents = () => {
  const { rowCount, handleResize } = useContext(StickyGridContext);
  const { getCursorIndex, dispatch } = useContext(InteractionsActionsContext);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const cursorIndex = getCursorIndex(event, false);

      if (cursorIndex) {
        dispatch({
          type: MOUSEMOVE,
          cursorIndex,
          columnSelection: { end: rowCount - 1 },
        });
      }
    },
    [getCursorIndex, dispatch, rowCount]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener("mousemove", handleMouseMove);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (event: React.SyntheticEvent) => {
      event.nativeEvent.stopImmediatePropagation();
      if (isRightClick({ event })) {
        event.preventDefault();
        return;
      }
      const cursorIndex = getCursorIndex(event);
      if (!cursorIndex) {
        return;
      }
      const type = getEventType(event);
      dispatch({
        type,
        cursorIndex,
        columnSelection: { end: rowCount - 1 },
      });
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [getCursorIndex, handleMouseUp, handleMouseMove, rowCount, dispatch]
  );

  const handleResizeDoubleClick = useCallback(
    (
      index: number,
      column: {
        initialWidth: number;
      }
    ) => {
      const { initialWidth } = column;
      handleResize(index, initialWidth);
    },
    [handleResize]
  );

  return {
    handleMouseDown,
    handleResizeDoubleClick,
  };
};
