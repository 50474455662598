import classNames from "classnames";

import styles from "./styles.module.scss";

type Props = {
  noIndent?: boolean;
  className?: string;
  testId?: string;
};

export const RecordLabel = (props: React.PropsWithChildren<Props>) => {
  const { children, noIndent, className, testId } = props;
  return (
    <div
      className={classNames(styles.label, className, {
        [styles.noIndent]: noIndent,
      })}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
