import * as Sentry from "@sentry/browser";
import React from "react";

type Props = {
  renderError: (error: Error) => JSX.Element | null;
} & React.PropsWithChildren;

type State = {
  error: Error | null;
};

export class SimpleErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error);
    Sentry.withScope(scope => {
      scope.setExtras({
        ...errorInfo,
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { children, renderError } = this.props;
    return this.state.error ? renderError(this.state.error) : children;
  }
}
