import React, { useContext, useMemo } from "react";

import { StickyGridContext, WidthContext } from "components/Datagrid/context";
import { useHeaderMouseEvents } from "components/Datagrid/hooks/useHeaderMouseEvents";
import { useResize } from "components/Datagrid/hooks/useResize";
import { ColumnWithProps, FAKE_SPACER } from "components/Datagrid/types";

import { STICKY_EXPANDED_HEIGHT, STICKY_HEIGHT } from "../constant";
import { HeaderFakeItem } from "./HeaderFakeItem";
import { HeaderItem } from "./HeaderItem";

import styles from "./styles.module.scss";

const headerBuilder = ({
  columnLeftOffsets,
  columns: headerColumns,
  dropdownExpand,
  getColumnWidth,
  maxColumn,
  minColumn,
}: {
  columnLeftOffsets: number[];
  columns: ColumnWithProps[];
  dropdownExpand: boolean;
  getColumnWidth: (index: number) => number;
  maxColumn: number;
  minColumn: number;
}) => {
  const columns: {
    style: React.CSSProperties;
    column: ColumnWithProps;
    index: number;
  }[] = [];

  for (let i = minColumn; i <= maxColumn; i++) {
    const style: React.CSSProperties = {
      height: dropdownExpand ? STICKY_EXPANDED_HEIGHT : STICKY_HEIGHT,

      width: getColumnWidth(i),
      left: columnLeftOffsets[i],
    };

    columns.push({
      style,
      column: headerColumns[i],
      index: i,
    });
  }

  return columns;
};

export const Header = React.memo<{
  minColumn: number;
  maxColumn: number;
}>(({ minColumn, maxColumn }) => {
  const { handleResize, columns, columnLeftOffsets, dropdownExpand } =
    useContext(StickyGridContext);

  const { getColumnWidth, width } = useContext(WidthContext);

  const headerColumns = useMemo(
    () =>
      headerBuilder({
        columnLeftOffsets,
        columns,
        dropdownExpand,
        getColumnWidth,
        maxColumn,
        minColumn,
      }),
    /* eslint-disable */
    [
      columnLeftOffsets,
      columns,
      dropdownExpand,
      getColumnWidth,
      maxColumn,
      minColumn,
      width,
    ]
    /* eslint-enable */
  );

  const { getItemProps } = useResize({
    onResizeFinish: handleResize,
  });

  const { handleMouseDown, handleResizeDoubleClick } = useHeaderMouseEvents();

  return (
    <div
      className={styles.header}
      data-testid="datagrid-header"
    >
      <div className={styles.header__scrollable}>
        {headerColumns.map(({ column, style, index }) => {
          if (column?.type === FAKE_SPACER) {
            return (
              <HeaderFakeItem
                key={index}
                style={style}
              />
            );
          }
          return (
            <HeaderItem
              column={column}
              index={index}
              key={index}
              style={style}
              onMouseDown={handleMouseDown}
              handleResizeDoubleClick={handleResizeDoubleClick}
              {...getItemProps({ ...column, index })}
            />
          );
        })}
      </div>
    </div>
  );
});
