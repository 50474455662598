export const createURLSearchParams = (
  existingSearchParams: URLSearchParams,
  params: { [key: string]: string }
) => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of [
    ...existingSearchParams.entries(),
    ...Object.entries(params),
  ]) {
    searchParams.append(key, value);
  }

  return searchParams.toString();
};

export const buildEngineUrl = (
  engineURL: URL,
  params: { [key: string]: string }
) => {
  const searchParams = createURLSearchParams(engineURL.searchParams, params);

  return `${engineURL.protocol}//${engineURL.host}${engineURL.pathname}?${searchParams}`;
};
