import { useState } from "react";

export function useActiveStep<T>({
  initialStep,
  steps,
  onNextStep,
  onPreviousStep,
  onSelectStep,
}: {
  initialStep: T;
  steps: {
    id: T;
    title: string;
  }[];
  onNextStep?: (data: any) => void;
  onPreviousStep?: () => void;
  onSelectStep?: (step: T) => void;
}) {
  const [activeStep, setActiveStep] = useState<T>(initialStep);

  const activeStepIndex = steps.findIndex(step => step.id === activeStep);

  const handleNextStep = (data: any) => {
    const nextIndex = Math.min(activeStepIndex + 1, steps.length);
    setActiveStep(steps[nextIndex].id);
    onNextStep?.(data);
  };

  const handlePreviousStep = () => {
    const previousIndex = Math.max(0, activeStepIndex - 1);
    setActiveStep(steps[previousIndex].id);
    onPreviousStep?.();
  };

  const handleSelectStep = (step: T) => {
    const stepIndex = steps.findIndex(s => s.id === step);
    if (stepIndex === undefined || stepIndex > activeStepIndex) {
      return;
    }
    setActiveStep(step);
    onSelectStep?.(step);
  };

  return {
    activeStep,
    setActiveStep,
    activeStepIndex,
    handlePreviousStep,
    handleNextStep,
    handleSelectStep,
  };
}
