import { Options } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/Table/Filters/Tabs/NumberFilter";

import { isDateType, isNumberType } from "./data";
import { formatNumber } from "./formatCell";

export const WHITESPACE = Symbol("WHITESPACE");
export const EMPTY = Symbol("EMPTY");

export const WHITESPACE_VALUE = /^[ ]+$/;

export const sortOrder = [null, NaN, Infinity, -Infinity, EMPTY, WHITESPACE];

export const isValueEmpty = (
  value: string | string[] | null | Symbol | number
) => {
  return value === "" || (Array.isArray(value) && !value.length);
};

export const getLabel = (
  value: null | typeof WHITESPACE,
  _typeUpperCase: string
): string => {
  if (value === null) {
    return "Null";
  }
  if (value === WHITESPACE) {
    return "Whitespace";
  }
  if (value === EMPTY) {
    return "Empty";
  }
  if (isNumberType(_typeUpperCase)) {
    return formatNumber(value);
  }
  return `${value}`;
};

const isEmpty = (value: string | null) => {
  return value == null || value === "";
};

export const filterMap = {
  [Options.GT]: (filter: any, value: string | number) => {
    const validValue = typeof value === "string" ? parseFloat(value) : value;

    return isEmpty(filter.value) || validValue > filter.value;
  },
  [Options.LT]: (filter: any, value: string | number) => {
    const validValue = typeof value === "string" ? parseFloat(value) : value;

    return isEmpty(filter.value) || validValue < filter.value;
  },
  [Options.GTE]: (filter: any, value: string | number) => {
    const validValue = typeof value === "string" ? parseFloat(value) : value;

    return isEmpty(filter.value) || validValue >= filter.value;
  },
  [Options.LTE]: (filter: any, value: string | number) => {
    const validValue = typeof value === "string" ? parseFloat(value) : value;

    return isEmpty(filter.value) || validValue <= filter.value;
  },
  [Options.BETWEEN]: (filter: any, value: string | number) => {
    const validValue = typeof value === "string" ? parseFloat(value) : value;

    return (
      (isEmpty(filter.right) && isEmpty(filter.left)) ||
      (validValue <= filter.right && validValue >= filter.left)
    );
  },
  contains: (filter: any, value: any) =>
    value === "" ||
    ("" + value).toLowerCase().includes(filter.value.toLowerCase()),
  starts: (filter: any, value: any) =>
    value === "" ||
    ("" + value).toLowerCase().startsWith(filter.value.toLowerCase()),
  ends: (filter: any, value: any) =>
    value === "" ||
    ("" + value).toLowerCase().endsWith(filter.value.toLowerCase()),
  not_contains: (filter: any, value: any) =>
    isEmpty(filter.value) ||
    !("" + value).toLowerCase().includes(filter.value.toLowerCase()),
  not_starts: (filter: any, value: any) =>
    isEmpty(filter.value) ||
    !("" + value).toLowerCase().startsWith(filter.value.toLowerCase()),
  not_ends: (filter: any, value: any) =>
    isEmpty(filter.value) ||
    !("" + value).toLowerCase().endsWith(filter.value.toLowerCase()),
};

export const includeFilter = (filter: any, value: any) => {
  const { itemsSet } = filter;
  if (!itemsSet.size) {
    return true;
  }
  let key = value;
  if (typeof value === "string" && value.match(WHITESPACE_VALUE)) {
    key = WHITESPACE;
  }
  if (isValueEmpty(value)) {
    key = EMPTY;
  }
  return itemsSet.has(key);
};

export const sortCandidates = (_typeUpperCase: any) => (a: any, b: any) => {
  const aValue = a[0];
  const bValue = b[0];
  const aSpecial = sortOrder.indexOf(aValue);
  const bSpecial = sortOrder.indexOf(bValue);

  if (aSpecial !== -1 && bSpecial !== -1) {
    return aSpecial > bSpecial ? 1 : -1;
  }

  if (aSpecial !== -1) {
    return -1;
  }

  if (bSpecial !== -1) {
    return 1;
  }

  if (isDateType(_typeUpperCase)) {
    return new Date(aValue) > new Date(bValue) ? -1 : 1;
  }

  return aValue > bValue ? 1 : -1;
};
