import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useEngineClusterFlag } from "featureFlags/hooks/useEngineClusterFlag";
import { CreateEngineInput } from "services/engines/createWorkspaceEngine";
import { EditEngineInput } from "services/engines/editWorkspaceEngine";

import { Cost } from "components/CreateEngine/Cost";
import {
  CreateEngine,
  Fields,
  engineCost,
  initialEngine,
  schema,
} from "components/CreateEngine/CreateEngine";
import { InputDisabledContext } from "components/InputState/InputDisabledContext";
import { Modal } from "components/Modal/Modal";

type Props = {
  onClose: () => void;
  onSubmit: (engine: EditEngineInput | CreateEngineInput) => void;
  isLoading: boolean;
  title: string;
  subtitle?: React.ReactNode;
  primaryButton: string;
  engine?: EditEngineInput;
};

export const EngineModal = (props: Props) => {
  const { title, subtitle, primaryButton, onClose, onSubmit, isLoading } =
    props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
  } = useForm<Fields>({
    resolver: zodResolver(schema),
    defaultValues: props.engine ?? initialEngine,
  });

  const onFormSubmit = (data: Fields) => {
    if (isLoading) {
      return;
    }
    onSubmit(data);
  };

  const showEngineClusterFlag = useEngineClusterFlag();
  const clusters = showEngineClusterFlag ? Number(watch("clusters")) : 1;

  const cost = clusters * Number(watch("nodes")) * engineCost[watch("type")];

  return (
    <InputDisabledContext.Provider value={isLoading}>
      <Modal
        title={title}
        subtitle={subtitle}
        onSubmit={handleSubmit(onFormSubmit)}
        onClose={onClose}
        isLoading={isLoading}
        primaryButton={t(primaryButton)}
        disabledSubmit={!isDirty || isLoading}
        footer={<Cost cost={cost} />}
      >
        <CreateEngine
          engine={props.engine}
          register={register}
          errors={errors}
          control={control}
          watch={watch}
        />
      </Modal>
    </InputDisabledContext.Provider>
  );
};
