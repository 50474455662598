import { stringify } from "csv-stringify/sync";

import { rowsValuesToString } from "./convert";
import { downloadFile } from "./downloadFile";

export function stringifyResultToCSV(rows: any[], delimiter: string) {
  if (!rows.length) {
    return "";
  }

  const header = Object.keys(rows[0]).reduce(
    (
      _header: {
        [key: string]: string;
      },
      col
    ) => {
      // eslint-disable-next-line no-param-reassign -- legacy code
      _header[col] = col;
      return _header;
    },
    {}
  );

  const data = [header, ...rowsValuesToString(rows)];

  return new Promise((resolve, reject) => {
    try {
      const res = stringify(data, { delimiter });

      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
}

export function downloadResults(data: any, type: string) {
  const blob = new Blob([data], { type: `text/${type.toLowerCase()}` });

  downloadFile(blob, `QueryResults.${type.toLowerCase()}`);
}
