import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Role } from "services/rbac/types";

const validateName = (
  t: TFunction,
  roleName: string,
  roles: Role[],
  currentRole: Role | undefined
) => {
  if (!roleName) {
    return t("roles.validation.required");
  }
  const duplicate = roles.filter(Boolean).find(role => {
    return (
      role.roleName === roleName && role.roleName !== currentRole?.roleName
    );
  });
  if (duplicate) {
    return t("roles.validation.unique");
  }
  if (roleName.match(/\s/)) {
    return t("roles.validation.no_space");
  }
  if (!roleName.match(/^[A-Za-z0-9_]*$/)) {
    return t("roles.validation.no_special");
  }
  return null;
};

export const useRoleNameChange = (role: Role | undefined, roles: Role[]) => {
  const initialRoleName = role?.roleName || "";
  const [roleName, setRoleName] = useState(initialRoleName);
  const [nameError, setNameError] = useState<string | null>(null);
  const { t } = useTranslation();
  const roleNameTrimed = roleName.trim();

  const validate = (value: string) => {
    const validationError = validateName(t, value, roles, role);
    return validationError;
  };

  const handleRoleNameChange = (name: string, value: string) => {
    setRoleName(value);
    const nameError = validate(value.trim());
    setNameError(nameError);
  };

  return {
    roleName,
    roleNameTrimed,
    initialRoleName,
    handleRoleNameChange,
    nameError,
    validate,
  };
};
