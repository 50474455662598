import classNames from "classnames";
import { ChangeEvent, ReactElement, SyntheticEvent } from "react";

import CheckboxCheckIcon from "assets/icons/CheckboxCheckIcon.svg?react";
import CheckboxPartitialIcon from "assets/icons/CheckboxPartitialIcon.svg?react";

import styles from "./Checkbox.module.scss";

type Props = {
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  checkedIcon?: ReactElement;
  intermediateIcon?: ReactElement;
  stopClickPropagation?: boolean;
  // onClick is saved for legacy reasons (please use onChange)
  onClick?: (e: SyntheticEvent, checked?: boolean) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
  testId?: string;
  classes?: { [key: string]: string };
  value?: string;
};

const CheckboxComponent: React.FC<Props> = props => {
  const {
    onClick,
    onChange,
    checked,
    disabled,
    indeterminate,
    checkedIcon,
    intermediateIcon,
    stopClickPropagation,
    testId = "checkbox",
    classes = {},
    value,
  } = props;

  const onChangeFn = onClick || onChange;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChangeFn && !disabled) {
      onChangeFn(event);
    }
  };

  const inputPreventClickBubbling = (event: SyntheticEvent) => {
    if (stopClickPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <label
      className={classNames(styles.root, classes?.root, {
        [styles.checked]: checked,
        [styles.indeterminate]: indeterminate,
        [styles.disabled]: disabled,
      })}
      data-testid={testId}
    >
      <input
        type="checkbox"
        onChange={handleChange}
        onClick={inputPreventClickBubbling}
        className={styles.input}
        checked={checked}
        value={value}
        data-testid={`${testId}-input`}
      />
      {indeterminate
        ? intermediateIcon || <CheckboxPartitialIcon className={styles.icon} />
        : checkedIcon || <CheckboxCheckIcon className={styles.icon} />}
    </label>
  );
};

export default CheckboxComponent;
