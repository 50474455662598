import classNames from "classnames";
import React from "react";

import CheckIcon from "assets/icons/CheckIcon.svg?react";
import InfoIcon from "assets/icons/Info.svg?react";

import Checkbox from "components/Checkbox";
import Tooltip from "components/Tooltip";

import commonStyles from "styles/common.module.scss";
import styles from "./ContextMenu.module.scss";

export interface ContextMenuItemProps {
  // eslint-disable-next-line react/no-unused-prop-types
  value?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  disableClose?: boolean;
  text?: string;
  onClick?: (
    event: React.SyntheticEvent<HTMLDivElement | HTMLAnchorElement>
  ) => void;
  secondaryText?: string;
  content?: React.ReactNode;
  checked?: boolean;
  checkedOptions?: {
    indeterminate?: boolean;
  };
  disabled?: boolean;
  checkedIconPlaceholder?: boolean;
  active?: boolean;
  availableForInteraction?: boolean;
  testId?: string;
  direction?: string;
  to?: string;
  skipFilter?: boolean;
  bulkItem?: boolean;
  textClassName?: string;
  renderWithWrapper?: (element: React.ReactElement) => React.ReactElement;
  disabledTooltip?: string;
}

const ContextMenuItem = React.memo((props: ContextMenuItemProps) => {
  const {
    text,
    checkedIconPlaceholder,
    active,
    availableForInteraction = true,
    testId,
    onClick,
    secondaryText,
    content,
    checked,
    checkedOptions,
    disabled,
    disabledTooltip,
    direction = "right",
    to,
    textClassName,
    renderWithWrapper,
  } = props;

  const handleClick = (
    event: React.SyntheticEvent<HTMLDivElement | HTMLAnchorElement>
  ) => {
    if (disabled) {
      return;
    }
    onClick && onClick(event);
  };

  const wrapByComponent = (element: React.ReactElement) => {
    if (!renderWithWrapper) {
      return element;
    }

    return renderWithWrapper(element);
  };

  const wrapByLink = (element: React.ReactElement) => {
    if (!to || disabled) {
      return element;
    }

    return (
      <a
        href={to}
        className={commonStyles.noFocus}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={-1}
        onKeyDown={event => handleClick(event)}
      >
        {element}
      </a>
    );
  };

  const element = (
    <div
      data-testid={testId}
      className={classNames(styles.item, {
        [styles.active]: active && availableForInteraction,
        [styles.interactionDisabled]: !availableForInteraction,
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
    >
      {direction === "left" && (
        <div className={styles.checkbox}>
          <Checkbox
            indeterminate={checkedOptions?.indeterminate}
            checked={Boolean(checked)}
            stopClickPropagation
          />
        </div>
      )}
      {text && (
        <div className={classNames(styles.text, textClassName)}>{text}</div>
      )}
      {secondaryText && (
        <div className={styles.secondaryText}>{secondaryText}</div>
      )}
      {content && <div className={styles.content}>{content}</div>}

      {disabledTooltip && (
        <Tooltip
          title={disabledTooltip}
          placement="top-start"
        >
          <div className={styles.disabledTooltipIcon}>
            <InfoIcon />
          </div>
        </Tooltip>
      )}
      {(checked || checkedIconPlaceholder) && direction === "right" && (
        <div className={styles.checkedWrapper}>{checked && <CheckIcon />}</div>
      )}
    </div>
  );
  return wrapByLink(wrapByComponent(element));
});

export default ContextMenuItem;
