import moment from "moment";

export const getTrialRemainingDays = (date: string | undefined) => {
  if (!date) return Infinity;

  const deadline = moment(date);
  const today = moment().startOf("day");

  return deadline.diff(today, "days");
};
