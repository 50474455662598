import { useTranslation } from "react-i18next";

import { SqlRbacAction } from "services/rbac/action";
import { RBAC_NAME_TYPE_MAP, SystemRole } from "services/rbac/constants";
import { Role } from "services/rbac/types";
import { getRoleDefinition } from "services/rbac/utils";

import { useViewActions } from "pages/govern/Roles/useViewActions";

import { useMenu } from "components/ActionMenu/useMenu";
import { useAccessManager } from "components/App/accessManager";
import DefinitionCodePreview from "components/DefinitionCodePreview/DefinitionCodePreview";

import { RecordLabel } from "../RecordLabel";
import { Menu } from "../RecordMenu/RecordMenu";
import { RecordNode } from "../RecordNode";
import { useDeleteRole } from "./useDeleteRole";

import styles from "./styles.module.scss";

type Props = {
  role: Role;
  handleView: ReturnType<typeof useViewActions>["handleView"];
  deleteRole: ReturnType<typeof useDeleteRole>["deleteRole"];
};

export const RoleRecord = (props: Props) => {
  const { handleView, deleteRole, role } = props;
  const { roleName } = role;
  const { t } = useTranslation();
  const { isAllowedTo } = useAccessManager();
  const menu = useMenu();

  const canDeleteRole = isAllowedTo(
    "account",
    "*",
    SqlRbacAction.ROLE_MODIFY_ANY
  );

  const systemDefined = RBAC_NAME_TYPE_MAP[roleName as SystemRole];

  const deleteItems =
    canDeleteRole && !systemDefined
      ? [
          [
            {
              key: "delete",
              text: t("workspace.roles_menu.delete"),
              action: () => {
                deleteRole(role.roleName);
              },
              testId: "delete-role-item",
            },
          ],
        ]
      : [];

  const items = [
    [
      {
        key: "view",
        text: t("workspace.roles_menu.view_grants"),
        action: () => {
          handleView(role);
        },
        testId: "view-grants-item",
      },
      {
        key: "view_definition",
        text: t("workspace.roles_menu.view_definition"),
        renderContent: (props: { onContentClose: () => void }) => {
          const value = getRoleDefinition(role.roleName, role.privileges);

          return (
            <DefinitionCodePreview
              code={value}
              onClose={props.onContentClose}
            />
          );
        },
        testId: "view-role-definition-item",
      },
    ],
    ...deleteItems,
  ];
  return (
    <RecordNode className={styles.node}>
      <div className={styles.node__inner}>
        <RecordLabel
          className={styles.label}
          testId={`role-label-${roleName}`}
        >
          {roleName}
        </RecordLabel>
        <Menu
          testId="role-more-icon"
          items={items}
          menu={menu}
        />
      </div>
    </RecordNode>
  );
};
