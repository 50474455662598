import { useCallback, useEffect, useRef } from "react";

import { InteractionsConstants } from "../types";

export const useScrollCell = ({
  activeCell,
  activeSelection,
  getRowHeight,
  gridRef,
  dimentions,
  columnWidths,
  columnRightOffsets,
  dropdownExpand,
  constants,
}: {
  activeCell: any;
  activeSelection: any;
  getRowHeight: any;
  gridRef: any;
  dimentions: any;
  columnWidths: any;
  columnRightOffsets: any;
  dropdownExpand: any;
  constants: InteractionsConstants;
}) => {
  const { SCROLLBAR_WIDTH, STICKY_EXPANDED_HEIGHT, STICKY_HEIGHT } = constants;

  const scrollColumn = useCallback(
    (cell: { col: number; row: number }) => {
      if (!gridRef.current) {
        return;
      }
      const { col: columnIndex } = cell;
      const { scrollLeft } = gridRef.current.state;
      const { width } = dimentions.current ?? {};

      const leftMax =
        columnRightOffsets[columnIndex] - columnWidths[columnIndex];

      const leftMin =
        columnRightOffsets[columnIndex - 1] +
        columnWidths[columnIndex] -
        width +
        1;

      if (scrollLeft > leftMax) {
        gridRef.current.scrollTo({
          scrollLeft: leftMax,
        });
      } else if (scrollLeft < leftMin) {
        gridRef.current.scrollTo({
          scrollLeft: leftMin + SCROLLBAR_WIDTH,
        });
      }
    },
    [gridRef, columnRightOffsets, columnWidths, dimentions, SCROLLBAR_WIDTH]
  );

  const scrollRow = useCallback(
    (cell: { col: number; row: number; noScrollY?: boolean }) => {
      if (!gridRef.current) {
        return;
      }
      const { row: rowIndex, noScrollY } = cell;
      const { scrollTop } = gridRef.current.state;
      const { height } = dimentions.current ?? {};

      const stickyHeight = dropdownExpand
        ? STICKY_EXPANDED_HEIGHT
        : STICKY_HEIGHT;

      const topMax = rowIndex * getRowHeight(0);
      const topMin =
        (rowIndex + 1) * getRowHeight(0) + stickyHeight - height + 1;

      if (scrollTop > topMax && !noScrollY) {
        gridRef.current.scrollTo({ scrollTop: topMax });
      } else if (scrollTop < topMin && !noScrollY) {
        gridRef.current.scrollTo({
          scrollTop: topMin + SCROLLBAR_WIDTH,
        });
      }
    },
    [
      getRowHeight,
      gridRef,
      dimentions,
      dropdownExpand,
      SCROLLBAR_WIDTH,
      STICKY_EXPANDED_HEIGHT,
      STICKY_HEIGHT,
    ]
  );

  const latestScrollRow = useRef(scrollRow);
  const latestScrollColumn = useRef(scrollRow);

  latestScrollRow.current = scrollRow;
  latestScrollColumn.current = scrollColumn;

  useEffect(() => {
    if (activeSelection) {
      latestScrollColumn.current(activeSelection);
      latestScrollRow.current(activeSelection);
    }
  }, [activeSelection]);

  useEffect(() => {
    if (activeCell) {
      latestScrollColumn.current(activeCell);
      latestScrollRow.current(activeCell);
    }
  }, [activeCell]);
};
