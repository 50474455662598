import classNames from "classnames";
import React from "react";

import { SimpleErrorBoundary } from "components/ErrorBoundary/SimpleErrorBoundary";

import { Operator } from "../types";

import styles from "./styles.module.scss";

type Props = {
  node: Operator;
  selectedNode?: Operator | null;
  onSelectNode?: (node: Operator) => void;
};

type NodeProps = Props & {
  lineBefore?: boolean;
  lineAfter?: boolean;
};

const Operation = (props: Props) => {
  const { node, selectedNode, onSelectNode } = props;
  const { annotations, operator_type, operator_id } = node;
  const { label, output_types } = annotations;

  const handleClick = () => {
    onSelectNode?.(node);
  };

  return (
    <div
      className={classNames(styles.operation, {
        [styles.active]: node.operator_id === selectedNode?.operator_id,
      })}
      onClick={handleClick}
    >
      <div className={styles.inner}>
        <div className={styles.operation__header}>
          <h2 className={styles.title}>{operator_type}</h2>
          <div className={styles.operation__id}>{operator_id}</div>
        </div>
      </div>
      <div className={styles.operation__body}>
        <div>{label}</div>
        {output_types.length ? (
          <div>
            <span className={styles.row__type}>RowType: </span>

            {output_types.join(", ")}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const Node: React.FC<NodeProps> = props => {
  const { lineBefore, lineAfter, node, selectedNode, onSelectNode } = props;

  return (
    <div
      className={classNames(styles.node, {
        [styles.node__lineBefore]: lineBefore,
        [styles.node__lineAfter]: lineAfter,
      })}
    >
      <div className={styles.node__inner}>
        <SimpleErrorBoundary renderError={() => null}>
          <Operation
            node={node}
            selectedNode={selectedNode}
            onSelectNode={onSelectNode}
          />
        </SimpleErrorBoundary>
      </div>
      {lineAfter && <div className={styles.arrow} />}
    </div>
  );
};
