import { useQueryClient } from "@tanstack/react-query";
import { Centrifuge } from "centrifuge";
import classNames from "classnames";
import { Suspense, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useHasAdminRole } from "services/auth/useHasAdminRole";
import { useUser } from "services/auth/useUser";
import { useMyTenants } from "services/costs/useMyTenants";
import { systemEngineEnvironment } from "services/environment/systemEngine";
import { useRegions } from "services/organization/useRegions";
import { getWSChannelTokens } from "services/ws/getWsAuthToken";

import AccountFormModal from "pages/configure/Accounts/AccountFormModal/AccountFormModal";

import Button from "components/Button/Button";
import { ErrorScreen, ErrorScreenImagePosition } from "components/ErrorScreen";
import { ChevronRight, NoAccess } from "components/Icons";
import LoadingOverlap from "components/LoadingOverlap";
import { clearSubscriptions } from "components/topLevel/Websocket/clearSubscriptions";
import { initializeWSClient } from "components/topLevel/Websocket/initializeWSClient";
import {
  ChannelTokens,
  EventTypes,
} from "components/topLevel/Websocket/websocket.types";

import styles from "./styles.module.scss";

export class NotAMemberError extends Error {}

const CreateAccountButton = (props: { onCreate: () => void }) => {
  const { onCreate } = props;
  const { t } = useTranslation();
  const regions = useRegions();
  const tenants = useMyTenants();
  const navigate = useNavigate();

  const [accountFormModal, setAccountFormModal] = useState<{} | null>(null);
  const handleCreateAccount = () => {
    setAccountFormModal({});
  };

  return (
    <div className={styles.button}>
      <Button
        className={classNames(styles.innerButton, styles.overwrite)}
        text={t("login.fallback_screen_not_associated.button")}
        endIcon={<ChevronRight className={styles.icon} />}
        onClick={handleCreateAccount}
      />
      {accountFormModal && (
        <AccountFormModal
          account={accountFormModal}
          onClose={() => {
            setAccountFormModal(null);
          }}
          onCreate={account => {
            const { accountName } = account;
            navigate(`/${accountName}/develop`);
            onCreate();
          }}
          availableRegions={regions}
          tenants={tenants}
        />
      )}
    </div>
  );
};

export const NotAMember = (props: { resetError?: () => void }) => {
  const { resetError } = props;
  const { t } = useTranslation();
  const hasAdminRole = useHasAdminRole();
  const user = useUser();
  const queryClient = useQueryClient();
  const loadingTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { email } = user;

  useEffect(() => {
    systemEngineEnvironment.initializeDefaultApiEndpoint();
  }, []);

  const wsClient = useRef<Centrifuge | undefined>(undefined);
  const tokens = useRef<ChannelTokens>({});

  useEffect(() => {
    if (wsClient.current) {
      return;
    }
    initializeWSClient().then(client => {
      wsClient.current = client;
    });
  }, []);

  useEffect(() => {
    const subscribe = async () => {
      if (!wsClient.current) return;

      const channelName = `login:${email}`;
      const channelTokens = await getWSChannelTokens([channelName]);
      tokens.current = channelTokens;

      try {
        const sub = wsClient.current.newSubscription(
          channelTokens[channelName].channel,
          {
            token: channelTokens[channelName].token,
            getToken: async () => {
              try {
                const tokens = await getWSChannelTokens([channelName]);

                return tokens[channelName].token || "";
              } catch (e) {
                return "";
              }
            },
          }
        );

        sub.on("publication", async ctx => {
          if (ctx.data.type === EventTypes.LoginAccountsChanged) {
            queryClient.removeQueries();
            resetError && resetError();
          }
        });

        sub.on("error", err => {
          console.log(`Subscription error: ${channelName}`, err);
        });

        sub.subscribe();
      } catch (error) {
        console.warn(error);
      }
    };

    subscribe();
    return () => {
      clearSubscriptions(wsClient.current);
      loadingTimeout.current && clearTimeout(loadingTimeout.current);
    };
  }, [wsClient, loadingTimeout, resetError, email, queryClient]);

  const onCreate = () => {
    setIsLoading(true);
    loadingTimeout.current = setTimeout(() => {
      window.location.reload();
    }, 20000);
  };

  if (isLoading) {
    return <LoadingOverlap isLoading />;
  }

  return (
    <ErrorScreen
      title={t("login.fallback_screen_not_associated.title")}
      image={<NoAccess />}
      imagePosition={ErrorScreenImagePosition.Top}
      classes={{
        image: classNames(styles.image, styles.forced, styles.forced2),
        description: styles.description,
      }}
      description={
        <>
          <Trans
            i18nKey="login.fallback_screen_not_associated.subtitle"
            values={{
              email: "support@firebolt.io",
            }}
            components={{
              a: <a href="mailto:support@firebolt.io">support@firebolt.io</a>,
            }}
          />
          {hasAdminRole && (
            <Suspense fallback={null}>
              <CreateAccountButton onCreate={onCreate} />
            </Suspense>
          )}
        </>
      }
    />
  );
};
