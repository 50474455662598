import { QueryStatement } from "pages/DevelopWorkspace/workspace.types";

export const setPrevStatus = (
  prevQueryStatement: QueryStatement,
  nextQueryStatement: QueryStatement
) => {
  if (prevQueryStatement.status === nextQueryStatement.status) {
    return nextQueryStatement;
  }

  return {
    ...nextQueryStatement,
    prevStatus: prevQueryStatement.status,
  };
};
