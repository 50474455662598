import classNames from "classnames";
import uniqueid from "lodash/uniqueId";
import React from "react";

import { Node } from "../Node/Node";
import { Explain, Operator } from "../types";

import styles from "./styles.module.scss";

type Props = {
  node: Operator;
  selectedNode?: Operator | null;
  onSelectNode?: (node: Operator) => void;
  lineBefore?: boolean;
  lineAfter?: boolean;
  horizontalLine?: boolean;
};

const Step = React.memo<Props>(props => {
  const {
    node,
    selectedNode,
    lineBefore,
    lineAfter,
    horizontalLine,
    onSelectNode,
  } = props;
  const { children = [] } = node;

  return (
    <li
      className={classNames(styles.step, {
        [styles.step__line]: horizontalLine,
      })}
      data-testid={`operation-${node.operator_id}`}
    >
      <Node
        node={node}
        selectedNode={selectedNode}
        lineBefore={lineBefore}
        lineAfter={lineAfter}
        onSelectNode={onSelectNode}
      />
      {children.length ? (
        <ul className={styles.children}>
          {children.map(child => {
            const lineAfter = !!child.children;
            const horizontalLine = children.length > 1;
            return (
              <Step
                key={uniqueid()}
                node={child}
                selectedNode={selectedNode}
                lineBefore
                lineAfter={lineAfter}
                horizontalLine={horizontalLine}
                onSelectNode={onSelectNode}
              />
            );
          })}
        </ul>
      ) : null}
    </li>
  );
});

const Subquery: React.FC<{
  node: Operator;
  selectedNode?: Operator | null;
  onSelectNode?: (node: Operator) => void;
}> = props => {
  const { node, selectedNode, onSelectNode } = props;
  const lineAfter = !!node.children;
  return (
    <ul
      className={styles.subquery}
      data-testid="subquery"
    >
      <Step
        node={node}
        selectedNode={selectedNode}
        onSelectNode={onSelectNode}
        lineAfter={lineAfter}
      />
    </ul>
  );
};

type SubqueriesProps = {
  explain: Explain;
  selectedNode?: Operator | null;
  onSelectNode?: (node: Operator) => void;
};

export const Subqueries: React.FC<SubqueriesProps> = props => {
  const { explain, selectedNode, onSelectNode } = props;
  const { operators } = explain;
  return (
    <div
      className={styles.subqueries}
      data-testid="subqueries"
    >
      {operators.map(operator => (
        <Subquery
          node={operator}
          selectedNode={selectedNode}
          onSelectNode={onSelectNode}
          key={operator.operator_id}
        />
      ))}
    </div>
  );
};
