import { InputAdornment } from "@mui/material";
import classNames from "classnames";
import React, { useEffect } from "react";

import CrossIcon from "assets/icons/Cross.svg?react";

import InputField from "components/Input";

import styles from "./styles.module.scss";

type Props = {
  value: string;
  name: string;
  onChange: (name: string, value: string) => void;
  expanded: boolean;
};

const AccordionInput = (props: Props) => {
  const { name, value, onChange, expanded } = props;

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (expanded) {
      inputRef.current?.focus();
    }
  }, [expanded]);

  return (
    <div className={styles.innerAccordionDetails}>
      <InputField
        testId={`${name}-input`}
        type="text"
        value={value}
        name={name}
        onChange={onChange}
        fullWidth
        required={false}
        showErrorContainer={false}
        inputRef={input => {
          inputRef.current = input;
        }}
        classes={{
          root: classNames(styles.innerAccordionInput, styles.overwriteMUI),
        }}
        InputProps={{
          classes: {
            root: classNames(styles.innerInputContainer, styles.overwriteMUI),
          },
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => onChange(name, "")}
            >
              <CrossIcon className={styles.crossIcon} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default AccordionInput;
