import { useTranslation } from "react-i18next";

import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import ReadOnlyEditor from "components/ReadOnlyEditor";
import { Step } from "components/Wizard/Step/Step";

import { usePreviewScript } from "../PreviewScript/helpers";
import { WizardDataType } from "../types";

import styles from "./styles.module.scss";

type Props = {
  onPrevStep: () => void;
  onClose: () => void;
  onFinished: () => void;
  activeStepIndex: number;
  wizardData: WizardDataType;
};

export const Review = (props: Props) => {
  const { onClose, onFinished, activeStepIndex, wizardData, onPrevStep } =
    props;
  const { t } = useTranslation();
  const {
    actions: { createDocument },
  } = useDocuments();

  const script = usePreviewScript({
    activeStepIndex,
    wizardData,
  });

  const onFormSubmit = async () => {
    await createDocument(
      {
        content: script as string,
      },
      {
        autoRun: true,
      }
    );
    onFinished();
  };

  const body = (
    <ReadOnlyEditor
      value={script}
      className={styles.review}
    />
  );
  return (
    <Step
      title={t("wizard.review.title")}
      subtitle={t("wizard.review.subtitle")}
      bodyClassName={styles.overflowHidden}
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      onPrevStep={onPrevStep}
      activeStepIndex={activeStepIndex}
      mainActionTitle={t("wizard.step.run")}
      secondaryActionTestId="ingestion-wizard-cancel"
      isLast
    />
  );
};
