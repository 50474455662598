import React, { useContext } from "react";

import { DiagramContext } from "../DiagramContext";
import { ExplainDiagramMap } from "./ExplainDiagramMap";

import styles from "./styles.module.scss";

type Props = {
  explain: any;
};

export const Sidebar = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { explain } = props;
  const { minimap } = useContext(DiagramContext);
  const style = {
    top: 70,
    left: minimap.transform.x,
  };

  return (
    <div
      className={styles.sidebar}
      style={style}
      ref={ref}
    >
      <ExplainDiagramMap explain={explain} />
    </div>
  );
});
