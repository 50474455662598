import { useCallback, useMemo, useState } from "react";

import { Row } from "components/Datagrid/types";

import { useColumnFilters } from "./useColumnFilters";
import { useColumnSort } from "./useColumnSort";

export const useFilters = ({
  setRows,
  setColumns,
  columns,
  originalRows,
}: {
  setRows: (rows: Row[]) => void;
  setColumns: (columns: any[]) => void;
  columns: any[];
  originalRows: React.MutableRefObject<Row[]>;
}) => {
  const [filtersOpened, setFiltersOpened] = useState(false);

  const {
    clearFilters,
    getFilters,
    setColumnFilters,
    getColumnFilters,
    getColumnCandidates,
    applyFiltersRef,
  } = useColumnFilters({
    originalRows,
    columns,
    setRows,
  });

  const { handleSort } = useColumnSort({
    columns,
    setColumns,
    setRows,
    originalRows,
  });

  const openFilters = useCallback((column: any) => {
    setFiltersOpened(column);
  }, []);

  const closeFilters = useCallback(() => {
    setFiltersOpened(false);
  }, []);

  const filtersContext = useMemo(() => {
    return {
      handleSort,
      openFilters,
      closeFilters,
      clearFilters,
      getFilters,
      setColumnFilters,
      getColumnFilters,
      getColumnCandidates,
      filtersOpened,
      applyFiltersRef,
    };
  }, [
    handleSort,
    openFilters,
    closeFilters,
    filtersOpened,
    clearFilters,
    getFilters,
    setColumnFilters,
    getColumnFilters,
    getColumnCandidates,
    applyFiltersRef,
  ]);

  return filtersContext;
};
