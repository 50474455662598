import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import UserFriendlyAPIError from "services/environment/UserFriendlyAPIError";
import { systemEngineEnvironment } from "services/environment/systemEngine";

export const deleteUser = async (name: string): Promise<boolean> => {
  try {
    await systemEngineEnvironment.execute(`DROP USER "${name}";`);

    return true;
  } catch (e) {
    if (e instanceof UserFriendlyAPIError) {
      throw e;
    }

    throw new ErrorWithTranslationKey("users.form.delete.error");
  }
};
