import intersection from "lodash/intersection";

import { ToggleExpandOptions, TreeNodeType, TreeNodesType } from "./types";

export const TREE_NODE_PADDING = 24;
export const EMPTY_TYPE = "empty";

export const KEY_VALUES = {
  LEFT: "ArrowLeft",
  UP: "ArrowUp",
  RIGHT: "ArrowRight",
  DOWN: "ArrowDown",
  ENTER: "Enter",
};

export interface EventsProps {
  down?: React.KeyboardEventHandler;
  up?: React.KeyboardEventHandler;
  enter?: React.KeyboardEventHandler;
  right?: React.KeyboardEventHandler;
  left?: React.KeyboardEventHandler;
}

export const onTreeKeyDown = (
  event: React.KeyboardEvent,
  events: EventsProps
) => {
  const { down, up, enter, right, left } = events;
  switch (event.key) {
    case KEY_VALUES.DOWN:
      down?.(event);
      event.preventDefault();
      break;
    case KEY_VALUES.UP:
      up?.(event);
      event.preventDefault();
      break;
    case KEY_VALUES.ENTER:
      enter?.(event);
      event.preventDefault();
      break;
    case KEY_VALUES.LEFT:
      left?.(event);
      break;
    case KEY_VALUES.RIGHT:
      right?.(event);
      break;
    default:
  }
};

export const getActiveItem = (
  focusItemValue: string | number,
  nodes: TreeNodesType
) => {
  const activeNode = Object.values(nodes).find(
    node => node.value === focusItemValue
  );

  if (activeNode) {
    return activeNode;
  }

  return null;
};

export const getNodeParentKeys = (nodes: TreeNodesType, node: TreeNodeType) => {
  const parentKeys: string[] = [];
  const traverse = (node: TreeNodeType) => {
    if (node?.parent?.refKey) {
      traverse(nodes[node.parent.refKey]);
      parentKeys.push(node?.parent?.value);
    }
  };

  traverse(node);
  return parentKeys;
};

export const shouldShowNodeByParentExpanded = (
  expandItemValues: string[] = [],
  parentKeys: string[] = []
) => {
  const intersectionKeys = intersection(expandItemValues, parentKeys);
  if (intersectionKeys.length === parentKeys.length) {
    return true;
  }
  return false;
};

export const getTreeNodeIndent = (layer: number) => {
  const offset = layer * TREE_NODE_PADDING;
  return offset;
};

export const toggleExpand = <T>({
  node,
  isExpand,
  expandItemValues,
}: ToggleExpandOptions<T>): T[] => {
  const newExpandItemValues = new Set<T>(expandItemValues);
  if (isExpand) {
    newExpandItemValues.add(node.value as T);
  } else {
    newExpandItemValues.delete(node.value as T);
  }
  return Array.from(newExpandItemValues);
};
