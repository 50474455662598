import React from "react";

type WidthContextType = {
  leadingWidth: number;
  columnWidths: Record<string, number>;
};

type TableContextType = {
  wrapper: React.MutableRefObject<HTMLDivElement | null>;
  floatingColumn?: string;
};

export const WidthContext = React.createContext<WidthContextType>({
  leadingWidth: 0,
  columnWidths: {},
});

export const TableContext = React.createContext<TableContextType>({
  wrapper: React.createRef(),
});
