import { systemEngineEnvironment } from "services/environment/systemEngine";
import { User } from "services/users/user.types";

export const getUsers = async (
  accountId: string,
  userName?: string
): Promise<User[]> => {
  const sql =
    `
SELECT
  u.user_name,
  u.login_name,
  u.service_account_name,
  u.default_database,
  u.default_engine,
  u.created,
  u.user_owner,
  u.last_altered,
  u.last_altered_by,
  ARRAY_DISTINCT(filter(x -> x <> '', ARRAY_AGG(ar.role_name))) as roles
FROM
  information_schema.users u
LEFT JOIN
  information_schema.applicable_roles ar
ON
  u.user_name = ar.grantee
` +
    (userName ? ` where user_name = :userName\n` : "") +
    "GROUP BY ALL";

  systemEngineEnvironment.setAccountContext(accountId);
  const result = await systemEngineEnvironment.execute<User>(sql, {
    namedParameters: { userName },
  });
  const [response] = result;
  return response.rows.map(user => ({
    ...user,
    roles: user.roles || [],
  }));
};
