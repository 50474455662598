import { Suspense, useMemo } from "react";
import { Control, Controller, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useLogins } from "services/login/useLogins";
import { useUsers } from "services/users/useUsers";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import FormRow from "./components/FormRow";

import formRowStyles from "./components/FormRow/FormRow.module.scss";

const AssignLoginList = (props: { control: Control<any> }) => {
  const { control } = props;
  const { t } = useTranslation();
  const { data: logins } = useLogins();
  const users = useUsers();

  const takenLogins = useMemo(() => {
    const logins = new Set();
    for (const user of users) {
      const { loginName } = user;
      if (loginName) {
        logins.add(loginName);
      }
    }
    return logins;
  }, [users]);

  const sortedLogins = logins
    .filter(({ loginName }) => !takenLogins.has(loginName))
    .sort((a, b) =>
      a.loginName
        .toLocaleLowerCase()
        .localeCompare(b.loginName.toLocaleLowerCase())
    );

  const { errors } = useFormState({
    control,
  });

  return (
    <Controller
      control={control}
      name="loginName"
      render={({ field: { onChange, value } }) => (
        <OutlinedSelect
          searchOptions={{
            searchPlaceholder: t("workspace.create_user.search_login"),
            noResultsText: t("workspace.create_user.no_login_found"),
          }}
          testId="assign-login-select"
          className={formRowStyles.selectInput}
          onSelect={(items: string[]) => {
            const [item] = items;
            onChange(item);
          }}
          initialSelected={[value]}
          error={(errors.loginName?.message as string) ?? ""}
        >
          {sortedLogins.map(item => {
            const { loginName } = item;
            return (
              <ContextMenuItem
                value={loginName}
                key={loginName}
                testId={`assign-login-item-${loginName}`}
                checked={value === loginName}
                checkedIconPlaceholder={true}
                text={loginName}
              />
            );
          })}
        </OutlinedSelect>
      )}
    />
  );
};

export const AssignLogin = (props: { control: Control<any> }) => {
  const { control } = props;

  const { t } = useTranslation();
  return (
    <FormRow
      label={t("workspace.create_user.assign_login")}
      input={
        <Suspense
          fallback={
            <OutlinedSelect
              className={formRowStyles.selectInput}
              testId="fallback-login-select"
              disabled
            />
          }
        >
          <AssignLoginList control={control} />
        </Suspense>
      }
    />
  );
};
