import { useMemo } from "react";

import { FAKE_SPACER } from "../types";

export const useColumnWidths = ({
  columns,
  getColumnWidth,
}: {
  columns: any[];
  getColumnWidth: (i: number) => number;
}) => {
  const columnWidths = useMemo(
    () => columns.map((_, i) => getColumnWidth(i)),
    [getColumnWidth, columns]
  );

  const [columnRightOffsets, columnLeftOffsets] = useMemo(() => {
    let left = 0;
    let right = 0;

    const rightOffset = columnWidths.map((width, index) => {
      const column = columns[index];
      if (column?.type === FAKE_SPACER) {
        return right;
      }
      right += width;
      return right;
    });

    const leftOffset = columnWidths.map(width => {
      const offset = left;
      left += width;
      return offset;
    });

    return [rightOffset, leftOffset];
  }, [columnWidths, columns]);

  return {
    columnWidths,
    columnRightOffsets,
    columnLeftOffsets,
  };
};
