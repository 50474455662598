import { useSuspenseQuery } from "@tanstack/react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { authService } from "./index";

const ACCOUNT_ADMIN = "account_admin";

const getLoginRoleNames = async (loginName: string) => {
  const result = await systemEngineEnvironment.execute<{
    roleName: string;
  }>(
    `select distinct role_name from information_schema.applicable_roles where grantee = (
      select user_name from information_schema.users where LOWER(login_name) = LOWER(?)
    )`,
    { parameters: [loginName] }
  );
  const [response] = result;
  return response.rows;
};

export const useHasAccountAdminRole = () => {
  const { data } = useSuspenseQuery({
    queryKey: [ReactQueryKeysAccount.accountAdminRole],
    queryFn: async () => {
      try {
        const auth0user = await authService.auth0client.getUser();
        const loginName = auth0user?.email;
        const roles = await getLoginRoleNames(loginName as string);
        const adminRole = roles.find(
          ({ roleName }) => roleName === ACCOUNT_ADMIN
        );
        return !!adminRole;
      } catch (error) {
        return false;
      }
    },
    retry: true,
  });
  return !!data;
};
