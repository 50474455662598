import React, { useContext } from "react";

import {
  InteractionsContext,
  StickyGridContext,
  WidthContext,
} from "components/Datagrid/context";

import { ActiveCell } from "./ActiveCell";
import { ContextMenu } from "./ContextMenu";
import { EditingCell } from "./EditingCell";
import { SelectionRect } from "./SelectionRect";

export const Interactions = () => {
  const {
    state: {
      activeCell,
      activeSelection,
      editingCell,
      selections,
      contextMenuPostion,
    },
    getRowHeight,
    setContextMenuPosition,
    finishEditing,
    getRawCell,
  } = useContext(InteractionsContext);

  const { getColumnWidth } = useContext(WidthContext);

  const { columnLeftOffsets } = useContext(StickyGridContext);

  const activeCellSelection = activeCell ? (
    <ActiveCell
      activeCell={activeCell}
      getColumnWidth={getColumnWidth}
      getRowHeight={getRowHeight}
      columnLeftOffsets={columnLeftOffsets}
      getRawCell={getRawCell}
    />
  ) : null;

  const activeSelectionBox =
    activeSelection && activeCell ? (
      <SelectionRect
        activeCell={activeCell}
        selectionCell={{
          start: { row: activeSelection.row, col: activeSelection.col },
          end: { row: activeCell.row, col: activeCell.col },
        }}
        getColumnWidth={getColumnWidth}
        getRowHeight={getRowHeight}
        columnLeftOffsets={columnLeftOffsets}
      />
    ) : null;

  const selectionBoxes = selections
    ? selections.map((selectionCell: any) => {
        const element = (
          <SelectionRect
            activeCell={activeCell}
            selectionCell={selectionCell}
            getColumnWidth={getColumnWidth}
            getRowHeight={getRowHeight}
            columnLeftOffsets={columnLeftOffsets}
          />
        );
        return (
          <React.Fragment
            key={JSON.stringify(activeCell) + JSON.stringify(selectionCell)}
          >
            {element}
          </React.Fragment>
        );
      })
    : null;

  const editingBox = editingCell ? (
    <EditingCell
      finishEditing={finishEditing}
      editingCell={editingCell}
      getColumnWidth={getColumnWidth}
      getRowHeight={getRowHeight}
      columnLeftOffsets={columnLeftOffsets}
    />
  ) : null;

  const contextMenu = contextMenuPostion ? (
    <ContextMenu
      {...contextMenuPostion}
      setContextMenuPosition={setContextMenuPosition}
    />
  ) : null;

  return (
    <>
      {activeCellSelection}
      {selectionBoxes}
      {activeSelectionBox}
      {editingBox}
      {contextMenu}
    </>
  );
};
