import { WorkspaceEngine } from "services/engines/engine.types";

import { Panel } from "../Panel";
import { CollapsedHeader } from "./CollapsedHeader";
import { EngineMenuActions } from "./EngineRow/EngineMenu";
import { EngineRow } from "./EngineRow/EngineRow";

import styles from "./styles.module.scss";

export const CollapsedPanel = (props: {
  items: WorkspaceEngine[];
  actions: EngineMenuActions;
  testId?: string;
}) => {
  const { items, actions, testId } = props;

  return (
    <Panel testId={testId}>
      <CollapsedHeader />
      <div className={styles.enginesList}>
        {items.map(engine => {
          return (
            <EngineRow
              key={engine.engineName}
              engine={engine}
              actions={actions}
            />
          );
        })}
      </div>
    </Panel>
  );
};
