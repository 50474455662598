import { isCtrlPressed } from "../hooks/useKeypressObserving";
import { CLICK, CTRL_CLICK, SHIFT_CLICK } from "../state/actions";

export const getEventType = (event: any) => {
  if (isCtrlPressed()) {
    return CTRL_CLICK;
  }
  if (event.shiftKey) {
    return SHIFT_CLICK;
  }
  return CLICK;
};
