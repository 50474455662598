import {
  QueryStatement,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const getQueryLabel = (
  queryStatement: QueryStatement,
  document: WorkspaceDocument
) => {
  if (document.context.settings.query_label) {
    // use user defined query label
    return document.context.settings.query_label;
  }

  // otherwise use UI generated id as query label
  return queryStatement.id;
};

export default getQueryLabel;
