import ReadOnlyEditor from "components/ReadOnlyEditor/ReadOnlyEditor";

import { WizardDataType } from "../types";
import { usePreviewScript } from "./helpers";

import styles from "./styles.module.scss";

type Props = {
  wizardData: WizardDataType;
  activeStepIndex: number;
};

export const PreviewScript = (props: Props) => {
  const { wizardData, activeStepIndex } = props;
  const script = usePreviewScript({ activeStepIndex, wizardData });
  return (
    <div className={styles.wrapper}>
      <ReadOnlyEditor value={script} />
    </div>
  );
};
