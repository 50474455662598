import { TREE_NODE_PADDING } from "./utils";

import styles from "./styles.module.scss";

export const IndentLine = (props: { layer: number; filter?: number[] }) => {
  const { layer, filter = [] } = props;

  if (layer < 2) {
    return null;
  }

  const lines = new Array(layer - 1)
    .fill("0")
    .filter((v, index) => {
      return !filter.includes(index);
    })
    .map((v, index) => {
      return (
        <div
          // eslint-disable-next-line react/no-array-index-key -- it's ok here
          key={index}
          className={styles.indentLine}
          style={{ left: -index * TREE_NODE_PADDING - 5 }}
        />
      );
    });
  return <div className={styles.indentLines}>{lines}</div>;
};
