export type DateValue = {
  date: Date;
  value: number;
};

export type Series<DataKey> = {
  type: DataKey;
  data: DateValue[];
};

export enum UtilMetrics {
  CPU = "CPU",
  Memory = "Memory",
  Disk = "Disk",
  Cache = "Cache",
}

export enum QueryMetrics {
  RunningQueries = "RunningQueries",
  SuspendedQueries = "SuspendedQueries",
  SpilledBytes = "SpilledBytes",
}
