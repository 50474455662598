import { WorkspaceDocument } from "pages/DevelopWorkspace/workspace.types";

const documentHasResults = (document: WorkspaceDocument) => {
  const execution = document.execution;

  if (!execution) {
    return false;
  }

  const queryStatements = execution.queryStatements;

  if (!queryStatements || queryStatements.length === 0) {
    return false;
  }

  const someQueryHasResults = queryStatements.some(
    queryStatement => queryStatement.result
  );

  return someQueryHasResults;
};

export { documentHasResults };
