import { RefObject, Suspense } from "react";

import { Database } from "services/databases/useDatabasesNames";

import NestedContextMenuItem from "components/ContextMenu/NestedContextMenuItem";
import LoadingOverlap from "components/LoadingOverlap";

import styles from "./styles.module.scss";

export const getDatabaseItems = ({
  databases,
  popoverRef,
  renderChildren,
}: {
  databases: Database[];
  popoverRef: RefObject<HTMLDivElement>;
  renderChildren: (catalogName: string) => React.ReactElement;
}) => {
  const databaseItems = databases.map(database => {
    const { catalogName } = database;
    const children = renderChildren(catalogName);
    return (
      <NestedContextMenuItem
        value={catalogName}
        key={catalogName}
        text={catalogName}
        anchorElement={popoverRef}
      >
        <Suspense
          fallback={
            <div className={styles.loader}>
              <LoadingOverlap isLoading />
            </div>
          }
        >
          {children}
        </Suspense>
      </NestedContextMenuItem>
    );
  });
  return databaseItems;
};
