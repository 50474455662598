import { useSuspenseQuery } from "@tanstack/react-query";

import { OrganizationService } from "services/organization";
import { ReactQueryKeysGlobal } from "services/queryKeys";

export const useIsOrganizationCreator = () => {
  const { data } = useSuspenseQuery({
    queryKey: [ReactQueryKeysGlobal.isOrganizationCreator],
    queryFn: () => {
      return OrganizationService.isOrganizationCreator();
    },
  });
  return data;
};
