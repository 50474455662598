import { useState } from "react";

import { DATABASE_TAB, formatTabMessages } from "./utils";

export const useTabs = (isLoading?: boolean) => {
  const [selectedTab, setSelectedTab] = useState(DATABASE_TAB);

  const selectTab = (tab: string) => {
    if (isLoading) {
      return;
    }
    setSelectedTab(tab);
  };

  const {
    tabHeader,
    tabDescription,
    toggleTypes = [],
  } = formatTabMessages(selectedTab);

  return {
    selectTab,
    selectedTab,
    tabHeader,
    tabDescription,
    toggleTypes,
  };
};
