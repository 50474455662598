import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ErrorWithTranslationKey,
  getSafeErrorMessage,
} from "utils/getSafeErrorMessage";

import { Account } from "services/account/account.types";
import { createAccount } from "services/account/createAccount";
import { deleteAccount } from "services/account/deleteAccount";
import { updateAccount } from "services/account/updateAccount";
import { Region } from "services/organization";
import { ReactQueryKeysGlobal } from "services/queryKeys";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

interface FormFields {
  name: string;
  region: string;
}

const isAccount = (account: Account | {}): account is Account => {
  return Object.keys(account).includes("accountName");
};

export const useAccountActions = ({
  account,
  regions,
  onClose,
  onCreate,
}: {
  account: Account | {};
  regions: Region[];
  onClose?: () => void;
  onCreate?: (account: { accountName: string }) => void;
}) => {
  const defaultRegion = regions[0].name;
  const { t } = useTranslation();
  const { putStatusMessage } = useStatusMessageQueue();
  const queryClient = useQueryClient();
  const editMode = isAccount(account);

  const [formWasEdited, setFormWasEdited] = useState(false);
  const [fieldsErrors, setFieldsErrors] = useState<FormFields>({
    name: "",
    region: "",
  });
  const [loading, setLoading] = useState(false);

  const [formFields, setFormFields] = useState<FormFields>(
    editMode
      ? { name: account.accountName, region: account.region }
      : { name: "", region: defaultRegion }
  );

  const createMutation = useMutation<Account, ErrorWithTranslationKey>({
    mutationFn: () => {
      return createAccount(formFields.name, formFields.region);
    },
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      putStatusMessage({
        message: t("accounts.form.create.success", {
          accountName: formFields.name,
        }),
        type: StatusMessageType.Success,
      });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeysGlobal.accounts],
        }),
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeysGlobal.myAccounts],
        }),
      ]);
      setLoading(false);
      onClose && onClose();
      onCreate && onCreate({ accountName: formFields.name });
    },
    onError: e => {
      putStatusMessage({
        message: getSafeErrorMessage(e, t),
        type: StatusMessageType.Error,
      });
      setLoading(false);
    },
  });

  const updateMutation = useMutation<Account, ErrorWithTranslationKey>({
    mutationFn: () => {
      if (isAccount(account)) {
        return updateAccount(account.accountName, formFields.name);
      }

      return Promise.reject(
        new ErrorWithTranslationKey("accounts.form.update.error")
      );
    },
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.accounts],
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.myAccounts],
      });

      putStatusMessage({
        message: t("accounts.form.update.success", {
          accountName: formFields.name,
        }),
        type: StatusMessageType.Success,
      });

      setLoading(false);
      onClose && onClose();
    },
    onError: e => {
      putStatusMessage({
        message: getSafeErrorMessage(e, t),
        type: StatusMessageType.Error,
      });
      setLoading(false);
    },
  });

  const deleteMutation = useMutation<unknown, ErrorWithTranslationKey>({
    mutationFn: () => {
      return deleteAccount((account as Account).accountName, true);
    },
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      putStatusMessage({
        message: t("accounts.form.delete.success", {
          accountName: formFields.name,
        }),
        type: StatusMessageType.Success,
      });

      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.accounts],
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysGlobal.myAccounts],
      });
      setLoading(false);
      onClose && onClose();
    },
    onError: e => {
      putStatusMessage({
        message: getSafeErrorMessage(e, t),
        type: StatusMessageType.Error,
      });
      setLoading(false);
    },
  });

  const validateForm = useCallback(() => {
    if (!formFields.name) {
      setFieldsErrors(errors => ({
        ...errors,
        name: t("accounts.form.validation.account_name.required"),
      }));
      return false;
    }

    // checks if first character is not a lowercase alphabetic character
    if (!/^[a-z]/.test(formFields.name)) {
      setFieldsErrors(errors => ({
        ...errors,
        name: t("accounts.form.validation.account_name.start_with_alpha"),
      }));
      return false;
    }

    // checks if the last character is not a lowercase alphanumeric character
    if (!/[a-z0-9]$/.test(formFields.name)) {
      setFieldsErrors(errors => ({
        ...errors,
        name: t("accounts.form.validation.account_name.end_with_alpha_num"),
      }));
      return false;
    }

    // account name must contain only lower alphanumeric characters and hyphens
    if (!/^[a-z](?:[a-z0-9]*-?[a-z0-9]+)*$/.test(formFields.name)) {
      setFieldsErrors(errors => ({
        ...errors,
        name: t("accounts.form.validation.account_name.invalid"),
      }));
      return false;
    }

    // check of the length is greater than 30
    if (formFields.name.length > 30) {
      setFieldsErrors(errors => ({
        ...errors,
        name: t("accounts.form.validation.account_name.max_length"),
      }));
      return false;
    }

    // check if the length is less than 3
    if (formFields.name.length < 3) {
      setFieldsErrors(errors => ({
        ...errors,
        name: t("accounts.form.validation.account_name.min_length"),
      }));
      return false;
    }

    setFieldsErrors({ name: "", region: "" });
    return true;
  }, [formFields, t, setFieldsErrors]);

  useEffect(() => {
    if (formWasEdited) {
      validateForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- deps are ok here
  }, [formFields, formWasEdited]);

  const isStateDirty = () => {
    if (editMode) {
      return (
        formFields.name !== (account as Account).accountName ||
        formFields.region !== (account as Account).region
      );
    }

    return true;
  };

  return {
    editMode,
    isStateDirty,
    loading,
    updateMutation,
    createMutation,
    deleteMutation,
    formFields,
    fieldsErrors,
    setFormFields,
    validateForm,
    formWasEdited,
    setFormWasEdited,
  };
};
