import { useSuspenseQuery } from "@tanstack/react-query";

import { OrganizationService, Region } from "services/organization";
import { ReactQueryKeysGlobal } from "services/queryKeys";

export const useRegions = (): Region[] => {
  const { data } = useSuspenseQuery({
    queryKey: [ReactQueryKeysGlobal.regions],
    queryFn: async () => {
      const data = await OrganizationService.availableRegions();
      return data.regions;
    },
  });
  return data || [];
};
