const template = `/* ***************************** */
/* Step 1: Create External Table */
/* ***************************** */ 
CREATE EXTERNAL TABLE IF NOT EXISTS <external_table_name> 
  ( 
    <col_name> <col_type> -- for example: my_column String
    -- add more columns using the above syntax
  ) 
  URL = 's3://<path_to_s3_objects>'
  CREDENTIALS = ( AWS_KEY_ID = '<your_aws_key_id>' AWS_SECRET_KEY = '<your_aws_secret_key>' )
  OBJECT_PATTERN = <pattern_regex> -- <pattern_regex> examples: '*', '*.parquet', '*/year=2020/*', '*.csv'
  TYPE = ( { CSV | JSON | PARQUET } ); -- choose the file type stored in S3


/* ****************************** */
/* Step 2: Create Firebolt Tables */
/* ****************************** */

/*
Choosing which tables to define as FACT or DIMENSION is similar to what is common in dimensional modeling. 
FACT tables should be your large and most granular/transactional tables, while DIMENSION tables should be the tables that are more descriptive in nature and typically joined with the FACT tables.

FACT tables are sharded across the nodes in the cluster, while DIMENSION tables are fully replicated across the nodes. 
When performing joins, the local shard of a FACT table on each node is joined with the local copy of the DIMENSION table. 

To create FACT table go to step 2.1, and for DIMENSION table - to step 2.2
*/

-- Step 2.1: Create FACT table
/* *************************** */
CREATE FACT TABLE IF NOT EXISTS <firebolt_table_name>
(
    <column_name> <column_type> 
    -- add more columns using the above syntax  
)
/*
Primary index speeds up query processing. 
Use the list of fields you are most likely to query by
*/
PRIMARY INDEX <column_name>, <another_column_name>, ... <add_as_many_columns_as_needed>

-- Step 2.2: Create DIMENSION table
/* ******************************** */
CREATE DIMENSION TABLE IF NOT EXISTS <firebolt_table_name>
(
    <column_name> <column_type> 
    -- add more columns using the above syntax  
)

/* ********************************************** */
/* step 3: (Optional) Create an aggregating index */
/* ********************************************** */
/*
Use an aggregating index to speed up queries involves grouping and aggregations. 
Aggregating indexes are supported for FACT tables.
The index will be auto populated in the next step
*/
CREATE AGGREGATING INDEX IF NOT EXISTS <unique_agg_index_name> ON <fact_table_name>
(
    -- Add as many columns as required for the index
    -- Choose the columns that will be used in your group by statements
    <column_name1>
    <column_name2>
    -- Add aggregations
    -- Choose the aggregations which will be performed when running your queries
    sum(column_to_sum)
    count(distinct column_to_count) 
    -- add more aggregations, as required
);

/* *************************************************** */
/* Step 4: Ingest data to your FACT & DIMENSION tables */
/* *************************************************** */
--Run the below command for each table created during step 2 (to which you would like to ingest data).
INSERT INTO <firebolt_table_name> SELECT * FROM <external_table_name>;
`;

export default template;
