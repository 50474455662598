import { systemEngineEnvironment } from "services/environment/systemEngine";

export const getDatabaseDefinitions = (database: {
  databaseName: string;
  description: string;
}) => {
  const descriptionSql = database.description
    ? systemEngineEnvironment.formatter.formatQuery("\nWITH DESCRIPTION = ?", [
        database.description,
      ])
    : "";

  const createDatabaseSql =
    `CREATE DATABASE "${database.databaseName}"` + descriptionSql;
  return createDatabaseSql;
};

export const createWorkspaceDatabase = async (database: {
  databaseName: string;
  description: string;
}) => {
  const sql = getDatabaseDefinitions(database);
  await systemEngineEnvironment.execute(sql);
};
