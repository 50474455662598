import { StateEffect, StateField, Transaction } from "@codemirror/state";
import { gutter } from "@codemirror/view";
import { Slice } from "utils/helpers/Query/splitSQLQuery";

import MarkerSpacer from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/extensions/MarkerSpacer";
import StatementMarker from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/extensions/StatementMarker";

interface State {
  slice: Slice | null;
}

export const StatementGutterMarkerEffect = StateEffect.define<State>();

export const StatementGutterMarkerState: StateField<State> =
  StateField.define<State>({
    create: () => {
      return {
        slice: null,
      };
    },
    update: (state: State, transaction: Transaction) => {
      for (const e of transaction.effects) {
        if (e.is(StatementGutterMarkerEffect)) {
          return e.value;
        }
      }

      return state;
    },
  });

export const findSliceAtPosition = (slices: Slice[], position: number) => {
  for (let i = 0; i < slices.length; i++) {
    const slice = slices[i];
    if (slice.pos[0] <= position && slice.pos[1] >= position) {
      return slice;
    }
  }
  return null;
};

export const StatementGutterMarkerDecorator = gutter({
  lineMarkerChange: update => {
    const hasStatementGutterEffect = update.transactions.some(tr =>
      tr.effects.some(e => e.is(StatementGutterMarkerEffect))
    );

    return hasStatementGutterEffect;
  },
  initialSpacer: () => {
    return new MarkerSpacer();
  },
  lineMarker(view, line) {
    const slice = view.state.field(StatementGutterMarkerState).slice;

    if (!slice) return null;

    const isStatementOnLine =
      line.from >= slice.pos[0] && line.to <= slice.pos[1];
    const isStart = slice.pos[0] >= line.from && slice.pos[0] <= line.to;
    const isEnd = slice.pos[1] >= line.from && slice.pos[1] <= line.to;

    if (isStart || isEnd || isStatementOnLine) {
      return new StatementMarker(isStart, isEnd);
    }

    return null;
  },
});
