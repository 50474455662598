import classNames from "classnames";
import { useTranslation } from "react-i18next";

import DatabaseIcon from "assets/images/database/Database.svg?react";
import EngineIcon from "assets/images/engineTypes/Engine.svg?react";

import InputField from "components/Input";

import { Footer } from "./Footer";

import styles from "./styles.module.scss";

type Props = {
  roleName: string;
  handleRoleNameChange: (name: string, value: string) => void;
  selectTab: (tab: string) => void;
  selectedTab: string;
  title: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  disableNameChange?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: string;
  nameError: string | null;
};

export const Side = (props: Props) => {
  const {
    roleName,
    handleRoleNameChange,
    selectTab,
    selectedTab,
    title,
    readOnly,
    disableNameChange,
    onClose,
    onConfirm,
    confirmText = "",
    nameError,
    isDisabled = false,
    isLoading = false,
  } = props;

  const { t } = useTranslation();
  const handleClick = (tab: string) => () => {
    selectTab(tab);
  };

  const tabs = [
    {
      type: "database",
      title: t("roles.tabs.database.title"),
      description: "",
      icon: <DatabaseIcon />,
    },
    {
      type: "engine",
      title: t("roles.tabs.engine.title"),
      description: "",
      icon: <EngineIcon />,
    },
  ];

  return (
    <div className={styles.side}>
      <div className={styles.side__header}>
        <h2>{title}</h2>
        <InputField
          errorText={nameError || ""}
          testId="role-name"
          disabled={readOnly || disableNameChange || isLoading}
          type="text"
          name="role"
          value={roleName}
          label={t("roles.tabs.name")}
          onChange={handleRoleNameChange}
          fullWidth
        />
      </div>
      <div className={styles.tabs}>
        <div className={styles.tabs__header}>Role privileges:</div>
        {tabs.map(tab => {
          const { title, description, type, icon } = tab;
          return (
            <div
              key={type}
              className={classNames(styles.tab, {
                [styles.tab__selected]: selectedTab === type,
              })}
              onClick={handleClick(type)}
              data-testid={`tab-${type}`}
            >
              <div className={styles.tab__icon}>{icon}</div>
              <div className={styles.tab__label}>
                <div className={styles.tab__title}>{title}</div>
                <div className={styles.tab__description}>{description}</div>
              </div>
            </div>
          );
        })}
      </div>
      {!readOnly && (
        <Footer
          onClose={onClose}
          onConfirm={onConfirm}
          isLoading={isLoading}
          isDisabled={isDisabled}
          confirmText={confirmText}
        />
      )}
    </div>
  );
};
