import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ReactQueryKeysAccount } from "services/queryKeys";
import { deleteRole } from "services/rbac/deleteRole";

import {
  ConfirmationModal,
  ObjectType,
} from "components/ConfirmationModal/ConfirmationModal";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  const [deleteRoleName, setDeleteRoleName] = useState<string | null>(null);
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: deleteRole,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });
      setDeleteRoleName(null);
    },
    onError: async (error: Error) => {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });
    },
  });

  const handleClose = () => {
    setDeleteRoleName(null);
  };

  const handleConfirm = () => {
    if (isLoading) {
      return;
    }
    if (deleteRoleName) {
      mutate(deleteRoleName);
    }
  };

  const deleteRoleMarkup = deleteRoleName ? (
    <ConfirmationModal
      title={t("workspace.delete_role.title")}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isLoading={isLoading}
      confirmButtonOptions={{ text: t("workspace.delete_role.confirm") }}
      message={
        <Trans
          i18nKey="workspace.delete_role.message"
          components={{ br: <br /> }}
        />
      }
      objectName={deleteRoleName}
      objectType={ObjectType.Role}
    />
  ) : null;

  const openDeleteRole = (name: string) => {
    setDeleteRoleName(name);
  };

  return {
    deleteRoleMarkup,
    deleteRole: openDeleteRole,
  };
};
