export const SYSTEM_QUERY_PARAMETERS = [
  "engine",
  "database",
  "account_id",
  "output_format",
];

export const FIREBOLT_UPDATE_PARAMETER_HEADER = "firebolt-update-parameters";

export const FIREBOLT_UPDATE_ENDPOINT_HEADER = "firebolt-update-endpoint";
export const FIREBOLT_PROTOCOL_VERSION_HEADER = "Firebolt-Protocol-Version";

// each query generated by UI and sent to the user engine must have this label
export const FIREBOLT_UI_USER_ENGINE_QUERY_LABEL = "go.firebolt.io query";
