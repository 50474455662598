import { Formatter } from "utils/helpers/Format";

type Props = {
  duration: number | undefined;
};

const DurationCell = (props: Props) => {
  const { duration } = props;

  if (typeof duration !== "number") return null;

  const parsedElapsedTimeShort = Formatter.secondsToTimeString(
    duration,
    false,
    false,
    true
  );

  return <span>{parsedElapsedTimeShort}</span>;
};

export default DurationCell;
