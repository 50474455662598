import classNames from "classnames";
import React, { useContext } from "react";

import { TableContext, WidthContext } from "./context";

import styles from "./styles.module.scss";

type CellProps = {
  id: string;
  noBorder?: boolean;
  fillAvailableWidth?: boolean;
  className?: string;
  children?: React.ReactNode;
  dataTestId?: string;
};

export const Cell: React.FC<CellProps> = props => {
  const { children, id, dataTestId, noBorder, className, fillAvailableWidth } =
    props;
  const { leadingWidth, columnWidths } = useContext(WidthContext);
  const { floatingColumn } = useContext(TableContext);
  const width = columnWidths[id];
  const lastColumnWidth = `calc(100% - ${leadingWidth}px)`;
  const style = {
    minWidth: width,
    width: fillAvailableWidth ? lastColumnWidth : width,
  };

  if (id === floatingColumn) {
    Object.assign(style, {
      position: "sticky",
      right: 0,
      background: `linear-gradient(90deg, rgba(255,255,255,0) 1%, var(--table-cell-background-color) 25%)`,
    });
  }

  return (
    <div
      style={style}
      className={classNames(styles.cell, className, {
        [styles.noBorder]: noBorder,
      })}
      data-role="cell"
      data-testid={dataTestId || id}
    >
      <div className={styles.text}>{children}</div>
    </div>
  );
};
