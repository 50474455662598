import { splitSQLQuery } from "utils/helpers/Query/splitSQLQuery";

export function getQueryList(sqlText: string) {
  return splitSQLQuery(sqlText);
}

export function formatQuery(query: string): string {
  // remove tail with "; "
  // eslint-disable-next-line no-param-reassign -- legacy code
  query = query.replace(/;\s*$/, "").trim();

  return query;
}

const FOUR_SPACES = "\u0020\u0020\u0020\u0020";

export const formatErrorForHtml = (error: string) => {
  try {
    // eslint-disable-next-line no-param-reassign -- legacy code
    error = error.replace(/\t/gm, FOUR_SPACES);
    return error;
  } catch (e) {
    return error;
  }
};