import { MeteringRecord } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/EngineMonitoring";
import { RESULTS_COLUMNS_MAP } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/constants";
import { Series } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/types";

interface DataValuesRaw {
  date: Date;
  values: number[];
}

export type AggregationFunctions<DataKey> = {
  [Key in keyof DataKey]: (values: number[]) => number;
};

export const createSeries = <DataKey>(
  result: MeteringRecord[],
  availableMetrics: DataKey[],
  aggregationFunctions: AggregationFunctions<DataKey>,
  clusters?: string[]
): Series<DataKey>[] => {
  return availableMetrics.map(key => {
    const resultKey = key as keyof typeof RESULTS_COLUMNS_MAP;

    const data: DataValuesRaw[] = [];

    result.forEach((d: MeteringRecord) => {
      if (clusters && clusters.length) {
        if (!clusters.includes(d.cluster_ordinal)) {
          return;
        }
      }

      const key = RESULTS_COLUMNS_MAP[resultKey];

      const item = data.find(
        e => e.date.getTime() === new Date(d.timestamp_c).getTime()
      );

      if (!item) {
        data.push({
          date: new Date(d.timestamp_c),
          values: [+d[key as keyof MeteringRecord]],
        });
      } else {
        item.values.push(+d[key as keyof MeteringRecord]);
      }
    });

    return {
      type: key,
      data: data.map(d => ({
        date: d.date,
        value: aggregationFunctions[key as keyof DataKey](d.values),
      })),
    };
  });
};
