import { useTooltipPosition } from "@visx/tooltip";
import cn from "classnames";
import React from "react";

import styles from "components/reports/charts/Chart/TooltipContent/TooltipContent.module.scss";

export type TooltipData<DataKey extends string> = {
  date: Date;
  data: Record<DataKey, number>;
};

interface TooltipContentProps<DataKey extends string> {
  data: TooltipData<DataKey>;
  tooltipTimeFormater: (date: Date) => string;
  tooltipTimeLabel: string;
  dataKeyNames: Record<DataKey, string>;
  dataKeyColors: Record<DataKey, string>;
  dataKeyValueFormatter: (
    key: DataKey,
    value: number
  ) => {
    value: string;
    unit: string;
    unitPosition: "before" | "after";
  };
}

const TooltipContent = <DataKey extends string>(
  props: TooltipContentProps<DataKey>
) => {
  const {
    data,
    tooltipTimeFormater,
    tooltipTimeLabel,
    dataKeyNames,
    dataKeyColors,
    dataKeyValueFormatter,
  } = props;

  const { isFlippedHorizontally } = useTooltipPosition();

  return (
    <>
      <div
        className={cn(styles.tooltipArrow, {
          [styles.flippedHorizontally]: isFlippedHorizontally,
        })}
      />
      <div className={styles.tooltipContent}>
        <div className={styles.group}>
          {Object.keys(dataKeyNames)
            .filter(key => data.data[key as DataKey] !== undefined)
            .map(key => {
              const formattedValue = dataKeyValueFormatter(
                key as DataKey,
                data.data[key as DataKey]
              );

              return (
                <div
                  key={key}
                  className={styles.item}
                >
                  <div className={styles.label}>
                    <div
                      className={styles.icon}
                      style={{ backgroundColor: dataKeyColors[key as DataKey] }}
                    />

                    <div className={styles.text}>
                      {dataKeyNames[key as DataKey]}
                    </div>
                  </div>
                  <div className={styles.value}>
                    {formattedValue.unitPosition === "before" && (
                      <span className={styles.units}>
                        {formattedValue.unit}
                      </span>
                    )}
                    {formattedValue.value}
                    {formattedValue.unitPosition === "after" && (
                      <span className={styles.units}>
                        {formattedValue.unit}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>

        <div className={cn(styles.group, styles.datetime)}>
          <div className={styles.item}>
            <div className={styles.label}>
              <div className={styles.text}>{tooltipTimeLabel}</div>
            </div>
            <div className={styles.value}>{tooltipTimeFormater(data.date)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TooltipContent;
