export const BrowserNames = {
  chrome: "chrome",
  firefox: "firefox",
  safari: "safari",
  opera: "opera",
  edge: "edge",
};

export const isMac = navigator && navigator.appVersion.indexOf("Mac") !== -1;

export const getBrowserName = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return BrowserNames.chrome;
  }

  if (userAgent.match(/firefox|fxios/i)) {
    return BrowserNames.firefox;
  }

  if (userAgent.match(/safari/i)) {
    return BrowserNames.safari;
  }

  if (userAgent.match(/opr\//i)) {
    return BrowserNames.opera;
  }

  if (userAgent.match(/edg/i)) {
    return BrowserNames.edge;
  }

  return "unknown";
};
