import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { CommonHeaderName } from "../../components/HeaderName";

import headerStyles from "../../components/HeaderName.module.scss";
import styles from "./QueryStatementsTable.module.scss";

type Props = {
  headers: { tCode: string; className: string }[];
};

const StatisticsHeader = (props: Props) => {
  const { headers } = props;
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        headerStyles.headerContainer,
        styles.headerContainer
      )}
    >
      {headers.map((header, idx) => (
        <div
          key={`statistics-header-cell-${idx}`} // eslint-disable-line react/no-array-index-key -- it's ok here
          className={classNames(headerStyles.header__column, header.className)}
        >
          <CommonHeaderName text={t(header.tCode)} />
        </div>
      ))}
    </div>
  );
};

export default StatisticsHeader;
