import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "utils/routes.constants";

import { useCreditsFlag } from "featureFlags/hooks/useCreditsFlag";
import { useFormatPath } from "hooks/useFormatPath";
import { useAccounts } from "services/account/useAccounts";
import { useHasAccountAdminRole } from "services/auth/useHasAccountAdminRole";
import { useHasAdminRole } from "services/auth/useHasAdminRole";

import Logo from "assets/icons/Logo.svg?react";
import SqlIcon from "assets/icons/mainMenuIcons/Develop.svg?react";
import GovernIcon from "assets/icons/mainMenuIcons/Govern.svg?react";
import ManagementIcon from "assets/icons/mainMenuIcons/Management.svg?react";

import { accessManager } from "components/App/accessManager";
import MenuItem from "components/MainSidebar/MenuItem/MenuItem";
import { UserBarWithAccounts } from "components/MainSidebar/UserBar/UserBar";
import { Show } from "components/Show/Show";
import { useSupportAgentAccess } from "components/SupportAgent/useSupportAgentAccess";
import ThemeSwitcher from "components/ThemeSwitcher/ThemeSwitcher";

import { AQA_LINK_HEADER, AQA_SQLWORKSPACE } from "./AqaIds";
import { CreditIndicator } from "./CreditIndicator/CreditIndicator";
import HelpBar from "./HelpBar/HelpBar";
import { MainSidebarGuest } from "./MainSidebarGuest";
import { SupportAccess } from "./SupportAccess/SupportAccess";
import { useShowMenuItems } from "./useShowMenuItems";

import styles from "./MainSidebar.module.scss";

export const MainSidebarUser = () => {
  const location = useLocation();
  const accounts = useAccounts();
  const hasAdminRole = useHasAdminRole();
  const hasAccountAdminRole = useHasAccountAdminRole();
  const formatPath = useFormatPath();
  const menuItemsVisibility = useShowMenuItems();

  const sqlPath = formatPath(ROUTES.DEVELOP);
  const configurePath = formatPath(ROUTES.CONFIGURE);
  const governPath = formatPath(ROUTES.GOVERN);

  const {
    supportAgentAccessMarkup,
    openSupportAgentAccess,
    openSupportAgentStep,
  } = useSupportAgentAccess();
  const showCreditsIndicator = useCreditsFlag();

  if (!accounts.length || !accessManager.privileges) {
    return <MainSidebarGuest />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.sidebarHead}>
        <Link
          key="app_header"
          to={sqlPath}
          data-testid={AQA_LINK_HEADER}
          className={styles.logo}
        >
          <Logo />
        </Link>
        <UserBarWithAccounts />

        <div>
          <MenuItem
            className={styles.item}
            testId={AQA_SQLWORKSPACE}
            icon={<SqlIcon />}
            tooltip="Develop"
            disabled={false}
            selected={location.pathname.indexOf(sqlPath) >= 0}
            to={sqlPath}
          />
          <Show condition={hasAdminRole || hasAccountAdminRole}>
            <MenuItem
              className={styles.item}
              icon={<ManagementIcon />}
              tooltip="Configure"
              testId="configure-menu"
              disabled={false}
              selected={location.pathname.indexOf(configurePath) >= 0}
              to={configurePath + (hasAdminRole ? "/accounts" : "/account")}
            />
          </Show>
          {menuItemsVisibility.govern && (
            <MenuItem
              className={styles.item}
              icon={<GovernIcon />}
              tooltip="Govern"
              testId="govern-menu"
              disabled={false}
              selected={location.pathname.indexOf(governPath) >= 0}
              to={governPath + menuItemsVisibility.governRoute}
            />
          )}
        </div>
      </div>
      <div className={styles.sidebarFooter}>
        {showCreditsIndicator && <CreditIndicator />}
        <SupportAccess openSupportAgentStep={openSupportAgentStep} />
        <HelpBar
          openSupportAgentAccess={openSupportAgentAccess}
          hasAdminRole={hasAccountAdminRole || hasAdminRole}
        />
        <ThemeSwitcher />
        {supportAgentAccessMarkup}
      </div>
    </div>
  );
};
