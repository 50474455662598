import {
  Script,
  ScriptsContextState,
} from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import {
  ScriptType,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const getDocumentScript = (
  document: WorkspaceDocument,
  scriptsState: ScriptsContextState
): Script | null => {
  // TODO add here one more output for error description?
  const scriptStorage =
    document.script.type === ScriptType.local
      ? scriptsState.localScripts
      : scriptsState.remoteScripts;

  const scriptState = scriptStorage[document.script.id];

  if (!scriptState) {
    return null;
  }

  const script = scriptState?.script;

  if (!script) {
    return null;
  }

  return script;
};

export { getDocumentScript };
