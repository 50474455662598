import {
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const isDocumentRunning = (document: WorkspaceDocument) => {
  const execution = document.execution;

  if (!execution) {
    return false;
  }

  const queryStatements = execution.queryStatements;

  if (!queryStatements || queryStatements.length === 0) {
    return false;
  }

  const someQueryIsRunning = queryStatements.some(queryStatement =>
    [QueryStatementStatus.running, QueryStatementStatus.pending].includes(
      queryStatement.status
    )
  );

  return someQueryIsRunning;
};

export { isDocumentRunning };
