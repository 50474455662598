import React, { useContext } from "react";

import { DiagramContext } from "../DiagramContext";
import { PADDING } from "../constants";

import styles from "./styles.module.scss";

type Props = {
  handlePointerDown: (event: React.MouseEvent) => void;
};

export const Viewport: React.FC<Props> = props => {
  const { handlePointerDown } = props;
  const { transform, container, minimap } = useContext(DiagramContext);
  const { x, y, k } = transform;

  const containerRect = container.current.getBoundingClientRect();

  const viewportWidth = (containerRect.width / k) * minimap.k;
  const viewportHeight = (containerRect.height / k) * minimap.k;

  const left = (PADDING - x / k) * minimap.k;
  const top = (PADDING - y / k) * minimap.k;

  const style = {
    width: viewportWidth,
    height: viewportHeight,
    top,
    left,
  };

  return (
    <div
      className={styles.viewport}
      style={style}
      onPointerDown={handlePointerDown}
    />
  );
};
