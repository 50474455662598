import cn from "classnames";
import React, { useCallback } from "react";

import Tooltip from "components/Tooltip/Tooltip";

import styles from "./HeaderName.module.scss";

interface Props {
  text: string;
  tooltip?: React.ReactNode;
  additionalText?: string;
  // todo: fix any
  headerItemRef?: React.Ref<any>;
  onClick?: () => void;
  iconComponent?: React.ReactNode | null;
  filtersDropdown?: React.ReactNode | null;
  draggingComponents?: React.ReactNode | null;
}
export const CommonHeaderName = (props: Props) => {
  const {
    text,
    tooltip,
    headerItemRef,
    onClick,
    iconComponent,
    filtersDropdown,
    draggingComponents,
    additionalText,
  } = props;

  const handleHeaderTextMouseDown = useCallback(
    (event: React.SyntheticEvent) => {
      event.nativeEvent.stopImmediatePropagation();
      event.stopPropagation();
    },
    []
  );

  const content = (
    <div
      className={styles.header__name}
      data-testid="datagrid-header-cell-name"
      onMouseDown={handleHeaderTextMouseDown}
    >
      <div className={styles.name__text}>
        <span className={styles.name__main}>{text}</span>
        {!!additionalText && (
          <span className={styles.name__additional_text}>{additionalText}</span>
        )}
      </div>
      {iconComponent}
      {filtersDropdown}
    </div>
  );

  return (
    <div
      className={cn(styles.header, {
        [styles.clickable]: !!onClick,
      })}
      data-role="name"
      ref={headerItemRef}
      onClick={onClick}
      data-testid={`sort-column-${text}`}
    >
      {tooltip ? (
        <Tooltip
          title={tooltip}
          placement="top-start"
        >
          {content}
        </Tooltip>
      ) : (
        content
      )}
      {draggingComponents}
    </div>
  );
};
