import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import DocumentObjectExplorerCollapseIcon from "assets/icons/DocumentObjectExplorerCollapseIcon.svg?react";
import DocumentOutputCollpaseIcon from "assets/icons/DocumentOutputCollpaseIcon.svg?react";
import PlusIcon from "assets/icons/PlusIcon.svg?react";
import TabsMenuIcon from "assets/icons/TabsMenuIcon.svg?react";

import Tab, { TabStatus } from "pages/DevelopWorkspace/Editor/TabsBar/Tab/Tab";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { useDocumentsActionConfirmation } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocumentsActionConfirmation";
import { useScripts } from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { documentHasErrors } from "pages/DevelopWorkspace/helpers/documentHasError";
import { documentHasResults } from "pages/DevelopWorkspace/helpers/documentHasResults";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";
import { isDocumentRunning } from "pages/DevelopWorkspace/helpers/isDocumentRunning";
import {
  ScriptType,
  SortOrder,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuHeader from "components/ContextMenu/ContextMenuHeader";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { useLayoutToggles } from "components/LayoutToggles/context";

import styles from "./TabsBar.module.scss";

const getDocumentStatus = (document: WorkspaceDocument) => {
  if (isDocumentRunning(document)) {
    return TabStatus.Running;
  }

  if (documentHasErrors(document)) {
    return TabStatus.Error;
  }

  if (documentHasResults(document)) {
    return TabStatus.Success;
  }

  return undefined;
};

const TabsBar = () => {
  const {
    state,
    actions: {
      createDocument,
      setActiveDocument,
      renameDocument,
      changeSortOrder,
    },
  } = useDocuments();
  const { handleDeleteDocument, handleDeleteAllDocuments, modalMarkup } =
    useDocumentsActionConfirmation();
  const [tabWidth, setTabWidth] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const tabRefs = useRef<Record<string, HTMLDivElement>>({});
  const { layout, setLayout } = useLayoutToggles();
  const { state: scriptsState } = useScripts();
  const tabsMenuAnchorEl = useRef<HTMLDivElement | null>(null);
  const [tabsMenuOpen, setTabsMenuOpen] = useState<boolean>(false);

  const { documents, activeDocumentId, sortOrder } = state;

  const sortedDocuments = [...documents].sort((a, b) => {
    if (sortOrder === SortOrder.NewestFirst) {
      return b.createdAt - a.createdAt;
    }

    return a.createdAt - b.createdAt;
  });

  const updateTabWidth = () => {
    const MAX_TAB_WIDTH = 140;
    const DEFAULT_TAB_WIDTH = MAX_TAB_WIDTH;
    const MIN_TAB_WIDTH = 70;
    const TAB_MARGIN = 2;
    const ADD_BUTTON_WIDTH = 40;

    const tabsContainer = tabsContainerRef.current;

    if (!tabsContainer) {
      setTabWidth(DEFAULT_TAB_WIDTH);
      return;
    }

    const tabsContainerWidth = tabsContainer.clientWidth - ADD_BUTTON_WIDTH;

    const width = Math.min(
      MAX_TAB_WIDTH,
      Math.max(
        MIN_TAB_WIDTH,
        tabsContainerWidth / sortedDocuments.length - TAB_MARGIN
      )
    );

    setHasScroll(tabsContainerWidth < sortedDocuments.length * width);

    setTabWidth(width);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateTabWidth();
    });

    resizeObserver.observe(tabsContainerRef.current!);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- it's ok here
  }, [sortedDocuments.length, tabsContainerRef.current]);

  useLayoutEffect(() => {
    updateTabWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- it's ok here
  }, [
    tabsContainerRef.current,
    sortedDocuments.length,
    layout.leftSidebar.expanded,
  ]);

  useEffect(() => {
    if (!activeDocumentId) {
      return;
    }

    const activeTabElement = tabRefs.current[activeDocumentId];

    if (activeTabElement) {
      setTimeout(() => {
        activeTabElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }, 0);
    }
  }, [activeDocumentId, sortOrder]);

  const activeDocument = documents.find(
    document => document.id === activeDocumentId
  );

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.tabsWrapper}>
          <div
            className={styles.tabs}
            ref={tabsContainerRef}
          >
            {sortedDocuments.map((document, index) => {
              const scriptStorage =
                document.script.type === ScriptType.local
                  ? scriptsState.localScripts
                  : scriptsState.remoteScripts;

              const scriptState = scriptStorage[document.script.id];

              const script = getDocumentScript(document, scriptsState);

              const status = getDocumentStatus(document);

              return (
                <div
                  key={document.id}
                  className={cn(styles.tab, {
                    [styles.lastScrollableTab]:
                      hasScroll && index === sortedDocuments.length - 1,
                  })}
                  data-testid={`document-tab-${script?.title}`}
                  style={{
                    width: tabWidth + "px",
                  }}
                  ref={ref => {
                    if (!ref) {
                      return;
                    }

                    tabRefs.current[document.id] = ref;
                  }}
                >
                  <Tab
                    isLoadingScript={!script}
                    title={script?.title}
                    content={script?.content}
                    isUnsaved={scriptState?.isUnsaved}
                    isActive={document.id === activeDocumentId}
                    onSelect={() => setActiveDocument(document.id)}
                    onClose={() => {
                      handleDeleteDocument(document.id);

                      //  removeDocument(document.id);
                    }}
                    onRenameSubmit={(newTitle: string) => {
                      renameDocument(document.id, newTitle);
                    }}
                    status={status}
                  />
                </div>
              );
            })}

            <div className={styles.addButtonWrapper}>
              <div
                className={styles.addButton}
                onClick={() => {
                  createDocument({
                    context: activeDocument
                      ? {
                          databaseName: activeDocument.context.databaseName,
                          engineName: activeDocument.context.engineName,
                        }
                      : undefined,
                  });
                }}
                data-testid="add-script-btn"
              >
                <div className={styles.icon}>
                  <PlusIcon />
                </div>
              </div>
            </div>
          </div>

          {hasScroll && <div className={styles.gradientBoundaryRight} />}
        </div>
      </div>

      <div className={styles.iconsWrapper}>
        <div
          className={styles.icon}
          ref={tabsMenuAnchorEl}
          onClick={() => {
            setTabsMenuOpen(true);
          }}
          data-testid="other-tabs-menu-icon"
        >
          <TabsMenuIcon />
        </div>
        <div
          className={cn(styles.icon, {
            [styles.active]: layout.leftSidebar.expanded,
          })}
          onClick={() => {
            setLayout({
              ...layout,
              leftSidebar: {
                ...layout.leftSidebar,
                expanded: !layout.leftSidebar.expanded,
              },
            });
          }}
          data-testid="toggle-workspace-left-sidebar"
        >
          <DocumentObjectExplorerCollapseIcon />
        </div>
        <div
          id={styles.outputCollapseIcon}
          className={cn(styles.icon, {
            [styles.active]: layout.results.expanded,
            [styles.disabled]: !activeDocument || !activeDocument.execution,
          })}
          data-testid="toggle-workspace-results"
          onClick={() => {
            setLayout({
              ...layout,
              results: {
                ...layout.results,
                expanded: !layout.results.expanded,
              },
            });
          }}
        >
          <DocumentOutputCollpaseIcon />
        </div>
      </div>

      {tabsMenuOpen && (
        <Popover
          open={tabsMenuOpen}
          anchorEl={tabsMenuAnchorEl.current}
          classes={{ paper: styles.tabsMenuPaper }}
          onClose={() => {
            setTabsMenuOpen(false);
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ContextMenu>
            <ContextMenuItem
              key="close-all"
              text="Close all tabs"
              testId="close-all-tabs"
              // secondaryText="Shortcut + 1"
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                handleDeleteAllDocuments();
              }}
            />
            <ContextMenuDivider />
            <ContextMenuItemsGroup
              maxHeight={300}
              isGroup={true}
            >
              {sortedDocuments.map(document => {
                const script = getDocumentScript(document, scriptsState);

                return (
                  <ContextMenuItem
                    checked={document.id === activeDocumentId}
                    key={document.id}
                    text={script?.title || ""}
                    testId={`script-tab-menu-item-${script?.title}`}
                    onClick={e => {
                      e.stopPropagation();
                      setTabsMenuOpen(false);
                      setActiveDocument(document.id);
                    }}
                  />
                );
              })}
            </ContextMenuItemsGroup>
            <ContextMenuDivider />
            <ContextMenuHeader text="Sort tabs by:" />
            <ContextMenuItem
              key="newest-first"
              text="Newest first"
              testId="sort-tabs-newest-first"
              checked={sortOrder === SortOrder.NewestFirst}
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                changeSortOrder(SortOrder.NewestFirst);
              }}
            />

            <ContextMenuItem
              key="newest-last"
              text="Newest last"
              testId="sort-tabs-newest-last"
              checked={sortOrder === SortOrder.NewestLast}
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                changeSortOrder(SortOrder.NewestLast);
              }}
            />
            {/*  <ContextMenuDivider />
            <ContextMenuItem
              key="keyboard-shortcuts"
              text="Keyboard shortcuts"
              //secondaryText="Shortcut + 1"
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
              }}
            /> */}
          </ContextMenu>
        </Popover>
      )}

      {modalMarkup}
    </div>
  );
};

export default TabsBar;
