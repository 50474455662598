export const QUERY_HISTORY_SQL_QUERY = `WITH qh_recent_status AS (
    SELECT qh.*,
            ROW_NUMBER() OVER (PARTITION BY query_id ORDER BY CASE 
WHEN qh.status = 'STARTED_EXECUTION' THEN 0
ELSE 1 END DESC) AS rn
    FROM information_schema.engine_query_history qh
)
  
SELECT *
EXCLUDE rn
FROM qh_recent_status
where rn = 1
order by start_time DESC;`;
