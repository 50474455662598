import { useTranslation } from "react-i18next";

import { useTableNames } from "services/databases/useTableNames";

import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { WithSearch } from "components/OutlinedSelect/OutlinedSelect";

import { ROLES_WIZARD_SET_RESOURCE_OBJECTS } from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { SelectItems } from "./SelectItems";
import { ALL_TABLES, ALL_TABLES_ALL_DATABASES, ANY_TABLE } from "./constant";

type Props = {
  catalogName: string;
  index: number;
  resource: string;
};

export const TablesItems = (props: Props) => {
  const { index, catalogName, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const tables = useTableNames({ database: catalogName });
  const { t } = useTranslation();

  const groupState = privilegesState.table[index];
  const { objects } = groupState;

  const selectedTables = objects
    .filter(object => object.catalogName === catalogName)
    .map(object => object.name);

  const filterBulkResources = (item: { name: string; catalogName: string }) => {
    if (item.name === ALL_TABLES_ALL_DATABASES) {
      return false;
    }
    if (item.catalogName !== catalogName) {
      return true;
    }
    if (item.catalogName === catalogName) {
      return item.name !== ALL_TABLES && item.name !== ANY_TABLE;
    }
  };

  const filterDatabaseResources = (item: {
    name: string;
    catalogName: string;
  }) => {
    if (item.name === ALL_TABLES_ALL_DATABASES) {
      return false;
    }
    if (item.catalogName !== catalogName) {
      return true;
    }
    return false;
  };

  const handleBulkResource = (name: string) => {
    const selectedObjects = objects.filter(filterDatabaseResources);

    privilegesDispatch({
      type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
      resource,
      objects: selectedTables.includes(name)
        ? [...selectedObjects]
        : [...selectedObjects, { catalogName, name }],
      index,
    });
  };

  const handleResource = (table: { tableName: string }) => {
    const selectedObjects = selectedTables.includes(table.tableName)
      ? objects.filter(object => {
          if (object.catalogName !== catalogName) {
            return true;
          }
          return object.name !== table.tableName;
        })
      : [...objects, { name: table.tableName, catalogName }].filter(
          filterBulkResources
        );

    privilegesDispatch({
      type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
      resource,
      index,
      database: catalogName,
      objects: selectedObjects,
    });
  };

  const items = [
    <ContextMenuItem
      value={ALL_TABLES}
      key={ALL_TABLES}
      checked={selectedTables.includes(ALL_TABLES)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.all_tables")}
      direction="left"
      bulkItem
      skipFilter
      onClick={() => {
        handleBulkResource(ALL_TABLES);
      }}
    />,
    <ContextMenuItem
      value={ANY_TABLE}
      key={ANY_TABLE}
      checked={selectedTables.includes(ANY_TABLE)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.any_table")}
      direction="left"
      bulkItem
      skipFilter
      onClick={() => {
        handleBulkResource(ANY_TABLE);
      }}
    />,
    <ContextMenuDivider key="divider" />,
    ...tables.map(table => {
      return (
        <ContextMenuItem
          key={table.tableName}
          value={table.tableName}
          text={table.tableName}
          direction="left"
          checked={selectedTables.includes(table.tableName)}
          onClick={() => {
            handleResource(table);
          }}
        />
      );
    }),
  ];

  return (
    <WithSearch
      items={items}
      searchOptions={{
        searchPlaceholder: t("roles_wizard.privileges.search_table"),
      }}
    >
      <SelectItems />
    </WithSearch>
  );
};
