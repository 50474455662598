import React, { useContext } from "react";

import { PrivilegesErrors, PrivilegesState } from "./types";

type PrivilegesContextType = {
  privilegesState: PrivilegesState & PrivilegesErrors;
  privilegesDispatch: (action?: any) => void;
};

export const PrivilegesContext = React.createContext<PrivilegesContextType>({
  privilegesState: {
    engine: [],
    account: [],
    database: [],
    table: [],
    view: [],
    schema: [],
    permissionErrors: {
      table: [],
      view: [],
      schema: [],
    },
  },
  privilegesDispatch: () => {},
});

export const usePrivilegesState = (): PrivilegesContextType => {
  const context = useContext(PrivilegesContext);
  return context;
};
