import {
  Divider,
  List,
  Popover,
  PopoverOrigin,
  PopoverPosition,
  PopoverReference,
} from "@mui/material";
import classNames from "classnames";
import React from "react";

import styles from "./ActionMenu.module.scss";

export interface Props {
  lists: React.ReactNode[][];
  open: boolean;
  anchorEl?: HTMLElement | null;
  anchorReference?: PopoverReference;
  anchorPosition?: PopoverPosition;
  onClose: (e: { target: EventTarget & HTMLElement }) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  testId?: string;
  classes?: {
    paper?: string;
  };
}

const ActionMenu = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    lists,
    open,
    anchorEl,
    anchorPosition,
    onClose,
    anchorOrigin,
    anchorReference,
    transformOrigin,
    testId,
    classes = {},
  } = props;

  return (
    <Popover
      open={open}
      data-testid={testId}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
        ...transformOrigin,
      }}
      classes={{
        paper: classNames(styles.paper, classes?.paper),
      }}
      anchorEl={anchorEl}
      anchorReference={anchorReference}
      anchorPosition={anchorPosition}
      onClose={onClose}
      ref={ref}
    >
      <List
        component="nav"
        classes={{ root: styles.list }}
      >
        {lists.flatMap((item, index) => {
          if (index > 0) {
            return [
              <Divider
                key={`divider-${index}`} // eslint-disable-line react/no-array-index-key -- it's ok here
                classes={{ root: styles.divider }}
              />,
              item,
            ];
          }

          return item;
        })}
      </List>
    </Popover>
  );
});

export default ActionMenu;
