import classNames from "classnames";
import trimEnd from "lodash/trimEnd";
import trimStart from "lodash/trimStart";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { File, FileTree } from "services/fileObjects/getFilesByPath";
import { useFiles, useRootFiles } from "services/fileObjects/useFiles";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import { Search } from "components/LeftSidebar/Search";
import { Step } from "components/Wizard/Step/Step";

import { FileTable } from "../FileTable/FileTable";
import { IngestionStep, SelectDataStep, WizardDataType } from "../types";
import { useEnsureEngineRunning } from "./useEnsureEngineRunning";
import {
  generateBreadcrumbs,
  getCompressionFromFileName,
  getExtensionFromFileName,
  getFileTypeFromExtension,
  getLastPathPart,
  mapObjects,
} from "./utils";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onSubmit: (data: SelectDataStep) => void;
  wizardData: WizardDataType;
  onPrevStep: () => void;
  activeStepIndex: number;
};

export const SelectData = (props: Props) => {
  const { onClose, onSubmit, onPrevStep, wizardData, activeStepIndex } = props;
  const { t } = useTranslation();
  useEnsureEngineRunning(wizardData);
  const awsData = wizardData[IngestionStep.selectAWS] ?? {};
  const engineData = wizardData[IngestionStep.selectEngine];
  const { storageUrl } = awsData;

  const [, bucketProtocol, bucketName, rootFolderMatch = "/"] =
    storageUrl.match(/(.+):\/\/(.+?)(\/.+)?$/) || [];

  const rootFolder =
    rootFolderMatch === "/"
      ? rootFolderMatch
      : trimStart(trimEnd(rootFolderMatch, "/"), "/");
  const [openedFolder, setOpenedFolder] = useState(rootFolder);
  const [selectedFiles, setSelectedFiles] = useState(
    wizardData[IngestionStep.selectData]?.selectedFiles ?? new Set<string>()
  );
  const [loadingItems, setLoadingItems] = useState(new Set<string>([]));

  const [searchQuery, setSearchQuery] = useState("");
  const { data: engines = [] } = useWorkspaceEngines(true);

  const engine = engines.find(
    e => e.engineName === engineData?.engine?.engineName
  );

  const onFormSubmit = () => {
    const files = [...selectedFiles].map(file => {
      const name = getLastPathPart(file);
      const extension = getExtensionFromFileName(name);
      const fileType = getFileTypeFromExtension(extension);
      const compression = getCompressionFromFileName(name);
      return {
        name,
        extension: fileType,
        compression,
        url: `${bucketProtocol}://${trimEnd(bucketName, "/")}/${file}`,
      };
    });
    onSubmit({
      selectedFiles,
      files,
    });
  };

  const rootObjects = useRootFiles({
    bucketName,
    bucketProtocol,
    rootFolder,
    auth: awsData,
    engine,
  });

  const [objects, setObjects] = useState<Record<string, File[]>>({
    [rootFolder]: rootObjects,
  });

  const { loadFiles } = useFiles({
    bucketName,
    bucketProtocol,
    auth: awsData,
    engine,
  });

  const folderFiles = objects[openedFolder];
  const flatFiles = mapObjects({
    objects: folderFiles,
    openedFolder,
    rootFolder,
    bucketProtocol,
    bucketName,
  });

  const filteredFiles = searchQuery
    ? flatFiles.filter(file =>
        file.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    : flatFiles;

  const handleOpenItem = async (path: string) => {
    try {
      setLoadingItems(loadingItems => new Set(loadingItems.add(path)));
      const files = (await loadFiles(path)) ?? [];
      setObjects(objects => {
        return {
          ...objects,
          [path]: files,
        };
      });
    } finally {
      setLoadingItems(prev => {
        const next = new Set(prev);
        next.delete(path);
        return next;
      });
    }
    setOpenedFolder(path);
    setSearchQuery("");
  };

  const toggleSelectItem = (path: string) => {
    if (selectedFiles.has(path)) {
      setSelectedFiles(new Set());
    } else {
      setSelectedFiles(new Set([path]));
    }
  };

  const breadrumbs = generateBreadcrumbs(rootFolder, openedFolder);

  const handleBreadCrumbClick = async ({ index }: { index: number }) => {
    const newPath = breadrumbs.slice(0, index + 1).join("/");
    const newPathFormatted = newPath === rootFolder ? newPath : `${newPath}/`;

    if (newPathFormatted === openedFolder) {
      return;
    }
    await handleOpenItem(newPathFormatted);
  };

  const body = (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        {!!breadrumbs.length && (
          <Breadcrumbs
            breadcrumbs={breadrumbs}
            handleBreadCrumbClick={handleBreadCrumbClick}
          />
        )}
        <div
          className={classNames(styles.search, {
            [styles.withBreadcrumbs]: !!breadrumbs.length,
          })}
        >
          <Search
            onChange={setSearchQuery}
            value={searchQuery}
            autoFocus={true}
            size="small"
            noBorder
            placeholder={
              breadrumbs.length
                ? t("wizard.select_data.search_short")
                : t("wizard.select_data.search")
            }
            testId="data-search-file-name"
          />
        </div>
      </div>
      <FileTable
        items={filteredFiles as FileTree[]}
        selectedItems={selectedFiles}
        loadingItems={loadingItems}
        handleOpenItem={handleOpenItem}
        toggleSelectItem={toggleSelectItem}
        searchQuery={searchQuery}
      />
    </div>
  );

  return (
    <Step
      title={t("wizard.select_data.title")}
      subtitle={t("wizard.select_data.subtitle")}
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      onPrevStep={onPrevStep}
      disabledSubmit={selectedFiles.size < 1}
      activeStepIndex={activeStepIndex}
    />
  );
};
