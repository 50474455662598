import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VariableSizeGrid as Grid } from "react-window";

import { useFilters } from "components/Datagrid/hooks/useFilters";
import {
  Column,
  ColumnWithProps,
  FAKE_SPACER,
  Row,
} from "components/Datagrid/types";
import { getFieldTextAlign } from "components/Datagrid/utils/getFieldTextAlign";
import { getInitialFieldWidth } from "components/Datagrid/utils/getInitialFieldWidth";

import {
  CELL_FONT,
  CELL_PADDING,
  DATE_WIDTH,
  DEFAULT_COLUMN_WIDTH,
  FILTER_ICON,
  HEADER_FONT,
  MAX_TEXT_COLUMN_WIDTH,
  ROW_HEIGHT,
  SAMPLE_SIZE,
  SCROLLBAR_WIDTH,
  SORT_ICON,
  TIMESTAMP_WIDTH,
  TYPE_ICON,
} from "../constant";

const getRowHeight = () => ROW_HEIGHT;

const spacer = {
  type: FAKE_SPACER,
  _typeUpperCase: FAKE_SPACER,
  name: "__firebolt_fake_spacer",
  width: 0,
  initialWidth: 0,
  textAlign: "left",
};

const INITIAL_FIELD_WIDTHS = {
  CELL_FONT,
  CELL_PADDING,
  DEFAULT_COLUMN_WIDTH,
  HEADER_FONT,
  MAX_TEXT_COLUMN_WIDTH,
  SORT_ICON,
  TIMESTAMP_WIDTH,
  DATE_WIDTH,
  TYPE_ICON,
  FILTER_ICON,
};

const getInitialColumns = ({
  rows,
  fields,
  hiddenColumnsByDefault,
}: {
  rows: Row[];
  fields: Column[];
  hiddenColumnsByDefault?: string[];
}) => {
  const rowSample = rows.slice(0, SAMPLE_SIZE);
  const filteredFields = fields.filter(
    field => !hiddenColumnsByDefault?.includes(field.name)
  );
  const fieldsWithProps = filteredFields.map(field => {
    const _typeUpperCase = field?.type?.toUpperCase();
    const fieldWithType = { ...field, _typeUpperCase };

    const width = getInitialFieldWidth({
      field: fieldWithType,
      rowSample,
      constants: INITIAL_FIELD_WIDTHS,
    });

    const textAlign = getFieldTextAlign({
      field: fieldWithType,
    });

    return {
      ...field,
      _typeUpperCase,
      width,
      initialWidth: width,
      textAlign,
    };
  });

  fieldsWithProps.push(spacer);
  return fieldsWithProps;
};

const getInitialVisibleColumns = (
  columns: ColumnWithProps[],
  hiddenColumnsByDefault: string[]
) => {
  const columnsWithoutSpacer = columns.filter(c => c.name !== spacer.name);
  const visibleColumns = columnsWithoutSpacer.filter(
    c => !hiddenColumnsByDefault.includes(c.name)
  );

  if (visibleColumns.length === columnsWithoutSpacer.length) return [];

  return visibleColumns.map(c => c.name);
};

export const useTable = ({
  data,
  fields,
  gridRef,
  dimentions,
  hiddenColumnsByDefault = [],
}: {
  data: Row[];
  fields: Column[];
  gridRef: React.MutableRefObject<Grid | null>;
  dimentions: React.MutableRefObject<{ width: number; height: number }>;
  hiddenColumnsByDefault?: string[];
}) => {
  const originalRows = useRef<Row[]>(data);

  const [rows, setRows] = useState<Row[]>(data);

  const [columns, setColumns] = useState<ColumnWithProps[]>(() =>
    getInitialColumns({ rows, fields, hiddenColumnsByDefault })
  );

  // when visibleColumns is empty, all columns are visible
  const [visibleColumns, setVisibleColumns] = useState<string[]>(() =>
    getInitialVisibleColumns(
      getInitialColumns({ rows, fields }),
      hiddenColumnsByDefault
    )
  );

  const filterTableColumns = (columnName: string) => {
    const newVisibleColumns = visibleColumns.includes(columnName)
      ? visibleColumns.filter(visibleColName => visibleColName !== columnName)
      : [...visibleColumns, columnName];
    setVisibleColumns(newVisibleColumns);

    if (!newVisibleColumns.length) {
      setColumns(getInitialColumns({ rows, fields }));
      return;
    }

    const filteredFields = fields.filter(field =>
      newVisibleColumns.includes(field.name)
    );

    setColumns(getInitialColumns({ rows, fields: filteredFields }));
  };

  const toggleShowAllColumns = () => {
    setVisibleColumns([]);
    setColumns(getInitialColumns({ rows, fields }));
  };

  const rowCount = rows.length;
  const columnCount = columns.length;

  const leadingWidth = useMemo(() => {
    return columns
      .filter(column => column?.type !== FAKE_SPACER)
      .reduce((acc, column) => {
        return acc + column.width;
      }, 0);
  }, [columns]);

  const handleResize = useCallback(
    (index: number, width: number) => {
      let resetIndex = 0;
      let widthChanged = false;
      const newColumns = columns.map((column, columnIndex) => {
        if (index === columnIndex) {
          resetIndex = index;
          widthChanged = column.width !== width;
          return {
            ...column,
            width,
          };
        }
        return column;
      });
      setColumns(newColumns);
      if (widthChanged) {
        gridRef.current?.resetAfterColumnIndex(resetIndex);
      }
    },
    [columns, gridRef]
  );

  const getColumnWidth = useCallback(
    (index: number) => {
      const column = columns[index];
      if (index === columns.length - 1) {
        const width = dimentions.current.width;
        return Math.max(width - leadingWidth - SCROLLBAR_WIDTH, column.width);
      }
      return column.width;
    },
    [columns, leadingWidth, dimentions]
  );

  const removeSpacer = useCallback(() => {
    if (columns[columns.length - 1]?.type === FAKE_SPACER) {
      setColumns(columns => {
        const newColumns = columns.slice(0, -1);
        return newColumns;
      });
    }
  }, [columns]);

  const addSpacer = useCallback(() => {
    if (columns[columns.length - 1]?.type === FAKE_SPACER) {
      return;
    }
    setColumns(columns => [...columns, spacer]);
  }, [columns]);

  const filtersContext = useFilters({
    setRows,
    setColumns,
    columns,
    originalRows,
  });

  useEffect(() => {
    originalRows.current = data;
    filtersContext.applyFiltersRef.current();
    // force update when data changes
  }, [data, filtersContext]);

  return {
    rows,
    columns,
    rowCount,
    columnCount,
    handleResize,
    getRowHeight,
    getColumnWidth,
    addSpacer,
    removeSpacer,
    leadingWidth,
    filtersContext,

    visibleColumns,
    toggleShowAllColumns,
    filterTableColumns,
  };
};
