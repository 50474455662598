import * as Sentry from "@sentry/browser";
import BigNumber from "bignumber.js";

export const sizeUnits = {
  // keep order for Object.keys
  TiB: 1024 ** 4,
  GiB: 1024 ** 3,
  MiB: 1024 ** 2,
  KiB: 1024,
  B: 1,
};

const getPluralField = (key: string, value: number) => {
  if (value > 1 || value === 0) {
    return key;
  }
  return {
    seconds: "second",
    minutes: "minute",
    hours: "hour",
    days: "day",
  }[key];
};

function nFormatter(num: number, digits: number) {
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];
  let i;
  for (i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return `${(num / si[i].value).toFixed(digits)}${si[i].symbol}`;
    }
  }
  return num.toFixed(digits);
}
export const BYTES_HELPER = {
  kiloByte: 1024,
  sizes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
};
function bytesFormatter(
  bytes: number | string,
  decimals?: number
): { size: number; notation: string; formattedString: string } {
  if (bytes === 0 || bytes === "0") {
    return {
      size: 0,
      notation: BYTES_HELPER.sizes[0],
      formattedString: `0 ${BYTES_HELPER.sizes[0]}`,
    };
  }
  const dm = Number.isInteger(decimals) ? decimals : 2;
  const numBytes = +bytes;
  const i = Math.floor(Math.log(numBytes) / Math.log(BYTES_HELPER.kiloByte));
  if (numBytes < BYTES_HELPER.kiloByte)
    return {
      size: numBytes,
      notation: BYTES_HELPER.sizes[0],
      formattedString: "< 1KB",
    };
  const size = parseFloat(
    String((numBytes / BYTES_HELPER.kiloByte ** i).toFixed(dm))
  );
  const notation = BYTES_HELPER.sizes[i];
  return {
    size,
    notation,
    formattedString: `${size} ${notation}`,
  };
}

function fillDecimals(numberString: string, length: number): string {
  if (!numberString.includes(".")) return numberString;

  const [, decimals] = numberString.split(".");
  const decimalsFill = new Array(length - decimals.length).fill("0");

  return numberString + decimalsFill.join("");
}

function formatNumberToLocale(
  numberString: string,
  decimalPoint?: number,
  minimumFractionDigits?: number
): string {
  const length = Number.isInteger(decimalPoint) ? decimalPoint : 2;
  const num = parseFloat(numberString).toLocaleString("en", {
    maximumFractionDigits: length,
    minimumFractionDigits,
  });
  return fillDecimals(num, length as number);
}

function secondsToTimeString(
  rawSeconds: number,
  countDays: boolean = true,
  hideSeconds: boolean = true,
  short: boolean = false
): string {
  const secondsStr = short ? "s" : " seconds";

  if (typeof rawSeconds !== "number" && !BigNumber.isBigNumber(rawSeconds)) {
    const error = new Error(
      `Got wrong data type for seconds: expect number but got ${typeof rawSeconds}, ${rawSeconds}`
    );
    Sentry.withScope(scope => {
      scope.setExtras({
        inputData: { seconds: rawSeconds, countDays, hideSeconds },
      });
      Sentry.captureException(error);
    });
    console.error(error);
    return `0${secondsStr}`;
  }

  let seconds = BigNumber.isBigNumber(rawSeconds)
    ? rawSeconds.toNumber()
    : rawSeconds;

  if (seconds < 60) {
    if (Number.isInteger(seconds)) {
      return `${seconds}${secondsStr}`;
    }

    return `${seconds.toFixed(2)}${secondsStr}`;
  }

  const res = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (countDays) {
    res.days = Math.floor(seconds / (3600 * 24));
    seconds -= res.days * (3600 * 24);
  }

  res.hours = Math.floor(seconds / 3600);
  seconds -= res.hours * 3600;

  res.minutes = Math.floor(seconds / 60);
  seconds -= res.minutes * 60;

  res.seconds = Math.floor(seconds);

  return Object.entries(res)
    .filter(([, val]) => {
      return !!val;
    })
    .filter(([key]) => {
      if (hideSeconds && key === "seconds") {
        return false;
      }

      return true;
    })
    .map(([key, val]) => {
      const field = getPluralField(key, val) || "";

      return `${val}${short ? field.slice(0, 1) : ` ${field}`}`;
    })
    .join(", ");
}

export function parseBool(val: string) {
  return !!JSON.parse(String(val).toLowerCase());
}

export const Formatter = {
  nFormatter,
  bytesFormatter,
  secondsToTimeString,
  formatNumberToLocale,
};
