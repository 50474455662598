import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { setOrganizationOnboardingCompleted } from "services/organization/setOrganizationOnboarding";

import Button, { ButtonSize, ButtonTemplate } from "components/Button";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { OnboardingDialog } from "../OnboardingDialog";
import { useOnboarding } from "../useOnboarding";
import DriveImage from "./drive.svg?react";

import styles from "./styles.module.scss";

const AUTOFINISH_TIMEOUT = 10 * 1000;

export const WelcomeCreditsDescription = () => {
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();
  const autofinishTimeout = useRef<NodeJS.Timeout | null>(null);

  const primaryButton = useRef<HTMLElement | null>(null);

  const handleDialogEnter = useCallback(() => {
    if (primaryButton.current) {
      primaryButton.current.focus();
    }
  }, []);

  const { nextStep } = useOnboarding();

  const TransitionProps = useMemo(() => {
    return {
      onEntered: handleDialogEnter,
    };
  }, [handleDialogEnter]);

  const handleAutoFinish = useCallback(async () => {
    autofinishTimeout.current && clearTimeout(autofinishTimeout.current);
    try {
      await setOrganizationOnboardingCompleted();
    } catch (error: any) {
      putStatusMessage({
        message: error.response?.data?.detail || error.message,
        type: StatusMessageType.Error,
      });
    }
  }, [putStatusMessage]);

  const handleFinishOnboarding = () => {
    nextStep();
  };

  useEffect(() => {
    autofinishTimeout.current = setTimeout(() => {
      handleAutoFinish();
    }, AUTOFINISH_TIMEOUT);
    return () => {
      autofinishTimeout.current && clearTimeout(autofinishTimeout.current);
    };
  }, [handleAutoFinish]);

  return (
    <OnboardingDialog
      dataTestId="onboarding-marketplace-dialog"
      image={<DriveImage />}
      title={<>{t("onboarding.welcome_credits.title")}</>}
      body={<>{t("onboarding.welcome_credits.body")}</>}
      actions={
        <div className={styles.actions}>
          <Button
            template={ButtonTemplate.Primary}
            size={ButtonSize.Medium}
            text={t("onboarding.welcome_credits.cta")}
            onClick={handleFinishOnboarding}
            className={styles.connect}
            dataTestid="credits-get-started"
          />
        </div>
      }
      TransitionProps={TransitionProps}
      onClose={handleFinishOnboarding}
    />
  );
};
