import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import { ReactQueryKeysAccount } from "../queryKeys";
import { WorkspaceEngine, WorkspaceEngineStatus } from "./engine.types";
import { startWorkspaceEngine } from "./startWorkspaceEngine";

export const useStartWorkspaceEngine = () => {
  const queryClient = useQueryClient();
  const { putStatusMessage } = useStatusMessageQueue();
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: startWorkspaceEngine,
    onMutate: async (engineName: string) => {
      await queryClient.cancelQueries({
        queryKey: [ReactQueryKeysAccount.workspaceEngines],
      });
      const previousEngines = queryClient.getQueryData([
        ReactQueryKeysAccount.workspaceEngines,
      ]);

      queryClient.setQueryData<WorkspaceEngine[]>(
        [ReactQueryKeysAccount.workspaceEngines],
        engines => {
          return (engines || []).map(engine => {
            if (engine.engineName === engineName) {
              return {
                ...engine,
                status: WorkspaceEngineStatus.STARTING,
              };
            }
            return engine;
          });
        }
      );

      return { previousEngines };
    },
    onError: async (error: Error) => {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeysAccount.workspaceEngines],
        }),
      ]);
    },
  });

  return {
    mutate,
    isLoading,
  };
};
